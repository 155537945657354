import React, { useState } from 'react';
import { NavLink } from 'reactstrap';
import { useSelector } from 'react-redux';
import { CContainer, CNavbar, CNavbarToggler, CCollapse, CNavbarNav, CNavItem } from '@coreui/bootstrap-react';
import { NavLink as RRNavLink } from 'react-router-dom';
import * as auditsService from '../../services/auditsService';

function AdministrationNavMenu() {
    const [visible, setVisible] = useState(false);

    const auth = useSelector(state => state.auth);
    const userPermissions = useSelector(state => state.auth.permissions);

    const handleNavClickLogging = (pageName) => {
        auditsService.logPageView(pageName);
    }

    return (
        <header className="oasis-rounded h-100">
            <CNavbar className="top-nav oasis-rounded h-100" expand="md" colorScheme="dark" toggleable="md">
                <CContainer fluid className="h-100">
                    <h2 className="ps-4 mb-0 list-header admin-header media-controlled">
                        Administration
                    </h2>
                    <CNavbarToggler onClick={ () => setVisible(!visible) } className="me-2" />
                    <CCollapse className="navbar-collapse" visible={ visible }>
                        <CNavbarNav className="admin-nav navbar-nav media-controlled ms-md-n2 ms-lg-3 ms-xl-5">
                            { auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewUserStatus") ?
                                <CNavItem className="mx-md-auto me-xl-5 adminNavItem media-controlled">
                                    <NavLink onClick={() => handleNavClickLogging("UserStatus")} tag={RRNavLink} to="/Administration/UserStatus">USER STATUS</NavLink>
                                </CNavItem>
                                : ""
                            }
                            { auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewAudits") ?
                                <CNavItem className="mx-md-auto me-xl-5 adminNavItem media-controlled">
                                    <NavLink onClick={ () => handleNavClickLogging("Audits") } tag={ RRNavLink } to="/Administration/Audits">AUDITS</NavLink>
                                </CNavItem>
                                : ""
                            }
                            { auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewRoles") ?
                                <CNavItem className="mx-md-auto me-xl-5 adminNavItem media-controlled">
                                    <NavLink onClick={ () => handleNavClickLogging("Roles") } tag={ RRNavLink } to="/Administration/Roles">ROLES</NavLink>
                                </CNavItem>
                                : ""
                            }
                            { auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewAdminSettings") ?
                                <CNavItem className="mx-md-auto me-xl-5 adminNavItem media-controlled">
                                    <NavLink onClick={ () => handleNavClickLogging("Maintenance") } tag={ RRNavLink } to="/Administration/Settings">SETTINGS</NavLink>
                                </CNavItem>
                                : ""
                            }
                        </CNavbarNav>
                    </CCollapse>
                </CContainer>
            </CNavbar>
        </header>
    );

}

export default AdministrationNavMenu;