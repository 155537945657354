import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import * as pageActions from '../../../redux/actions/pageActions';
import * as userStatusesActions from '../../../redux/actions/userStatusesActions';
import * as ModalBackgroundRemover from '../../common/ModalBackgroundRemover';
import PaginatedList from '../../common/PaginatedList';
import FilterSlideout from '../../common/FilterSlideout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function UserStatusPage({ theme }) {

    const [columnFiltersPopulated, setColumnFiltersPopulated] = useState(false);

    const searchTerm = useSelector(state => state.page.userStatuses.searchTerm);
    const userPermissions = useSelector(state => state.auth.permissions);
    const filterByColumn = useSelector(state => state.page.userStatuses.filterByColumn);
    const textFilters = useSelector(state => state.page.userStatuses.textFilters);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userPermissions.some(p => p.name === "ViewUserStatus")) {
            dispatch(userStatusesActions.loadUserStatuses()).catch(error => {
                toast.error("Loading User Status Failed:" + error, { autoClose: false });
            });
        }
        ModalBackgroundRemover.removeModalBackground();
    }, []);

    useEffect(() => {
        let noFilters = true;
        for (const text in textFilters) {
            if (textFilters[text] != "") {
                noFilters = false;
                setColumnFiltersPopulated(true);
            }
        }
        if (noFilters) {
            setColumnFiltersPopulated(false);
        }
    }, [textFilters])

    const handleSearchTermChange = (event) => {
        dispatch(pageActions.updateSearchTerm("userStatuses", event.target.value));
    }

    const handleToggleFilterByColumn = () => {
        dispatch(pageActions.toggleFilterByColumn("userStatuses", !filterByColumn));
    }

    const handleClearFilterByColumn = () => {
        dispatch(pageActions.clearTextFilters("userStatuses"));
        setColumnFiltersPopulated(false);
    }

    return (
        <>
            <div id="view-all" className={ "d-flex flex-column oasis-rounded h-100 " + theme }>
                <div className="administration-search-container d-flex flex-row px-4">
                    <div className="d-flex ms-auto">
                        <div className="my-auto">
                            <a
                                onClick={ handleToggleFilterByColumn }
                                className={ theme + " column-filter-icon media-controlled" }
                                data-tip="Show/Hide Filters"
                                data-type={ theme }
                                data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                data-delay-show="500"
                                data-border={ true }
                            >
                                {
                                    filterByColumn ?
                                        <FontAwesomeIcon icon="fa-solid fa-filters" />
                                        : <FontAwesomeIcon icon="fa-regular fa-filters" />
                                }
                            </a>
                            <a
                                onClick={ handleClearFilterByColumn }
                                className={ theme + " column-filter-icon media-controlled" }
                                data-tip="Clear Filters"
                                data-type={ theme }
                                data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                data-delay-show="500"
                                data-border={ true }
                                data-event-off="click"
                            >
                                {
                                    columnFiltersPopulated ?
                                        <FontAwesomeIcon icon="fa-solid fa-filter-circle-xmark" />
                                        : <FontAwesomeIcon icon="fa-regular fa-filter-circle-xmark" />
                                }
                            </a>
                        </div>
                        <div className="my-auto me-3">
                            <FontAwesomeIcon className="searchIcon media-controlled" icon="fas fa-search" />
                            <input
                                onChange={ handleSearchTermChange }
                                id="search_term_userStatus"
                                className={ "search-input media-controlled" + theme }
                                autocomplete="off"
                                name="UserStatusSearchTerm"
                                type="text"
                                value={ searchTerm }
                            />
                        </div>
                    </div>
                </div>
                <div className="administration-list d-flex flex-row">
                    <FilterSlideout RecordType={ "userStatuses" } theme={ theme } />
                    <div className="d-flex flex-column w-100 h-100">
                        <PaginatedList RecordType="userStatuses" theme={ theme } />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserStatusPage;