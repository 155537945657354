import React, { useState, useEffect, useCallback } from 'react'
import * as pageActions from '../../redux/actions/pageActions';
import * as usersActions from '../../redux/actions/usersActions';
import * as usersService from '../../services/usersService';
import * as dateUtils from '../../utils/dateUtils';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/bootstrap-react'
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as bootstrap from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function UserDetails({ theme }) {

    const [activeTab, setActiveTab] = useState("Info");
    const [detailsModal, setDetailsModal] = useState();
    const [user, setUser] = useState(useSelector(state => state.page.users.selectedUser));

    const selectedUser = useSelector(state => state.page.users.selectedUser);
    const authServices = useSelector(state => state.services.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(pageActions.clearSelectedUser());
        };
    }, [])

    useEffect(() => {
        setUser(selectedUser);
        var detailsModal = document.getElementById('details-modal');
        detailsModal.addEventListener('hidden.bs.modal', () => {
            if (usersService.getUserAbortController[selectedUser.userId]) {
                usersService.getUserAbortController[selectedUser.userId].abort();
            }
            dispatch(pageActions.clearSelectedUser());
            setActiveTab("Info");
        });
        setDetailsModal(bootstrap.Modal.getInstance(document.getElementById('details-modal')));
    }, [selectedUser])

    const refreshUser = (userId) => {
        if (detailsModal && detailsModal._isShown) {
            usersService.getUser(userId)
                .then(returnedUser => {
                    dispatch(usersActions.loadUserSuccess(returnedUser));
                    setUser(returnedUser);
                    dispatch(pageActions.updateSelectedUser(returnedUser));
                })
                .catch(error => {
                    if (error.message != "canceled") {
                        toast.error("Loading Selected User Failed:" + error, { autoClose: false });
                    }
                });
        }
    }

    const checkUpdateRequired = useCallback(async (user) => {
        if (detailsModal && detailsModal._isShown) {
            if (await usersService.updateRequired(user)) {
                refreshUser(user.userId);
            }
        }
    }, [detailsModal]);

    useEffect(() => {
        const updateRequiredIntervalId = setInterval(() => checkUpdateRequired(user), 1000);
        return () => {
            clearInterval(updateRequiredIntervalId);
        };
    }, [checkUpdateRequired, user])

    useEffect(() => {
        if (selectedUser.userId) {
            refreshUser(selectedUser.userId);
        }
    }, [selectedUser.userId, detailsModal])

    return (
        <div className="modal fade" tabindex="-1" role="dialog" id="details-modal">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className={ "modal-content " + theme }>
                    <div className="modal-header">
                        <h2 className="modal-title">{ user.email }</h2>
                        <a className="close" data-bs-dismiss="modal" aria-label="Close">
                            <FontAwesomeIcon icon="fas fa-times" />
                        </a>
                    </div>
                    <div className="modal-body">
                        { user.userId ?
                            <div className="row">
                                <div className="col-md-12">
                                    <CNav className={ theme } variant="tabs" role="tablist">
                                        <CNavItem>
                                            <CNavLink
                                                active={ activeTab === "Info" }
                                                onClick={ () => setActiveTab("Info") }
                                            >
                                                Info
                                            </CNavLink>
                                        </CNavItem>
                                        { user.integrationServices.map(service => {
                                            return (
                                                <CNavItem>
                                                    <CNavLink
                                                        active={ activeTab === service.name }
                                                        onClick={ () => setActiveTab(service.name) }
                                                    >
                                                        { service.name }
                                                    </CNavLink>
                                                </CNavItem>
                                            )
                                        }) }
                                    </CNav>
                                    <CTabContent>
                                        <CTabPane role="tabpanel" aria-labelledby="details-info-tab" visible={ activeTab === "Info" }>
                                            <div className="container-fluid h-100">
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        First Name :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.firstName }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Last Name :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.lastName }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Email :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.email }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Partner :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.partnerName }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Created By :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.createdBy }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Created Date :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { dateUtils.getLocalDate(user.createdDate) } { dateUtils.getLocalTime(user.createdDate, { hour: '2-digit', minute: '2-digit' }) }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Last Modified By :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.lastModifiedBy }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Last Modified Date :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { dateUtils.getLocalDate(user.lastModifiedDate) } { dateUtils.getLocalTime(user.lastModifiedDate, { hour: '2-digit', minute: '2-digit' }) }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Last Login Date :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { dateUtils.getLocalDate(user.lastLoginDate) } { dateUtils.getLocalTime(user.lastLoginDate, { hour: '2-digit', minute: '2-digit' }) }
                                                    </div>
                                                </div>
                                            </div>
                                        </CTabPane>
                                        <CTabPane role="tabpanel" aria-labelledby="details-communications-tab" visible={ activeTab === "Communications" }>
                                            <div className="container-fluid h-100">
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        Point of Contact :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.pocs.length > 0 ? user.pocs.map(poc => {
                                                            return poc.name
                                                        }).join(', ') : "Not a Point of Contact" }
                                                    </div>
                                                </div>
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        Subscriptions :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.subscriptions.length > 0 ? user.subscriptions.map(subscription => {
                                                            return subscription.name
                                                        }).join(', ') : "No Subscriptions" }
                                                    </div>
                                                </div>
                                            </div>
                                        </CTabPane>
                                        <CTabPane role="tabpanel" aria-labelledby="details-login-tab" visible={ activeTab === "Login" }>
                                            <div className="container-fluid h-100">
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        Roles :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.roles.map(role => role.name).join(', ') }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Two Factor Auth :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.twoFactorMethodName }
                                                    </div>
                                                </div>
                                                { user.twoFactorMethodId && authServices.length > 0 ? authServices.find(s => s.twoFactorMethodId === user.twoFactorMethodId).claims.map(claim => {
                                                    return (
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                { claim.claimLabel } :
                                                            </div>
                                                            <div className="col-md-8">
                                                                { user.twoFactorMethodClaims.find(c => c.claimType === claim.claimType) ? user.twoFactorMethodClaims.find(c => c.claimType === claim.claimType).claimLabel : "" }
                                                            </div>
                                                        </div>
                                                    )
                                                }) : "" }
                                            </div>
                                        </CTabPane>
                                        <CTabPane role="tabpanel" aria-labelledby="details-support-tab" visible={ activeTab === "Support" }>
                                            <div className="container-fluid h-100">
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        Jira Groups :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { user.jiraGroups.length > 0 ? user.jiraGroups.map(jiraGroup => {
                                                            return jiraGroup.name
                                                        }).join(', ') : "No managed Jira Groups Associated to User" }
                                                    </div>
                                                </div>
                                            </div>
                                        </CTabPane>
                                    </CTabContent>
                                </div>
                            </div>
                            : "" }
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-new btn-block" data-bs-dismiss="modal">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDetails;