import * as types from "./types";
import * as permissionsService from "../../services/permissionsService";

export function loadPermissionsSuccess(permissions) {
    return { type: types.LOAD_PERMISSIONS_SUCCESS, permissions }
}

export function loadUserPermissionsSuccess(userPermissions) {
    return { type: types.LOAD_USER_PERMISSIONS_SUCCESS, userPermissions }
}

export function loadCanAssignRolesSuccess(canAssignRoles) {
    return { type: types.LOAD_CAN_ASSIGN_ROLES_SUCCESS, canAssignRoles }
}

export function loadUserRolesSuccess(userRoles) {
    return { type: types.LOAD_USER_ROLES_SUCCESS, userRoles }
}

export function loadPermissions() {
    return function (dispatch) {
        return permissionsService.getPermissions()
            .then(permissions => {
                dispatch(loadPermissionsSuccess(permissions));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadUserPermissions() {
    return function (dispatch) {
        return permissionsService.getUserPermissions()
            .then(userPermissions => {
                dispatch(loadUserPermissionsSuccess(userPermissions));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadCanAssignRoles() {
    return function (dispatch) {
        return permissionsService.getCanAssignRoles()
            .then(canAssignRoles => {
                dispatch(loadCanAssignRolesSuccess(canAssignRoles));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadUserRoles() {
    return function (dispatch) {
        return permissionsService.getUserRoles()
            .then(userRoles => {
                dispatch(loadUserRolesSuccess(userRoles));
            })
            .catch(error => {
                throw error;
            });
    };
}
