import * as types from '../actions/types';
import initialState from './InitialState';

export default function fileTransferStatsReducer(state = initialState.fileTransferStats, action) {
    switch (action.type) {
        case types.LOAD_FILE_TRANSFER_STATS_SUCCESS:
            return { ...state, statCards: action.fileTransferStats };
        case types.CLEAR_FILE_TRANSFER_STATS:
            return { ...state, statCards: initialState.fileTransferStats.statCards };
        case types.LOAD_PARTNER_PORTALS_SUCCESS:
            return { ...state, portals: action.portals };
        default:
            return state;
    }
}