import axios from 'axios'
import { config } from '../config';

export let getUserAbortController = [];

export async function updateRequired(userInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/user/update-required', userInfo);
    return response.data;
}

export async function getUser(userId) {
    getUserAbortController[userId] = new AbortController();
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/user?userId=' + userId, { signal: getUserAbortController[userId].signal }).catch(error => {
        if (error.message != "canceled" && error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function getUserPartnerName() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/user/partner-name');
    return response.data;
}

export async function getUsers() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/user/list');
    return response.data;
}

export async function disableUser(userInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/user/disable', userInfo).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function enableUser(userInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/user/enable', userInfo).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function resetUser2fa(userInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/user/reset2fa', userInfo).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error;
        }
    });
    return response.data;
}

export async function resetUserPassword(userInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/user/resetpassword', userInfo).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error;
        }
    });
    return response.data;
}

export async function saveUser(userInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/user/save', userInfo).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data
}

export async function updateUserActivity() {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/user/updateUserActivity').catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function getAllLoggedInUserStatuses() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/user/getAllLoggedInUserSessions');
    return response.data;
}

export async function massEditSubscriptions(massEditSubscriptionInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/user/massOperation/subscriptions', massEditSubscriptionInfo).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error;
        } else {
            throw error
        }
    });
    return response.data
}