import * as types from "./types";
import * as supportStatsService from "../../services/supportService";

export function loadSupportStatsSuccess(supportStats) {
    return { type: types.LOAD_SUPPORT_STATS_SUCCESS, supportStats }
}

export function clearSupportStats() {
    return { type: types.CLEAR_SUPPORT_STATS }
}

export function loadSupportUrlSuccess(supportUrl) {
    return { type: types.LOAD_SUPPORT_URL_SUCCESS, supportUrl }
}

export function reloadSupportStats() {
    return function (dispatch) {
        dispatch(clearSupportStats());
        dispatch(loadSupportStats());
    }
}

export function loadSupportStats() {
    return function (dispatch) {
        return supportStatsService.getSupportStats()
            .then(supportStats => {
                dispatch(loadSupportStatsSuccess(supportStats));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadSupportUrl() {
    return function (dispatch) {
        return supportStatsService.getSupportUrl()
            .then(supportUrl => {
                dispatch(loadSupportUrlSuccess(supportUrl));
            })
            .catch(error => {
                throw error;
            });
    };
}