export const STORE_USER = 'STORE_USER';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const USER_EXPIRED = 'USER_EXPIRED';
export const STORE_USER_ERROR = 'STORE_USER_ERROR';
export const LOADING_USER = 'LOADING_USER';
export const UPDATE_DATALIMIT_SETTING = 'UPDATE_DATALIMIT_SETTING';
export const UPDATE_SORTBY_SETTING = 'UPDATE_SORTBY_SETTING';
export const UPDATE_FILTER_SETTING = 'UPDATE_FILTER_SETTING';
export const LOAD_USER_AUTH_SUCCESS = 'LOAD_USER_AUTH_SUCCESS';
export const LOAD_KNOWLEDGEBASE_BOOKS_SUCCESS = 'LOAD_KNOWLEDGEBASE_BOOKS_SUCCESS';
export const LOAD_KNOWLEDGEBASE_PAGE_SUCCESS = 'LOAD_KNOWLEDGEBASE_PAGE_SUCCESS';
export const CLEAR_KB_SEARCH_RESULTS = 'CLEAR_KB_SEARCH_RESULTS';
export const UPDATE_SELECTED_KB_CONTENT = 'UPDATE_SELECTED_KB_CONTENT';
export const CLEAR_SELECTED_KB_CONTENT = 'CLEAR_SELECTED_KB_CONTENT';
export const SEARCH_KB_SUCCESS = 'SEARCH_KB_SUCCESS';
export const LOAD_KB_PDF_SUCCESS = 'LOAD_KB_PDF_SUCCESS';
export const UPDATE_RESOURCES = 'UPDATE_RESOURCES';
export const UPDATE_PREVIEW_RESOURCES = 'UPDATE_PREVIEW_RESOURCES';
export const UPDATE_SELECTED_RESOURCE = 'UPDATE_SELECTED_RESOURCE';
export const CLEAR_SELECTED_RESOURCE = 'CLEAR_SELECTED_RESOURCE';
export const LOAD_RELATIVITY_STATS_SUCCESS = 'LOAD_RELATIVITY_STATS_SUCCESS';
export const LOAD_RELATIVITY_INSTANCES_SUCCESS = 'LOAD_RELATIVITY_LINK_SUCCESS';
export const LOAD_BRAINSPACE_STATS_SUCCESS = 'LOAD_BRAINSPACE_STATS_SUCCESS';
export const LOAD_STORAGE_STATS_SUCCESS = 'LOAD_STORAGE_STATS_SUCCESS';
export const LOAD_BLACKOUT_STATS_SUCCESS = 'LOAD_BLACKOUT_STATS_SUCCESS';
export const LOAD_READYSUITE_STATS_SUCCESS = 'LOAD_READYSUITE_STATS_SUCCESS';
export const LOAD_LSI_TRANSLATION_STATS_SUCCESS = 'LOAD_LSI_TRANSLATION_STATS_SUCCESS';
export const LOAD_WEBPROCESSING_STATS_SUCCESS = 'LOAD_WEBPROCESSING_STATS_SUCCESS';
export const CLEAR_RELATIVITY_STATS = 'CLEAR_RELATIVITY_STATS';
export const CLEAR_BRAINSPACE_STATS = 'CLEAR_BRAINSPACE_STATS';
export const CLEAR_STORAGE_STATS = 'CLEAR_STORAGE_STATS';
export const CLEAR_BLACKOUT_STATS = 'CLEAR_BLACKOUT_STATS';
export const CLEAR_READYSUITE_STATS = 'CLEAR_READYSUITE_STATS';
export const CLEAR_LSI_TRANSLATION_STATS = 'CLEAR_LSI_TRANSLATION_STATS';
export const CLEAR_WEBPROCESSING_STATS = 'CLEAR_WEBPROCESSING_STATS';
export const SAVE_USER_RELATIVITY_INSTANCES_SUCCESS = 'SAVE_USER_RELATIVITY_INSTANCES_SUCCESS';
export const LOAD_USER_RELATIVITY_INSTANCES_SUCCESS = 'LOAD_USER_RELATIVITY_INSTANCES_SUCCESS';
export const LOAD_SUPPORT_STATS_SUCCESS = 'LOAD_SUPPORT_STATS_SUCCESS';
export const LOAD_SUPPORT_URL_SUCCESS = 'LOAD_SUPPORT_LINK_SUCCESS';
export const CLEAR_SUPPORT_STATS = 'CLEAR_SUPPORT_STATS';
export const LOAD_SUPPORT_GROUPS_SUCCESS = 'LOAD_SUPPORT_GROUPS_SUCCESS';
export const UPDATE_SUPPORT_GROUPS_SUCCESS = 'UPDATE_SUPPORT_GROUPS_SUCCESS';
export const LOAD_FILE_TRANSFER_STATS_SUCCESS = 'LOAD_FILE_TRANSFER_STATS_SUCCESS';
export const LOAD_PARTNER_PORTALS_SUCCESS = 'LOAD_PARTNER_PORTALS_SUCCESS';
export const CLEAR_FILE_TRANSFER_STATS = 'CLEAR_FILE_TRANSFER_STATS';
export const LOAD_PARTNER_SUCCESS = 'LOAD_PARTNER_SUCCESS';
export const LOAD_PARTNERS_SUCCESS = 'LOAD_PARTNERS_SUCCESS';
export const UPDATE_SELECTED_PARTNER = 'UPDATE_SELECTED_PARTNER';
export const CLEAR_SELECTED_PARTNER = 'CLEAR_SELECTED_PARTNER';
export const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER';
export const UPDATE_SELECTED_USERS = 'UPDATE_SELECTED_USERS';
export const ALL_USERS_SELECTED = 'ALL_USERS_SELECTED';
export const CLEAR_SELECTED_USER = 'CLEAR_SELECTED_USER';
export const CLEAR_SELECTED_USERS = 'CLEAR_SELECTED_USERS';
export const UPDATE_PARTNERS = 'UPDATE_PARTNERS';
export const SEARCH_PARTNERS = 'SEARCH_PARTNERS';
export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';
export const UPDATE_TEXT_FILTER = 'UPDATE_TEXT_FILTER';
export const CLEAR_TEXT_FILTERS = 'CLEAR_TEXT_FILTERS';
export const TOGGLE_FILTER_BY_COLUMN = 'TOGGLE_FILTER_BY_COLUMN';
export const DISABLE_PARTNER_SUCCESS = 'DISABLE_PARTNER_SUCCESS';
export const ENABLE_PARTNER_SUCCESS = 'ENABLE_PARTNER_SUCCESS';
export const SAVE_PARTNER_SUCCESS = 'SAVE_PARTNER_SUCCESS';
export const CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_PERMISSION_LEVELS_SUCCESS = 'LOAD_PERMISSION_LEVELS_SUCCESS';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const LOAD_AUTH_SERVICES_SUCCESS = 'LOAD_AUTH_SERVICES_SUCCESS';
export const LOAD_PARTNER_SERVICES_SUCCESS = 'LOAD_PARTNER_SERVICES_SUCCESS';
export const UPDATE_USER_ACTIVITY = 'UPDATE_USER_ACTIVITY';
export const RESET_2FA_SUCCESS = 'RESET_2FA-SUCCESS';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const LOAD_GLOBAL_LINKS_SUCCESS = 'LOAD_GLOBAL_LINKS_SUCCESS';
export const LOAD_AVAILABLE_LINKS_SUCCESS = 'LOAD_AVAILABLE_LINKS_SUCCESS';
export const LOAD_AVAILABLE_JIRA_ORGANIZATIONS_SUCCESS = 'LOAD_AVAILABLE_JIRA_ORGANIZATIONS_SUCCESS';
export const LOAD_AVAILABLE_JIRA_GROUPS_SUCCESS = 'LOAD_AVAILABLE_JIRA_GROUPS_SUCCESS';
export const LOAD_VIRTUAL_MACHINES_SUCCESS = 'LOAD_VIRTUAL_MACHINES_SUCCESS';
export const UPDATE_VIRTUAL_MACHINE_STATUS_SUCCESS = "UPDATE_VIRTUAL_MACHINE_STATUS_SUCCESS";
export const UPDATE_SELECTED_MACHINE = 'UPDATE_SELECTED_MACHINE';
export const LOAD_ROLE_SUCCESS = 'LOAD_ROLE_SUCCESS';
export const LOAD_ROLES_SUCCESS = 'LOAD_ROLES_SUCCESS';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const SAVE_ROLE_SUCCESS = 'SAVE_ROLE_SUCCESS';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const LOAD_PERMISSIONS_SUCCESS = 'LOAD_PERMISSIONS_SUCCESS';
export const LOAD_USER_PERMISSIONS_SUCCESS = 'LOAD_USER_PERMISSIONS_SUCCESS';
export const LOAD_CAN_ASSIGN_ROLES_SUCCESS = 'LOAD_CAN_ASSIGN_ROLES_SUCCESS';
export const LOAD_USER_ROLES_SUCCESS = 'LOAD_USER_ROLES_SUCCESS';
export const UPDATE_SELECTED_ROLE = 'UPDATE_SELECTED_ROLE';
export const CLEAR_SELECTED_ROLE = 'CLEAR_SELECTED_ROLE';
export const DELETE_SELECTED_ROLE = 'DELETE_SELECTED_ROLE';
export const LOADED_PAGE = 'LOADED_PAGE';
export const LOAD_AVAILABLE_PORTALS_SUCCESS = 'LOAD_AVAILABLE_PORTALS_SUCCESS'
export const UPDATE_STAT_CARD_NUMBER = 'UPDATE_STAT_CARD_NUMBER';
export const LOAD_AUDITS_SUCCESS = 'LOAD_AUDITS_SUCCESS';
export const UPDATE_SELECTED_AUDIT_DETAILS = 'UPDATE_SELECTED_AUDIT_DETAILS';
export const CLEAR_SELECTED_AUDIT_DETAILS = 'CLEAR_SELECTED_AUDIT_DETAILS';
export const LOAD_MAINTENANCE_MODE_SUCCESS = 'LOAD_MAINTENANCE_MODE_SUCCESS';
export const LOAD_RESOURCE_CYCLE_INTERVAL_SUCCESS = 'LOAD_RESOURCE_CYCLE_INTERVAL_SUCCESS';
export const LOAD_USER_STATUSES_SUCCESS = 'LOAD_USER_STATUSES_SUCCESS';
export const LOAD_USER_PREFERENCES_SUCCESS = 'LOAD_USER_PREFERENCES_SUCCESS';
export const SAVE_USER_PREFERENCE_SUCCESS = 'SAVE_USER_PREFERENCE_SUCCESS';
export const LOAD_AVAILABLE_SUBSCRIPTIONS_SUCCESS = 'LOAD_AVAILABLE_SUBSCRIPTIONS_SUCCESS';
export const LOAD_AVAILABLE_POCS_SUCCESS = 'LOAD_AVAILABLE_POCS_SUCCESS';
export const LOAD_AVAILABLE_TEMPLATES_SUCCESS = 'LOAD_AVAILABLE_TEMPLATES_SUCCESS';
export const LOAD_NOTIFICATIONS_SUCCESS = 'LOAD_NOTIFICATIONS_SUCCESS';
export const SAVE_NOTIFICATION_SUCCESS = 'SAVE_NOTIFICATION_SUCCESS';
export const REMOVE_NOTIFICATION_SUCCESS = 'REMOVE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const LOAD_DASHBOARD_LAYOUT_SUCCESS = 'LOAD_DASHBOARD_LAYOUT_SUCCESS';
export const LOAD_AVAILABLE_WIDGETS_SUCCESS = 'LOAD_AVAILABLE_WIDGETS_SUCCESS';
export const UPDATE_DASHBOARD_LAYOUT = 'UPDATE_DASHBOARD_LAYOUT';
export const LOAD_DASHBOARD_WIDGETS_SUCCESS = 'LOAD_DASHBOARD_WIDGETS_SUCCESS';
export const UPDATE_DASHBOARD_EDIT_MODE = 'UPDATE_DASHBOARD_EDIT_MODE';
