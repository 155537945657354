import axios from 'axios'
import { config } from '../config';

export async function getPermissions() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/permission');
    return response.data;
}

// Permissions for currently logged in user
export async function getUserPermissions() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/permission/user/permissions');
    return response.data;
}

export async function getCanAssignRoles() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/permission/user/canassignroles');
    return response.data;
}

export async function getUserRoles() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/permission/user/roles').catch(error => {
        if (error.response.data.reason === "Maintenance Mode Active") {
            return { data: [] };
        } else {
            throw error
        }
    });
    return response.data;
}
