import axios from 'axios'
import { config } from '../config';

export async function getAuditDetails(userActionAuditLogEntryId) {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/audit/' + userActionAuditLogEntryId + '/details').catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function getAudits() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/audit').catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function logPageView(pageName) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/audit/view/page/' + pageName).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function logContentViewWithInfo(contentName, info) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/audit/view/page/' + contentName + '/additionalInfo/' + encodeURIComponent(info)).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function logLoginSupport() {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/audit/login/support').catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function logConfidentialityAgreement(name) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/audit/confidentialityAgreement?name=' + encodeURIComponent(name)).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}