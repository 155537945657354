import * as types from '../actions/types';
import initialState from './InitialState';

export default function notificationReducer(state = initialState.notifications, action) {
    switch (action.type) {
        case types.SAVE_NOTIFICATION_SUCCESS:
            return state.map(notification => {
                if (notification.notificationId === action.notification.notificationId) {
                    return action.notification;
                } else {
                    return notification;
                }
            });
        case types.REMOVE_NOTIFICATION_SUCCESS:
            return state.map(notification => {
                if (notification.notificationId !== action.notification.notificationId) {
                    return notification;
                }
            });
        case types.LOAD_NOTIFICATIONS_SUCCESS:
            return action.notifications;

        case types.UPDATE_NOTIFICATION_SUCCESS:
            return state.map(notification => {
                if (notification.notificationId === action.notification.notificationId) {
                    return action.notification;
                } else {
                    return notification;
                }
            });
        default:
            return state;
    }
}