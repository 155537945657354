import * as types from "./types";
import * as dashboardService from "../../services/dashboardService";
import initialState from '../../redux/reducers/InitialState.js';

export function updateSelectedKBContent(selectedContent) {
    return { type: types.UPDATE_SELECTED_KB_CONTENT, selectedContent };
}

export function clearSelectedKBContent() {
    return { type: types.CLEAR_SELECTED_KB_CONTENT };
}

export function updateSelectedPartner(selectedPartner) {
    return { type: types.UPDATE_SELECTED_PARTNER, selectedPartner };
}

export function clearSelectedPartner() {
    return { type: types.CLEAR_SELECTED_PARTNER };
}

export function updateSelectedUser(selectedUser) {
    return { type: types.UPDATE_SELECTED_USER, selectedUser };
}

export function updateSelectedUsers(selectedUsers) {
    return { type: types.UPDATE_SELECTED_USERS, selectedUsers };
}

export function clearSelectedUser() {
    return { type: types.CLEAR_SELECTED_USER };
}

export function clearSelectedUsers() {
    return { type: types.CLEAR_SELECTED_USERS };
}

export function updateSelectedRole(selectedRole) {
    return { type: types.UPDATE_SELECTED_ROLE, selectedRole };
}

export function updateSelectedMachine(selectedMachine) {
    return { type: types.UPDATE_SELECTED_MACHINE, selectedMachine };
}

export function clearSelectedRole() {
    return { type: types.CLEAR_SELECTED_ROLE };
}

export function updateSelectedAuditDetails(auditDetails) {
    return { type: types.UPDATE_SELECTED_AUDIT_DETAILS, auditDetails };
}

export function clearSelectedAuditDetails() {
    return { type: types.CLEAR_SELECTED_AUDIT_DETAILS };
}

export function updateSearchTerm(recordType, searchTerm) {
    return { type: types.UPDATE_SEARCH_TERM, recordType, searchTerm };
}

export function updateTextFilter(recordType, textFilter, searchTerm) {
    return { type: types.UPDATE_TEXT_FILTER, recordType, textFilter, searchTerm };
}

export function clearTextFilters(recordType) {
    return { type: types.CLEAR_TEXT_FILTERS, recordType };
}

export function toggleFilterByColumn(recordType, filterByColumn) {
    return { type: types.TOGGLE_FILTER_BY_COLUMN, recordType, filterByColumn };
}

export function updatePageNumber(recordType, pageNumber) {
    return { type: types.UPDATE_PAGE_NUMBER, recordType, pageNumber };
}

export function loadedPage(recordType) {
    return { type: types.LOADED_PAGE, recordType };
}

export function allUsersSelected(recordType, allUsersSelected) {
    return { type: types.ALL_USERS_SELECTED, recordType, allUsersSelected };
}

export function updateDashboardLayout(dashboardLayout) {
    return { type: types.UPDATE_DASHBOARD_LAYOUT, dashboardLayout }
}

export function loadDashboardLayoutSuccess(dashboardLayout) {
    return { type: types.LOAD_DASHBOARD_LAYOUT_SUCCESS, dashboardLayout };
}

export function loadAvailableWidgetsSuccess(widgets) {
    return { type: types.LOAD_AVAILABLE_WIDGETS_SUCCESS, widgets };
}

export function updateDashboardEditMode(isEnabled) {
    return { type: types.UPDATE_DASHBOARD_EDIT_MODE, isEnabled };
}

export function loadAvailableWidgets() {
    return function (dispatch) {
        return dashboardService.getAvailableWidgets()
            .then(widgets => {
                dispatch(loadAvailableWidgetsSuccess(widgets));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadUserDashboardLayout() {
    return function (dispatch) {
        return dashboardService.getUserDashboardLayout()
            .then(dashboardLayout => {
                if(dashboardLayout){
                    dispatch(loadDashboardLayoutSuccess(JSON.parse(dashboardLayout.layout)));
                }
                else {
                    //load default layout
                    dispatch(loadDashboardLayoutSuccess(initialState.page.home.defaultDashboardLayout));
                }
            })
            .catch(error => {
                throw error;
            });
    };
}

export function saveUserDashboardLayout(layout) {
    return function (dispatch) {
        return dashboardService.saveUserDashboardLayout(layout)
            .then(dashboardLayout => {
                dispatch(loadDashboardLayoutSuccess(JSON.parse(dashboardLayout.layout)));
            })
            .catch(error => {
                throw error;
            });
    };
}