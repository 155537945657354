export function getLocalDateTime(dateTime) {
    if (dateTime) {
        return new Date(dateTime + 'Z').toLocaleString()
    } else {
        return "";
    }
}

export function getLocalDate(dateTime) {
    if (dateTime) {
        return new Date(dateTime + 'Z').toLocaleDateString()
    } else {
        return "";
    }
}

export function getLocalTime(dateTime, format) {
    if (dateTime) {
        return format ? new Date(dateTime + 'Z').toLocaleTimeString([], format) : new Date(dateTime + 'Z').toLocaleTimeString();
    } else {
        return "";
    }
}