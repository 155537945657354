import * as types from "./types";
import * as webProcessingStatsService from "../../services/webProcessingStatsService";

export function loadWebProcessingStatsSuccess(webProcessingStats) {
    return { type: types.LOAD_WEBPROCESSING_STATS_SUCCESS, webProcessingStats }
}

export function clearWebProcessingStats() {
    return { type: types.CLEAR_WEBPROCESSING_STATS }
}

export function reloadWebProcessingStats() {
    return function (dispatch) {
        dispatch(clearWebProcessingStats());
        dispatch(loadWebProcessingStats());
    }
}

export function loadWebProcessingStats() {
    return function (dispatch) {
        return webProcessingStatsService.getWebProcessingStats()
            .then(webProcessingStats => {
                dispatch(loadWebProcessingStatsSuccess(webProcessingStats));
            })
    };
}