import * as types from '../actions/types';
import initialState from './InitialState';

export default function rolesReducer(state = initialState.roles, action) {
    switch (action.type) {
        case types.LOAD_ROLE_SUCCESS:
            return state.map(role => {
                if (role.roleId === action.role.roleId) {
                    return action.role
                } else {
                    return role
                }
            })
        case types.LOAD_ROLES_SUCCESS:
            return action.roles;
        case types.DELETE_ROLE_SUCCESS:
            return state.map(role => {
                if (role.roleId !== action.roleId) {
                    return role
                }
            })
        case types.SAVE_ROLE_SUCCESS:
            return state.map(role => {
                if (role.roleId === action.role.roleId) {
                    return action.role
                } else {
                    return role
                }
            }).sort(sortRoles)
        case types.CREATE_ROLE_SUCCESS:
            return [...state, { ...action.role }].sort(sortRoles)
        default:
            return state;
    }
}

function sortRoles(roleA, roleB) {
    var x = roleA.name.toLowerCase();
    var y = roleB.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
}