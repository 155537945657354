import * as pageActions from "../actions/pageActions";
import * as types from "./types";
import * as usersService from "../../services/usersService";

export function loadUserSuccess(user) {
    return { type: types.LOAD_USER_SUCCESS, user }
}

export function loadUsersSuccess(users) {
    return { type: types.LOAD_USERS_SUCCESS, users }
}

export function disableUserSuccess(user) {
    return { type: types.DISABLE_USER_SUCCESS, user }
}

export function enableUserSuccess(user) {
    return { type: types.ENABLE_USER_SUCCESS, user }
}

export function resetUser2faSuccess(user) {
    return { type: types.RESET_2FA_SUCCESS, user }
}

export function resetUserPasswordSuccess(user) {
    return { type: types.RESET_PASSWORD_SUCCESS, user }
}

export function saveUserSuccess(user) {
    return { type: types.SAVE_USER_SUCCESS, user }
}

export function createUserSuccess(user) {
    return { type: types.CREATE_USER_SUCCESS, user }
}

export function loadUser(user) {
    return function (dispatch) {
        return usersService.getUser(user.userId)
            .then(returnedUser => {
                dispatch(loadUserSuccess(returnedUser));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadUsers() {
    return function (dispatch) {
        return usersService.getUsers()
            .then(users => {
                dispatch(loadUsersSuccess(users));
                dispatch(pageActions.loadedPage("users"));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function disableUser(user) {
    return function (dispatch) {
        return usersService.disableUser(user)
            .then(returnedUser => {
                dispatch(disableUserSuccess(returnedUser));
            }).catch(error => {
                throw error;
            })
    }
}

export function enableUser(user) {
    return function (dispatch) {
        return usersService.enableUser(user)
            .then(returnedUser => {
                dispatch(enableUserSuccess(returnedUser));
            }).catch(error => {
                throw error;
            })
    }
}

export function resetUser2fa(user) {
    return function (dispatch) {
        return usersService.resetUser2fa(user)
            .then(returnedUser => {
                dispatch(resetUser2faSuccess(returnedUser));
            }).catch(error => {
                throw error;
            })
    }
}

export function resetUserPassword(user) {
    return function (dispatch) {
        return usersService.resetUserPassword(user)
            .then(returnedUser => {
                dispatch(resetUserPasswordSuccess(returnedUser));
            }).catch(error => {
                throw error;
            })
    }
}

export function saveUser(user) {
    return function (dispatch) {
        return usersService.saveUser(user)
            .then(savedUser => {
                user.userId
                    ? dispatch(saveUserSuccess(savedUser))
                    : dispatch(createUserSuccess(savedUser));
            }).catch(error => {
                throw error;
            })
    }
}