import * as types from '../actions/types';
import initialState from './InitialState';

export default function appSettingsReducer(state = initialState.appSettings, action) {
    switch (action.type) {
        case types.LOAD_MAINTENANCE_MODE_SUCCESS:
            return { ...state, maintenanceMode: action.maintenanceMode };     
        case types.LOAD_RESOURCE_CYCLE_INTERVAL_SUCCESS:
            return { ...state, resourceCycleIntervalInSeconds: action.resourceCycleIntervalInSeconds };  
        default:
            return state;
    }
}