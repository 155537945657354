import * as types from '../actions/types';
import initialState from './InitialState';

export default function groupsReducer(state = initialState.groups, action) {
    switch (action.type) {
        case types.LOAD_AVAILABLE_SUBSCRIPTIONS_SUCCESS:
            return { ...state, availableSubscriptions: action.subscriptions.sort(sortGroups) };
        case types.LOAD_AVAILABLE_POCS_SUCCESS:
            return { ...state, availablePocs: action.pocs.sort(sortGroups) };
        default:
            return state;
    }
}

function sortGroups(groupA, groupB) {
    var x = groupA.name.toLowerCase();
    var y = groupB.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
}