import React, { useEffect } from 'react'
import { signoutRedirectCallback } from '../../services/userService'
import { useNavigate } from 'react-router-dom'

function SignoutOidc() {
    const Navigate = useNavigate()
    useEffect(() => {
        async function signoutAsync() {
            await signoutRedirectCallback()
            Navigate('/')
        }
        signoutAsync()
    }, [Navigate])

    return (
        <>
        </>
    )
}

export default SignoutOidc
