import * as types from '../actions/types';
import initialState from './InitialState';

export default function webprocessingStatsReducer(state = initialState.webProcessingStats, action) {
    switch (action.type) {
        case types.LOAD_WEBPROCESSING_STATS_SUCCESS:
            return { ...state, stats: action.webProcessingStats.stats, title: action.webProcessingStats.title, error: action.webProcessingStats.error };
        case types.CLEAR_WEBPROCESSING_STATS:
            return { ...state, stats: initialState.webProcessingStats.stats, title: initialState.webProcessingStats.title, error: initialState.webProcessingStats.error };
        default:
            return state;
    }
}