import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ConfirmationModal({ id, title, displayValue, onSubmit, theme }) {
    return (
        <div className="modal fade" tabindex="-1" role="dialog" id={ id } >
            <div className="modal-dialog" role="document">
                <div className={ "modal-content " + theme }>
                    <form onSubmit={ onSubmit }>
                        <div className="modal-header">
                            <h2 className="modal-title">{ title }</h2>
                            <a className="close" data-bs-dismiss="modal" aria-label="Close">
                                <FontAwesomeIcon icon="fas fa-times" />
                            </a>
                        </div>
                        <div className="modal-body" dangerouslySetInnerHTML={ {
                            __html: displayValue
                        } }>
                        </div>
                        <div className="modal-footer">
                            <div className="row">
                                <div className="col">
                                    <input type="submit" value="Yes" className="btn btn-new btn-block" />
                                </div>
                                <div className="col">
                                    <input data-bs-dismiss="modal" value="No" className="btn btn-no btn-block" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationModal;