import React, { useEffect, useRef, useState } from 'react';
import { config } from '../../../../config';
import { AreaChart, Area, ResponsiveContainer, Tooltip } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as dateUtils from '../../../../utils/dateUtils';
import { useSelector } from "react-redux";
import AutoTruncateText from '../../../common/AutoTruncateText';

function GraphWidget({ Stats, theme, sideNavState, isInEditMode = false, onDeleteItem, widgetTitle }) {

    const [data, setData] = useState([]);
    const [dataVersionId, setDataVersionId] = useState(0);
    const [brand, setBrand] = useState("");

    const selectedRelativityInstances = useSelector(state => state.relativityStats.selectedRelativityInstances);
    const widgetHeight = useSelector(state => state.page.home.dashboardLayout.lg.find(x => x.i == widgetTitle).h);
    const resizableDivRef = useRef(null);
    const titleRef = useRef(null);
    const contentRef = useRef(null);

    useEffect(() => {
        setBrand(getBrandName());
    }, [])

    useEffect(() => {
        let now = new Date();
        let lastYear = new Date();
        let past12monthsDates = getPast12MonthsDates();
        var fullYearStatsHistory = [...Stats.statHistory] ;

        //create empty stat history for months without data
        past12monthsDates.forEach( (date) => {
            if(!fullYearStatsHistory.some(x => new Date(x.statDateTime).getMonth() == date.getMonth() && new Date(x.statDateTime).getFullYear() == date.getFullYear())){
                fullYearStatsHistory.push({ statDateTime: date, statValue: 0})
            }
        });

        //sort stat dates ascending
        fullYearStatsHistory.sort((a, b) => {
            return new Date(a.statDateTime) - new Date(b.statDateTime)
        });

        lastYear.setYear(now.getFullYear() - 1);

        if (Stats.statHistory.length > 0) {
            setData(
                fullYearStatsHistory.map(x => ({
                    name: x.statDateTime,
                    value: x.statValue
                }))
            );
        } 
        else {
            setData([
                { name: lastYear.toJSON(), value: 0 },
                { name: now.toJSON(), value: 0 }
            ]);
        } 

        //force rerender of graph so dots show
        setDataVersionId((previousValue) => previousValue + 1);

    }, [Stats])

    useEffect(() => {
        updateFontSize();
    }, [resizableDivRef?.current?.offsetWidth, resizableDivRef?.current?.offsetHeight]);

    const prettifyUnits = (n) => {

        switch (Stats?.label) {
            case 'Hosted Size':
            case 'Staging Server Size':
            case 'Archive Server Size':
            case 'Discovered Document Size':
                if (n > 1024.0) {
                    return (n / 1024.0).toFixed(1) + " TB";
                }
                else if (n / 1024.0 > 1024.0) {
                    return (n / 1024.0 / 1024.0).toFixed(1) + " MB";
                }
                else {
                    return (n).toFixed(1) + " GB";
                }
            default:
                if (n < 1e3) return n;
                if (n >= 1e3 && n < 1e6) {
                    return (n / 1e3).toFixed(1) + " K";
                }
                if (n >= 1e6 && n < 1e9) {
                    return (n / 1e6).toFixed(1) + " M";
                }
                if (n >= 1e9 && n < 1e12) {
                    return (n / 1e9).toFixed(1) + " B";
                }
                if (n >= 1e12) {
                    return (n / 1e12).toFixed(1) + " T";
                }
        }
    }

    const GraphTooltip = ({ payload, active }) => {

        if (active) {
            let rawDate = payload[0].payload.name;
            let value = payload[0].payload.value;
            let [month, day, year] = dateUtils.getLocalDate(rawDate).split('/');
            let date = new Date(year, month - 1, day);
            date = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });

            value = prettifyUnits(value);

            if (selectedRelativityInstances?.length == 0){
                return (
                    <div className={ "graph-tooltip " + theme }>
                        <p className="graph-tip-text text-center">Select a Relativity Instance</p>
                    </div>
                );
            }

            if (dateUtils.getLocalDate(rawDate) != "Invalid Date") {
                return (
                    <div className={ "graph-tooltip " + theme }>
                        <p className="graph-tip-text text-center">{ date }</p>
                        <p className="graph-tip-text text-center">{ value }</p>
                    </div>
                );
            }                        
        }
        return null;
    }

    const GraphDot = (props) => {
        const { cx, cy } = props;

        if (data) {
            return (
                <FontAwesomeIcon
                    icon="fa-solid fa-circle"
                    style={ { color: "#1E8EDD" } }
                    x={ cx - 2 }
                    y={ cy - 2 }
                    width={ 4 }
                    height={ 4 }
                />
            );
        }
        return null;
    };

    const updateFontSize = () => {
        //lower size multiplier allows ref element to be bigger 
        updateElementSize(titleRef, 12, 35, 35);
        updateElementSize(contentRef, 12, 35, 35);
    }

    const updateElementSize = (refItem, minSize, maxSize, sizeMultiplier) => {
        const element = refItem.current;
        const resizableDivElement = resizableDivRef.current;

        const fontSize = (resizableDivElement.offsetHeight + resizableDivElement.offsetWidth) / sizeMultiplier;

        element.style.fontSize = fontSize + 'px';

        //handle min max size of header
        if (fontSize < minSize || widgetHeight < 5) {
            element.style.fontSize = minSize + 'px';
        }

        if (fontSize > maxSize) {
            element.style.fontSize = maxSize + 'px';
        }
    }

    const getBrandName = () => {
        if (widgetTitle == "Relativity Workspaces" || widgetTitle == "Relativity Users" || widgetTitle == "Relativity Hosted Size" || widgetTitle == "Relativity Documents") {
            return "Relativity";
        }

        if (widgetTitle == "Brainspace Documents") {
            return "Brainspace";
        }

        if (widgetTitle == "Storage Archive Size" || widgetTitle == "Storage Staging Size") {
            return "Storage"
        }

        if (widgetTitle == "Blackout Native Redacted Documents" || widgetTitle == "Blackout Native Redactions Placed" || widgetTitle == "Blackout Image Redacted Documents" || widgetTitle == "Blackout Redacted Pages") {
            return "Blackout"
        }

        if (widgetTitle == "ReadySuite Number of Users") {
            return "ReadySuite"
        }

        if (widgetTitle == "Relativity Web Processing Discovered Documents" || widgetTitle == "Relativity Web Processing Discovered Size") {
            return "Relativity Web Processing"
        }

        if (widgetTitle == "LSI Translation Document Count" || widgetTitle == "LSI Translation Case Count") {
            return "LSI Translation"
        }
    } 

    const getPast12MonthsDates = () => {
        let monthsDates = [];
        let currentDate = new Date();

        for (let i = 0; i < 12; i++) {
            monthsDates.unshift(new Date(currentDate));
            currentDate.setMonth(currentDate.getMonth() - 1);
        }

        return monthsDates;
    }

    return (
        <div ref={ resizableDivRef } class={ theme + ' d-flex py-3 px-4 widget oasis-rounded widget-container ' + sideNavState }>
            { isInEditMode == true ?
                <div onClick={ () => onDeleteItem() }>
                    <a className='close btn-widget-delete'>
                        <FontAwesomeIcon icon="fas fa-times" beat={ true } />
                    </a>
                </div> :
                ""
            }
            <div ref={ titleRef } className='col-4' >
                <div className='row position-absolute gx-0 pb-1 fw-bold widgetTitle'>
                    <AutoTruncateText text={ Stats?.label } theme={ theme } />
                </div>
                <div ref={ contentRef } className='row gx-0 pb-1 ps-2 h6 widgetValue'>
                    { Stats?.value }
                </div>
                <div
                    className="position-absolute bottom-0 start-0 p-3 ms-2 mb-1"
                    data-tip={ brand }
                    data-type={ theme }
                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                    data-border={ true }
                >
                    { brand == "Relativity" ?
                        <div className="fa-layers default-cursor">
                            <FontAwesomeIcon icon='far fa-square' transform="down-3 left-3" />
                            <FontAwesomeIcon icon='far fa-square' transform="up-3 right-3" />
                        </div> : ""
                    }
                    { brand == "Brainspace" ?
                        <img
                            className="graph-widget-logo"
                            src={ config.OASIS_PORTAL_URL + ("images/brainspace-logo.png") }
                            alt="Brainspace"
                        /> : ""
                    }
                    { brand == "Storage" ?
                        <img
                            className="graph-widget-logo"
                            src={ config.OASIS_PORTAL_URL + ("images/storage-logo.png") }
                            alt="Storage"
                        /> : ""
                    }
                    { brand == "Blackout" ?
                        <img
                            className="graph-widget-logo"
                            src={ config.OASIS_PORTAL_URL + ("images/blackout-logo.png") }
                            alt="Blackout"
                        /> : ""
                    }
                    { brand == "ReadySuite" ?
                        <img
                            className="graph-widget-logo"
                            src={ config.OASIS_PORTAL_URL + ("images/readySuite-logo.png") }
                            alt="ReadySuite"
                        /> : ""
                    }
                    { brand == "Relativity Web Processing" ?
                        <img
                            className="graph-widget-logo"
                            src={ config.OASIS_PORTAL_URL + ("images/relativity-webProcessing-logo.png") }
                            alt="ReadySuite"
                        /> : ""
                    }
                    { brand == "LSI Translation" ?
                        <img
                            className="graph-widget-logo"
                            src={ config.OASIS_PORTAL_URL + ("images/lsi-translation-logo.jpg") }
                            alt="LSI Translation"
                        /> : ""
                    }
                </div>
            </div>
            <div className='col-8 widgetChart pb-3' >
                <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                        data={ data }
                    >
                        <Area dataKey="value" dot={ <GraphDot /> } key={ "data_" + dataVersionId } />
                        <Tooltip offset={ 10 } allowEscapeViewBox={ { x: true, y: true } } content={ <GraphTooltip /> } wrapperStyle={ { zIndex: 3 } } />
                        <defs>
                            <linearGradient id="valueColor" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="#2A99E2" stopOpacity={ 0.8 } />
                                <stop offset="100%" stopColor="#2A99E2" stopOpacity={ 0 } />
                            </linearGradient>
                        </defs>
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default GraphWidget;