import * as types from '../actions/types';
import initialState from './InitialState';

export default function linksReducer(state = initialState.links, action) {
    switch (action.type) {
        case types.LOAD_GLOBAL_LINKS_SUCCESS:
            return { ...state, global: action.globalLinks };
        case types.LOAD_AVAILABLE_LINKS_SUCCESS:
            return { ...state, available: action.available };
        default:
            return state;
    }
}