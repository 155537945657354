import { UserManager, WebStorageStateStore } from 'oidc-client';
import { storeUserError, storeUser } from '../redux/actions/authActions'
import { config } from '../config';
import { setAuthHeader } from '../utils/axiosHeaders';

const identityConfig = {
    authority: config.IDENTITY_URL,
    client_id: "oasisportalclient",
    redirect_uri: config.OASIS_PORTAL_URL + "signin-oidc",
    response_type: "code",
    monitorSession: false,
    accessTokenExpiringNotificationTime: 60,
    scope: "offline_access openid profile address permissionlevel roles username dashboard_url mediashuttle_url client_name email oasisportalapi oasisvmmapi",
    post_logout_redirect_uri: config.OASIS_PORTAL_URL + "signout-oidc",
    userStore: new WebStorageStateStore({ store: window.localStorage })
};

const userManager = new UserManager(identityConfig);

export async function loadUser() {
    let user = await userManager.getUser();
    if (user) {
        setAuthHeader(user.access_token);
    }
    return user;
}

export async function loadUserFromStorage(store) {
    try {
        let user = await userManager.getUser()
        if (!user) { return store.dispatch(storeUserError()) }
        store.dispatch(storeUser(user))
    } catch (e) {
        console.error(`User not found: ${e}`)
        store.dispatch(storeUserError())
    }
}

export function signinRedirect() {
    return userManager.signinRedirect()
}

export function customLoginRedirect(redirectUri) {
    const customLoginIdentityConfig = {
        authority: config.IDENTITY_URL,
        client_id: "oasisportalclient",
        redirect_uri: redirectUri + "/signin-oidc",
        response_type: "code",
        monitorSession: false,
        scope: "openid profile address permissionlevel roles username dashboard_url mediashuttle_url client_name email oasisportalapi",
        post_logout_redirect_uri: redirectUri + "/signout-oidc",
        userStore: new WebStorageStateStore({ store: window.localStorage })
    };
    const customLoginUserManager = new UserManager(customLoginIdentityConfig);
    return customLoginUserManager.signinRedirect();
}

export function signinRedirectCallback() {
    return userManager.signinRedirectCallback()
}

export function signoutRedirect() {
    return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
    userManager.clearStaleState()
    userManager.removeUser()
    return userManager.signoutRedirectCallback()
}

export default userManager