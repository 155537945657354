import React, { Component } from 'react';
import AdministrationNavMenu from './AdministrationNavMenu';

export class AdministrationLayout extends Component {

    render() {

        return (
            <div className="flex-row admin-page oasis-rounded">
                <AdministrationNavMenu />
                <div id="admin-container">
                    { this.props.children }
                </div>
            </div>
        );
    }
}