import * as types from '../actions/types';
import initialState from './InitialState';

export default function supportReducer(state = initialState.support, action) {
    switch (action.type) {
        case types.LOAD_SUPPORT_GROUPS_SUCCESS:
            return { ...state, groups: action.groups };
        case types.UPDATE_SUPPORT_GROUPS_SUCCESS:
            return { ...state, groups: action.groups };
        default:
            return state;
    }
}