import * as types from '../actions/types';
import initialState from './InitialState';

export default function userStatusesReducer(state = initialState.userStatuses, action) {
    switch (action.type) {       
        case types.LOAD_USER_STATUSES_SUCCESS:
            return action.userStatuses;   
        default:
            return state;
    }
}