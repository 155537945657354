import * as types from "./types";
import * as readySuiteStatsService from "../../services/readySuiteStatsService";

export function loadReadySuiteStatsSuccess(readySuiteStats) {
    return { type: types.LOAD_READYSUITE_STATS_SUCCESS, readySuiteStats }
}

export function clearReadySuiteStats() {
    return { type: types.CLEAR_READYSUITE_STATS }
}

export function reloadReadySuiteStats() {
    return function (dispatch) {
        dispatch(clearReadySuiteStats());
        dispatch(loadReadySuiteStats());
    }
}

export function loadReadySuiteStats() {
    return function (dispatch) {
        return readySuiteStatsService.getReadySuiteStats()
            .then(readySuiteStats => {
                dispatch(loadReadySuiteStatsSuccess(readySuiteStats));
            })
    };
}