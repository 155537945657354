import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as pageActions from '../../redux/actions/pageActions';
import * as kbActions from '../../redux/actions/kbActions';
import * as auditsService from '../../services/auditsService';
import { useDispatch, useSelector } from "react-redux";
import KnowledgeBaseExpandableBook from '../knowledgebase/KnowledgeBaseExpandableBook';
import ConfirmationModal from '../common/ConfirmationModal';
import * as bootstrap from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function KnowledgeBasePage({ theme }) {
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let params = useParams();
    let navigate = useNavigate();
    let query = useQuery();
    let querySearchTerm = query.get("searchTerm");

    const type = params.type;
    const contentId = params.contentId;

    const [isSearching, setIsSearching] = useState(false);

    const searchTerm = useSelector(state => querySearchTerm ? querySearchTerm : state.page.kb.searchTerm);

    const searchResults = useSelector(state => state.kb.searchResults);

    const books = useSelector(state => state.kb.books);

    const selectedContent = useSelector(state => state.page.kb.selectedContent);

    const knowledgeBasePdf = useSelector(state => state.kb.pdf);
    const [pdfLoading, setPdfLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(kbActions.loadKnowledgeBaseBooks()).catch(error => {
            toast.error("Loading KB Books Failed: " + error, { autoClose: false });
        });

    }, []);

    useEffect(() => {
        if (selectedContent.name) {
            auditsService.logContentViewWithInfo("Knowledge Base", "Viewed " + type + " - " + selectedContent.name);
        }
        document.getElementById('right-scrolling-div').scrollTop = 0;
    }, [selectedContent]);

    useEffect(() => {
        if (contentId && type) {
            dispatch(kbActions.loadKnowledgeBaseContent(type, contentId));
        } else {
            dispatch(pageActions.clearSelectedKBContent());
        }
        dispatch(pageActions.updateSearchTerm("kb", ""));
    }, [contentId, type])

    useEffect(() => {
        var delayDebounce = null;
        if (searchTerm != "") {
            setIsSearching(true);
            delayDebounce = setTimeout(() => {
                dispatch(kbActions.searchKnowledgeBase(searchTerm)).then(() => {
                    setIsSearching(false);
                }).catch(error => {
                    setIsSearching(false);
                    toast.error("Searching KB Failed: " + error, { autoClose: false });
                });
            }, 300);
            dispatch(pageActions.clearSelectedKBContent());
        } else {
            dispatch(kbActions.clearKBSearchResults());
        }

        return () => clearTimeout(delayDebounce)
    }, [searchTerm]);

    useEffect(() => {
        if(knowledgeBasePdf.fileName != ""){
            var link = document.createElement('a');
            link.href = knowledgeBasePdf.content;
            link.download = knowledgeBasePdf.fileName;
            link.dispatchEvent(new MouseEvent('click'));
            dispatch(kbActions.clearPdf());
        }
    }, [knowledgeBasePdf])

    const handleSearchTermChange = (event) => {
        dispatch(pageActions.updateSearchTerm("kb", event.target.value));
        navigate("/KnowledgeBase/full/content/search?searchTerm=" + encodeURIComponent(event.target.value));
    }

    const handleSelectedContentClicked = () => {
        dispatch(pageActions.updateSearchTerm("kb", ""));
    }

    const handlePrintPdf = () => {
        new bootstrap.Modal(document.getElementById('print-to-pdf-confirmation-modal'), { backdrop: 'static', keyboard: false }).show(); 
    }

    const handlePrintPdfConfirmed = (event) => {
        event.preventDefault();
        auditsService.logConfidentialityAgreement(selectedContent.name);
        bootstrap.Modal.getInstance(document.getElementById('print-to-pdf-confirmation-modal')).hide();
        setPdfLoading(true);

        dispatch(kbActions.getPdf(selectedContent.contentId)).catch(error => {
            toast.error("Print to PDF Failed: " + error, { autoClose: false });
        }).finally(() => {
            setPdfLoading(false);
        });
    }

    return (
        <>
            <div className="h-100">
                <div id="view-all" className={ "py-3 px-4 oasis-rounded page-card h-100 media-controlled " + theme }>
                    <div className="d-flex flex-row py-3 ps-5 pe-4 page-card-header">
                        <div className="d-flex my-auto">
                            <h2 className="mb-0 list-header media-controlled">Knowledge Base</h2>
                        </div>
                        <div className="d-flex ms-auto">
                            <div className="my-auto me-3">
                                <FontAwesomeIcon className="searchIcon media-controlled" icon="fas fa-search" />
                                <input
                                    onChange={ handleSearchTermChange }
                                    id="search_term"
                                    className={ "search-input media-controlled " + theme }
                                    autocomplete="off"
                                    name="SearchTerm"
                                    type="text"
                                    value={ searchTerm }
                                />
                            </div>
                        </div>
                        <span
                            data-tip="Export to PDF"
                            data-delay-show="500"
                            data-type={ theme }
                            data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                            data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                            data-border={ true }>
                            <a 
                                onClick={ handlePrintPdf }
                                className={ "media-controlled btn btn-new my-auto me-3 py-2 px-3" + (type != "page" || pdfLoading == true ? " disabled" : "") }> { pdfLoading ? <FontAwesomeIcon icon="fas fa-download" fade /> : <FontAwesomeIcon icon="fas fa-download" /> }</a>
                        </span>         
                    </div>
                    <div className="d-flex flex-row pb-3 page-card-body">
                        <div className={ "flex-column kb-select " + theme }>
                            { books.length > 0 ? books.map((book, i) => {
                                return (
                                    <KnowledgeBaseExpandableBook book={ book } type={ type } handleSelectedContentClicked={ () => handleSelectedContentClicked() } />
                                )
                            }) : <div className="h-100 centered"><FontAwesomeIcon icon="fas fa-spinner" pulse /></div> }
                        </div>
                        <div id="right-scrolling-div" className="flex-column kb-article px-5 pt-3">

                            { searchTerm != "" ? <><div className="bottom-border"><h4>Search Results</h4></div><div className="search-results"> { isSearching ?
                                <div className="h-100 centered"><span className="fa-fade">Searching...</span></div>
                                : searchResults.length > 0 ? searchResults.map(searchResult => (
                                    <Link to={ "/KnowledgeBase/" + searchResult.type + "/" + searchResult.searchResultId + "/" + encodeURIComponent(searchResult.slug) } onClick={ () => handleSelectedContentClicked() }>
                                        <div className="search-result">
                                            <div className="ps-3 pt-4" dangerouslySetInnerHTML={ { __html: searchResult.previewHtml.name } } ></div>
                                            <div className="bottom-border px-5 pb-4" dangerouslySetInnerHTML={ { __html: searchResult.previewHtml.content } } />
                                        </div>
                                    </Link>
                                )) : <div className="pt-4">No search results</div> }</div> </> :
                                selectedContent ?
                                    type == 'page' ?
                                        <>
                                            <div className="bottom-border"><h4>{ selectedContent.name }</h4></div>
                                            <div className={ "kb-content pt-4 " + theme } dangerouslySetInnerHTML={ { __html: selectedContent.html ? selectedContent.html.replaceAll(/color: [#A-z0-9(), ]*;/g, "") : '' } } />
                                        </>
                                        :
                                        type == 'chapter' ?
                                            <>
                                                <div className="bottom-border"><h4>{ selectedContent.name }</h4></div>
                                                { selectedContent.name === "Permission Denied" ?
                                                    <div className={ "kb-content pt-4 " + theme }><p>You do not have permission to view this content.</p></div> :
                                                    <div className="search-results"> { selectedContent.pages ? selectedContent.pages.map(page => (
                                                        <Link to={ "/KnowledgeBase/page/" + page.pageId + "/" + encodeURIComponent(page.slug ? page.slug : page.name) } onClick={ () => handleSelectedContentClicked() }>
                                                            <div className="search-result">
                                                                <div className="bottom-border ps-3 pb-3 pt-4" ><h5>{ page.name }</h5></div>
                                                            </div>
                                                        </Link>
                                                    )) : "" }
                                                    </div> }
                                            </>
                                            :
                                            type == 'book' ?
                                                <>
                                                    <div className="bottom-border"><h4>{ selectedContent.name }</h4></div>
                                                    { selectedContent.name === "Permission Denied" ?
                                                        <div className={ "kb-content pt-4 " + theme }><p>You do not have permission to view this content.</p></div> :
                                                        <div className="search-results"> { selectedContent.contents ? selectedContent.contents.map(content => (
                                                            <Link to={ "/KnowledgeBase/" + content.type + "/" + content.contentId + "/" + encodeURIComponent(content.slug) } onClick={ () => handleSelectedContentClicked() }>
                                                                <div className="search-result">
                                                                    <div className="bottom-border ps-3 pb-3 pt-4" ><h5>{ content.name }</h5></div>
                                                                </div>
                                                            </Link>
                                                        )) : "" }
                                                        </div> }
                                                </>
                                                : ""
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                title="Confidentiality Notice"
                id="print-to-pdf-confirmation-modal"
                displayValue={ `This document and its contents may contain confidential and or legally privileged information. No other use, duplication or distribution
                of the information contained herein is permitted without the express written consent of Oasis. <br><br>Do you accept these terms?` }
                onSubmit={ handlePrintPdfConfirmed }
                theme={ theme }
            />
        </>
    )
}

export default KnowledgeBasePage;