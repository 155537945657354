import React, { useEffect } from 'react'
import { signinRedirectCallback } from '../../services/userService'
import { TabLocalStorage } from '../../utils/localStorageUtils'
import { useNavigate } from 'react-router-dom'

function SigninOidc() {
    const navigate = useNavigate()
    useEffect(() => {
        async function signinAsync() {
            try {
                await signinRedirectCallback();
            }
            catch (error) {
                console.error(error);
            }

            var defaultTab = "/";

            var storageTab = new TabLocalStorage().get();

            defaultTab = storageTab ?? defaultTab;

            navigate(defaultTab)
        }
        signinAsync()
    }, [navigate])

    return (
        <>
        </>
    )
}

export default SigninOidc
