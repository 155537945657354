import * as types from '../actions/types';
import initialState from './InitialState';

export default function selectedResourceReducer(state = initialState.selectedResource, action) {
    switch (action.type) {
        case types.UPDATE_SELECTED_RESOURCE:
            return action.selectedResource;
        case types.CLEAR_SELECTED_RESOURCE:
            return initialState.selectedResource;
        default:
            return state;
    }
}