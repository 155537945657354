import * as types from '../actions/types';
import initialState from './InitialState';

export default function previewResourcesReducer(state = initialState.previewResources, action) {
    switch (action.type) {
        case types.UPDATE_PREVIEW_RESOURCES:
            return action.resources;
        default:
            return state;
    }
}