import * as types from "./types";
import * as relativityStatsService from "../../services/relativityStatsService";
import { loadBrainspaceStats } from "./brainspaceStatsActions";
import { loadBlackoutStats } from "./blackoutStatsActions";
import { loadStorageStats } from "./storageStatsActions";
import { loadReadySuiteStats } from "./readySuiteStatsActions";
import { loadLsiTranslationStats } from "./lsiTranslationStatsActions";
import { loadWebProcessingStats } from "./webProcessingStatsActions";

export function loadRelativityStatsSuccess(relativityStats) {
    return { type: types.LOAD_RELATIVITY_STATS_SUCCESS, relativityStats }
}

export function clearRelativityStats() {
    return { type: types.CLEAR_RELATIVITY_STATS}
}

export function loadRelativityInstancesSuccess(relativityInstances) {
    return { type: types.LOAD_RELATIVITY_INSTANCES_SUCCESS, relativityInstances }
}

export function saveUserRelativityInstancesSuccess(relativityInstances) {
    return { type: types.SAVE_USER_RELATIVITY_INSTANCES_SUCCESS, relativityInstances };
}

export function loadUserRelativityInstancesSuccess(relativityInstances) {
    return { type: types.LOAD_USER_RELATIVITY_INSTANCES_SUCCESS, relativityInstances }
}

export function reloadRelativityStats() {
    return function (dispatch) {
        dispatch(clearRelativityStats());
        dispatch(loadRelativityStats());
    }
}

export function loadRelativityStats() {
    return function (dispatch) {
        return relativityStatsService.getRelativityStats()
            .then(relativityStats => {
                dispatch(loadRelativityStatsSuccess(relativityStats));
            })
    };
}

export function loadRelativityInstances() {
    return function (dispatch) {
        return relativityStatsService.getRelativityInstances()
            .then(relativityInstances => {
                dispatch(loadRelativityInstancesSuccess(relativityInstances));
            })
    };
}

export function saveUserRelativityInstances(relativityInstances) {
    return function (dispatch) {
        return relativityStatsService.saveUserRelativityInstances(relativityInstances)
            .then((relativityInstances) => {
                dispatch(saveUserRelativityInstancesSuccess(relativityInstances));
                dispatch(loadRelativityStats());
                dispatch(loadBrainspaceStats());
                dispatch(loadBlackoutStats());
                dispatch(loadLsiTranslationStats());
                dispatch(loadStorageStats());
                dispatch(loadReadySuiteStats());
                dispatch(loadWebProcessingStats());
            })
    };
}

export function loadUserRelativityInstances() {
    return function (dispatch) {
        return relativityStatsService.getUserRelativityInstances()
            .then((relativityInstances) => {
                dispatch(loadUserRelativityInstancesSuccess(relativityInstances));
            })
    }
}