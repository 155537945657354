import * as types from '../actions/types';
import initialState from './InitialState';

export default function lsiTranslationStatsReducer(state = initialState.lsiTranslationStats, action) {
    switch (action.type) {
        case types.LOAD_LSI_TRANSLATION_STATS_SUCCESS:
            return { ...state, stats: action.lsiTranslationStats.stats, title: action.lsiTranslationStats.title, error: action.lsiTranslationStats.error };
        case types.CLEAR_LSI_TRANSLATION_STATS:
            return { ...state, stats: initialState.lsiTranslationStats.stats, title: initialState.lsiTranslationStats.title, error: initialState.lsiTranslationStats.error };
        default:
            return state;
    }
}