import * as types from '../actions/types';
import initialState from './InitialState';

export default function storageStatsReducer(state = initialState.storageStats, action) {
    switch (action.type) {
        case types.LOAD_STORAGE_STATS_SUCCESS:
            return { ...state, stats: action.storageStats.stats, title: action.storageStats.title, error: action.storageStats.error };
        case types.CLEAR_STORAGE_STATS:
            return { ...state, stats: initialState.storageStats.stats, title: initialState.storageStats.title, error: initialState.storageStats.error };
        default:
            return state;
    }
}