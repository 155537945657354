import React, { useEffect, useState } from 'react';
import * as rolesActions from '../../../redux/actions/rolesActions';
import * as permissionsActions from '../../../redux/actions/permissionsActions';
import * as pageActions from '../../../redux/actions/pageActions';
import * as partnersActions from '../../../redux/actions/partnersActions';
import RoleEdit from '../roles/RoleEdit';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PaginatedList from '../../common/PaginatedList';
import FilterSlideout from '../../common/FilterSlideout';
import * as bootstrap from 'bootstrap';
import ConfirmationModal from '../../common/ConfirmationModal';
import * as ModalBackgroundRemover from '../../common/ModalBackgroundRemover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function RolesPage({ theme }) {

    const [columnFiltersPopulated, setColumnFiltersPopulated] = useState(false);

    const searchTerm = useSelector(state => state.page.roles.searchTerm);
    const selectedRole = useSelector(state => state.page.roles.selectedRole);
    const userPermissions = useSelector(state => state.auth.permissions);
    const filterByColumn = useSelector(state => state.page.roles.filterByColumn);
    const textFilters = useSelector(state => state.page.roles.textFilters);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(permissionsActions.loadUserPermissions()).then(() => {
            if (userPermissions.some(p => p.name === "ViewRoles")) {
                dispatch(rolesActions.loadRoles()).catch(error => {
                    toast.error("Loading Roles Failed:" + error, { autoClose: false });
                });
            }
            if (userPermissions.some(p => p.name === "ViewPartners")) {
                dispatch(partnersActions.loadPartners()).catch(error => {
                    console.log("Loading Partners Failed:" + error);
                });
            }
        }).catch(error => {
            toast.error("Loading User Permissions Failed:" + error, { autoClose: false });
        });
        dispatch(permissionsActions.loadPermissions()).catch(error => {
            toast.error("Loading Permissions Failed:" + error, { autoClose: false });
        });
        ModalBackgroundRemover.removeModalBackground();
    }, []);

    useEffect(() => {
        let noFilters = true;
        for (const text in textFilters) {
            if (textFilters[text] != "") {
                noFilters = false;
                setColumnFiltersPopulated(true);
            }
        }
        if (noFilters) {
            setColumnFiltersPopulated(false);
        }
    }, [textFilters])

    const handleNewRole = () => {
        dispatch(pageActions.updateSelectedRole({
            partnerId: 0,
            permissions: [],
            canAssignRoles: []
        }));
        new bootstrap.Modal(document.getElementById('edit-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleDeleteRoleConfirmed = (event) => {
        event.preventDefault();
        dispatch(rolesActions.deleteRole(selectedRole))
            .then(() => {
                dispatch(rolesActions.loadRoles()).then(() => {
                    toast.success(selectedRole.name + " has been deleted");
                    bootstrap.Modal.getInstance(document.getElementById('delete-modal')).hide();
                });
            })
            .catch(error => {
                toast.error("Delete Role Failed: " + error, { autoClose: false })
            });
    }

    const handleSearchTermChange = (event) => {
        dispatch(pageActions.updateSearchTerm("roles", event.target.value));
    }

    const handleToggleFilterByColumn = () => {
        dispatch(pageActions.toggleFilterByColumn("roles", !filterByColumn));
    }

    const handleClearFilterByColumn = () => {
        dispatch(pageActions.clearTextFilters("roles"));
        setColumnFiltersPopulated(false);
    }

    return (
        <>
            <div id="view-all" className={ "d-flex flex-column oasis-rounded h-100 " + theme }>
                <div className="administration-search-container d-flex flex-row px-4">
                    <div className="d-flex ms-auto">
                        <div className="my-auto">
                            <a
                                onClick={ handleToggleFilterByColumn }
                                className={ theme + " column-filter-icon media-controlled" }
                                data-tip="Show/Hide Filters"
                                data-type={ theme }
                                data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                data-delay-show="500"
                                data-border={ true }
                            >
                                {
                                    filterByColumn ?
                                        <FontAwesomeIcon icon="fa-solid fa-filters" />
                                        : <FontAwesomeIcon icon="fa-regular fa-filters" />
                                }
                            </a>
                            <a
                                onClick={ handleClearFilterByColumn }
                                className={ theme + " column-filter-icon media-controlled" }
                                data-tip="Clear Filters"
                                data-type={ theme }
                                data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                data-delay-show="500"
                                data-border={ true }
                                data-event-off="click"
                            >
                                {
                                    columnFiltersPopulated ?
                                        <FontAwesomeIcon icon="fa-solid fa-filter-circle-xmark" />
                                        : <FontAwesomeIcon icon="fa-regular fa-filter-circle-xmark" />
                                }
                            </a>
                        </div>
                        <div className="my-auto me-3">
                            <FontAwesomeIcon className="searchIcon media-controlled" icon="fas fa-search" />
                            <input
                                onChange={ handleSearchTermChange }
                                id="search_term_roles"
                                className={ "my-auto search-input media-controlled " + theme }
                                autocomplete="off"
                                name="RolesSearchTerm"
                                type="text"
                                value={ searchTerm }
                            />
                        </div>
                    </div>
                </div>
                <div className="administration-list d-flex flex-row">
                    <FilterSlideout RecordType={ "roles" } theme={ theme } />
                    <div className="d-flex flex-column w-100 h-100">
                        <PaginatedList RecordType="roles" theme={ theme } />
                    </div>
                </div>
            </div>
            <RoleEdit theme={ theme } />
            <ConfirmationModal
                title="Delete Confirmation"
                id="delete-modal"
                displayValue={ "Are you sure you want to delete " + selectedRole.name + "?" }
                onSubmit={ handleDeleteRoleConfirmed }
                theme={ theme }
            />
        </>
    )
}

export default RolesPage;