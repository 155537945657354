import * as types from '../actions/types';
import initialState from './InitialState';

export default function userPreferencesReducer(state = initialState.userPreferences, action) {
    switch (action.type) {
        case types.LOAD_USER_PREFERENCES_SUCCESS:
            return action.userPreferences;
        case types.SAVE_USER_PREFERENCE_SUCCESS:
            return state.map(userPreference => {
                if (userPreference.userPreferenceId === action.userPreference.userPreferenceId) {
                    return action.userPreference;
                } else {
                    return userPreference;
                }
            })
        default:
            return state;
    }
}