import React, { useEffect } from 'react';
import PaginatedResourceCard from './PaginatedResourceCardWidget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ResourceCardPreview({ theme }) {

    useEffect(() => {
        let homeResourceCard = document.querySelector('#home-resource-card');
        let previewResourceCard = document.querySelector('#resource-card-preview');
        let domRect = homeResourceCard.getBoundingClientRect();
        previewResourceCard.style.width = domRect.width + 'px';
        previewResourceCard.style.height = domRect.height + 'px';
    }, [])

    return (
        <>
            <div className="modal fade" tabindex="-1" role="dialog" id="resource-card-preview-modal" >
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className={ "modal-content-dark-bg modal-content resource-card-preview-modal " + theme }>
                        <form>
                            <div className="modal-header pb-0">
                                <h2 className="modal-title">Resource Card Preview</h2>
                                <a className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon="fas fa-times" />
                                </a>
                            </div>
                            <div className="modal-body p-0">
                                <div className="container-fluid p-0">
                                    <div className="mx-auto mb-4 mt-4" id="resource-card-preview">
                                        <PaginatedResourceCard isPreview={ true } theme={ theme } />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div >
                </div >
            </div >
        </>
    )
}

export default ResourceCardPreview;