import * as types from "./types";
import * as linksService from "../../services/linksService";

export function loadAvailableLinksSuccess(available) {
    return { type: types.LOAD_AVAILABLE_LINKS_SUCCESS, available }
}

export function loadGlobalLinksSuccess(globalLinks) {
    return { type: types.LOAD_GLOBAL_LINKS_SUCCESS, globalLinks }
}

export function loadAvailableLinks() {
    return function (dispatch) {
        return linksService.getAvailableLinks()
            .then(available => {
                dispatch(loadAvailableLinksSuccess(available));
            })
            .catch(error => {
                throw error;
            });
    }
}

export function loadGlobalLinks() {
    return function (dispatch) {
        return linksService.getGlobalLinks()
            .then(globalLinks => {
                dispatch(loadGlobalLinksSuccess(globalLinks));
            })
            .catch(error => {
                throw error;
            });
    };
}