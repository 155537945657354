import React, { useState, useRef } from 'react';
import ReactDOM from "react-dom";
import { NavLink } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CContainer, CNavbar, CCollapse, CNavbarNav, CNavItem } from '@coreui/bootstrap-react';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { usePopper } from 'react-popper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { config } from '../config';
import ReactTooltip from 'react-tooltip';
import * as pageActions from '../redux/actions/pageActions';
import * as userPreferencesActions from '../redux/actions/userPreferencesActions';
import * as auditsService from '../services/auditsService';
import * as relativityStatsActions from '../redux/actions/relativityStatsActions';
import * as fileTransferStatsActions from '../redux/actions/fileTransferStatsActions';
import * as supportStatsActions from '../redux/actions/supportStatsActions';

function SideNavMenu({ sideNavState, theme }) {

    const [isActive, setIsActive] = useState("");
    const [width, setWidth] = useState(0);
    const [userPreferredSideNav, setUserPreferredSideNav] = useState("Open");
    const showRelativityPopup = useRef(false);
    const showFileTransferPopup = useRef(false);

    const auth = useSelector(state => state.auth);
    const userPermissions = useSelector(state => state.auth.permissions);
    const relativityStats = useSelector(state => state.relativityStats);
    const fileTransferStats = useSelector(state => state.fileTransferStats);
    const supportStats = useSelector(state => state.supportStats);
    const userPreferences = useSelector(state => state.userPreferences);
    const minWidth = 1360;

    const [relativityReferenceElement, setRelativityReferenceElement] = useState(null);
    const [relativityPopperElement, setRelativityPopperElement] = useState(null);
    const { styles: relativityPopperStyles, attributes: relativityPopperAttributes } = usePopper(relativityReferenceElement, relativityPopperElement, { placement: "right" });
    const relativitySideNavChildRef = useRef();

    const [fileTransferReferenceElement, setFileTransferReferenceElement] = useState(null);
    const [fileTransferPopperElement, setFileTransferPopperElement] = useState(null);
    const { styles: fileTransferPopperStyles, attributes: fileTransferPopperAttributes } = usePopper(fileTransferReferenceElement, fileTransferPopperElement, { placement: "right" });
    const fileTransferSideNavChildRef = useRef();

    const adminHomeLink = auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewUserStatus") ?
        "/Administration/UserStatus" :
        auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewAudits") ?
            "/Administration/Audits" :
            auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewRoles") ?
                "/Administration/Roles" :
                "/Administration/Maintenance";
    const adminHomePageName = auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewUserStatus") ?
        "UserStatus" :
        auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewAudits") ?
            "Audits" :
            auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewRoles") ?
                "Roles" :
                "Maintenance";
    const dispatch = useDispatch();

    useEffect(() => {
        ReactTooltip.rebuild();
    })

    useEffect(() => {
        setUserPreferredSideNav(sideNavState)
        window.addEventListener("resize", handleWindowResize);
        window.addEventListener("mousedown", handleClickOutsideSideNavPopups);

        //cleanup listeners to prevent memory leak
        return () => {
            window.removeEventListener("resize", handleWindowResize);
            window.removeEventListener("mousedown", handleClickOutsideSideNavPopups);
        }
    }, [])

    useEffect(() => {
        if (width === 0) { return; }
        if (!auth.user) { return; }

        if (width < minWidth && sideNavState === "open") {
            updateSideNavMenuState("Closed")
        } else if (width >= minWidth && userPreferredSideNav.toLowerCase() === "open") {
            updateSideNavMenuState("Open")
        }
    }, [width])

    useEffect(() => {
        if (auth.user) {
            dispatch(relativityStatsActions.loadRelativityInstances()).catch(error => {
                toast.error("Loading Relativity Instances Failed:" + error, { autoClose: false })
            })
            dispatch(fileTransferStatsActions.loadPartnerPortals()).catch(error => {
                toast.error("Loading Partner Portals Failed:" + error, { autoClose: false })
            });
            dispatch(supportStatsActions.loadSupportUrl()).catch(error => {
                toast.error("Loading Support Url Failed:" + error, { autoClose: false })
            });
        }
    }, [auth.user])

    useEffect(() => {
        if (theme == 'light') {
            document.body.style.backgroundColor = '#F4F7FC';
            document.body.style.color = '#131523';
        } else if (theme == 'dark') {
            document.body.style.backgroundColor = '#161616';
            document.body.style.color = '#F4F7FC';
        }
    }, [theme])

    useEffect(() => {
        if (window.location.pathname.includes("/Administration")) {
            setIsActive(" active");
        } else {
            setIsActive("");
        }
    }, [window.location.pathname]);

    const handleWindowResize = () => {
        setWidth(window.innerWidth)
    }

    const handleClickOutsideSideNavPopups = (event) => {
        if (relativitySideNavChildRef.current && !relativitySideNavChildRef.current.contains(event.target)) {
            if (!event.target.classList.contains('relativity-side-nav')) {
                showRelativityPopup.current = false;
            }
        }

        if (fileTransferSideNavChildRef.current && !fileTransferSideNavChildRef.current.contains(event.target)) {
            if (!event.target.classList.contains('filetransfer-side-nav')) {
                showFileTransferPopup.current = false;
            }
        }
    }

    const handleNavClickLogging = (pageName) => {
        auditsService.logPageView(pageName);
        if (pageName === "Knowledge Base") {
            dispatch(pageActions.clearSelectedKBContent());
        }
    }

    const handleLogLoginSupport = () => {
        auditsService.logLoginSupport();
    }

    const handleFileTransferNavLogging = (portalName) => {
        auditsService.logContentViewWithInfo("File Transfer", "Navigated to - " + portalName);
    }

    const handleThemeChange = () => {
        let updatedThemePreference = [...userPreferences].find(x => x.preferenceName === 'Theme');

        if (updatedThemePreference.preferenceValue === "Light") {
            updatedThemePreference.preferenceValue = "Dark";
        } else if (updatedThemePreference.preferenceValue === "Dark") {
            updatedThemePreference.preferenceValue = "Light";
        }
        dispatch(userPreferencesActions.saveUserPreference(updatedThemePreference))
            .then(empty => {
                userPreferencesActions.loadUserPreferences();
            }).catch(error => {
                toast.error("Theme Change Failed: " + error, { autoClose: false });
            });
    }

    const handleToggleSideNav = () => {
        var toggledSideNavState = sideNavState === "open" ? "Closed" : "Open";
        updateSideNavMenuState(toggledSideNavState);
        setUserPreferredSideNav(toggledSideNavState);
    }

    var updateSideNavMenuState = (state) => {
        if (sideNavState != state) {
            let updatedSideNavStatePreference = [...userPreferences].find(x => x.preferenceName === 'SideNavState');

            updatedSideNavStatePreference.preferenceValue = state;

            dispatch(userPreferencesActions.saveUserPreference(updatedSideNavStatePreference))
                .then(empty => {
                    userPreferencesActions.loadUserPreferences();
                }).catch(error => {
                    toast.error("Side Navigation Collapse Change Failed: " + error, { autoClose: false });
                });
        }
    }

    const handleRelativitySideNavClick = () => {
        showRelativityPopup.current = !(showRelativityPopup.current);
    }

    const handleFileTransferSideNavClick = () => {
        showFileTransferPopup.current = !(showFileTransferPopup.current);
    }

    return (
        <>
            <div className={ sideNavState + " side-nav-container-spacer col p-0" } />
            <CNavbar className={ theme + " side-nav side-nav-body side-nav-bar-container col p-0 " + sideNavState } expand={ true }>
                <CContainer className={ "side-nav p-0 m-0 " + sideNavState }>
                    <CCollapse className={ "navbar-collapse side-nav " + sideNavState } visible={ false }>
                        <CNavbarNav className={ "navbar-nav side-nav " + sideNavState }>
                            <CNavItem className={ "side-nav-item " + sideNavState }>
                                <NavLink
                                    className="side-nav-link"
                                    onClick={ () => handleNavClickLogging("Home") }
                                    tag={ RRNavLink }
                                    to="/"
                                    end
                                    data-tip={ sideNavState === "open" ? "" : "Home" }
                                    data-delay-show="1000"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-border={ true }
                                >
                                    <span className="nav-icon col text-center"><FontAwesomeIcon icon="fa-solid fa-house" /></span>
                                    { sideNavState === "open" ? <span className="nav-text col">Home</span> : "" }
                                </NavLink>
                            </CNavItem>
                            { auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewPartners") ?
                                <CNavItem className={ "side-nav-item " + sideNavState }>
                                    <NavLink
                                        className="side-nav-link"
                                        onClick={ () => handleNavClickLogging("Partners") }
                                        tag={ RRNavLink }
                                        to="/Partners"
                                        data-tip={ sideNavState === "open" ? "" : "Partners" }
                                        data-delay-show="1000"
                                        data-type={ theme }
                                        data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                        data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                        data-border={ true }
                                    >
                                        <span className="nav-icon col text-center"><FontAwesomeIcon icon="fa-solid fa-handshake-simple" /></span>
                                        { sideNavState === "open" ? <span className="nav-text col">Partners</span> : "" }
                                    </NavLink>
                                </CNavItem>
                                : ""
                            }
                            { auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewUsers") ?
                                <CNavItem className={ "side-nav-item " + sideNavState }>
                                    <NavLink
                                        className="side-nav-link"
                                        onClick={ () => handleNavClickLogging("Users") }
                                        tag={ RRNavLink }
                                        to="/Users"
                                        data-tip={ sideNavState === "open" ? "" : "Users" }
                                        data-delay-show="1000"
                                        data-type={ theme }
                                        data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                        data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                        data-border={ true }
                                    >
                                        <span className="nav-icon col text-center"><FontAwesomeIcon icon="fa-solid fa-users" /></span>
                                        { sideNavState === "open" ? <span className="nav-text col">Users</span> : "" }
                                    </NavLink>
                                </CNavItem>
                                : ""
                            }
                            { auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ViewVirtualMachines") ?
                                <CNavItem className={ "side-nav-item " + sideNavState }>
                                    <NavLink
                                        className="side-nav-link"
                                        onClick={ () => handleNavClickLogging("Virtual Machines") }
                                        tag={ RRNavLink }
                                        to="/VirtualMachines"
                                        data-tip={ sideNavState === "open" ? "" : "Virtual Machines" }
                                        data-delay-show="1000"
                                        data-type={ theme }
                                        data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                        data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                        data-border={ true }
                                    >
                                        <span className="nav-icon col text-center"><FontAwesomeIcon icon="fa-solid fa-network-wired" /></span>
                                        { sideNavState === "open" ? <span className="nav-text col">Virtual Machines</span> : "" }
                                    </NavLink>
                                </CNavItem>
                                : ""
                            }
                            { auth.user ?
                                <CNavItem className={ "side-nav-item " + sideNavState }>
                                    <NavLink
                                        className={ "side-nav-link " + sideNavState }
                                        onClick={ () => handleNavClickLogging("Knowledge Base") }
                                        tag={ RRNavLink }
                                        to="/KnowledgeBase"
                                        data-tip={ sideNavState === "open" ? "" : "Knowledge Base" }
                                        data-delay-show="1000"
                                        data-type={ theme }
                                        data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                        data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                        data-border={ true }
                                    >
                                        <span className="nav-icon col text-center"><FontAwesomeIcon icon="fa-solid fa-book" /></span>
                                        { sideNavState === "open" ? <span className="nav-text col">Knowledge Base</span> : "" }
                                    </NavLink>
                                </CNavItem>
                                : ""
                            }
                            <CNavItem className={ "side-nav-item " + sideNavState }>
                                <NavLink
                                    className={ "side-nav-link " + sideNavState + (supportStats.url ? "" : " disabledLink") }
                                    onClick={ () => handleLogLoginSupport() }
                                    href={ supportStats.url ? supportStats.url : "" }
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    data-tip={ sideNavState === "open" ? "" : "Support" }
                                    data-delay-show="1000"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-border={ true }
                                >
                                    <span className="nav-icon col text-center"><FontAwesomeIcon icon='fas fa-wrench' /></span>
                                    { sideNavState === "open" ? <span className='nav-text col'>Support</span> : "" }
                                </NavLink>
                            </CNavItem>
                            <CNavItem className={ "side-nav-item " + sideNavState } ref={ setRelativityReferenceElement }>
                                <span
                                    className="relativity-side-nav side-nav-link nav-link"
                                    data-tip={ sideNavState === "open" ? "" : "Relativity" }
                                    data-delay-show="2000"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-border={ true }
                                >
                                    { relativityStats.instances.length < 1 ?
                                        <a className="side-nav-external-link disabledLink">
                                            <span className="fa-layers fa-fw">
                                                <FontAwesomeIcon icon='far fa-square' transform="down-3 left-3" />
                                                <FontAwesomeIcon icon='far fa-square' transform="up-3 right-3" />
                                            </span>
                                            { sideNavState === "open" ? <span className='nav-text'>Relativity</span> : "" }
                                        </a>
                                        : relativityStats.instances.length == 1 ?
                                            <a
                                                className="side-nav-external-link"
                                                href={ config.IDENTITY_URL + 'api/Relativity/SignIn?serviceProviderId=' + relativityStats.instances[0].baseUrl + '/Relativity/' }
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <span className="fa-layers fa-fw">
                                                    <FontAwesomeIcon icon='far fa-square' transform="down-3 left-3" />
                                                    <FontAwesomeIcon icon='far fa-square' transform="up-3 right-3" />
                                                </span>
                                                { sideNavState === "open" ? <span className='nav-text'>Relativity</span> : "" }
                                            </a>
                                            : <>
                                                <div onClick={ () => handleRelativitySideNavClick() } className='relativity-side-nav side-nav-external-link dropend side-nav-dropdown'>
                                                    <span className="relativity-side-nav fa-layers fa-fw">
                                                        <FontAwesomeIcon className='relativity-side-nav' icon='far fa-square' transform="down-3 left-3" />
                                                        <FontAwesomeIcon className='relativity-side-nav' icon='far fa-square' transform="up-3 right-3" />
                                                    </span>
                                                    { sideNavState === "open" ?
                                                        <>
                                                            <span className='relativity-side-nav nav-text'>Relativity</span>
                                                            <span className="relativity-side-nav pe-2 side-nav-arrow">
                                                                <FontAwesomeIcon className='relativity-side-nav' icon='fas fa-angle-right' />
                                                            </span>
                                                        </>
                                                        : ""
                                                    }
                                                </div>
                                            </>
                                    }
                                </span>
                            </CNavItem>
                            <CNavItem className={ "side-nav-item " + sideNavState } ref={ setFileTransferReferenceElement }>
                                <span
                                    className="side-nav-link nav-link"
                                    data-tip={ sideNavState === "open" ? "" : "File Transfer" }
                                    data-delay-show="1000"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-border={ true }
                                >
                                    { fileTransferStats.portals.length > 1 ?
                                        <>
                                            <div onClick={ () => handleFileTransferSideNavClick() } className='filetransfer-side-nav side-nav-external-link dropend side-nav-dropdown'>
                                                <span className="filetransfer-side-nav text-center">
                                                    <FontAwesomeIcon className='filetransfer-side-nav' icon='fas fa-exchange-alt' />
                                                </span>
                                                { sideNavState === "open" ?
                                                    <>
                                                        <span className='filetransfer-side-nav nav-text'>File Transfer</span>
                                                        <span className="filetransfer-side-nav pe-2 side-nav-arrow text-center">
                                                            <FontAwesomeIcon className='filetransfer-side-nav' icon='fas fa-angle-right' />
                                                        </span>
                                                    </>
                                                    : ""
                                                }
                                            </div>
                                        </> :
                                        fileTransferStats.portals.length > 0 ?
                                            <>
                                                <a
                                                    className="side-nav-external-link"
                                                    onClick={ () => handleFileTransferNavLogging(fileTransferStats.portals[0].name) }
                                                    href={ 'https://' + fileTransferStats.portals[0].url }
                                                    target='_blank'
                                                >
                                                    <FontAwesomeIcon className="pe-1 text-center" icon='fas fa-exchange-alt' />
                                                    { sideNavState === "open" ? <span className='nav-text'>File Transfer</span> : "" }
                                                </a>
                                            </> :
                                            <a className='side-nav-external-link disabledLink'>
                                                <FontAwesomeIcon className="pe-1 text-center" icon='fas fa-exchange-alt' />
                                                { sideNavState === "open" ? <span className='nav-text'>File Transfer</span> : "" }
                                            </a>
                                    }
                                </span>
                            </CNavItem>
                            { auth.user && userPermissions.length > 0 &&
                                userPermissions.some(p => p.name === "ViewUserStatus") ||
                                userPermissions.some(p => p.name === "ViewAudits") ||
                                userPermissions.some(p => p.name === "ViewRoles") ||
                                userPermissions.some(p => p.name === "ViewMaintenance") ?
                                <CNavItem className={ "side-nav-item " + sideNavState }>
                                    <NavLink
                                        className={ "side-nav-external-link side-nav-link" + isActive }
                                        onClick={ () => handleNavClickLogging(adminHomePageName) }
                                        tag={ RRNavLink }
                                        to={ adminHomeLink }
                                        data-tip={ sideNavState === "open" ? "" : "Administration" }
                                        data-delay-show="1000"
                                        data-type={ theme }
                                        data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                        data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                        data-border={ true }
                                    >
                                        <span
                                            className="nav-icon col text-center">
                                            <FontAwesomeIcon icon="fa-solid fa-gear" />
                                        </span>
                                        { sideNavState === "open" ? <span className="nav-text col">Administration</span> : "" }
                                    </NavLink>
                                </CNavItem>
                                : ""
                            }
                            <div
                                id="themeToggle"
                                className={ "mt-auto " + theme + " " + sideNavState }
                                onClick={ handleThemeChange }
                                data-tip={ sideNavState === "open" ? "" : "Dark Mode" }
                                data-delay-show="1000"
                                data-type={ theme }
                                data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                data-border={ true }
                            >
                                <span className={ "theme-icon col text-center " + theme }><FontAwesomeIcon icon="fa-solid fa-lightbulb" /></span>
                                { sideNavState === "open" ? <span className="theme-text col">Dark Mode</span> : "" }
                            </div>
                            <div onClick={ () => handleToggleSideNav() } className={ "btn btn-side-nav-show " + theme + " " + sideNavState }
                                data-tip={ sideNavState === "open" ? "Click to collapse Navigation Bar" : "Click to expand Navigation Bar" }
                                data-delay-show="1000"
                                data-type={ theme }
                                data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                data-border={ true }
                            >
                                { sideNavState === "open" ? <FontAwesomeIcon icon="fas fa-angle-double-left" /> : <FontAwesomeIcon icon="fas fa-angle-double-right" /> }
                            </div>
                        </CNavbarNav>
                    </CCollapse>
                </CContainer>
            </CNavbar >
            { ReactDOM.createPortal(
                <>
                    { showRelativityPopup.current ?
                        <div
                            ref={ setRelativityPopperElement }
                            style={ { ...relativityPopperStyles.popper, zIndex: 3 } }
                            { ...relativityPopperAttributes.popper }
                        >
                            <div ref={ relativitySideNavChildRef } className={ 'sidenav-popup ' + theme + " " + sideNavState }>
                                { relativityStats.instances.map(relativityInstance => (
                                    <a
                                        onClick={ () => showRelativityPopup.current = false }
                                        className={ 'dropdown-item ' + theme }
                                        href={ config.IDENTITY_URL + 'api/Relativity/SignIn?serviceProviderId=' + relativityInstance.baseUrl + '/Relativity/' }
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        { relativityInstance.friendlyName }
                                    </a>
                                )) }
                            </div>

                        </div>
                        : "" }

                    { showFileTransferPopup.current ?
                        <div
                            ref={ setFileTransferPopperElement }
                            style={ { ...fileTransferPopperStyles.popper, zIndex: 3 } }
                            { ...fileTransferPopperAttributes.popper }
                        >
                            <div ref={ fileTransferSideNavChildRef } className={ 'sidenav-popup ' + theme + " " + sideNavState }>
                                { fileTransferStats && fileTransferStats.portals.map(portal => (
                                    <a
                                        className={ 'dropdown-item ' + theme }
                                        onClick={ () => {
                                            handleFileTransferNavLogging(portal.name);
                                            showFileTransferPopup.current = false;
                                        }}
                                        href={ 'https://' + portal.url }
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        { portal.name }
                                    </a>
                                )) }
                            </div>

                        </div>
                        : "" }
                </>,
                document.body
            ) }

        </>
    );
}

export default SideNavMenu;