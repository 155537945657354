import React, { useEffect, useState } from 'react'
import * as pageActions from '../../../redux/actions/pageActions';
import * as dateUtils from '../../../utils/dateUtils';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function AuditDetails({ theme }) {

    const [activeTab, setActiveTab] = useState("Info");

    const selectedAuditDetails = useSelector(state => state.page.audits.selectedAuditDetails);
    const dispatch = useDispatch();

    useEffect(() => {
        var detailsModal = document.getElementById('details-modal');
        detailsModal.addEventListener('hidden.bs.modal', () => {
            dispatch(pageActions.clearSelectedAuditDetails());
        });
    }, [])

    return (
        <div className="modal fade" tabindex="-1" role="dialog" id='details-modal' >
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className={ "modal-content " + theme }>
                    <div className="modal-header bottom-border">
                        <h2 className="modal-title">Audit Details</h2>
                        <a className="close" data-bs-dismiss="modal" aria-label="Close">
                            <FontAwesomeIcon icon="fas fa-times" />
                        </a>
                    </div>
                    <div className="modal-body p-0">
                        <div className="container-fluid">
                            <div className="row pt-4">
                                <div className="col-md-2">
                                    Timestamp :
                                </div>
                                <div className="col-md-10">
                                    { dateUtils.getLocalTime(selectedAuditDetails.userActionAuditLogEntry.createdDate) } { dateUtils.getLocalDate(selectedAuditDetails.userActionAuditLogEntry.createdDate) }
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-2">
                                    User :
                                </div>
                                <div className="col-md-10">
                                    { selectedAuditDetails.userActionAuditLogEntry ? selectedAuditDetails.userActionAuditLogEntry.userEmail : "" }
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-2">
                                    Partner :
                                </div>
                                <div className="col-md-10">
                                    { selectedAuditDetails.userActionAuditLogEntry ? selectedAuditDetails.userActionAuditLogEntry.partnerName : "" }
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-2">
                                    Action :
                                </div>
                                <div className="col-md-10">
                                    { selectedAuditDetails.userActionAuditLogEntry ? selectedAuditDetails.userActionAuditLogEntry.action : "" }
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-2">
                                    Type :
                                </div>
                                <div className="col-md-10">
                                    { selectedAuditDetails.userActionAuditLogEntry ? selectedAuditDetails.userActionAuditLogEntry.actedUpon : "" }
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-md-2">
                                    Name :
                                </div>
                                <div className="col-md-10">
                                    { selectedAuditDetails.userActionAuditLogEntry ? selectedAuditDetails.userActionAuditLogEntry.name : "" }
                                </div>
                            </div>
                            <div className="row pt-3 pb-4">
                                <div className="col-md-2">
                                    Details :
                                </div>
                                <div className="col-md-10 text-break scrollable-additional-details-content">
                                    { selectedAuditDetails.detail }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuditDetails;