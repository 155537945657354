import * as types from '../actions/types';
import initialState from './InitialState';

export default function blackoutStatsReducer(state = initialState.blackoutStats, action) {
    switch (action.type) {
        case types.LOAD_BLACKOUT_STATS_SUCCESS:
            return { ...state, stats: action.blackoutStats.stats, title: action.blackoutStats.title, error: action.blackoutStats.error };
        case types.CLEAR_BLACKOUT_STATS:
            return { ...state, stats: initialState.blackoutStats.stats, title: initialState.blackoutStats.title, error: initialState.blackoutStats.error };
        default:
            return state;
    }
}