import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CTableDataCell, CFormSwitch } from '@coreui/bootstrap-react';
import { DateRangePicker } from 'rsuite';
import * as settingsActions from '../../../redux/actions/settingsActions';
import * as styles from '../DropdownStyles';

function RangeFilter({ RecordType, Filter }) {

    const filters = useSelector(state => state.settings[RecordType].filters);
    const filterSettings = useSelector(state => state.settings[RecordType].filters[Filter.filterIdentifier]);
    const dispatch = useDispatch();

    const handleDateChange = (event) => {
        if (event) {
            filterSettings.value.from = event[0];
            filterSettings.value.to = event[1];
        } else {
            filterSettings.value.from = "";
            filterSettings.value.to = "";

        }
        if (filterSettings.value.from != "") {
            filterSettings.isActive = true;
        } else {
            filterSettings.isActive = false;
        }
        dispatch(settingsActions.updateFilterSetting(RecordType, filters));
    }

    return (
        <>
            <CTableDataCell colSpan="3">
                <DateRangePicker
                    showOneCalendar
                    placeholder="Select Date Range..."
                    format="MM-dd-yyyy"
                    onChange={ handleDateChange }
                />
            </CTableDataCell>
        </>
    )
}

export default RangeFilter;