import axios from 'axios'
import { config } from '../config';

export async function getAuthServices() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/services/auth');
    return response.data;
}

export async function getPartnerServices() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/services/partner');
    return response.data;
}