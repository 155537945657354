import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoTruncateText from '../../../common/AutoTruncateText';

function StatCard({ Stats, theme, ReloadStats, sideNavState, isInEditMode = false, onDeleteItem }) {

    const widgetHeight = useSelector(state => state.page.home.dashboardLayout.lg.find(x => x.i == Stats.title).h);

    const resizableDivRef = useRef(null);
    const titleRef = useRef(null);
    const statContentRef = useRef(null);

    useEffect(() => {
        updateFontSize();
    }, [resizableDivRef?.current?.offsetWidth, resizableDivRef?.current?.offsetHeight]);

    const updateFontSize = () => {
        //lower size multiplier allows ref element to be bigger 
        updateElementSize(titleRef, 12, 35, 35);
        updateElementSize(statContentRef, 10, 20, 45);
    }

    const updateElementSize = (refItem, minSize, maxSize, sizeMultiplier) => {
        const element = refItem.current;
        const resizableDivElement = resizableDivRef.current;

        const fontSize = (resizableDivElement.offsetHeight + resizableDivElement.offsetWidth) / sizeMultiplier;

        element.style.fontSize = fontSize + 'px';

        //handle min max size of header
        if (fontSize < minSize || widgetHeight < 4) {
            element.style.fontSize = minSize + 'px';
        }

        if (fontSize > maxSize) {
            element.style.fontSize = maxSize + 'px';
        }
    }

    return (
        <div ref={ resizableDivRef } className={ theme + " d-flex flex-column py-3 px-4 oasis-rounded widget-container stat-card " + sideNavState }>
            { isInEditMode == true ?
                <div onClick={ () => onDeleteItem() }>
                    <a className='close btn-widget-delete'>
                        <FontAwesomeIcon icon="fas fa-times" beat={ true } />
                    </a>
                </div> :
                ""
            }
            <div className="row pt-1">
                <div className="col pb-2 text-nowrap fw-bold">
                    <div ref={ titleRef }>{ Stats.title }</div>
                </div>
                { Stats.error ?
                    <div className="col-1 ms-auto">
                        <a onClick={ ReloadStats }><FontAwesomeIcon icon="fa-solid fa-arrow-rotate-right" /></a>
                    </div> : ''
                }
            </div>
            <div ref={ statContentRef } className="d-flex flex-column w-100 justify-content-start">
                { Stats.stats.length > 0 ?
                    Stats.stats.map(stat => {
                        return (
                            <div className="d-flex flex-row ps-4 pt-2 justify-content-between">
                                <div className="pe-2 d-inline-block"><AutoTruncateText text={ stat.label } theme={ theme } /></div>
                                <div className="me-5">{ stat.value }</div>
                            </div>
                        )
                    })
                    :
                    (Stats.error ?
                        <div className="mt-2 mx-4">
                            { Stats.error }
                        </div>
                        :
                        <div className="my-auto mx-auto">
                            <FontAwesomeIcon icon="fas fa-spinner" pulse />
                        </div>)
                }
            </div>
        </div>
    )
}

export default StatCard;