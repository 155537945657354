import axios from 'axios'
import { config } from '../config';

export async function getBooks() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/knowledgebase/books');
    return response.data;
}

export async function getContent(type, contentId) {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/knowledgebase/content/' + type + '/' + contentId);
    return response.data;
}

export async function searchKnowledgeBase(query) {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/knowledgebase/search?query=' + encodeURIComponent(query))
    return response.data;
}

export async function getPdf(contentId) {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/knowledgebase/exportPdf?contentId=' + contentId );
    return response.data;
}