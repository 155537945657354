import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/bootstrap-react';
import * as bootstrap from 'bootstrap';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { toast } from 'react-toastify';
import * as fileTransferActions from '../../redux/actions/fileTransferActions';
import * as jiraActions from '../../redux/actions/jiraActions';
import * as linksActions from '../../redux/actions/linksActions';
import * as pageActions from '../../redux/actions/pageActions';
import * as partnersActions from '../../redux/actions/partnersActions';
import * as servicesActions from '../../redux/actions/servicesActions';
import * as usersActions from '../../redux/actions/usersActions';
import * as partnersService from '../../services/partnersService';
import ConfirmationModal from '../common/ConfirmationModal';
import * as styles from '../common/DropdownStyles';
import TextInput from '../common/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PartnerEdit({ theme }) {

    const [isLoading, setIsLoading] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [editModal, setEditModal] = useState();
    const [refreshPartnerToastId, setRefreshPartnerToastId] = useState("");
    const [newJiraOrganizations, setNewJiraOrganizations] = useState([]);
    const [activeTab, setActiveTab] = useState("Info");
    const [errors, setErrors] = useState({});
    const [billingPocList, setBillingPocList] = useState([]);
    const [newBillingPocList, setNewBillingPocList] = useState([]);
    const [salesPocList, setSalesPocList] = useState([]);
    const [newSalesPocList, setNewSalesPocList] = useState([]);
    const [operationalPocList, setOperationalPocList] = useState([]);
    const [newOperationalPocList, setNewOperationalPocList] = useState([]);
    const [authPocList, setAuthPocList] = useState([]);
    const [newAuthPocList, setNewAuthPocList] = useState([]);
    const [partnerUsers, setPartnerUsers] = useState([]);
    const [partner, setPartner] = useState(useSelector(state => state.page.partners.selectedPartner));

    const sortJiraOrganizations = (organizationA, organizationB) => {
        var x = organizationA.name.toLowerCase();
        var y = organizationB.name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    }

    const sortGlobalLinks = (globalLinkA, globalLinkB) => {
        var x = globalLinkA.label.toLowerCase();
        var y = globalLinkB.label.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    }

    const selectedPartner = useSelector(state => state.page.partners.selectedPartner);
    const allPartners = useSelector(state => state.partners);
    const users = useSelector(state => state.users);
    const authServices = useSelector(state => state.services.auth);
    const availableSubscriptions = useSelector(state => state.groups.availableSubscriptions);
    const availablePocs = useSelector(state => state.groups.availablePocs);
    const jiraOrganizations = useSelector(state => state.jira.availableOrganizations.sort(sortJiraOrganizations));
    const portals = useSelector(state => state.fileTransfer.availablePortals);
    const globalLinks = useSelector(state => state.links.global);
    const userPermissions = useSelector(state => state.auth.permissions);
    const jiraGroups = useSelector(state => state.jira.availableGroups);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(linksActions.loadGlobalLinks()).catch(error => {
            toast.error("Loading Global Links Failed:" + error, { autoClose: false });
        });
        dispatch(fileTransferActions.loadAvailablePortals()).catch(error => {
            toast.error("Loading Available Portals Failed:" + error, { autoClose: false });
        });
        dispatch(servicesActions.loadAuthServices()).catch(error => {
            toast.error("Loading Available Auth Services Failed:" + error, { autoClose: false });
        });
        if (userPermissions.some(p => p.name === "ViewJiraOrganizations")) {
            dispatch(jiraActions.loadAvailableJiraOrganizations()).catch(error => {
                toast.error("Loading Available Jira Organizations Failed:" + error, { autoClose: false });
            });
        }
        if (userPermissions.some(p => p.name === "ViewJiraGroups")) {
            dispatch(jiraActions.loadAvailableJiraGroups()).catch(error => {
                toast.error("Loading Available Jira Groups Failed:" + error, { autoClose: false });
            });
        }
        return () => {
            dispatch(pageActions.clearSelectedPartner());
        };
    }, [])

    const refreshMessage = (partner) => (
        <div className="container">
            <div className="row py-2">
                { partner.name }<br />has been modified:
            </div>
            <div className="row py-2">
                To refresh click this message.
            </div>
            <div className="row py-2 pt-1 tiny-msg">
                Please note that refreshing will overwrite any current changes and saving new changes will not be permitted without a refresh.
            </div>
        </div>
    );

    const refreshPartner = (partnerId, dismissToast = false) => {
        if (editModal && editModal._isShown) {
            setIsLoading(true);
            partnersService.getPartner(partnerId)
                .then(returnedPartner => {
                    dispatch(partnersActions.loadPartnerSuccess(returnedPartner));
                    if (dismissToast) {
                        toast.dismiss(refreshPartnerToastId);
                        setRefreshPartnerToastId("");
                    }
                    setPartner(returnedPartner);
                    dispatch(pageActions.updateSelectedPartner(returnedPartner));
                })
                .catch(error => {
                    if (error.message != "canceled") {
                        toast.error("Loading Selected Partner Failed:" + error, { autoClose: false });
                    }
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }

    const checkUpdateRequired = useCallback(async (partner) => {
        if (editModal && editModal._isShown) {
            if (partner.partnerId && !isSaving && !isLoading) {
                if (await partnersService.updateRequired(partner) && refreshPartnerToastId == '') {
                    let newToastId = toast.warning(refreshMessage(partner), {
                        autoClose: false,
                        position: "top-center",
                        hideProgressBar: true,
                        closeOnClick: false,
                        closeButton: false,
                        draggable: false,
                        onClick: () => refreshPartner(partner.partnerId, true)
                    })
                    setRefreshPartnerToastId(newToastId);
                }
            }
        }
    }, [refreshPartnerToastId, isSaving, isLoading, editModal]);

    useEffect(() => {
        setPartnerUsers(users.filter(user => user.partnerId == selectedPartner.partnerId && !user.disabled && user.integrationServices.some(service => service.name == 'Communications')));
        let billingContacts = users.filter(user => user.partnerId === selectedPartner.partnerId && user.pocs.some(poc => poc.name === "Accounting")).map(user => ({
            userId: user.userId,
            email: user.email
        }))
        let salesContacts = users.filter(user => user.partnerId === selectedPartner.partnerId && user.pocs.some(poc => poc.name === "Sales")).map(user => ({
            userId: user.userId,
            email: user.email
        }))
        let operationalContacts = users.filter(user => user.partnerId === selectedPartner.partnerId && user.pocs.some(poc => poc.name === "Operations")).map(user => ({
            userId: user.userId,
            email: user.email
        }))
        let authContacts = users.filter(user => user.partnerId === selectedPartner.partnerId && user.pocs.some(poc => poc.name === "Security")).map(user => ({
            userId: user.userId,
            email: user.email
        }))
        setBillingPocList(billingContacts);
        setSalesPocList(salesContacts);
        setOperationalPocList(operationalContacts);
        setAuthPocList(authContacts);
        setNewBillingPocList(billingPocList);
        setNewSalesPocList(salesContacts);
        setNewOperationalPocList(operationalContacts);
        setNewAuthPocList(authContacts);

        selectedPartner.availableGlobalLinks = globalLinks.filter(gl => !partner.externalLinks.some(el => el.externalLinkId == gl.externalLinkId));
        setPartner(selectedPartner);
        var editModal = document.getElementById('edit-modal');
        editModal.addEventListener('hidden.bs.modal', () => {
            if (partnersService.getPartnerAbortController[selectedPartner.partnerId]) {
                partnersService.getPartnerAbortController[selectedPartner.partnerId].abort();
            }
            dispatch(pageActions.clearSelectedPartner());
            setErrors({});
            setActiveTab("Info");
        });
        setEditModal(bootstrap.Modal.getInstance(document.getElementById('edit-modal')));
    }, [selectedPartner])

    useEffect(() => {
        if (selectedPartner.partnerId) {
            refreshPartner(selectedPartner.partnerId);
        }
    }, [selectedPartner.partnerId, editModal])

    useEffect(() => {
        const updateRequiredIntervalId = setInterval(() => checkUpdateRequired(partner), 1000);
        return () => {
            clearInterval(updateRequiredIntervalId);
        };
    }, [checkUpdateRequired, partner])

    useEffect(() => {
        if (!partner.integrationServices.some(s => s.name === activeTab && !s.isDeleted)) {
            setActiveTab("Info");
        }
    }, [partner.integrationServices])

    const handlePartnerSave = (event) => {
        setIsSaving(true);
        event.preventDefault();
        let updatePartner = { ...partner };
        let integrationServices = updatePartner.integrationServices;
        let isCreate = updatePartner.partnerId === undefined;
        if (!partnerIsValid(updatePartner)) {
            setIsSaving(false);
            return;
        }

        if (integrationServices.some(s => s.name === 'Communications' && s.isDeleted)) {
            updatePartner.defaultSubscriptions = []
        }

        if (integrationServices.some(s => s.name === "Support" && s.isDeleted)) {
            updatePartner.jiraOrganization.id = "0";
            updatePartner.jiraOrganization.name = "Please Select a Jira Organization...";
            updatePartner.jiraGroups = []
        }
        if (integrationServices.some(s => s.name === "Links" && s.isDeleted)) {
            updatePartner.externalLinks = [];
        }
        if (integrationServices.some(s => s.name === "Login" && s.isDeleted)) {
            updatePartner.twoFactorMethods = [];
        }

        if (updatePartner.mediaShuttleUrl === undefined) {
            updatePartner.mediaShuttleUrl = "";
        }

        //IdentifyThePocGroups
        let accountingPocGroup = availablePocs.find(poc => poc.name === "Accounting");
        let salesPocGroup = availablePocs.find(poc => poc.name === "Sales");
        let operationsPocGroup = availablePocs.find(poc => poc.name === "Operations");
        let securityPocGroup = availablePocs.find(poc => poc.name === "Security");

        //Find all the Poc Upodates
        let pocUpdates = newBillingPocList.filter(nPoc => !billingPocList.some(poc => poc.userId == nPoc.userId)).map(poc => ({ userId: poc.userId, updates: [{ poc: accountingPocGroup, isAdded: true }] })).concat(
            billingPocList.filter(nPoc => !newBillingPocList.some(poc => poc.userId == nPoc.userId)).map(poc => ({ userId: poc.userId, updates: [{ poc: accountingPocGroup, isAdded: false }] })),
            newSalesPocList.filter(nPoc => !salesPocList.some(poc => poc.userId == nPoc.userId)).map(poc => ({ userId: poc.userId, updates: [{ poc: salesPocGroup, isAdded: true }] })),
            salesPocList.filter(nPoc => !newSalesPocList.some(poc => poc.userId == nPoc.userId)).map(poc => ({ userId: poc.userId, updates: [{ poc: salesPocGroup, isAdded: false }] })),
            newOperationalPocList.filter(nPoc => !operationalPocList.some(poc => poc.userId == nPoc.userId)).map(poc => ({ userId: poc.userId, updates: [{ poc: operationsPocGroup, isAdded: true }] })),
            operationalPocList.filter(nPoc => !newOperationalPocList.some(poc => poc.userId == nPoc.userId)).map(poc => ({ userId: poc.userId, updates: [{ poc: operationsPocGroup, isAdded: false }] })),
            newAuthPocList.filter(nPoc => !authPocList.some(poc => poc.userId == nPoc.userId)).map(poc => ({ userId: poc.userId, updates: [{ poc: securityPocGroup, isAdded: true }] })),
            authPocList.filter(nPoc => !newAuthPocList.some(poc => poc.userId == nPoc.userId)).map(poc => ({ userId: poc.userId, updates: [{ poc: securityPocGroup, isAdded: false }] })));

        //Reduce to a single user
        let combinedPocUpdates = pocUpdates.filter(poc => pocUpdates.indexOf(poc) == pocUpdates.findIndex(pu => pu.userId == poc.userId)).map(poc => {
            return pocUpdates.filter(innerPoc => poc.userId == innerPoc.userId).reduce((updateUser, user) => {
                updateUser.updates = updateUser.updates.concat(user.updates);
                return updateUser;
            });
        });

        //Update the users poc array for all updates and save user
        combinedPocUpdates.forEach(pocUpdate => {
            let updateUser = users.find(user => pocUpdate.userId == user.userId);
            pocUpdate.updates.forEach(update => {
                if (!updateUser.integrationServices.some(is => is.name === "Communications")) {
                    let communicationsService = updateUser.availableServices.find(as => as.name === "Communications");
                    let index = updateUser.availableServices.findIndex(as => as.name === "Communications");
                    updateUser.integrationServices.push(communicationsService);
                    updateUser.availableServices.splice(index, 1);
                }
                if (update.isAdded) {
                    updateUser.pocs.push(update.poc);
                } else {
                    let index = updateUser.pocs.findIndex(poc => poc.name == update.poc.name);
                    updateUser.pocs.splice(index, 1);
                }
            });
            dispatch(usersActions.saveUser(updateUser));
        });

        dispatch(partnersActions.savePartner(updatePartner)).then(empty => {
            if (userPermissions.some(up => up.name === "ViewJiraOrganizations")) {
                dispatch(jiraActions.loadAvailableJiraOrganizations()).catch(error => {
                    toast.error("Loading Available Jira Organizations Failed:" + error, { autoClose: false });
                })
            }
            if (userPermissions.some(up => up.name === 'ViewJiraGroups')) {
                dispatch(jiraActions.loadAvailableJiraGroups()).catch(error => {
                    toast.error("Loading Available Jira Groups Failed:" + error, { autoClose: false });
                })
            }
            if ((integrationServices.some(s => s.name === "Links"))) {
                dispatch(linksActions.loadGlobalLinks()).catch(error => {
                    toast.error("Loading Global Links Failed:" + error, { autoClose: false })
                })
            }
            if ((integrationServices.some(s => s.name === "File Transfer"))) {
                dispatch(fileTransferActions.loadAvailablePortals()).catch(error => {
                    toast.error("Loading Available Portals Failed:" + error, { autoClose: false })
                })
            }
            if (isCreate) {
                toast.success(updatePartner.name + " has been created");
            } else {
                toast.success(updatePartner.name + " has been saved");
            }
            bootstrap.Modal.getInstance(document.getElementById('edit-modal')).hide()
            dispatch(pageActions.clearSelectedPartner());
            setIsSaving(false);
        }).catch(error => {
            setIsSaving(false);
            toast.error("Save Partner Failed: " + error, { autoClose: false })
        });
    }

    const partnerIsValid = (updatePartner) => {
        const { name, parentPartnerId, twoFactorMethods, partnerADIdentifier, partnerId,
            integrationServices, jiraGroups, externalLinks, mediaShuttlePortals } = updatePartner;
        const errors = {};

        if (!name) {
            errors.name = "(Required)";
        } else if (
            (
                !partnerId && allPartners.find(p =>
                    p.name.toLowerCase() === name.toLowerCase()
                )
            ) ||
            (partnerId && (
                allPartners.find(p =>
                    p.partnerId === partnerId
                ).name.toLowerCase() != name.toLowerCase()
            ) &&
                allPartners.find(p =>
                    p.name.toLowerCase() === name.toLowerCase()
                )
            )
        ) {
            errors.name = "(Duplicate Name)";
        }

        if (!partnerADIdentifier) {
            errors.partnerADIdentifier = "(Required)";
        } else if (
            (
                !partnerId && allPartners.find(p =>
                    p.partnerADIdentifier.toLowerCase() === partnerADIdentifier.toLowerCase()
                )
            ) ||
            (partnerId && (
                allPartners.find(p =>
                    p.partnerId === partnerId
                ).partnerADIdentifier.toLowerCase() != partnerADIdentifier.toLowerCase()
            ) &&
                allPartners.find(p =>
                    p.partnerADIdentifier.toLowerCase() === partnerADIdentifier.toLowerCase()
                )
            )
        ) {
            errors.partnerADIdentifier = "(Duplicate ADI)";
        }

        if (!parentPartnerId) {
            errors.parentPartnerId = "(Required)";
        }

        if (integrationServices.find(s => s.name === "Support" && !s.isDeleted) && jiraGroups.length < 1) {
            errors.jiraGroups = "(Required)";
        }

        if (integrationServices.find(s => s.name === "Links" && !s.isDeleted) && externalLinks.filter(el => el.label === "").length > 0) {
            errors.externalLinkLabel = "(Required)";
        }

        if (integrationServices.find(s => s.name === "Links" && !s.isDeleted) && externalLinks.filter(el => el.url === "").length > 0) {
            errors.externalLinkUrl = "(Required)";
        }

        if (integrationServices.some(s => s.name === "Links" && !s.isDeleted) && externalLinks.length < 1) {
            errors.noExternalLinks = "(Add external links, or remove Links service)";
        }

        if (integrationServices.some(s => s.name === "File Transfer" && !s.isDeleted) && mediaShuttlePortals.length < 1) {
            errors.fileTransferPortals = "Must select at least one portal";
        }

        if (errors.name || errors.parentPartnerId || errors.partnerADIdentifier) {
            setActiveTab("Info");
        } else if (errors.hubSpotCompany) {
            setActiveTab("Communications");
        } else if (errors.jiraGroups) {
            setActiveTab("Support");
        } else if (errors.twoFactorMethods) {
            setActiveTab("Login");
        } else if (errors.externalLinkLabel || errors.externalLinkUrl || errors.noExternalLinks) {
            setActiveTab("Links");
        } else if (errors.fileTransferPortals) {
            setActiveTab("File Transfer")
        }

        setErrors(errors);

        return Object.keys(errors).length === 0;
    }

    const handlePartnerChange = (event) => {
        const { name, value } = event.target;
        setPartner({ ...partner, [name]: name === "parentPartnerId" ? parseInt(value, 10) : value });
    }

    const handleParentPartnerSelectChange = (event) => {
        setPartner({ ...partner, parentPartnerId: event.value, parentPartnerName: event.label });
    }

    const handlePartnerTwoFactorChange = (event) => {

        let selectedMethods = event.map(e => ({ twoFactorMethodId: e.value, name: e.label }));
        let removedTwoFactorMethods = partner.twoFactorMethods.filter(ptfm => !selectedMethods.some(sm => sm.twoFactorMethodId == ptfm.twoFactorMethodId));
        let usersWithRemovedMethod = partnerUsers.filter(user => removedTwoFactorMethods.some(m => m.twoFactorMethodId == user.twoFactorMethodId));

        //prevent removal of two factor method if users currently using this login method
        if (usersWithRemovedMethod.length > 0) {
            toast.error("The two factor method '" + usersWithRemovedMethod[0].twoFactorMethodName + "' could not be removed because it is linked to " + usersWithRemovedMethod.length + " user" + (usersWithRemovedMethod.length > 1 ? "s" : "") + ".", {
                autoClose: false
            });

            return;
        }

        setPartner({ ...partner, twoFactorMethods: selectedMethods });
    }

    const handleJiraOrganizationChange = (event) => {
        if (event.length > 0) {        
            let selectedOrganizations = event.map(e => jiraOrganizations.find(org => org.id === e.value));
            if(selectedOrganizations[selectedOrganizations.length - 1].id == "0"){
                selectedOrganizations = [{ id: "0", name: "No Organization" }];
                setNewJiraOrganizations(selectedOrganizations);
                new bootstrap.Modal(document.getElementById('jira-modal'), { backdrop: 'static', keyboard: false }).show();
            }else if(selectedOrganizations[selectedOrganizations.length - 1].id == "-1"){
                selectedOrganizations = [{ id: "-1", name: "--Managed by Jira Admins--" }];
                setNewJiraOrganizations(selectedOrganizations);
                new bootstrap.Modal(document.getElementById('jira-modal'), { backdrop: 'static', keyboard: false }).show();
            }else {
                selectedOrganizations = selectedOrganizations.filter(org => org.id != "0" && org.id != "-1");
                setNewJiraOrganizations(selectedOrganizations);
                new bootstrap.Modal(document.getElementById('jira-modal'), { backdrop: 'static', keyboard: false }).show();
            }                         

        } else {
            setNewJiraOrganizations([{ id: "0", name: "No Organization" }]);
            new bootstrap.Modal(document.getElementById('jira-modal'), { backdrop: 'static', keyboard: false }).show();
        }
    }

    const handleAvailableJiraOrganizationChange = (event) => {
        let selectedOrganizations = event.map(e => jiraOrganizations.find(org => org.id === e.value));
        setPartner({ ...partner, availableJiraOrganizations: selectedOrganizations });
    }

    const handleJiraGroupsChange = (event) => {
        let selectedGroups = event.map(e => jiraGroups.find(g => g.groupId === e.value));
        setPartner({ ...partner, jiraGroups: selectedGroups });
    }

    const handlePortalsChange = (event) => {
        let selectedPortals = event.map(e => portals.find(p => p.portalId === e.value));
        selectedPortals.forEach((value, index) => {
            value.sort = index + 1
        });
        setPartner({ ...partner, mediaShuttlePortals: selectedPortals });
    }

    function arrayMove(array, from, to) {
        const slicedArray = array.slice();
        slicedArray.splice(
            to < 0 ? array.length + to : to,
            0,
            slicedArray.splice(from, 1)[0]
        );
        return slicedArray;
    }

    const SortableMultiValue = SortableElement(
        (props) => {
            const onMouseDown = (e) => {
                e.preventDefault();
                e.stopPropagation();
            };
            const innerProps = { ...props.innerProps, onMouseDown };
            return <components.MultiValue { ...props } innerProps={ innerProps } />;
        }
    );

    const SortableMultiValueLabel = SortableHandle(
        (props) => <components.MultiValueLabel { ...props } />
    );

    const SortableSelect = SortableContainer(Select);

    const onPortalsSortEnd = ({ oldIndex, newIndex }) => {
        const sortedPortals = arrayMove([...partner.mediaShuttlePortals], oldIndex, newIndex);
        sortedPortals.forEach((value, index) => {
            value.sort = index + 1
        });
        setPartner({ ...partner, mediaShuttlePortals: sortedPortals })
    };

    const handleJiraOrganizationConfirmed = (event) => {
        event.preventDefault();
        //add any organizations that arent already in available jira organizations list
        var availableOrganizations = partner.availableJiraOrganizations;
        newJiraOrganizations.forEach(org => {                   
            if(!availableOrganizations.find( aorg => aorg.id == org.id))
            {
                availableOrganizations.push(org)
            }                                                    
        });

        const updatedProperties = {
            defaultJiraOrganizations: newJiraOrganizations,//add the new jiraorganizations
            availableJiraOrganizations: availableOrganizations
        }

        setPartner({ ...partner, ...updatedProperties } );

        bootstrap.Modal.getInstance(document.getElementById('jira-modal')).hide();
    }

    const handleExternalLinkChange = (event) => {
        let { name, value } = event.target;
        let newLinks = JSON.parse(JSON.stringify(partner.externalLinks));
        if (name.includes('url')) {
            name = name.replace('url', '')
            newLinks[name].url = value;
        } else {
            name = name.replace('label', '')
            newLinks[name].label = value;
        }
        setPartner({ ...partner, externalLinks: newLinks });
    }

    const handleGlobalToggle = (event) => {
        let { name } = event.currentTarget;
        let index = name.replace("check-box", "")
        let externalLinks = JSON.parse(JSON.stringify(partner.externalLinks));
        externalLinks[index].global = !externalLinks[index].global
        setPartner({ ...partner, externalLinks });
    }

    const handleAddGlobalLink = (event) => {
        let globalLink = globalLinks.find(globalLink => globalLink.externalLinkId === event.value)
        let externalLinks = [...partner.externalLinks]
        externalLinks.unshift(globalLink);
        let availableGlobalLinks = [...partner.availableGlobalLinks];
        let index = availableGlobalLinks.indexOf(globalLink);
        availableGlobalLinks.splice(index, 1);
        setPartner({ ...partner, externalLinks, availableGlobalLinks });
    }

    const handleAddLink = () => {
        let externalLinks = [...partner.externalLinks]
        externalLinks.unshift({ externalLinkId: 0, url: "", label: "", global: false });
        setPartner({ ...partner, externalLinks });
    }

    const handleRemoveLink = (event) => {
        let { name } = event.currentTarget;
        let externalLinks = [...partner.externalLinks];
        let availableGlobalLinks = [...partner.availableGlobalLinks];
        let index = name.replace('remove-link', '');
        if (globalLinks.some(gl => gl.externalLinkId === externalLinks[index].externalLinkId)) {
            availableGlobalLinks.push(externalLinks[index]);
            availableGlobalLinks.sort(sortGlobalLinks);
        }
        externalLinks.splice(index, 1)
        setPartner({ ...partner, externalLinks, availableGlobalLinks });
    }

    const handleRemoveService = (service) => {
        let integrationServices = [...partner.integrationServices];
        let availableServices = [...partner.availableServices];
        let externalLinks = [...partner.externalLinks];
        let index = integrationServices.indexOf(service);

        //check if users assigned to service being removed have the removed service. If so then dont allow the removal of service
        let usersWithService = partnerUsers.filter(user => user.integrationServices.some(s => s.name == service.name));

        if (usersWithService.length > 0) {
            toast.error("The service '" + service.name + "' could not be removed because it is linked to " + usersWithService.length + " user" + (usersWithService.length > 1 ? "s" : "") + ".", {
                autoClose: false, toastId: service.serviceId
            });

            return;
        }

        integrationServices[index].isDeleted = true;
        availableServices.push(service);
        setPartner({
            ...partner,
            integrationServices,
            availableServices,
            externalLinks
        });
        setActiveTab("Info");

    }

    const handleAddService = (service) => {
        let integrationServices = [...partner.integrationServices];
        if (integrationServices.some(is => is.isDeleted && is.serviceId == service.serviceId)) {
            let index = integrationServices.indexOf(service);
            integrationServices[index].isDeleted = false;
        } else {
            integrationServices.push(service);
        }
        let availableServices = [...partner.availableServices];
        let index = availableServices.indexOf(service);
        availableServices.splice(index, 1);
        setPartner({
            ...partner,
            integrationServices,
            availableServices
        });
        setActiveTab(service.name);
    }

    const handleBillingPocChange = (event) => {
        let selectedUsers = event.map(e => ({
            userId: e.value,
            email: e.label
        }));
        setNewBillingPocList(selectedUsers);
    };

    const handleSalesPocChange = (event) => {
        let selectedUsers = event.map(e => ({
            userId: e.value,
            email: e.label
        }));
        setNewSalesPocList(selectedUsers);
    };

    const handleOperationalPocChange = (event) => {
        let selectedUsers = event.map(e => ({
            userId: e.value,
            email: e.label
        }));
        setNewOperationalPocList(selectedUsers);
    };

    const handleAuthPocChange = (event) => {
        let selectedUsers = event.map(e => ({
            userId: e.value,
            email: e.label
        }));
        setNewAuthPocList(selectedUsers);
    };

    const handleDefaultSubscriptionsChange = (event) => {
        let selectedSubscriptions = event.map(e => ({
            subscriptionId: e.value,
            partnerId: selectedPartner.partnerId,
            name: e.label
        }));
        setPartner({
            ...partner,
            defaultSubscriptions: selectedSubscriptions
        });
    }

    const handleTAMsChange = (event) => {
        let selectedTAMs = event.map(e => users.find(user => user.userId === e.value));
        setPartner({
            ...partner,
            tams: selectedTAMs
        });
    }

    const handleSAMsChange = (event) => {
        let selectedSAMs = event.map(e => users.find(user => user.userId === e.value));
        setPartner({
            ...partner,
            sams: selectedSAMs
        });
    }

    const hasEditPointOfContactsPermission = userPermissions.some(up => up.name === "EditPointOfContacts");
    const hasEditPartnersPermission = userPermissions.some(up => up.name === "EditPartners");
    const hasEditPartnerTAMsSAMsPermission = userPermissions.some(up => up.name === "EditPartnerTAMsSAMs");
    const hasEditJiraUserGroupPermission = userPermissions.some(up => up.name === "EditJiraUserGroup");
    const hasEditJiraUserOrganizationPermission = userPermissions.some(up => up.name === "EditJiraUserOrganization");

    const oasisUsers = (users.length > 0 && allPartners.length > 0 && hasEditPartnerTAMsSAMsPermission) ? users.filter(user => user.partnerId === allPartners.find(partner => partner.name === "Oasis").partnerId) : [];

    const integrationServices = partner.integrationServices ? partner.integrationServices.filter(service => service.name != "Support") : [];

    const findName = (s) => {
        return availableSubscriptions.find(as => s.subscriptionId == as.groupId).name;
    }

    return (
        <>
            <div className="modal fade" tabindex="-1" role="dialog" id='edit-modal' >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className={ "modal-content " + theme }>
                        <form onSubmit={ handlePartnerSave }>
                            <div className="modal-header">
                                <h2 className="modal-title">{ partner.partnerId ? "Update Partner (" + selectedPartner.name + ")" : "New Partner" }</h2>
                                <a className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon="fas fa-times" />
                                </a>
                            </div>
                            <div className="modal-body">
                                { (isSaving || isLoading) ?
                                    <div className="modal-overlay" />
                                    : ""
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <CNav className={ theme } variant="tabs" role="tablist">
                                            <CNavItem>
                                                <CNavLink
                                                    active={ activeTab === "Info" }
                                                    onClick={ () => setActiveTab("Info") }
                                                >
                                                    Info
                                                </CNavLink>
                                            </CNavItem>
                                            <CNavItem>
                                                <CNavLink
                                                    active={ activeTab === "Support" }
                                                    onClick={ () => setActiveTab("Support") }
                                                >
                                                    Support
                                                </CNavLink>
                                            </CNavItem>
                                            { integrationServices.map(service => {
                                                if (!service.isDeleted) {
                                                    return (
                                                        <CNavItem>
                                                            <CNavLink
                                                                active={ activeTab === service.name }
                                                                onClick={ () => setActiveTab(service.name) }
                                                            >
                                                                { service.name }{ hasEditPartnersPermission ? <a className="remove" onClick={ () => handleRemoveService(service) }><FontAwesomeIcon icon="fas fa-times" /></a> : "" }
                                                            </CNavLink>
                                                        </CNavItem>
                                                    )
                                                }
                                            }) }
                                            { partner.availableServices.length > 0 ?
                                                <li className="nav-item" role="presentation">
                                                    <div className="dropdown">
                                                        <a
                                                            className={ "nav-link " + theme }
                                                            id="newServiceDropdownMenu"
                                                            data-bs-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            <FontAwesomeIcon icon="fa fa-plus" />
                                                        </a>
                                                        <div className={ "dropdown-menu " + theme }>
                                                            { partner.availableServices.map(service => {
                                                                return (
                                                                    <a
                                                                        className={ "dropdown-item " + theme }
                                                                        id={ service.name + "-menu-item" }
                                                                        onClick={ () => handleAddService(service) }
                                                                    >
                                                                        { service.name }
                                                                    </a>
                                                                )
                                                            }) }
                                                        </div>
                                                    </div>
                                                </li>
                                                : ""
                                            }
                                        </CNav>
                                        <CTabContent className={ theme }>
                                            <CTabPane role="tabpanel" aria-labelledby="info-tab" visible={ activeTab === "Info" }>
                                                <div className="container-fluid h-100">
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                            <TextInput
                                                                name="name"
                                                                label="Name"
                                                                value={ partner.name || "" }
                                                                onChange={ handlePartnerChange }
                                                                error={ errors.name }
                                                                wrapperClass="mb-3"
                                                                isRequired={ true }
                                                                disabled={ !hasEditPartnersPermission }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <TextInput
                                                                name="partnerADIdentifier"
                                                                label="Active Directory Identifier"
                                                                value={ partner.partnerADIdentifier || "" }
                                                                onChange={ handlePartnerChange }
                                                                error={ errors.partnerADIdentifier }
                                                                wrapperClass="mb-3"
                                                                isRequired={ true }
                                                                disabled={ !hasEditPartnersPermission }
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label className={ "form-label" + (!hasEditPartnersPermission ? " disabled" : "") }>Parent Partner*{ errors.parentPartnerId && <span className="text-danger field-validation-error ms-3">{ errors.parentPartnerId }</span> }</label>
                                                                <Select
                                                                    type="text"
                                                                    isSearchable
                                                                    classNamePrefix="react-select"
                                                                    name="parentPartnerId"
                                                                    value={ { value: partner.parentPartnerId, label: partner.parentPartnerName } }
                                                                    onChange={ handleParentPartnerSelectChange }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={allPartners.filter(p => p.partnerId != partner.partnerId).map(partner => ({
                                                                        value: partner.partnerId,
                                                                        label: partner.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.DropdownSelectStyleLight : styles.DropdownSelectStyleDark }
                                                                    isDisabled={ !hasEditPartnersPermission }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { hasEditPartnerTAMsSAMsPermission ?
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <label className="form-label">
                                                                    TAMs
                                                                </label>
                                                                <Select
                                                                    name="tams"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    classNamePrefix="react-select"
                                                                    placeholder="No TAMs Selected"
                                                                    onChange={ handleTAMsChange }
                                                                    value={ partner.tams.map(tam => ({
                                                                        value: tam.userId,
                                                                        label: tam.firstName + " " + tam.lastName
                                                                    })) }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ oasisUsers.map(user => ({
                                                                        value: user.userId,
                                                                        label: user.firstName + " " + user.lastName
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label">
                                                                        SAMs
                                                                    </label>
                                                                    <Select
                                                                        name="sams"
                                                                        isMulti
                                                                        closeMenuOnSelect={ false }
                                                                        classNamePrefix="react-select"
                                                                        placeholder="No SAMs Selected"
                                                                        onChange={ handleSAMsChange }
                                                                        value={ partner.sams.map(sam => ({
                                                                            value: sam.userId,
                                                                            label: sam.firstName + " " + sam.lastName
                                                                        })) }
                                                                        menuPosition="fixed"
                                                                        menuPlacement="bottom"
                                                                        options={ oasisUsers.map(user => ({
                                                                            value: user.userId,
                                                                            label: user.firstName + " " + user.lastName
                                                                        })) }
                                                                        styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : "" }
                                                </div>
                                            </CTabPane>
                                            <CTabPane role="tabpanel" aria-labelledby="communications-tab" visible={ activeTab === "Communications" }>
                                                <div className="container-fluid h-100 scrollable-tab-content">
                                                    <div className="row mb-3 mt-3">
                                                        <label className="form-label">
                                                            Default New User Subscriptions
                                                        </label>
                                                        <Select
                                                            name="defaultSubscriptions"
                                                            isMulti
                                                            closeMenuOnSelect={ false }
                                                            classNamePrefix="react-select"
                                                            placeholder="No Subscriptions Selected"
                                                            onChange={ handleDefaultSubscriptionsChange }
                                                            value={ partner.defaultSubscriptions.map(subscription => ({
                                                                value: subscription.subscriptionId,
                                                                label: findName(subscription)
                                                            })) }
                                                            menuPosition="fixed"
                                                            menuPlacement="bottom"
                                                            options={ availableSubscriptions.map(subscription => ({
                                                                value: subscription.groupId,
                                                                label: subscription.name
                                                            })) }
                                                            styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                        />
                                                    </div>
                                                    <div className="row">
                                                        <div className="mb-3 mt-4 border-bottom border-white">
                                                            <label className="form-label">
                                                                Points of Contact
                                                            </label>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 mb-3">
                                                                <label className={ "form-label" + (!hasEditPointOfContactsPermission ? " disabled" : "") }>
                                                                    Accounting
                                                                </label>
                                                                <Select
                                                                    name="billingContacts"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    classNamePrefix="react-select"
                                                                    placeholder="No Users Selected"
                                                                    onChange={ handleBillingPocChange }
                                                                    value={ newBillingPocList.map(user => ({
                                                                        value: user.userId,
                                                                        label: user.email
                                                                    })) }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ partnerUsers.map(user => ({
                                                                        value: user.userId,
                                                                        label: user.email
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    isDisabled={ !hasEditPointOfContactsPermission }
                                                                />
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label className={ "form-label" + (!hasEditPointOfContactsPermission ? " disabled" : "") }>
                                                                    Sales
                                                                </label>
                                                                <Select
                                                                    name="salesContacts"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    classNamePrefix="react-select"
                                                                    placeholder="No Users Selected"
                                                                    onChange={ handleSalesPocChange }
                                                                    value={ newSalesPocList.map(user => ({
                                                                        value: user.userId,
                                                                        label: user.email
                                                                    })) }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ partnerUsers.map(user => ({
                                                                        value: user.userId,
                                                                        label: user.email
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    isDisabled={ !hasEditPointOfContactsPermission }
                                                                />
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label className={ "form-label" + (!hasEditPointOfContactsPermission ? " disabled" : "") }>
                                                                    Operations
                                                                </label>
                                                                <Select
                                                                    name="operationsContacts"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    classNamePrefix="react-select"
                                                                    placeholder="No Users Selected"
                                                                    onChange={ handleOperationalPocChange }
                                                                    value={ newOperationalPocList.map(user => ({
                                                                        value: user.userId,
                                                                        label: user.email
                                                                    })) }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ partnerUsers.map(user => ({
                                                                        value: user.userId,
                                                                        label: user.email
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    isDisabled={ !hasEditPointOfContactsPermission }
                                                                />
                                                            </div>
                                                            <div className="col-md-6 mb-3">
                                                                <label className={ "form-label" + (!hasEditPointOfContactsPermission ? " disabled" : "") }>
                                                                    Security
                                                                </label>
                                                                <Select
                                                                    name="authorizationContacts"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    classNamePrefix="react-select"
                                                                    placeholder="No Users Selected"
                                                                    onChange={ handleAuthPocChange }
                                                                    value={ newAuthPocList.map(user => ({
                                                                        value: user.userId,
                                                                        label: user.email
                                                                    })) }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ partnerUsers.map(user => ({
                                                                        value: user.userId,
                                                                        label: user.email
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    isDisabled={ !hasEditPointOfContactsPermission }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CTabPane>
                                            <CTabPane role="tabpanel" aria-labelledby="support-tab" visible={ activeTab === "Support" }>
                                                <div className="container-fluid h-100">
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                            <div className="mb-1">
                                                                <label className={ "form-label" + (!hasEditJiraUserOrganizationPermission ? " disabled" : "") }>Available Jira Organizations</label>
                                                                <Select
                                                                    type="text"
                                                                    isSearchable
                                                                    classNamePrefix="react-select"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    name="availablejiraOrganizationId"
                                                                    value={ partner.availableJiraOrganizations.map(org => ({
                                                                        value: org.id,
                                                                        label: org.name
                                                                    })) }
                                                                    onChange={ handleAvailableJiraOrganizationChange }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ jiraOrganizations.map(organization => ({
                                                                        value: organization.id,
                                                                        label: organization.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    isDisabled={ !hasEditJiraUserOrganizationPermission }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                            <div className="mb-1">
                                                                <label className={ "form-label" + (!hasEditJiraUserOrganizationPermission ? " disabled" : "") }>Default Jira Organizations{ errors.jiraOrganization && <span className="text-danger field-validation-error ms-3">{ errors.jiraOrganization }</span> }</label>
                                                                <Select
                                                                    type="text"
                                                                    isSearchable
                                                                    classNamePrefix="react-select"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    name="jiraOrganizationId"
                                                                    value={ partner.defaultJiraOrganizations.map(org => ({
                                                                        value: org.id,
                                                                        label: org.name
                                                                    })) }
                                                                    onChange={ handleJiraOrganizationChange }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ jiraOrganizations.map(organization => ({
                                                                        value: organization.id,
                                                                        label: organization.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    isDisabled={ !hasEditJiraUserOrganizationPermission }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                            <div className="mb-1">
                                                                <label className={ "form-label" + (!hasEditJiraUserGroupPermission ? " disabled" : "") }>Default Jira Groups*{ errors.jiraGroups && <span className="text-danger field-validation-error ms-3">{ errors.jiraGroups }</span> }</label>
                                                                <Select
                                                                    type="text"
                                                                    isSearchable
                                                                    classNamePrefix="react-select"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    name="jiraGroupId"
                                                                    value={ partner.jiraGroups.map(group => ({
                                                                        value: group.groupId,
                                                                        label: group.name
                                                                    })) }
                                                                    onChange={ handleJiraGroupsChange }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ jiraGroups.map(group => ({
                                                                        value: group.groupId,
                                                                        label: group.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    isDisabled={ !hasEditJiraUserGroupPermission }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CTabPane>
                                            <CTabPane role="tabpanel" aria-labelledby="login-tab" visible={ activeTab === "Login" }>
                                                <div className="container-fluid h-100">
                                                    <div className="row pt-4">
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">
                                                                    Two Factor Methods{ errors.twoFactorMethods && <span className="text-danger field-validation-error ms-3">{ errors.twoFactorMethods }</span> }
                                                                </label>
                                                                <Select
                                                                    name="twoFactorMethods"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    placeholder="Select Two Factor Methods..."
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    onChange={ handlePartnerTwoFactorChange }
                                                                    value={ partner.twoFactorMethods.map(method => ({
                                                                        value: method.twoFactorMethodId,
                                                                        label: method.name
                                                                    })) }
                                                                    options={ authServices.map(service => ({
                                                                        value: service.twoFactorMethodId,
                                                                        label: service.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CTabPane>
                                            <CTabPane role="tabpanel" aria-labelledby="links-tab" visible={ activeTab === "Links" }>
                                                <div className="container-fluid h-100">
                                                    <div className="row pt-4">
                                                        { errors.noExternalLinks ?
                                                            <div className="col">
                                                                <p className="text-danger field-validation-error my-2 text-center">{ errors.noExternalLinks }</p>
                                                            </div>
                                                            : ""
                                                        }
                                                        <div className="col-md-5 ms-auto">
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <a
                                                                        data-tip="Add New Link"
                                                                        data-delay-show="500"
                                                                        data-type={ theme }
                                                                        data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                                                        data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                                                        data-border={ true }
                                                                        data-class="modal-tip"
                                                                        onClick={ handleAddLink }
                                                                        className="btn btn-new"
                                                                    >
                                                                        <FontAwesomeIcon icon="fas fa-plus" />
                                                                    </a>
                                                                </div>
                                                                <div className="col">
                                                                    <Select
                                                                        type="text"
                                                                        isSearchable
                                                                        classNamePrefix="react-select"
                                                                        name="externalLinkId"
                                                                        value={ { value: "Add a global link", label: "Add a global link..." } }
                                                                        onChange={ handleAddGlobalLink }
                                                                        menuPosition="fixed"
                                                                        menuPlacement="bottom"
                                                                        options={
                                                                            partner.availableGlobalLinks ?
                                                                                partner.availableGlobalLinks.map(globalLink => ({
                                                                                    value: globalLink.externalLinkId,
                                                                                    label: globalLink.label
                                                                                }))
                                                                                : []
                                                                        }
                                                                        styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { partner.externalLinks.length > 0 ?
                                                        <div className="row pt-2 link-headers mx-0">
                                                            <div className="col-md-5 me-0 link-header">
                                                                <label>Label*{ errors.externalLinkLabel && <span className="text-danger field-validation-error ms-3">{ errors.externalLinkLabel }</span> }</label>
                                                            </div>
                                                            <div className="col-md-5 me-0 link-header">
                                                                <label>Url*{ errors.externalLinkUrl && <span className="text-danger field-validation-error ms-3">{ errors.externalLinkUrl }</span> }</label>
                                                            </div>
                                                            <div className="text-center me-0 col-md-1 p-0 link-header">
                                                                <label>Global</label>
                                                            </div>
                                                        </div>
                                                        : ""
                                                    }
                                                    <div className={ "link-tab-content " + theme }>
                                                        { partner.externalLinks.map(link => {
                                                            let index = partner.externalLinks.indexOf(link);
                                                            let urlName = "url" + index;
                                                            let labelName = "label" + index;
                                                            let checkBoxName = "check-box" + index;
                                                            let removeLinkName = "remove-link" + index;
                                                            return (
                                                                <div className="row m-0">
                                                                    <div className="col-md-5">
                                                                        <TextInput
                                                                            name={ labelName }
                                                                            value={ link.label }
                                                                            onChange={ handleExternalLinkChange }
                                                                            error={ '' }
                                                                            wrapperClass="mt-1"
                                                                            isRequired={ false }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-5">
                                                                        <TextInput
                                                                            name={ urlName }
                                                                            value={ link.url }
                                                                            onChange={ handleExternalLinkChange }
                                                                            error={ '' }
                                                                            wrapperClass="mt-1"
                                                                            isRequired={ false }
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-1 mt-1">
                                                                        <input
                                                                            className="oasisCheckbox"
                                                                            id="globalLinkCheckbox"
                                                                            name={ checkBoxName }
                                                                            type="checkbox"
                                                                            onChange={ handleGlobalToggle }
                                                                            checked={ partner.externalLinks[index].global } />
                                                                    </div>
                                                                    <div className="col-md-1">
                                                                        <a name={ removeLinkName } onClick={ handleRemoveLink } className={ "btn btn-remove mt-1 " + theme }><FontAwesomeIcon icon="fa-solid fa-trash-can" /></a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            </CTabPane>
                                            <CTabPane role="tabpanel" aria-labelledby="file-transfer-tab" visible={ activeTab === "File Transfer" }>
                                                <div className="container-fluid h-100 scrollable-tab-content">
                                                    <div className="row pt-4">
                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Portals*{ errors.fileTransferPortals && <span className="text-danger field-validation-error ms-3">{ errors.fileTransferPortals }</span> }</label>
                                                                {/*{ This is a sortable select and cannot have the menu stay open after an item is selected react-sortable-hoc closeMenuOnSelect deprecated }*/ }
                                                                <SortableSelect
                                                                    useDragHandle
                                                                    axis="y"
                                                                    onSortEnd={ onPortalsSortEnd }
                                                                    distance={ 4 }
                                                                    name="portals"
                                                                    isMulti
                                                                    classNamePrefix="react-select"
                                                                    placeholder="Select File Transfer Portals"
                                                                    updateBeforeSortStart={ (node) => { node.node.style.zIndex = 9999 } }
                                                                    onChange={ handlePortalsChange }
                                                                    value={ partner.mediaShuttlePortals.map(portal => ({
                                                                        value: portal.portalId,
                                                                        label: portal.name
                                                                    })) }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ portals.map(portal => ({
                                                                        value: portal.portalId,
                                                                        label: portal.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    components={ { MultiValue: SortableMultiValue, MultiValueLabel: SortableMultiValueLabel } }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CTabPane>
                                        </CTabContent>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div id="required-disclaimer" className="col">
                                    <p className="ms-3">
                                        <span className="align-bottom">*</span><span className="small align-top"> Required</span>
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        { isSaving ?
                                            <div className="btn btn-new btn-block"><span className="saving-button fa-fade">Saving...</span></div>
                                            : isLoading ? <div className="btn btn-new btn-block"><span className="saving-button fa-fade">Loading...</span></div>
                                                : <input type="submit" value="Save" className="btn btn-new btn-block" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                title="Default Jira Organizations"
                id="jira-modal"
                displayValue={ "Are you sure you want to update the Jira Organization? This action can affect its users.<br /><br />Partner: <b>" + (selectedPartner.name ? selectedPartner.name : "this new partner") }
                onSubmit={ handleJiraOrganizationConfirmed }
                theme={ theme }
            />
        </>
    )
}

export default PartnerEdit;