import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './style.css'
import App from './components/App';
import store from './redux/store';
import { Provider as ReduxProvider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-grid-layout/css/styles.css' 
import 'react-resizable/css/styles.css'

ReactDOM.render(
    <ReduxProvider store={ store }>
        <Router>
                <App />
        </Router>
    </ReduxProvider>,
    document.getElementById('root')
);