import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import ReactTooltip from 'react-tooltip';
import { CTable, CTableBody, CTableHead, CTableHeaderCell, CTableRow, CTableDataCell } from '@coreui/bootstrap-react';
import { PaginatedListMenuStyleLight, PaginatedListMenuStyleDark } from './DropdownStyles';
import * as settingsActions from '../../redux/actions/settingsActions';
import * as pageActions from '../../redux/actions/pageActions';
import * as dateUtils from '../../utils/dateUtils';
import PartnerRow from '../partners/PartnerRow';
import UserRow from '../users/UserRow';
import VirtualMachineRow from '../virtualMachines/VirtualMachineRow';
import RoleRow from '../administration/roles/RoleRow';
import AuditRow from '../administration/audits/AuditRow';
import UserStatusRow from '../administration/userStatus/UserStatusRow';
import { toast } from 'react-toastify';
import * as bootstrap from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PaginatedList({ RecordType, theme }) {

    const [selectedEntitiesCount, setSelectedEntitiesCount] = useState("");
    const [allUsersChecked, setAllUsersChecked] = useState(false);

    const data = useSelector(state => state[RecordType]);
    const dataLimit = useSelector(state => state.settings[RecordType].dataLimit);
    const sortBy = useSelector(state => state.settings[RecordType].sortBy);
    const filterSettings = useSelector(state => state.settings[RecordType].filters);
    const pageNumber = useSelector(state => state.page[RecordType].pageNumber);
    const dataHeaders = useSelector(state => state.page[RecordType].dataHeaders);
    const dataLabel = useSelector(state => state.page[RecordType].dataLabel);
    const searchTerm = useSelector(state => state.page[RecordType].searchTerm.toLowerCase());
    const showColumnFilters = useSelector(state => state.page[RecordType].filterByColumn);
    const textFilters = useSelector(state => state.page[RecordType].textFilters)
    const isLoading = useSelector(state => state.page[RecordType].isLoading);
    const selectedUsers = useSelector(state => state.page.users.selectedUsers);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    let RenderComponent = PartnerRow;
    switch (RecordType) {
        case "partners":
            RenderComponent = PartnerRow;
            break;
        case "users":
            RenderComponent = UserRow;
            break;
        case "virtualMachines":
            RenderComponent = VirtualMachineRow;
            break;
        case "roles":
            RenderComponent = RoleRow;
            break;
        case "audits":
            RenderComponent = AuditRow;
            break;
        case "userStatuses":
            RenderComponent = UserStatusRow;
            break;
    };

    const handleDataLimitChange = (event) => {
        dispatch(settingsActions.updateDataLimitSetting(event.value, RecordType));
    }

    const handleMassOperationChange = (operation) => {
        if (operation === 'EditSubscriptions') {
            new bootstrap.Modal(document.getElementById('mass-user-edit-modal'), { backdrop: 'static', keyboard: false }).show();
        }
    }

    const handleSortByChange = (sortBy) => {
        dispatch(settingsActions.updateSortBySetting(sortBy, RecordType));
    }

    const goToNextPage = () => { dispatch(pageActions.updatePageNumber(RecordType, pageNumber + 1)); }

    const goToPreviousPage = () => { dispatch(pageActions.updatePageNumber(RecordType, pageNumber - 1)); }

    const goToFirstPage = () => { dispatch(pageActions.updatePageNumber(RecordType, 1)); }

    const getStartingIndex = () => { return (pageNumber * dataLimit) - dataLimit; }

    const getEndingIndex = () => { return getStartingIndex() + dataLimit }

    const getTotalRecords = () => { return getFilteredData().length; }

    const goToLastPage = () => { dispatch(pageActions.updatePageNumber(RecordType, Math.ceil(getFilteredData().length / dataLimit))); }

    const getPaginatedData = () => {
        const startIndex = getStartingIndex();
        const endIndex = getEndingIndex();
        return getSortedData().slice(startIndex, endIndex);
    };

    const getSortedData = () => {
        return getFilteredData().sort(sortData);
    };

    const handleDownloadCSV = () => {
        if (getSortedData().length <= 0) {
            toast.warn('No ' + dataLabel + ' to export');
            return false;
        }
    }

    const previousMonthDate = () => {
        let newCompareDate = new Date();
        newCompareDate.setMonth(newCompareDate.getMonth() - 1);
        return newCompareDate;
    }

    const filterServices = (originalData, filterSetting, serviceName) => {
        let newFilteredData = originalData;
        if (filterSetting.isActive) {
            newFilteredData = originalData.filter(entity => entity.integrationServices.some(service => service.name === serviceName && !service.isDeleted) === filterSetting.value);
        }
        return newFilteredData;
    }

    const filterPocs = (originalData, filterSetting, pocValue) => {
        let newFilteredData = originalData;
        if (filterSetting.isActive) {
            newFilteredData = originalData.filter(user => user.pocs.some(poc => poc.name === pocValue) === filterSetting.value);
        }
        return newFilteredData;
    }

    const filterSubscriptions = (originalData, filterSetting, subscriptionName) => {
        let newFilteredData = originalData;
        if (filterSetting.isActive && filterSetting.value) {
            newFilteredData = originalData.filter(user => user.subscriptions.some(subscription => subscription.name === subscriptionName));
        } else if (filterSetting.isActive && !filterSetting.value) {
            newFilteredData = originalData.filter(user => !user.subscriptions.some(subscription => subscription.name === subscriptionName));
        }
        return newFilteredData;
    }

    const getFilteredData = () => {
        let filteredData = getSearchedData();
        switch (RecordType) {
            case "partners":
                if (filterSettings.enabled.isActive) {
                    filteredData = filteredData.filter(partner => !partner.disabled == filterSettings.enabled.value);
                }
                if (filterSettings.recentlyUpdated.isActive) {
                    filteredData = filteredData.filter(partner => Date.parse(partner.lastModifiedDate) >= previousMonthDate() === filterSettings.recentlyUpdated.value);
                }
                filteredData = filterServices(filteredData, filterSettings.communications, 'Communications');
                filteredData = filterServices(filteredData, filterSettings.links, 'Links');
                filteredData = filterServices(filteredData, filterSettings.support, 'Support');
                filteredData = filterServices(filteredData, filterSettings.infrastructure, 'Infrastructure');
                if (filterSettings.twoFA.isActive) {
                    filteredData = filteredData.filter(partner => partner.twoFactorMethods.some(method => method.name != 'None') === filterSettings.twoFA.value);
                }
                if (filterSettings.tams.isActive) {
                    filteredData = filteredData.filter(partner => filterSettings.tams.value.some(filteredTam => partner.tams.some(tam => (tam.firstName + " " + tam.lastName) === filteredTam.value)));
                }
                if (filterSettings.sams.isActive) {
                    filteredData = filteredData.filter(partner => filterSettings.sams.value.some(filteredSam => partner.sams.some(sam => (sam.firstName + " " + sam.lastName) === filteredSam.value)));
                }
                return filteredData;
            case "users":
                if (filterSettings.enabled.isActive) {
                    filteredData = filteredData.filter(user => !user.disabled === filterSettings.enabled.value);
                }
                if (filterSettings.recentlyUpdated.isActive) {
                    filteredData = filteredData.filter(user => Date.parse(user.lastModifiedDate) >= previousMonthDate() === filterSettings.recentlyUpdated.value);
                }
                if (filterSettings.twoFA.isActive && filterSettings.twoFA.value) {
                    filteredData = filteredData.filter(user => user.twoFactorMethodName != "" &&
                        user.integrationServices.some(service => service.name === 'Login'));
                } else if (filterSettings.twoFA.isActive && !filterSettings.twoFA.value) {
                    filteredData = filteredData.filter(user => user.twoFactorMethodName === "" ||
                        !user.integrationServices.some(service => service.name === 'Login'));
                }
                filteredData = filterServices(filteredData, filterSettings.internal, 'Login');
                filteredData = filterServices(filteredData, filterSettings.communications, 'Communications');
                filteredData = filterServices(filteredData, filterSettings.support, 'Support');
                filteredData = filterPocs(filteredData, filterSettings.auth, 'Security');
                filteredData = filterPocs(filteredData, filterSettings.billing, 'Accounting');
                filteredData = filterPocs(filteredData, filterSettings.operations, 'Operations');
                filteredData = filterPocs(filteredData, filterSettings.sales, 'Sales');
                filteredData = filterSubscriptions(filteredData, filterSettings.emergencyNotifications, 'Emergency Notifications (outages, critical issues, etc)');
                filteredData = filterSubscriptions(filteredData, filterSettings.insightsNewsletter, 'Insights Newsletter (Monthly publication)');
                filteredData = filterSubscriptions(filteredData, filterSettings.oasisBlog, 'Oasis Blog (eDiscovery Unredacted)');
                filteredData = filterSubscriptions(filteredData, filterSettings.maintenance, 'Maintenance/End of Month Notifications');
                filteredData = filterSubscriptions(filteredData, filterSettings.ukMaintenance, 'UK Maintenance Communications');
                filteredData = filterSubscriptions(filteredData, filterSettings.relativityOneEmergency, 'RelativityOne Emergency');
                filteredData = filterSubscriptions(filteredData, filterSettings.revealEmergency, 'Reveal Emergency');
                return filteredData;
            case "virtualMachines":
                return filteredData;
            case "roles":
                if (filterSettings.isSystemDefined.isActive) {
                    filteredData = filteredData.filter(role => role.isSystemDefined == filterSettings.isSystemDefined.value);
                }
                return filteredData;
            case "audits":
                if (filterSettings.partners.isActive) {
                    filteredData = filteredData.filter(audit => filterSettings.partners.value.some(partner => partner.value === audit.partnerName));
                }
                if (filterSettings.actions.isActive) {
                    filteredData = filteredData.filter(audit => filterSettings.actions.value.some(action => action.value === audit.action));
                }
                if (filterSettings.types.isActive) {
                    filteredData = filteredData.filter(audit => filterSettings.types.value.some(type => type.value === audit.actedUpon));
                }
                if (filterSettings.users.isActive) {
                    filteredData = filteredData.filter(audit => filterSettings.users.value.some(user => user.value === audit.userEmail));
                }
                if (filterSettings.names.isActive) {
                    filteredData = filteredData.filter(audit => filterSettings.names.value.some(name => name.value === audit.name));
                }
                if (filterSettings.date.isActive) {
                    filteredData = filteredData.filter(audit => filterSettings.date.value.from < (new Date(audit.createdDate)).setHours(24, 0, 0) && (new Date(audit.createdDate)).setHours(0, 0, 0) < filterSettings.date.value.to);
                }
                return filteredData;
            case "userStatuses":
                return filteredData;
        }
    };

    const getSearchedData = () => {
        let searchedData;
        switch (RecordType) {
            case "partners":
                searchedData = data.filter(partner => (partner.name.toLowerCase().search(searchTerm) > -1
                    || partner.partnerADIdentifier.toLowerCase().search(searchTerm) > -1
                    || partner.parentPartnerName.toLowerCase().search(searchTerm) > -1
                    || (searchTerm && partner.tams.some(tam => tam.firstName.toLowerCase().search(searchTerm) > -1 || tam.lastName.toLowerCase().search(searchTerm) > -1))
                    || (searchTerm && partner.sams.some(sam => sam.firstName.toLowerCase().search(searchTerm) > -1 || sam.lastName.toLowerCase().search(searchTerm) > -1)))
                    && partner.name.toLowerCase().search(textFilters.name.toLowerCase()) > -1
                    && partner.partnerADIdentifier.toLowerCase().search(textFilters.partnerADIdentifier.toLowerCase()) > -1
                    && partner.parentPartnerName.toLowerCase().search(textFilters.parentPartnerName.toLowerCase()) > -1
                    && (textFilters.tams ?
                        partner.tams.some(tam => tam.firstName.toLowerCase().search(textFilters.tams.toLowerCase()) > -1
                            || tam.lastName.toLowerCase().search(textFilters.tams.toLowerCase()) > -1)
                        : true
                    )
                    && (textFilters.sams ?
                        partner.sams.some(sam => sam.firstName.toLowerCase().search(textFilters.sams.toLowerCase()) > -1
                            || sam.lastName.toLowerCase().search(textFilters.sams.toLowerCase()) > -1)
                        : true
                    )
                );
                return searchedData;
            case "users":
                searchedData = data.filter(user => ((user.firstName && user.firstName.toLowerCase().search(searchTerm) > -1)
                    || (user.lastName && user.lastName.toLowerCase().search(searchTerm) > -1)
                    || (user.email && user.email.toLowerCase().search(searchTerm) > -1)
                    || (user.partnerName && user.partnerName.toLowerCase().search(searchTerm) > -1))
                    && (user.firstName && user.firstName.toLowerCase().search(textFilters.firstName.toLowerCase()) > -1)
                    && (user.lastName && user.lastName.toLowerCase().search(textFilters.lastName.toLowerCase()) > -1)
                    && (user.email && user.email.toLowerCase().search(textFilters.email.toLowerCase()) > -1)
                    && (user.partnerName && user.partnerName.toLowerCase().search(textFilters.partnerName.toLowerCase()) > -1));
                return searchedData;
            case "virtualMachines":
                searchedData = data.filter(machine => ((machine.name && machine.name.toLowerCase().search(searchTerm) > -1)
                    || (machine.statusString && machine.statusString.toLowerCase().search(searchTerm) > -1)
                    || (machine.partnerName && machine.partnerName.toLowerCase().search(searchTerm) > -1))
                    && (machine.name && machine.name.toLowerCase().search(textFilters.name.toLowerCase()) > -1)
                    && (machine.statusString && machine.statusString.toLowerCase().search(textFilters.statusString.toLowerCase()) > -1)
                    && (machine.partnerName && machine.partnerName.toLowerCase().search(textFilters.partnerName.toLowerCase()) > -1));
                return searchedData;
            case "roles":
                searchedData = data.filter(role => (role && ((role.name && role.name.toLowerCase().search(searchTerm) > -1)
                    || (role.partnerName && role.partnerName.toLowerCase().search(searchTerm) > -1)))
                    && (role.name && role.name.toLowerCase().search(textFilters.name.toLowerCase()) > -1)
                    && (role.partnerName && role.partnerName.toLowerCase().search(textFilters.partnerName.toLowerCase()) > -1));
                return searchedData;
            case "audits":
                searchedData = data.filter(audit => ((audit.userEmail.toLowerCase().search(searchTerm) > -1)
                    || (audit.partnerName.toLowerCase().search(searchTerm) > -1)
                    || (audit.action.toLowerCase().search(searchTerm) > -1)
                    || (audit.actedUpon.toLowerCase().search(searchTerm) > -1)
                    || (audit.name !== undefined && audit.name && audit.name.toLowerCase().search(searchTerm) > -1))
                    && (audit.userEmail.toLowerCase().search(textFilters.userEmail.toLowerCase()) > -1)
                    && (audit.partnerName.toLowerCase().search(textFilters.partnerName.toLowerCase()) > -1)
                    && (audit.action.toLowerCase().search(textFilters.action.toLowerCase()) > -1)
                    && (audit.actedUpon.toLowerCase().search(textFilters.actedUpon.toLowerCase()) > -1)
                    && (audit.name !== undefined && audit.name.toLowerCase().search(textFilters.name.toLowerCase()) > -1));

                return searchedData;
            case "userStatuses":
                searchedData = data.filter(userStatus => ((userStatus.firstName.toLowerCase().search(searchTerm) > -1)
                    || (userStatus.lastName.toLowerCase().search(searchTerm) > -1)
                    || (userStatus.partner.toLowerCase().search(searchTerm) > -1)
                    || (userStatus.emailAddress.toLowerCase().search(searchTerm) > -1))
                    && (userStatus.firstName.toLowerCase().search(textFilters.firstName.toLowerCase()) > -1)
                    && (userStatus.lastName.toLowerCase().search(textFilters.lastName.toLowerCase()) > -1)
                    && (userStatus.partner.toLowerCase().search(textFilters.partner.toLowerCase()) > -1)
                    && (userStatus.emailAddress.toLowerCase().search(textFilters.emailAddress.toLowerCase()) > -1));
                return searchedData;
        }
    };

    const getCsvData = () => {
        if (getFilteredData().length > 0) {
            const csvData = JSON.parse(JSON.stringify(getFilteredData()));
            switch (RecordType) {
                case "partners":
                    csvData.forEach(data => {
                        delete data['partnerId'];
                        delete data['parentPartnerId'];
                        delete data['createdById'];
                        delete data['lastModifiedById'];
                        delete data['hubSpotCompany'];
                        delete data['jiraOrganization'];
                        delete data['concurrencyStamp'];
                        delete data['availableUserServices'];
                        delete data['defaultSubscriptions'];
                        delete data['availableGlobalLinks'];
                        data['mediaShuttlePortals'] = data['mediaShuttlePortals'].map(portal => portal.name).join(';');
                        data['createdDate'] = dateUtils.getLocalDateTime(data['createdDate']);
                        data['lastModifiedDate'] = dateUtils.getLocalDateTime(data['lastModifiedDate']);
                        data['twoFactorMethods'] = data['twoFactorMethods'].map(method => method.name).join(';');
                        data['integrationServices'] = data['integrationServices'].map(service => service.name).join(';');
                        data['availableServices'] = data['availableServices'].map(service => service.name).join(';');
                        data['externalLinks'] = data['externalLinks'].map(link =>
                            '{ Label: ' + link.label + ' | ' + 'Url: ' + link.url + ' }').join(';');
                        data['jiraGroups'] = data['jiraGroups'].map(group => group.name).join(';');
                        data['tams'] = data['tams'].map(tam => tam.firstName + " " + tam.lastName).join(';');
                        data['sams'] = data['sams'].map(sam => sam.firstName + " " + sam.lastName).join(';');
                    });
                    return csvData;
                case "users":
                    csvData.forEach(data => {
                        delete data['userId'];
                        delete data['partnerId'];
                        delete data['createdById'];
                        delete data['lastModifiedById'];
                        delete data['twoFactorMethodId'];
                        delete data['availableServices'];
                        delete data['concurrencyStamp'];
                        data['lastLoginDate'] = dateUtils.getLocalDateTime(data['lastLoginDate']);
                        data['createdDate'] = dateUtils.getLocalDateTime(data['createdDate']);
                        data['lastModifiedDate'] = dateUtils.getLocalDateTime(data['lastModifiedDate']);
                        data['roles'] = data['roles'].map(role => role.name).join(';');
                        data['integrationServices'] = data['integrationServices'].map(service => service.name).join(';');
                        data['pocs'] = data['pocs'].map(poc => poc.name).join(';');
                        data['subscriptions'] = data['subscriptions'].map(subscription => subscription.name).join(';');
                        data['twoFactorMethodClaims'] = data['twoFactorMethodClaims'].map(claim =>
                            '{ ClaimType: ' + claim.claimType + ' | ' + 'ClaimLabel: ' + claim.claimLabel + ' }').join(';');
                        data['jiraGroups'] = data['jiraGroups'].map(group => group.name).join(';');
                    });
                    return csvData;
                case "virtualMachines":
                    csvData.forEach(data => {
                        delete data['statusString'];
                        delete data['id'];
                        delete data['virtualMachineId'];
                        delete data['virtualMachineRoleId'];
                        delete data['partnerId'];
                        data['status'] = data['statusString'];
                        data['virtualMachineRole'] = data['virtualMachineRole'].name;
                    });
                    return csvData;
                case "roles":
                    return csvData;
                case "audits":
                    csvData.forEach(data => {
                        data['createdDate'] = dateUtils.getLocalDateTime(data['createdDate']);
                    });
                    return csvData;
                case "userStatuses":
                    csvData.forEach(data => {
                        data['login'] = dateUtils.getLocalDateTime(data['login']);
                        data['lastAction'] = dateUtils.getLocalDateTime(data['lastAction']);
                    });
                    return csvData;
            }
        } else {
            return [];
        }
    };

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [getCsvData])

    useEffect(() => {
        if (selectedUsers) {
            setSelectedEntitiesCount(selectedUsers.length);
        }
    }, [selectedUsers])

    useEffect(() => {
        if (RecordType === "users") {
            var filtered = getFilteredData();
            for (var i = 0; i < filtered.length; i++) {
                if (!selectedUsers.some(s => s.userId === filtered[i].userId)) {
                    setAllUsersChecked(false);
                    return;
                }
            }

            if (filtered.length !== 0) {
                setAllUsersChecked(true);
            }
        }

    }, [getFilteredData])

    const totalPages = () => { return Math.ceil(getFilteredData().length / dataLimit) < 1 ? 1 : Math.ceil(getFilteredData().length / dataLimit); }

    useEffect(() => {
        if (pageNumber > totalPages() || pageNumber < 1) {
            dispatch(pageActions.updatePageNumber(RecordType, 1));
        }
    }, [totalPages()]);

    const updateSorting = (dataField) => {
        if (sortBy.value === dataField) {
            handleSortByChange({ value: dataField, ascending: !sortBy.ascending })
        } else {
            handleSortByChange({ value: dataField, ascending: true })
        }
    }

    const sortData = (dataA, dataB) => {
        switch (sortBy.value) {
            case "permissions":
                var x = dataA[sortBy.value].length;
                var y = dataB[sortBy.value].length;
                break;
            case "lastLoginDate":
                var x = new Date(dataA[sortBy.value]);
                var y = new Date(dataB[sortBy.value]);
                break;
            case "createdDate":
                var x = new Date(dataA[sortBy.value]);
                var y = new Date(dataB[sortBy.value]);
                break;
            case "tams":
            case "sams":
                var x = dataA[sortBy.value].map(data => data.firstName + " " + data.lastName).join(';');
                var y = dataB[sortBy.value].map(data => data.firstName + " " + data.lastName).join(';');
                (x == "" && sortBy.ascending) ? x = "ZZZZZZZZZ" : x = x;
                (y == "" && sortBy.ascending) ? y = "ZZZZZZZZZ" : y = y;
                break;
            default:
                var x = dataA[sortBy.value];
                var y = dataB[sortBy.value];

        }
        if (typeof x == "string") {
            x = x.toLowerCase();
        }
        if (typeof y == "string") {
            y = y.toLowerCase();
        }
        switch (sortBy.value) {
            case "lastLoginDate":
                if (sortBy.ascending) {
                    return x - y;
                } else {
                    return y - x;
                }
            default:
                if (sortBy.ascending) {
                    return x < y ? -1 : x > y ? 1 : 0;
                } else {
                    return x < y ? 1 : x > y ? -1 : 0;
                }
        }
    }

    const toggleSelectAll = (event) => {
        switch (RecordType) {
            case "users":
                if (event.target.checked) {
                    let filteredSelectAllData = getFilteredData();
                    dispatch(pageActions.updateSelectedUsers(filteredSelectAllData));
                    dispatch(pageActions.allUsersSelected(RecordType, true));
                } else {
                    dispatch(pageActions.clearSelectedUsers());
                    dispatch(pageActions.allUsersSelected(RecordType, false));
                }
                break;
        }
    }

    const handleColumnSearch = (event) => {
        dispatch(pageActions.updateTextFilter(RecordType, event.target.id.split('_')[0], event.target.value));
    }

    return (
        data.length > 0 || !isLoading ?
            <>
                <div className="ps-3 pe-2 table-fix-head media-controlled">
                    <CTable color={ theme } hover small borderless>
                        <CTableHead className={ "thead-" + theme }>
                            <CTableRow>
                                { dataHeaders.map(header => {
                                    if (header.text) {
                                        return (
                                            <CTableHeaderCell className="clickable" style={ { width: header.width, color: '#92929D' } }>
                                                <div
                                                    className={ "thead-sort " + theme }
                                                    style={ { width: '100%' } }
                                                    onClick={ () => updateSorting(header.dataField) }
                                                >
                                                    { header.text }
                                                    <span className="table-header-icon-group">
                                                        {
                                                            textFilters[header.dataField] != "" ?
                                                                <span className="me-2" style={ { color: '#92929D' } }>
                                                                    <FontAwesomeIcon icon="fa-regular fa-filter" style={ { color: "#0079DD" } } />
                                                                </span>
                                                                : ""
                                                        }
                                                        <span className="me-2" style={ { color: '#92929D' } }>
                                                            {
                                                                header.dataField === sortBy.value ?
                                                                    (sortBy.ascending ?
                                                                        <FontAwesomeIcon icon="fas fa-arrow-down" />
                                                                        : <FontAwesomeIcon icon="fa-solid fa-arrow-up" />)
                                                                    : ''
                                                            }
                                                        </span>
                                                    </span>
                                                </div>
                                                {
                                                    showColumnFilters && header.filterable ?
                                                        <input
                                                            onChange={ handleColumnSearch }
                                                            id={ header.dataField + "_column_search_term" }
                                                            className={ "column-search-input media-controlled " + theme }
                                                            autocomplete="off"
                                                            name="ColumnSearchTerm"
                                                            type="text"
                                                            value={ textFilters[header.dataField] }
                                                        />
                                                        : showColumnFilters && !header.filterable ?
                                                            <div className="column-search-spacer" />
                                                            : ''
                                                }
                                            </CTableHeaderCell>
                                        )
                                    } else if (header.datafield === "all" && auth.permissions.some(permission => permission.name === "EditUsers")) {
                                        return <CTableHeaderCell style={ { width: header.width } }>
                                            <div className="form-check media-controlled m-0 ms-1">
                                                <input
                                                    className="allEntitiesCheckbox"
                                                    type="checkbox"
                                                    id={ RecordType + "-check-all" }
                                                    onChange={ toggleSelectAll }
                                                    checked={ allUsersChecked }
                                                />
                                                { selectedUsers.length > 0 ?
                                                    <div>
                                                        <a className="dropdown" id="dropdownId" data-bs-toggle="dropdown">
                                                            <span className={ "fa-layers fa-fw ms-1 " + (((selectedEntitiesCount + 1) / 1000 > 1) ? "mass-edit-icon-4" : ((selectedEntitiesCount + 1) / 100 > 1) ? "mass-edit-icon-3" : ((selectedEntitiesCount + 1) / 10 > 1) ? "mass-edit-icon-2" : "mass-edit-icon-1") }>
                                                                <FontAwesomeIcon icon="fas fa-pencil-alt" transform={ ((selectedEntitiesCount + 1) / 1000 > 1) ? "left-35" : ((selectedEntitiesCount + 1) / 100 > 1) ? "left-32" : ((selectedEntitiesCount + 1) / 10 > 1) ? "left-27" : "left-22" + " down-1" } />
                                                                <span className="fa-layers-text entityCount">( { selectedEntitiesCount } )</span>
                                                            </span>
                                                        </a>
                                                        <div className={ "dropdown-menu " + theme } id="table-header-dropdown-menu" aria-labelledby="dropdownId">
                                                            <a onClick={ () => handleMassOperationChange("EditSubscriptions") } className={ "dropdown-item " + theme }>Edit Subscriptions</a>
                                                        </div>
                                                    </div>
                                                    : ''
                                                }

                                            </div>
                                        </CTableHeaderCell>
                                    } else {
                                        return <CTableHeaderCell style={ { width: header.width } }></CTableHeaderCell>
                                    }
                                }) }
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {
                                getPaginatedData().map(entity => {
                                    return (
                                        <RenderComponent data={ entity } theme={ theme } />
                                    )
                                })
                            }
                        </CTableBody>
                    </CTable>
                </div>
                <div className="row pt-3 pe-5 ps-3 pagination-controls media-controlled align-items-center">
                    <div className="col-3">
                        <div>
                            Displaying { getTotalRecords() > 0 ? getStartingIndex() + 1 : getTotalRecords() } - { getEndingIndex() < getTotalRecords() ? getEndingIndex() : getTotalRecords() } of { getTotalRecords() }
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row justify-content-center">
                            <div className="pagination-btn-container">
                                <button onClick={ goToFirstPage } className={ "btn btn-short btn-outline-" + theme } disabled={ pageNumber === 1 ? 'disabled' : '' }>
                                    <FontAwesomeIcon icon="fas fa-angle-double-left" />
                                </button>
                                <button onClick={ goToPreviousPage } className={ "btn btn-short ms-2 btn-outline-" + theme } disabled={ pageNumber === 1 ? 'disabled' : '' }>
                                    <FontAwesomeIcon icon="fas fa-angle-left" />
                                </button>
                                <span className="px-3 text-center">Page { pageNumber } of { totalPages() }</span>
                                <button onClick={ goToNextPage } className={ "btn btn-short me-2 btn-outline-" + theme } disabled={ pageNumber === totalPages() ? 'disabled' : '' }>
                                    <FontAwesomeIcon icon="fas fa-angle-right" />
                                </button>
                                <button onClick={ goToLastPage } className={ "btn btn-short btn-outline-" + theme } disabled={ pageNumber === totalPages() ? 'disabled' : '' }>
                                    <FontAwesomeIcon icon="fas fa-angle-double-right" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="row justify-content-end">
                            <div className="select-pagination-container">
                                { auth.user && RecordType != "roles" ?
                                    <CSVLink
                                        data-tip={ "Export " + dataLabel }
                                        data-delay-show="1000"
                                        data-type={ theme }
                                        data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                        data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                        data-border={ true }
                                        data={ getCsvData() }
                                        filename={ "portal-" + RecordType + ".csv" }
                                        className="media-controlled btn btn-new me-2"
                                        onClick={ handleDownloadCSV }
                                    >
                                        <FontAwesomeIcon icon="fas fa-download" />
                                    </CSVLink>
                                    : ""
                                }
                                <Select
                                    id="pagination-select"
                                    type="text"
                                    isSearchable
                                    classNamePrefix="react-select"
                                    name="dataLimit"
                                    value={ { value: dataLimit, label: dataLimit != 100000 ? dataLimit : "All" } }
                                    menuPosition="fixed"
                                    menuPlacement="top"
                                    onChange={ handleDataLimitChange }
                                    options={
                                        [
                                            { value: 10, label: 10 },
                                            { value: 25, label: 25 },
                                            { value: 50, label: 50 },
                                            { value: 100, label: 100 },
                                            { value: 100000, label: "All" }
                                        ]
                                    }
                                    styles={ theme == 'light' ? PaginatedListMenuStyleLight : PaginatedListMenuStyleDark }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
            : <div className="my-auto mx-auto"><FontAwesomeIcon icon="fas fa-spinner" pulse /></div>
    )
}

export default PaginatedList;