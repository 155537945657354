import React, { useState, useEffect, useCallback } from 'react';
import TextInput from '../../common/TextInput';
import Select from 'react-select';
import * as rolesActions from '../../../redux/actions/rolesActions';
import * as partnersActions from '../../../redux/actions/partnersActions';
import * as rolesService from '../../../services/rolesService';
import { useDispatch, useSelector } from 'react-redux';
import * as pageActions from '../../../redux/actions/pageActions';
import * as styles from '../../common/DropdownStyles';
import * as bootstrap from 'bootstrap';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function RoleEdit({ theme }) {

    const [isLoading, setIsLoading] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [editModal, setEditModal] = useState();
    const [refreshRoleToastId, setRefreshRoleToastId] = useState("");
    const [errors, setErrors] = useState({});
    const [role, setRole] = useState(useSelector(state => state.page.roles.selectedRole));

    const sortPermissions = (permissionA, permissionB) => {
        var x = permissionA.friendlyName.toLowerCase();
        var y = permissionB.friendlyName.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    }

    const selectedRole = useSelector(state => state.page.roles.selectedRole);
    const allRoles = useSelector(state => state.roles.filter(p => { return p && p.name != "System Admin"; }));
    const userPermissions = useSelector(state => state.auth.permissions).sort(sortPermissions);
    const partners = useSelector(state => state.partners);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(pageActions.clearSelectedRole());
        };
    }, [])

    const refreshMessage = (role) => (
        <div className="container">
            <div className="row py-2">
                { role.name }<br />has been modified:
            </div>
            <div className="row py-2">
                To refresh click this message.
            </div>
            <div className="row py-2 pt-1 tiny-msg">
                Please note that refreshing will overwrite any current changes and saving new changes will not be permitted without a refresh.
            </div>
        </div>
    );

    const refreshRole = (roleId, dismissToast = false) => {
        if (editModal && editModal._isShown) {
            setIsLoading(true);
            rolesService.getRole(roleId)
                .then(returnedRole => {
                    dispatch(rolesActions.loadRoleSuccess(returnedRole));
                    if (dismissToast) {
                        toast.dismiss(refreshRoleToastId);
                        setRefreshRoleToastId("");
                    }
                    setRole(returnedRole);
                    dispatch(pageActions.updateSelectedRole(returnedRole));
                }).catch(error => {
                    toast.error("Loading Selected Role Failed:" + error, { autoClose: false });

                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }

    const checkUpdateRequired = useCallback(async (role) => {
        if (editModal && editModal._isShown) {
            if (role.roleId && !isSaving && !isLoading) {
                if (await rolesService.updateRequired(role) && refreshRoleToastId == '') {
                    let newToastId = toast.warning(refreshMessage(role), {
                        autoClose: false,
                        position: "top-center",
                        hideProgressBar: true,
                        closeOnClick: false,
                        closeButton: false,
                        draggable: false,
                        onClick: () => refreshRole(role.roleId, true)
                    })
                    setRefreshRoleToastId(newToastId);
                }
            }
        }
    }, [refreshRoleToastId, isSaving, isLoading, editModal]);

    useEffect(() => {
        setRole(selectedRole);
        if (userPermissions.some(p => p.name === "ViewPartners")) {
            dispatch(partnersActions.loadPartners()).catch(error => {
                console.log("Loading Partners Failed:" + error);
            });
        }
        var editModal = document.getElementById('edit-modal');
        editModal.addEventListener('hidden.bs.modal', () => {
            dispatch(pageActions.clearSelectedRole());
            setErrors({});
        });
        setEditModal(bootstrap.Modal.getInstance(document.getElementById('edit-modal')));
    }, [selectedRole])

    useEffect(() => {
        if (selectedRole.roleId) {
            refreshRole(selectedRole.roleId);
        }
    }, [selectedRole.roleId, editModal])

    useEffect(() => {
        const updateRequiredIntervalId = setInterval(() => checkUpdateRequired(role), 1000);
        return () => {
            clearInterval(updateRequiredIntervalId);
        };
    }, [checkUpdateRequired, role])

    const handleRoleSave = (event) => {
        setIsSaving(true);
        event.preventDefault();
        let updateRole = { ...role };
        let isCreate = updateRole.roleId === undefined;
        if (!roleIsValid(updateRole)) {
            setIsSaving(false);
            return;
        }

        dispatch(rolesActions.saveRole(updateRole)).then(empty => {
            if (isCreate) {
                toast.success(updateRole.name + " has been created");
            } else {
                toast.success(updateRole.name + " has been saved");
            }
            bootstrap.Modal.getInstance(document.getElementById('edit-modal')).hide()
            dispatch(pageActions.clearSelectedRole());
            setIsSaving(false);
        }).catch(error => {
            setIsSaving(false);
            toast.error("Save Role Failed: " + error, { autoClose: false })
        });
    }

    const roleIsValid = (updateRole) => {
        const { name, permissions, partnerId } = updateRole;
        const errors = {};

        if (!name) {
            errors.name = "(Required)";
        }

        if (partnerId == 0) {
            errors.partnerId = "(Required)";
        }

        if (!permissions.length > 0) {
            errors.permissions = "A Role must have at least one permission";
        }

        setErrors(errors);

        return Object.keys(errors).length === 0;
    }

    const handleRoleChange = (event) => {
        const { name, value } = event.target;
        setRole({ ...role, [name]: value });
    }

    const handleRolesChange = (event) => {
        let selectedRoles = event.map(e => ({ roleId: e.value, name: e.label }));
        setRole({ ...role, canAssignRoles: selectedRoles });
    }

    const handlePartnerChange = (event) => {
        let partnerId = event.value;
        let partnerName = event.label;
        setRole({ ...role, partnerId, partnerName })
    }

    const handlePermissionsChange = (event) => {
        let selectedPermissions = event.map(e => ({ permissionId: e.value, friendlyName: e.label }));
        setRole({ ...role, permissions: selectedPermissions });
    }

    const enabledPartners = partners.filter(p => p.disabled === false);
    const partnerName = role.partnerId == 0 ? "Select a Partner..." : role.partnerName;

    return (
        <>
            <div className="modal fade" tabindex="-1" role="dialog" id='edit-modal' >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className={ "modal-content " + theme }>
                        <form onSubmit={ handleRoleSave }>
                            <div className="modal-header">
                                <h2 className="modal-title">{ role.roleId ? "Update Role (" + selectedRole.name + ")" : "New Role" }</h2>
                                <a className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon="fas fa-times" />
                                </a>
                            </div>
                            <div className="modal-body">
                                { (isSaving || isLoading) ?
                                    <div className="modal-overlay" />
                                    : ""
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container-fluid h-100">
                                            <div className="row pt-4">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <div className="col">
                                                            <TextInput
                                                                name="name"
                                                                label="Name"
                                                                value={ role.name || "" }
                                                                onChange={ handleRoleChange }
                                                                error={ errors.name }
                                                                wrapperClass="mb-3"
                                                                isRequired={ true }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Partner*{ errors.partnerId && <span className="text-danger field-validation-error ms-3">{ errors.partnerId }</span> }</label>
                                                        <Select
                                                            type="text"
                                                            isSearchable
                                                            classNamePrefix="react-select"
                                                            name="partnerId"
                                                            value={ { value: role.partnerId, label: partnerName } }
                                                            onChange={ handlePartnerChange }
                                                            menuPosition="fixed"
                                                            menuPlacement="bottom"
                                                            options={ enabledPartners.map(partner => ({
                                                                value: partner.partnerId,
                                                                label: partner.name
                                                            })) }
                                                            styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="mb-3">
                                                        <label className="form-label">Permissions*{ errors.permissions && <span className="text-danger field-validation-error ms-3">{ errors.permissions }</span> }</label>
                                                        <Select
                                                            name="permissions"
                                                            isMulti
                                                            closeMenuOnSelect={ false }
                                                            placeholder="Select Permissions..."
                                                            classNamePrefix="react-select"
                                                            menuPosition="fixed"
                                                            menuPlacement="bottom"
                                                            onChange={ handlePermissionsChange }
                                                            value={ role.permissions.map(permission => ({
                                                                value: permission.permissionId,
                                                                label: permission.friendlyName
                                                            })) }
                                                            options={ userPermissions.map(permission => ({
                                                                value: permission.permissionId,
                                                                label: permission.friendlyName
                                                            })) }
                                                            styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label className="form-label">Manageable Roles{ errors.roles && <span className="text-danger field-validation-error ms-3">{ errors.roles }</span> }</label>
                                                    <Select
                                                        name="roles"
                                                        isMulti
                                                        closeMenuOnSelect={ false }
                                                        placeholder="Select Manageable Roles..."
                                                        classNamePrefix="react-select"
                                                        menuPosition="fixed"
                                                        menuPlacement="bottom"
                                                        onChange={ handleRolesChange }
                                                        value={ role.canAssignRoles.map(role => ({
                                                            value: role.roleId,
                                                            label: role.name
                                                        })) }
                                                        options={ allRoles.map(role => ({
                                                            value: role.roleId,
                                                            label: role.name
                                                        })) }
                                                        styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div id="required-disclaimer" className="col">
                                    <p className="ms-3">
                                        <span className="align-bottom">*</span><span className="small align-top"> Required</span>
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        { isSaving ?
                                            <div className="btn btn-new btn-block"><span className="saving-button fa-fade">Saving...</span></div>
                                            : isLoading ? <div className="btn btn-new btn-block"><span className="saving-button fa-fade">Loading...</span></div>
                                                : <input type="submit" value="Save" className="btn btn-new btn-block" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default RoleEdit;