class TabLocalStorage
{
    constructor() {
        let name = "OasisPortal_CurrentTab";

        this.set = (value) =>
        {
            localStorage.setItem(name, value);
        }

        this.get = () =>
        {
            return localStorage.getItem(name);
        }
    }
}

export { TabLocalStorage };