import * as types from '../actions/types';
import initialState from './InitialState';

export default function statCardReducer(state = initialState.statCard, action) {
    switch (action.type) {
        case types.UPDATE_STAT_CARD_NUMBER:
            return { ...state, cardNumber: action.cardNumber };
        default:
            return state;
    }
}