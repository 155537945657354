import * as types from '../actions/types';
import initialState from './InitialState';

export default function supportStatsReducer(state = initialState.supportStats, action) {
    switch (action.type) {
        case types.LOAD_SUPPORT_STATS_SUCCESS:
            return { ...state, stats: action.supportStats.stats, title: action.supportStats.title, error: action.supportStats.error };
        case types.CLEAR_SUPPORT_STATS:
            return { ...state, stats: initialState.supportStats.stats, title: initialState.supportStats.title, error: initialState.supportStats.error };
        case types.LOAD_SUPPORT_URL_SUCCESS:
            return { ...state, url: action.supportUrl };
        default:
            return state;
    }
}