import axios from 'axios'
import { config } from '../config';

export async function getUserDashboardLayout() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/dashboard/layout');
    return response.data;
}

export async function saveUserDashboardLayout(layout) {
    const json = { layout : JSON.stringify(layout) }
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/Dashboard/layout/save', json);
    return response.data;
}

export async function getAvailableWidgets() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/dashboard/widgets');
    return response.data;
}

