import * as pageActions from "../actions/pageActions";
import * as types from "./types";
import * as rolesService from "../../services/rolesService";

export function loadRoleSuccess(role) {
    return { type: types.LOAD_ROLE_SUCCESS, role }
}

export function loadRolesSuccess(roles) {
    return { type: types.LOAD_ROLES_SUCCESS, roles }
}

export function deleteRoleSuccess(roleId) {
    return { type: types.DELETE_ROLE_SUCCESS, roleId }
}

export function saveRoleSuccess(role) {
    return { type: types.SAVE_ROLE_SUCCESS, role }
}

export function createRoleSuccess(role) {
    return { type: types.CREATE_ROLE_SUCCESS, role }
}

export function loadRole(role) {
    return function (dispatch) {
        return rolesService.getRole(role.roleId)
            .then(role => {
                dispatch(loadRoleSuccess(role));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadRoles() {
    return function (dispatch) {
        return rolesService.getRoles()
            .then(roles => {
                dispatch(loadRolesSuccess(roles));
                dispatch(pageActions.loadedPage("roles"));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function deleteRole(role) {
    return function (dispatch) {
        return rolesService.deleteRole(role.roleId)
            .then(empty => {
                dispatch(deleteRoleSuccess(role.roleId));
            }).catch(error => {
                throw error;
            });
    };
}

export function saveRole(role) {
    return function (dispatch) {
        return rolesService.saveRole(role)
            .then(savedRole => {
                role.roleId
                    ? dispatch(saveRoleSuccess(savedRole))
                    : dispatch(createRoleSuccess(savedRole));
            }).catch(error => {
                throw error;
            });
    };
}