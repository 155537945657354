import React from "react";

function TextInput({ name, label, onChange, placeholder, value, error, wrapperClass, isRequired, disabled, maxlength, textArea, rows, cols }) {
    if (error && error.length > 0) {
        wrapperClass += " " + "has-error";
    }

    return (
        <div className={ wrapperClass }>
            { label
                ? <label className="form-label" htmlFor={ name }>
                    { label }{ isRequired ? "*" : "" } { error && <span className="text-danger field-validation-error ms-3">{ error }</span> }
                </label>
                : "" }
            <div className="field">
                { textArea ?
                    !disabled ?
                        <textarea
                            autoComplete="off"
                            type="text"
                            name={ name }
                            className="form-control"
                            placeholder={ placeholder }
                            onChange={ onChange }
                            maxlength={ maxlength }
                            value={ value }
                            rows={ rows }
                            cols={ cols }
                        ></textarea>
                        : <textarea
                            autoComplete="off"
                            type="text"
                            name={ name }
                            className="form-control"
                            placeholder={ placeholder }
                            onChange={ onChange }
                            disabled
                            maxlength={ maxlength }
                            value={ value }
                            rows={ rows }
                            cols={ cols }
                        ></textarea>
                    : !disabled ?
                        <input
                            autoComplete="off"
                            type="text"
                            name={ name }
                            className="form-control"
                            placeholder={ placeholder }
                            value={ value }
                            onChange={ onChange }
                            maxlength={ maxlength }
                        />
                        : <input
                            autoComplete="off"
                            type="text"
                            name={ name }
                            className="form-control"
                            placeholder={ placeholder }
                            value={ value }
                            onChange={ onChange }
                            disabled
                            maxlength={ maxlength }
                        /> }
            </div>
        </div>
    );
};

export default TextInput;