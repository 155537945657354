import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as settingsActions from '../../../redux/actions/settingsActions';
import { CTableDataCell } from '@coreui/bootstrap-react';

function TriToggleFilter({ RecordType, Filter, theme }) {

    const [togglePosition, setTogglePosition] = useState();
    const [firstPoint, setFirstPoint] = useState();
    const [secondPoint, setSecondPoint] = useState();
    const [thirdPoint, setThirdPoint] = useState();

    const filters = useSelector(state => state.settings[RecordType].filters);
    const filterValue = useSelector(state => state.settings[RecordType].filters[Filter.filterIdentifier].value);
    const filterIsActive = useSelector(state => state.settings[RecordType].filters[Filter.filterIdentifier].isActive);
    const dispatch = useDispatch();

    useEffect(() => {
        if (filters[Filter.filterIdentifier].isActive === false) {
            setTogglePosition(0);
            setFirstPoint("");
            setSecondPoint("hide");
            setThirdPoint("");
        } else if (filters[Filter.filterIdentifier].value === true) {
            setTogglePosition(-1);
            setFirstPoint("hide");
            setSecondPoint("");
            setThirdPoint("");
        } else {
            setTogglePosition(1);
            setFirstPoint("");
            setSecondPoint("");
            setThirdPoint("hide");
        }
    }, [filterValue, filterIsActive])

    const leftToggleLabelClass = "clickable p-0 text-end align-middle ";
    const rightToggleLabelClass = "clickable p-0 text-start align-middle ";

    const handleLeftLabelClick = () => {
        if (filterValue == true && filterIsActive == true) {
            filters[Filter.filterIdentifier].isActive = false;
            filters[Filter.filterIdentifier].value = true;
        } else {
            filters[Filter.filterIdentifier].isActive = true;
            filters[Filter.filterIdentifier].value = true;
        }
        dispatch(settingsActions.updateFilterSetting(filters, RecordType));
    }

    const handleRightLabelClick = () => {
        if (filterValue == false && filterIsActive == true) {
            filters[Filter.filterIdentifier].isActive = false;
            filters[Filter.filterIdentifier].value = false;
        } else {
            filters[Filter.filterIdentifier].isActive = true;
            filters[Filter.filterIdentifier].value = false;
        }
        dispatch(settingsActions.updateFilterSetting(filters, RecordType));
    }

    const handleToggle = (event) => {
        if (event.target.value === "-1") {
            filters[Filter.filterIdentifier].isActive = true;
            filters[Filter.filterIdentifier].value = true;
        } else if (event.target.value === "0") {
            filters[Filter.filterIdentifier].isActive = false;
        } else if (event.target.value === "1") {
            filters[Filter.filterIdentifier].isActive = true;
            filters[Filter.filterIdentifier].value = false;
        }
        dispatch(settingsActions.updateFilterSetting(filters, RecordType));
    }

    return (
        <>
            <CTableDataCell
                className={ leftToggleLabelClass + (filterIsActive ? (filterValue ? "active-toggle-label" : "toggle-label") : "active-toggle-label") }
                data-tip={ Filter.leftTooltip }
                data-type={ theme }
                data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                data-delay-show="1500"
                data-border={ true }
                onClick={ handleLeftLabelClick }
            >
                { Filter.leftLabel }
            </CTableDataCell>
            <CTableDataCell className="text-nowrap p-0 align-middle" id="toggle-cell">
                <input className="tri-state-toggle" type="range" min={ -1 } max={ 1 } value={ togglePosition } onChange={ handleToggle }
                    data-tip="Select center position to show all"
                    data-type={ theme }
                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                    data-delay-show="1500"
                    data-border={ true }
                />
                <span className={ `toggle-position toggle-first-position ${firstPoint}` } />
                <span className={ `toggle-position toggle-second-position ${secondPoint}` } />
                <span className={ `toggle-position toggle-third-position ${thirdPoint}` } />
            </CTableDataCell>
            <CTableDataCell
                className={ rightToggleLabelClass + (filterIsActive ? (!filterValue ? "active-toggle-label" : "toggle-label") : "active-toggle-label") }
                data-tip={ Filter.rightTooltip }
                data-type={ theme }
                data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                data-delay-show="1500"
                data-border={ true }
                onClick={ handleRightLabelClick }
            >
                { Filter.rightLabel }
            </CTableDataCell>
        </>
    )
}

export default TriToggleFilter;