import React from 'react';
import * as idpUserService from '../../services/idpUserService';
import * as pageActions from '../../redux/actions/pageActions';
import * as dateUtils from '../../utils/dateUtils';
import { CTableDataCell, CTableRow } from '@coreui/bootstrap-react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as bootstrap from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function UserRow({ data, theme }) {

    const userPermissions = useSelector(state => state.auth.permissions);
    const userProfile = useSelector(state => state.auth.user.profile);
    const canAssignRoles = useSelector(state => state.auth.canAssignRoles);
    const selectedUsers = useSelector(state => state.page.users.selectedUsers);
    const users = useSelector(state => state.users);
    const dispatch = useDispatch();

    const handleEditUser = () => {
        dispatch(pageActions.updateSelectedUser(data));
        new bootstrap.Modal(document.getElementById('edit-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleDisplayUserDetails = () => {
        dispatch(pageActions.updateSelectedUser(data));
        new bootstrap.Modal(document.getElementById('details-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleDisableUser = () => {
        dispatch(pageActions.updateSelectedUser(data));
        new bootstrap.Modal(document.getElementById('disable-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleEnableUser = () => {
        dispatch(pageActions.updateSelectedUser(data));
        new bootstrap.Modal(document.getElementById('enable-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleReset2faUser = () => {
        dispatch(pageActions.updateSelectedUser(data));
        new bootstrap.Modal(document.getElementById('reset-2fa-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleResetPasswordUser = () => {
        dispatch(pageActions.updateSelectedUser(data));
        new bootstrap.Modal(document.getElementById('reset-password-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleInviteUser = () => {
        idpUserService.sendEmail(data.userId, 'Invite').then(empty => {
            toast.success(data.email + " has been invited");
        }).catch(error => {
            toast.error("User Invite Failed: " + error, { autoClose: false })
        })
    }

    const handleSelectUser = (event) => {
        let selectingUsers = [];
        let user = users.find(user => user.userId === event.target.value);
        if (event.target.checked === true) {
            selectingUsers = [...selectedUsers, user];
        } else {
            selectingUsers = selectedUsers.filter(user => user.userId != event.target.value);
            document.getElementById('users-check-all').checked = false;
        }
        dispatch(pageActions.updateSelectedUsers(selectingUsers));
    }

    const canEditUser = (userPermissions.some(up => up.name == "EditUsers") && (!data.roles.length > 0 || data.roles.some(dr => canAssignRoles.some(car => car.roleId == dr.roleId))))
        || (userPermissions.some(up => up.name == "EditSelf") && data.username == userProfile.email);

    const canEnableUser = userPermissions.some(up => up.name == "EnableUsers") && (!data.roles.length > 0 || data.roles.some(dr => canAssignRoles.some(car => car.roleId == dr.roleId)));
    const canDisableUser = userPermissions.some(up => up.name == "DisableUsers") && (!data.roles.length > 0 || data.roles.some(dr => canAssignRoles.some(car => car.roleId == dr.roleId)));
    const canResetPassword = userPermissions.some(up => up.name == "ResetPasswordUsers") && (!data.roles.length > 0 || data.roles.some(dr => canAssignRoles.some(car => car.roleId == dr.roleId)));
    const canReset2FA = userPermissions.some(up => up.name == "Reset2FAUsers") && (!data.roles.length > 0 || data.roles.some(dr => canAssignRoles.some(car => car.roleId == dr.roleId)));
    const canInviteUser = userPermissions.some(up => up.name == "InviteUsers") && (!data.roles.length > 0 || data.roles.some(dr => canAssignRoles.some(car => car.roleId == dr.roleId)));

    return (
        <CTableRow className="clickable" key={ data.userId } onDoubleClick={ canEditUser ? handleEditUser : "" }>
            <CTableDataCell>
                { userPermissions.some(permission => permission.name === "EditUsers") ?
                    <input
                        className="oasisCheckbox"
                        name="userCheckbox"
                        type="checkbox"
                        value={ data.userId }
                        onChange={ handleSelectUser }
                        checked={ selectedUsers.some(selectedUser => selectedUser.userId === data.userId) ? true : false } />
                    : ""
                }
            </CTableDataCell>
            <CTableDataCell>{ data.username }</CTableDataCell>
            <CTableDataCell>{ data.firstName }</CTableDataCell>
            <CTableDataCell>{ data.lastName }</CTableDataCell>
            <CTableDataCell>{ data.partnerName }</CTableDataCell>
            <CTableDataCell>{ dateUtils.getLocalDateTime(data.lastLoginDate) }</CTableDataCell>
            <CTableDataCell className="right-align">
                <a className="dropdown" id="dropdownId" data-bs-toggle="dropdown"><FontAwesomeIcon icon="fas fa-ellipsis-h" /></a>
                <div className={ "dropdown-menu " + theme } aria-labelledby="dropdownId">
                    { canEditUser ?
                        <a onClick={ handleEditUser } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="fas fa-pencil-alt" /> Edit</a>
                        : ""
                    }
                    <a onClick={ handleDisplayUserDetails } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="fas fa-info-circle" /> Details</a>
                    {
                        (data.integrationServices.filter(service => service.name === 'Login').length > 0 && !data.isPasswordSet) &&
                            canInviteUser ?
                            <a onClick={ handleInviteUser } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="far fa-envelope" /> Invite</a>
                            : ""
                    }
                    {
                        (data.integrationServices.filter(service => service.name === 'Login').length > 0 && data.isPasswordSet) &&
                            canResetPassword ?
                            <a onClick={ handleResetPasswordUser } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="fas fa-unlock" /> Reset Password</a>
                            : ""
                    }
                    {
                        (data.twoFactorEnabled && canReset2FA) ?
                            <a onClick={ handleReset2faUser } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="fas fa-user-lock" /> Reset 2FA</a>
                            : ''
                    }
                    {
                        data.disabled ?
                            canEnableUser ?
                                <a onClick={ handleEnableUser } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="fas fa-plus" /> Enable</a>
                                : ""
                            : canDisableUser ?
                                <a onClick={ handleDisableUser } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="fas fa-minus" /> Disable</a>
                                : ""
                    }
                </div>
            </CTableDataCell>
        </CTableRow>
    );
}

export default UserRow;