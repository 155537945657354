import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import SigninOidc from './common/Signin-Oidc';
import SignoutOidc from './common/Signout-Oidc';
import HomePage from './home/HomePage';
import KnowledgeBasePage from './knowledgebase/KnowledgeBasePage';
import PartnersPage from './partners/PartnersPage';
import UsersPage from './users/UsersPage';
import VirtualMachinesPage from './virtualMachines/VirtualMachinesPage';
import AdministrationPage from './administration/AdministrationPage';
import Login from './common/Login';
import store from '../redux/store';
import userManager, { loadUserFromStorage } from '../services/userService';
import AuthProvider from '../utils/authProvider';
import PrivateRoute from '../utils/protectedRoute';
import Layout from './Layout';
import PageNotFound from './PageNotFound';
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import "react-toastify/dist/ReactToastify.css";
import ActivityTracker from '../utils/ActivityTracker';
import { TabLocalStorage } from '../utils/localStorageUtils';
import 'rsuite/dist/rsuite.min.css';
import PreDataLoader from '../components/PreDataLoader';
import { CustomProvider } from 'rsuite';
import { useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

function App() {

    library.add(fas, far, fad);

    const location = useLocation();
    const userPreferences = useSelector(state => state.userPreferences);
    const theme = userPreferences.find(x => x.preferenceName === 'Theme').preferenceValue.toLowerCase();
    const sideNavState = userPreferences.find(x => x.preferenceName === 'SideNavState').preferenceValue.toLowerCase();

    useEffect(() => {
        // fetch current user from cookies
        loadUserFromStorage(store)
    }, [])

    useEffect(() => {
        //dont save authentication specific paths to localstorage
        if (location.pathname != "/signout-oidc" && location.pathname != "/signin-oidc" && location.pathname != "/login") {
            new TabLocalStorage().set(location.pathname);
        }
    }, [location])

    return (
        <PreDataLoader>
            <CustomProvider theme={ theme } sideNavState={ sideNavState }>
                <AuthProvider userManager={ userManager } store={ store }>
                    <ActivityTracker userManager={ userManager } store={ store }>
                        <Layout theme={ theme } sideNavState={ sideNavState }>
                            <Routes>
                                <Route
                                    path="/login"
                                    element={ <Login /> }
                                />
                                <Route
                                    path="/signout-oidc"
                                    element={ <SignoutOidc /> }
                                />
                                <Route
                                    path="/signin-oidc"
                                    element={ <SigninOidc /> }
                                />
                                <Route
                                    path="/"
                                    element={ <PrivateRoute redirectTo="/login"><HomePage theme={ theme } sideNavState={ sideNavState } /> </PrivateRoute> }
                                />
                                <Route
                                    path="/KnowledgeBase/:type/:contentId/:slug"
                                    action={ ({ params }) => { } }
                                    element={ <PrivateRoute redirectTo="/login"> <KnowledgeBasePage theme={ theme } sideNavState={ sideNavState } /> </PrivateRoute> }
                                />
                                <Route
                                    path="/KnowledgeBase"
                                    action={ ({ params }) => { return { selectedPageId: 0 } } }
                                    element={ <PrivateRoute redirectTo="/login"> <KnowledgeBasePage theme={ theme } sideNavState={ sideNavState } /> </PrivateRoute> }
                                />
                                <Route
                                    path="/Users"
                                    element={ <PrivateRoute redirectTo="/login" permission="ViewUsers"> <UsersPage theme={ theme } sideNavState={ sideNavState } /> </PrivateRoute> }
                                />
                                <Route
                                    path="/Partners"
                                    element={ <PrivateRoute redirectTo="/login" permission="ViewPartners"> <PartnersPage theme={ theme } sideNavState={ sideNavState } /> </PrivateRoute> }
                                />
                                <Route
                                    path="/VirtualMachines"
                                    element={ <PrivateRoute redirectTo="/login" permission="ViewVirtualMachines"> <VirtualMachinesPage theme={ theme } sideNavState={ sideNavState } /> </PrivateRoute> }
                                />
                                <Route
                                    path="/Administration/*"
                                    element={ <PrivateRoute redirectTo="/login"> <AdministrationPage theme={ theme } sideNavState={ sideNavState } /> </PrivateRoute> }
                                />
                                <Route
                                    path="*"
                                    element={ <PageNotFound /> }
                                />
                            </Routes>
                            <ToastContainer autoClose={ 5000 } hideProgressBar theme={ theme } sideNavState={ sideNavState } />
                            <ReactTooltip effect="solid" />
                        </Layout>
                    </ActivityTracker>
                </AuthProvider>
            </CustomProvider>
        </PreDataLoader>
    );
}

export default App;
