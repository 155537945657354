import * as types from "./types";
import * as storageStatsService from "../../services/storageStatsService";

export function loadStorageStatsSuccess(storageStats) {
    return { type: types.LOAD_STORAGE_STATS_SUCCESS, storageStats }
}

export function clearStorageStats() {
    return { type: types.CLEAR_STORAGE_STATS }
}

export function reloadStorageStats() {
    return function (dispatch) {
        dispatch(clearStorageStats());
        dispatch(loadStorageStats());
    }
}

export function loadStorageStats() {
    return function (dispatch) {
        return storageStatsService.getStorageStats()
            .then(storageStats => {
                dispatch(loadStorageStatsSuccess(storageStats));
            })
    };
}