import React, { useEffect, useState, useCallback } from 'react'
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/bootstrap-react'
import * as pageActions from '../../redux/actions/pageActions';
import * as partnersActions from '../../redux/actions/partnersActions';
import * as partnersService from '../../services/partnersService';
import * as dateUtils from '../../utils/dateUtils';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as bootstrap from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PartnerDetails({ theme }) {

    const [activeTab, setActiveTab] = useState("Info");
    const [detailsModal, setDetailsModal] = useState();
    const [partner, setPartner] = useState(useSelector(state => state.page.partners.selectedPartner));

    const selectedPartner = useSelector(state => state.page.partners.selectedPartner);
    const availableSubscriptions = useSelector(state => state.groups.availableSubscriptions);
    const users = useSelector(state => state.users);
    const userPermissions = useSelector(state => state.auth.permissions);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(pageActions.clearSelectedPartner());
        };
    }, [])

    useEffect(() => {
        setPartner(selectedPartner);
        var detailsModal = document.getElementById('details-modal');
        detailsModal.addEventListener('hidden.bs.modal', () => {
            if (partnersService.getPartnerAbortController[selectedPartner.partnerId]) {
                partnersService.getPartnerAbortController[selectedPartner.partnerId].abort();
            }
            dispatch(pageActions.clearSelectedPartner());
            setActiveTab("Info");
        });
        setDetailsModal(bootstrap.Modal.getInstance(document.getElementById('details-modal')));
    }, [selectedPartner])

    const refreshPartner = (partnerId) => {
        if (detailsModal && detailsModal._isShown) {
            partnersService.getPartner(partnerId)
                .then(returnedPartner => {
                    dispatch(partnersActions.loadPartnerSuccess(returnedPartner));
                    setPartner(returnedPartner);
                    dispatch(pageActions.updateSelectedPartner(returnedPartner));
                })
                .catch(error => {
                    if (error.message != "canceled") {
                        toast.error("Loading Selected Partner Failed:" + error, { autoClose: false });
                    }
                });
        }
    }

    const checkUpdateRequired = useCallback(async (partner) => {
        if (detailsModal && detailsModal._isShown) {
            if (await partnersService.updateRequired(partner)) {
                refreshPartner(partner.partnerId);
            }
        }
    }, [detailsModal]);

    useEffect(() => {
        const updateRequiredIntervalId = setInterval(() => checkUpdateRequired(partner), 1000);
        return () => {
            clearInterval(updateRequiredIntervalId);
        };
    }, [checkUpdateRequired, partner])

    useEffect(() => {
        if (selectedPartner.partnerId) {
            refreshPartner(selectedPartner.partnerId);
        }
    }, [selectedPartner.partnerId, detailsModal])

    const ableToEditPartnerServices = userPermissions.some(up => up.name === "EditPartnerServices");

    return (
        <div className="modal fade" tabindex="-1" role="dialog" id='details-modal' >
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className={ "modal-content " + theme }>
                    <div className="modal-header">
                        <h2 className="modal-title">{ partner.name }</h2>
                        <a className="close" data-bs-dismiss="modal" aria-label="Close">
                            <FontAwesomeIcon icon="fas fa-times" />
                        </a>
                    </div>
                    <div className="modal-body">
                        { partner.partnerId ?
                            <div className="row">
                                <div className="col-md-12">
                                    <CNav className={ theme } variant="tabs" role="tablist">
                                        <CNavItem>
                                            <CNavLink
                                                active={ activeTab === "Info" }
                                                onClick={ () => setActiveTab("Info") }
                                            >
                                                Info
                                            </CNavLink>
                                        </CNavItem>
                                        { partner.integrationServices.map(service => {
                                            return (
                                                <CNavItem>
                                                    <CNavLink
                                                        active={ activeTab === service.name }
                                                        onClick={ () => setActiveTab(service.name) }
                                                    >
                                                        { service.name }
                                                    </CNavLink>
                                                </CNavItem>
                                            )
                                        }) }
                                    </CNav>
                                    <CTabContent>
                                        <CTabPane
                                            role="tabpanel"
                                            arialabelledby="details-info-tab"
                                            visible={ activeTab === "Info" }
                                        >
                                            <div className="container-fluid h-100">
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        Name :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.name }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Active Directory Identifier :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.partnerADIdentifier }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Parent Partner :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.parentPartnerName }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Media Shuttle Url :
                                                    </div>
                                                    <div className="col-md-8">
                                                        <a href={ partner.mediaShuttleUrl } target="_blank">{ partner.mediaShuttleUrl }</a>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Created By :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.createdBy }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Created Date :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { dateUtils.getLocalDate(partner.createdDate) } { dateUtils.getLocalTime(partner.createdDate, { hour: '2-digit', minute: '2-digit' }) }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Last Modified By :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.lastModifiedBy }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Last Modified Date :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { dateUtils.getLocalDate(partner.lastModifiedDate) } { dateUtils.getLocalTime(partner.lastModifiedDate, { hour: '2-digit', minute: '2-digit' }) }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        TAMs :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.tams.map(tam => tam.firstName + " " + tam.lastName).join(';') }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        SAMs :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.sams.map(sam => sam.firstName + " " + sam.lastName).join(';') }
                                                    </div>
                                                </div>
                                            </div>
                                        </CTabPane>
                                        <CTabPane
                                            role="tabpanel"
                                            arialabelledby="details-communications-tab"
                                            visible={ activeTab === "Communications" }
                                        >
                                            <div className="container-fluid h-100 scrollable-tab-content">
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        Default New User Subscriptions :
                                                    </div>
                                                    <div className="col-md-8 text-justify">
                                                        { partner.defaultSubscriptions.map(sub => {
                                                            return availableSubscriptions.find(as => sub.subscriptionId == as.groupId).name
                                                        }).join(', ') }
                                                    </div>
                                                </div>
                                                <div className="row pt-5">
                                                    <div className="col-md-12 font-weight-bold h4">
                                                        Points of Contact
                                                    </div>
                                                </div>
                                                <div className="row pt-3">
                                                    <div className="col-md-4">
                                                        Billing & Accounting :
                                                    </div>
                                                    <div className="col-md-8 text-justify">
                                                        { users.filter(user => user.partnerId === selectedPartner.partnerId && user.pocs.some(poc => poc.name === "Accounting")).map(user => {
                                                            return user.email
                                                        }).join(', ') }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Sales :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { users.filter(user => user.partnerId === selectedPartner.partnerId && user.pocs.some(poc => poc.name === "Sales")).map(user => {
                                                            return user.email
                                                        }).join(', ') }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Operational :
                                                    </div>
                                                    <div className="col-md-8 text-justify">
                                                        { users.filter(user => user.partnerId === selectedPartner.partnerId && user.pocs.some(poc => poc.name === "Operations")).map(user => {
                                                            return user.email
                                                        }).join(', ') }
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        Authorization & Security :
                                                    </div>
                                                    <div className="col-md-8 text-justify">
                                                        { users.filter(user => user.partnerId === selectedPartner.partnerId && user.pocs.some(poc => poc.name === "Security")).map(user => {
                                                            return user.email
                                                        }).join(', ') }
                                                    </div>
                                                </div>
                                            </div>
                                        </CTabPane>
                                        <CTabPane
                                            role="tabpanel"
                                            arialabelledby="details-support-tab"
                                            visible={ activeTab === "Support" }
                                        >
                                            <div className="container-fluid h-100">
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        Jira Organizations :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.defaultJiraOrganizations.map(org => {
                                                            return org.name
                                                        }).join(', ') }
                                                    </div>
                                                </div>
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        Jira Groups :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.jiraGroups.map(group => {
                                                            return group.name
                                                        }).join(';') }
                                                    </div>
                                                </div>
                                            </div>
                                        </CTabPane>
                                        <CTabPane
                                            role="tabpanel"
                                            arialabelledby="details-login-tab"
                                            visible={ activeTab === "Login" }
                                        >
                                            <div className="container-fluid h-100">
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        Two Factor Methods :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.twoFactorMethods.map(method => {
                                                            return method.name
                                                        }).join(';') }
                                                    </div>
                                                </div>
                                            </div>
                                        </CTabPane>
                                        <CTabPane
                                            role="tabpanel"
                                            arialabelledby="details-links-tab"
                                            visible={ activeTab === "Links" }
                                        >
                                            <div className="container-fluid h-100">
                                                <div className="row pt-4 link-headers mx-0">
                                                    <div className="col-md-4 me-0 link-header">
                                                        <label>Label</label>
                                                    </div>
                                                    <div className="col-md-8 me-0 link-header">
                                                        <label>Url</label>
                                                    </div>
                                                </div>
                                                { partner.externalLinks.map(link => {
                                                    return (
                                                        <div className="row m-0">
                                                            <div className="col-md-4">
                                                                <div>{ link.label }</div>
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div>{ link.url }</div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) }
                                            </div>
                                        </CTabPane>
                                        <CTabPane
                                            role="tabpanel"
                                            arialabelledby="details-file-transfer-tab"
                                            visible={ activeTab === "File Transfer" }
                                        >
                                            <div className="container-fluid h-100">
                                                <div className="row pt-4">
                                                    <div className="col-md-4">
                                                        Portals :
                                                    </div>
                                                    <div className="col-md-8">
                                                        { partner.mediaShuttlePortals.map(portal => {
                                                            return portal.name
                                                        }).join(';') }
                                                    </div>
                                                </div>
                                            </div>
                                        </CTabPane>
                                    </CTabContent>
                                </div>
                            </div>
                            : "" }
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-new btn-block" data-bs-dismiss="modal">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PartnerDetails;