import * as pageActions from "../actions/pageActions";
import * as types from "./types";
import * as partnersService from "../../services/partnersService";

export function loadPartnerSuccess(partner) {
    return { type: types.LOAD_PARTNER_SUCCESS, partner }
}

export function loadPartnersSuccess(partners) {
    return { type: types.LOAD_PARTNERS_SUCCESS, partners }
}

export function disablePartnerSuccess(partner) {
    return { type: types.DISABLE_PARTNER_SUCCESS, partner }
}

export function enablePartnerSuccess(partner) {
    return { type: types.ENABLE_PARTNER_SUCCESS, partner }
}

export function savePartnerSuccess(partner) {
    return { type: types.SAVE_PARTNER_SUCCESS, partner }
}

export function createPartnerSuccess(partner) {
    return { type: types.CREATE_PARTNER_SUCCESS, partner }
}

export function loadPartner(partner) {
    return function (dispatch) {
        return partnersService.getPartner(partner.partnerId)
            .then(partner => {
                dispatch(loadPartnerSuccess(partner));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadPartners() {
    return function (dispatch) {
        return partnersService.getPartners()
            .then(partners => {
                dispatch(loadPartnersSuccess(partners));
                dispatch(pageActions.loadedPage("partners"));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function disablePartner(partner) {
    return function (dispatch) {
        return partnersService.disablePartner(partner.partnerId)
            .then(partner => {
                dispatch(disablePartnerSuccess(partner));
            }).catch(error => {
                throw error;
            })
    }
}

export function enablePartner(partner) {
    return function (dispatch) {
        return partnersService.enablePartner(partner.partnerId)
            .then(partner => {
                dispatch(enablePartnerSuccess(partner));
            }).catch(error => {
                throw error;
            })
    }
}

export function savePartner(partner) {
    return function (dispatch) {
        return partnersService.savePartner(partner)
            .then(savedPartner => {
                partner.partnerId
                    ? dispatch(savePartnerSuccess(savedPartner))
                    : dispatch(createPartnerSuccess(savedPartner));
            }).catch(error => {
                throw error;
            })
    }
}