import * as maintenanceService from '../../services/maintenanceService';
import * as applicationSettingService from '../../services/applicationSettingService';
import * as types from "./types";

export function loadMaintenanceModeSuccess(maintenanceMode) {
    return { type: types.LOAD_MAINTENANCE_MODE_SUCCESS, maintenanceMode };
}

export function loadResourceCycleIntervalSuccess(resourceCycleIntervalInSeconds) {
    return { type: types.LOAD_RESOURCE_CYCLE_INTERVAL_SUCCESS, resourceCycleIntervalInSeconds };
}

export function loadMaintenanceMode() {
    return function (dispatch) {
        return maintenanceService.getMaintenanceModeState()
            .then(maintenanceMode => {
                dispatch(loadMaintenanceModeSuccess(maintenanceMode));
            })
            .catch(error => {
                throw error;
            });
    }
}

export function loadResourceCycleInterval() {
    return function (dispatch) {
        return applicationSettingService.getApplicationSetting("resourceCycleIntervalInSeconds")
            .then(resourceCycleIntervalInSeconds => {
                dispatch(loadResourceCycleIntervalSuccess(resourceCycleIntervalInSeconds));
            })
            .catch(error => {
                throw error;
            });
    }
}
