import axios from 'axios'
import { config } from '../config';

export async function saveNotification(notification) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/notification/create/alert', notification).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error;
        }
    });
    return response.data;
}

export async function removeNotification(notification) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/notification/remove/alert', notification).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error;
        }
    });
    return response.data;
}

export async function loadNotifications() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/notification/list').catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error;
        }
    });
    return response.data;
}

export async function updateNotification(notification) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/notification/update/alert', notification).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error;
        }
    });
    return response.data;
}
