import React, { useEffect, useState } from 'react';
import * as virtualMachinesActions from '../../redux/actions/virtualMachinesActions';
import * as pageActions from '../../redux/actions/pageActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as bootstrap from 'bootstrap';
import ConfirmationModal from '../common/ConfirmationModal';
import PaginatedList from '../common/PaginatedList';
import FilterSlideout from '../common/FilterSlideout';
import * as ModalBackgroundRemover from '../common/ModalBackgroundRemover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function VirtualMachinesPage({ theme }) {

    const [columnFiltersPopulated, setColumnFiltersPopulated] = useState(false);

    const searchTerm = useSelector(state => state.page.virtualMachines.searchTerm);
    const userPermissions = useSelector(state => state.auth.permissions);
    const selectedMachine = useSelector(state => state.page.virtualMachines.selectedMachine);
    const filterByColumn = useSelector(state => state.page.virtualMachines.filterByColumn);
    const textFilters = useSelector(state => state.page.virtualMachines.textFilters);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userPermissions.some(p => p.name === "ViewVirtualMachines")) {
            dispatch(virtualMachinesActions.loadVirtualMachines()).catch(error => {
                toast.error("Loading Virtual Machines Failed:" + error, { autoClose: false });
            });
        }

        ModalBackgroundRemover.removeModalBackground();
    }, []);

    useEffect(() => {
        let noFilters = true;
        for (const text in textFilters) {
            if (textFilters[text] != "") {
                noFilters = false;
                setColumnFiltersPopulated(true);
            }
        }
        if (noFilters) {
            setColumnFiltersPopulated(false);
        }
    }, [textFilters])

    const handleStartVMConfirmed = (event, machine) => {
        event.preventDefault();
        dispatch(virtualMachinesActions.startVirtualMachine(machine.virtualMachineId)).then(() => {
            toast.success(machine.name + " has been started");
            dispatch(virtualMachinesActions.loadVirtualMachines()).catch(error => {
                toast.error("Loading Virtual Machines Failed:" + error, { autoClose: false });
            });
        }).catch(error => {
            toast.error("Starting " + machine.name + " Failed: " + error, { autoClose: false })
        });
        bootstrap.Modal.getInstance(document.getElementById('start-modal')).hide();
    }

    const handleRestartVMConfirmed = (event, machine) => {
        event.preventDefault();
        let description = event.target[0].value;
        let restartInfo = { virtualMachineId: machine.virtualMachineId, description: description };
        dispatch(virtualMachinesActions.restartVirtualMachine(restartInfo)).then(() => {
            toast.success(machine.name + " has been restarted");
            dispatch(virtualMachinesActions.loadVirtualMachines()).catch(error => {
                toast.error("Loading Virtual Machines Failed:" + error, { autoClose: false });
            });
        }).catch(error => {
            toast.error("Restarting " + machine.name + " Failed: " + error, { autoClose: false })
        });
        bootstrap.Modal.getInstance(document.getElementById('restart-modal')).hide();
    }

    const handleStopVMConfirmed = (event, machine) => {
        event.preventDefault();
        let description = event.target[0].value;
        let stopInfo = { virtualMachineId: machine.virtualMachineId, description: description };
        dispatch(virtualMachinesActions.stopVirtualMachine(stopInfo)).then(() => {
            toast.success(machine.name + " has been shut down");
            dispatch(virtualMachinesActions.loadVirtualMachines()).catch(error => {
                toast.error("Loading Virtual Machines Failed:" + error, { autoClose: false });
            });
        }).catch(error => {
            toast.error("Shutdown " + machine.name + " Failed: " + error, { autoClose: false })
        });
        bootstrap.Modal.getInstance(document.getElementById('stop-modal')).hide();
    }

    const handleSearchTermChange = (event) => {
        dispatch(pageActions.updateSearchTerm("virtualMachines", event.target.value));
    }

    const handleToggleFilterByColumn = () => {
        dispatch(pageActions.toggleFilterByColumn("virtualMachines", !filterByColumn));
    }

    const handleClearFilterByColumn = () => {
        dispatch(pageActions.clearTextFilters("virtualMachines"));
        setColumnFiltersPopulated(false);
    }

    return (
        <>
            <div className="h-100">
                <div id="view-all" className={ "py-3 px-4 oasis-rounded page-card h-100 media-controlled " + theme }>
                    <div className="d-flex flex-row py-3 ps-5 pe-4 page-card-header">
                        <div className="d-flex my-auto">
                            <h2 className="mb-0 list-header media-controlled">Virtual Machines</h2>
                        </div>
                        <div className="d-flex ms-auto">
                            <div className="my-auto">
                                <a
                                    onClick={ handleToggleFilterByColumn }
                                    className={ theme + " column-filter-icon media-controlled" }
                                    data-tip="Show/Hide Filters"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-delay-show="500"
                                    data-border={ true }
                                >
                                    {
                                        filterByColumn ?
                                            <FontAwesomeIcon icon="fa-solid fa-filters" />
                                            : <FontAwesomeIcon icon="fa-regular fa-filters" />
                                    }
                                </a>
                                <a
                                    onClick={ handleClearFilterByColumn }
                                    className={ theme + " column-filter-icon media-controlled" }
                                    data-tip="Clear Filters"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-delay-show="500"
                                    data-border={ true }
                                    data-event-off="click"
                                >
                                    {
                                        columnFiltersPopulated ?
                                            <FontAwesomeIcon icon="fa-solid fa-filter-circle-xmark" />
                                            : <FontAwesomeIcon icon="fa-regular fa-filter-circle-xmark" />
                                    }
                                </a>
                            </div>
                            <div className="my-auto me-3">
                                <FontAwesomeIcon className="searchIcon media-controlled" icon="fas fa-search" />
                                <input
                                    onChange={ handleSearchTermChange }
                                    id="search_term"
                                    className={ "search-input media-controlled " + theme }
                                    autocomplete="off"
                                    name="SearchTerm"
                                    type="text"
                                    value={ searchTerm }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row page-card-body">
                        <FilterSlideout RecordType={ "virtualMachines" } theme={ theme } />
                        <div className="d-flex flex-column w-100 h-100">
                            <PaginatedList RecordType="virtualMachines" theme={ theme } />
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                title="Start VM Confirmation"
                id="start-modal"
                displayValue={ "This action turns on " + selectedMachine.name + ", which may incur billable infrastructure charges.<br><br>Proceed?" }
                onSubmit={ (event) => handleStartVMConfirmed(event, { name: selectedMachine.name, virtualMachineId: selectedMachine.virtualMachineId }) }
                theme={ theme }
            />
            <ConfirmationModal
                title="Restart VM Confirmation"
                id="restart-modal"
                displayValue={ "WARNING: This action restarts " + selectedMachine.name + ", disconnecting all users and causing any unsaved work to be lost.<br><br><textarea id='restart-description' rows='3' maxLength='100' class='form-control' placeholder='Description (Max 100 Characters)'></textarea><br>Proceed?" }
                onSubmit={ (event) => handleRestartVMConfirmed(event, { name: selectedMachine.name, virtualMachineId: selectedMachine.virtualMachineId }) }
                theme={ theme }
            />
            <ConfirmationModal
                title="Stop VM Confirmation"
                id="stop-modal"
                displayValue={ "WARNING: This action powers off " + selectedMachine.name + ", disconnecting all users and causing any unsaved work to be lost.<br><br><textarea id='stop-description' rows='3' maxLength='100' class='form-control' placeholder='Description (Max 100 Characters)'></textarea><br>Proceed?" }
                onSubmit={ (event) => handleStopVMConfirmed(event, { name: selectedMachine.name, virtualMachineId: selectedMachine.virtualMachineId }) }
                theme={ theme }
            />
        </>
    )
}

export default VirtualMachinesPage;