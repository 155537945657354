import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as statCardActions from '../../../../redux/actions/statCardActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoTruncateText from '../../../common/AutoTruncateText';

function PaginatedStatCard({ Stats, ReloadStats, theme, sideNavState, isInEditMode = false, onDeleteItem }) {

    const cardNumber = useSelector(state => state.statCard.cardNumber);
    const widgetHeight = useSelector(state => state.page.home.dashboardLayout.lg.find(x => x.i == Stats.title).h);

    const resizableDivRef = useRef(null);
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const statContentRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        updateFontSize();
    }, [resizableDivRef?.current?.offsetWidth, resizableDivRef?.current?.offsetHeight]);

    const goToNextCard = () => { dispatch(statCardActions.updateCardNumber(nextCardNumber())); }

    const nextCardNumber = () => {
        if (cardNumber > Stats.statCards.length - 1) {
            return 1;
        } else {
            return cardNumber + 1;
        }
    }

    const goToPreviousCard = () => { dispatch(statCardActions.updateCardNumber(previousCardNumber())); }

    const previousCardNumber = () => {
        if (cardNumber < 2) {
            return Stats.statCards.length;
        } else {
            return cardNumber - 1;
        }
    }

    const updateFontSize = () => {
        //lower size multiplier allows ref element to be bigger 
        updateElementSize(titleRef, 12, 35, 35);
        updateElementSize(subtitleRef, 10, 20, 45);
        updateElementSize(statContentRef, 10, 20, 45);
    }

    const updateElementSize = (refItem, minSize, maxSize, sizeMultiplier) => {
        const element = refItem.current;
        const resizableDivElement = resizableDivRef.current;

        const fontSize = (resizableDivElement.offsetHeight + resizableDivElement.offsetWidth) / sizeMultiplier;

        element.style.fontSize = fontSize + 'px';

        //handle min max size of header
        if (fontSize < minSize || widgetHeight < 4) {
            element.style.fontSize = minSize + 'px';
        }

        if (fontSize > maxSize) {
            element.style.fontSize = maxSize + 'px';
        }
    }

    return (
        <div ref={ resizableDivRef } className={ theme + " d-flex flex-column py-3 px-4 oasis-rounded widget-container stat-card " + sideNavState } >
            { isInEditMode == true ?
                <div onClick={ () => onDeleteItem() }>
                    <a className='close btn-widget-delete'>
                        <FontAwesomeIcon icon="fas fa-times" beat={ true } />
                    </a>
                </div> :
                ""
            }
            <div className="row pt-1">
                <div className="col pb-2 text-nowrap fw-bold">
                    <div ref={ titleRef }>{ Stats.title }</div>
                </div>
                <div className="col stat-card-subtitle text-truncate">
                    <div className="ms-auto" ref={ subtitleRef }><AutoTruncateText text={ Stats.statCards[cardNumber - 1].subTitle } theme={ theme } alignDirection="right" /></div>
                </div>
                { Stats.statCards[cardNumber - 1].error ?
                    Stats.statCards[cardNumber - 1].error === "No File Transfer Available" ?
                        ""
                        : <div className="col-1 ms-auto">
                            <a onClick={ ReloadStats }><FontAwesomeIcon icon="fa-solid fa-arrow-rotate-right" /></a>
                        </div>
                    : ""
                }
            </div>
            <div className="d-flex flex-row h-100 w-100">
                { Stats.statCards.length > 1 ?
                    <div className="px-0 my-auto clickable">
                        <FontAwesomeIcon icon="fas fa-angle-left" className="card-nav-enabled" onClick={ goToPreviousCard } />
                    </div>
                    : ""
                }
                <div ref={ statContentRef } className="d-flex flex-column w-100 justify-content-start">
                    { Stats.statCards[cardNumber - 1].stats && Stats.statCards[cardNumber - 1].stats.length > 0 ?
                        Stats.statCards[cardNumber - 1].stats.map(stat => {

                            return (
                                <div className="d-flex flex-row ps-4 pt-2 justify-content-between">
                                    <div className="pe-2 d-inline-block"><AutoTruncateText text={ stat.label } theme={ theme } /></div>
                                    <div className="me-5">{ stat.value }</div>
                                </div>
                            )
                        })
                        :
                        (Stats.statCards[cardNumber - 1].error ?
                            (Stats.statCards[cardNumber - 1].error === "No " + Stats.title + " Available" ?
                                <div className="mt-2 mx-4">
                                    <a href="https://support.lexi.legal/servicedesk/customer/portal/3/create/31" target="_blank">Submit a Support ticket</a> to set up a Media Shuttle portal for fast and secure data transfer to and from Oasis.
                                </div>
                                :
                                <div className="mt-2 mx-4">
                                    { Stats.statCards[cardNumber - 1].error }
                                </div>
                            )
                            :
                            <div className="my-auto mx-auto">
                                <FontAwesomeIcon icon="fas fa-spinner" pulse />
                            </div>
                        )
                    }
                </div>
                { Stats.statCards.length > 1 ?
                    <div className="px-0 my-auto clickable">
                        <FontAwesomeIcon icon="fas fa-angle-right" className="card-nav-enabled" onClick={ goToNextCard } />
                    </div>
                    :
                    ""
                }
            </div>
        </div>
    )
}

export default PaginatedStatCard;