import React, { useRef, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

const AutoTruncateText = ({ text, theme, alignDirection }) => {
    const containerRef = useRef(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const container = containerRef.current;
        if (container.scrollWidth > container.clientWidth) {
            setIsTruncated(true);
        } else {
            setIsTruncated(false);
        }
    }, [containerRef.current?.scrollWidth]);

    useEffect(() => {
        const handleResize = () => {
            const container = containerRef.current;

            if (container.scrollWidth > container.clientWidth) {
                setIsTruncated(true);
            } else {
                setIsTruncated(false);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            ref={ containerRef }
            style={ { overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: alignDirection } }
        >
            { isTruncated ?
                <span
                    data-tip={ text }
                    data-type={ theme }
                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                    data-border={ true }
                >
                    { text }
                </span>
                : <span>{ text }</span>
            }
        </div>
    );
};

export default AutoTruncateText;