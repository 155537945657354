import React, { useEffect, useState } from 'react';
import * as partnersActions from '../../redux/actions/partnersActions';
import * as usersActions from '../../redux/actions/usersActions';
import * as pageActions from '../../redux/actions/pageActions';
import * as servicesActions from '../../redux/actions/servicesActions';
import * as linksActions from '../../redux/actions/linksActions';
import * as groupsActions from '../../redux/actions/groupsActions';
import { useDispatch, useSelector } from 'react-redux';
import PartnerEdit from './PartnerEdit';
import PartnerDetails from './PartnerDetails';
import { toast } from 'react-toastify';
import ConfirmationModal from '../common/ConfirmationModal';
import PaginatedList from '../common/PaginatedList';
import FilterSlideout from '../common/FilterSlideout';
import * as bootstrap from 'bootstrap';
import * as ModalBackgroundRemover from '../common/ModalBackgroundRemover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PartnersPage({ theme }) {

    const [columnFiltersPopulated, setColumnFiltersPopulated] = useState(false);

    const searchTerm = useSelector(state => state.page.partners.searchTerm);
    const allPartners = useSelector(state => state.partners);
    const selectedPartner = useSelector(state => state.page.partners.selectedPartner);
    const partnerServices = useSelector(state => state.services.partner);
    const globalLinks = useSelector(state => state.links.global);
    const userPermissions = useSelector(state => state.auth.permissions);
    const filterByColumn = useSelector(state => state.page.partners.filterByColumn);
    const textFilters = useSelector(state => state.page.partners.textFilters);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userPermissions.some(p => p.name === "ViewPartners")) {
            dispatch(partnersActions.loadPartners()).catch(error => {
                toast.error("Loading Partners Failed:" + error, { autoClose: false });
            });
        }
        if (userPermissions.some(p => p.name === "ViewUsers")) {
            dispatch(usersActions.loadUsers()).catch(error => {
                toast.error("Loading Users Failed:" + error, { autoClose: false });
            });
        }

        dispatch(linksActions.loadGlobalLinks()).catch(error => {
            toast.error("Loading Global Links Failed:" + error, { autoClose: false });
        });
        dispatch(servicesActions.loadPartnerServices()).catch(error => {
            toast.error("Loading Partner Services Failed:" + error, { autoClose: false });
        });
        ModalBackgroundRemover.removeModalBackground();
        dispatch(groupsActions.loadAvailableSubscriptions()).catch(error => {
            toast.error("Loading Available Subscriptions Failed: " + error, { autoClose: false })
        });
        dispatch(groupsActions.loadAvailablePocs()).catch(error => {
            toast.error("Loading Available Pocs Failed: " + error, { autoClose: false })
        });
    }, [])

    useEffect(() => {
        let noFilters = true;
        for (const text in textFilters) {
            if (textFilters[text] != "") {
                noFilters = false;
                setColumnFiltersPopulated(true);
            }
        }
        if (noFilters) {
            setColumnFiltersPopulated(false);
        }
    }, [textFilters])

    const handleNewPartner = () => {
        let oasisPartner = allPartners.find(p => p.name === "Oasis");
        let parentPartnerId = 0;
        let parentPartnerName = "Select the Parent Partner...";
        if (oasisPartner) {
            parentPartnerId = oasisPartner.partnerId;
            parentPartnerName = oasisPartner.name;
        }
        let integrationServices = partnerServices.filter(ps => ps.name === "Login" || ps.name === "Communications" || ps.name === "File Transfer" || ps.name === "Support");
        integrationServices.forEach(is => is.isDeleted = false);
        let availableServices = partnerServices.filter(ps => integrationServices.indexOf(ps) === -1);
        let availableGlobalLinks = [...globalLinks]
        dispatch(pageActions.updateSelectedPartner({
            integrationServices: integrationServices,
            availableServices: availableServices,
            defaultSubscriptions: [],
            parentPartnerId,
            parentPartnerName,
            twoFactorMethods: [],
            hubSpotCompany: { id: "0", name: "Please Select a HubSpot Company..." },
            availableJiraOrganizations: [{ id: "-1", name: "--Managed by Jira Admins--" }],
            availableGlobalLinks,
            externalLinks: [],
            mediaShuttlePortals: [],
            jiraGroups: [],
            defaultJiraOrganizations: [{ id: "-1", name: "--Managed by Jira Admins--" }],
            tams: [],
            sams: []
        }));
        new bootstrap.Modal(document.getElementById('edit-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleDisablePartnerConfirmed = (event) => {
        event.preventDefault();
        dispatch(partnersActions.disablePartner(selectedPartner))
            .then(empty => {
                toast.success(selectedPartner.name + " has been disabled");
                bootstrap.Modal.getInstance(document.getElementById('disable-modal')).hide();
                dispatch(partnersActions.loadPartner(selectedPartner));
            })
            .catch(error => {
                toast.error("Disable Partner Failed: " + error, { autoClose: false })
            });
    }

    const handleEnablePartnerConfirmed = (event) => {
        event.preventDefault();
        dispatch(partnersActions.enablePartner(selectedPartner))
            .then(empty => {
                toast.success(selectedPartner.name + " has been enabled");
                bootstrap.Modal.getInstance(document.getElementById('enable-modal')).hide();
                dispatch(partnersActions.loadPartner(selectedPartner));
            })
            .catch(error => {
                toast.error("Enable Partner Failed: " + error, { autoClose: false })
            });
    }

    const handleSearchTermChange = (event) => {
        dispatch(pageActions.updateSearchTerm("partners", event.target.value));
    }

    const handleToggleFilterByColumn = () => {
        dispatch(pageActions.toggleFilterByColumn("partners", !filterByColumn));
    }

    const handleClearFilterByColumn = () => {
        dispatch(pageActions.clearTextFilters("partners"));
        setColumnFiltersPopulated(false);
    }

    return (
        <>
            <div className="h-100">
                <div id="view-all" className={ "py-3 px-4 oasis-rounded page-card media-controlled " + theme }>
                    <div className="d-flex flex-row py-3 ps-5 pe-4 page-card-header">
                        <div className="d-flex my-auto">
                            <h2 className="mb-0 list-header media-controlled">Partners</h2>
                        </div>
                        <div className="d-flex ms-auto">
                            <div className="my-auto">
                                <a
                                    onClick={ handleToggleFilterByColumn }
                                    className={ theme + " column-filter-icon media-controlled" }
                                    data-tip="Show/Hide Filters"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-delay-show="500"
                                    data-border={ true }
                                >
                                    {
                                        filterByColumn ?
                                            <FontAwesomeIcon icon="fa-solid fa-filters" />
                                            : <FontAwesomeIcon icon="fa-regular fa-filters" />
                                    }
                                </a>
                                <a
                                    onClick={ handleClearFilterByColumn }
                                    className={ theme + " column-filter-icon media-controlled" }
                                    data-tip="Clear Filters"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-delay-show="500"
                                    data-border={ true }
                                    data-event-off="click"
                                >
                                    {
                                        columnFiltersPopulated ?
                                            <FontAwesomeIcon icon="fa-solid fa-filter-circle-xmark" />
                                            : <FontAwesomeIcon icon="fa-regular fa-filter-circle-xmark" />
                                    }
                                </a>
                            </div>
                            <div className="my-auto me-3">
                                <FontAwesomeIcon className="searchIcon media-controlled" icon="fas fa-search" />
                                <input
                                    onChange={ handleSearchTermChange }
                                    id="search_term"
                                    className={ "search-input media-controlled " + theme }
                                    autocomplete="off"
                                    name="SearchTerm"
                                    type="text"
                                    value={ searchTerm }
                                />
                            </div>
                        </div>
                        { userPermissions.some(up => up.name == "AddPartners") ?
                            <a onClick={ handleNewPartner } className="media-controlled btn btn-new my-auto me-3 py-2 px-3"><FontAwesomeIcon icon="fas fa-plus" /> New Partner</a>
                            : ""
                        }
                    </div>
                    <div className="d-flex flex-row page-card-body">
                        <FilterSlideout RecordType={ "partners" } theme={ theme } />
                        <div className="d-flex flex-column w-100 h-100">
                            <PaginatedList RecordType="partners" theme={ theme } />
                        </div>
                    </div>
                </div>
            </div>
            <PartnerEdit theme={ theme } />
            <PartnerDetails theme={ theme } />
            <ConfirmationModal
                title="Disable Confirmation"
                id="disable-modal"
                displayValue={ "Are you sure you want to disable " + selectedPartner.name + "?" + (selectedPartner.hasEnabledUsers ? "<br /><br /><b>" + selectedPartner.name + " has enabled users and these users will also be disabled.</b>" : "") }
                onSubmit={ handleDisablePartnerConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Enable Confirmation"
                id="enable-modal"
                displayValue={ "Are you sure you want to enable " + selectedPartner.name + "?" }
                onSubmit={ handleEnablePartnerConfirmed }
                theme={ theme }
            />
        </>
    )
}

export default PartnersPage;