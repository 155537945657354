import * as supportService from '../../services/supportService';
import * as types from "./types";

export function loadGroupsSuccess(groups) {
    return { type: types.LOAD_SUPPORT_GROUPS_SUCCESS, groups };
}

export function updateGroupsSuccess(groups) {
    return { type: types.UPDATE_SUPPORT_GROUPS_SUCCESS, groups };
}

export function loadGroups() {
    return function (dispatch) {
        return supportService.getGroups()
            .then(groups => {
                dispatch(loadGroupsSuccess(groups));
            })
            .catch(error => {
                throw error;
            });
    }
}

export function updateGroups(updatedGroups) {
    return function (dispatch) {
        return supportService.updateGroups(updatedGroups)
            .then(groups => {
                dispatch(updateGroupsSuccess(groups));
            })
            .catch(error => {
                throw error;
            });
    }
}