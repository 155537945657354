import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as kbActions from '../../../../redux/actions/kbActions';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function KBCard({ theme, sideNavState, isInEditMode = false, onDeleteItem }) {

    const [selectedPages, setSelectedPages] = useState([]);

    const books = useSelector(state => state.kb.books);
    const widgetHeight = useSelector(state => state.page.home.dashboardLayout.lg.find(x => x.i == "Knowledge Base").h);
    const resizableDivRef = useRef(null);
    const titleRef = useRef(null);
    const contentRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(kbActions.loadKnowledgeBaseBooks()).catch(error => {
            toast.error("Loading KB Books Failed: " + error, { autoClose: false });
        });
    }, [])

    useEffect(() => {
        let pages = [];
        let pagesOutsideOfChapters = books.map(book => book.contents.filter(content => content.type == "page")).flat();
        let chapters = books.map(book => book.contents.filter(content => content.type == "chapter")).flat();
        let pagesInsideOfChapters = chapters.map(chapter => chapter.pages).flat();
        let totalPageCount = pagesInsideOfChapters.length + pagesOutsideOfChapters.length;
        let pickFromThesePages = [...pagesOutsideOfChapters.concat(pagesInsideOfChapters)];
        for (let i = 0; i < (totalPageCount < 5 ? totalPageCount : 5); i++) {
            let pagePosition = Math.floor(Math.random() * pickFromThesePages.length);
            pages[i] = pickFromThesePages[pagePosition];
            pickFromThesePages.splice(pagePosition, 1);
        }
        setSelectedPages(pages);
    }, [books])

    useEffect(() => {
        updateFontSize();
    }, [resizableDivRef?.current?.offsetWidth, resizableDivRef?.current?.offsetHeight]);

    const updateFontSize = () => {
        //lower size multiplier allows ref element to be bigger 
        updateElementSize(titleRef, 12, 35, 35);
        updateElementSize(contentRef, 10, 20, 70);
    }

    const updateElementSize = (refItem, minSize, maxSize, sizeMultiplier) => {
        const element = refItem.current;
        const resizableDivElement = resizableDivRef.current;

        const fontSize = (resizableDivElement.offsetHeight + resizableDivElement.offsetWidth) / sizeMultiplier;

        element.style.fontSize = fontSize + 'px';

        //handle min max size of header
        if (fontSize < minSize || widgetHeight < 5) {
            element.style.fontSize = minSize + 'px';
        }

        if (fontSize > maxSize) {
            element.style.fontSize = maxSize + 'px';
        }
    }

    return (
        <div ref={ resizableDivRef } className={ theme + " d-flex flex-column py-3 px-4 oasis-rounded widget-container info-card " + sideNavState }>
            { isInEditMode == true ?
                <div onClick={ () => onDeleteItem() }>
                    <a className='close btn-widget-delete'>
                        <FontAwesomeIcon icon="fas fa-times" beat={ true } />
                    </a>
                </div> :
                ""
            }
            <div className="row pt-1">
                <div className="col pb-2 text-nowrap fw-bold">
                    <div ref={ titleRef }>Knowledge Base</div>
                </div>
            </div>
            <div ref={ contentRef } className="d-flex flex-column w-100 justify-content-start">
                { selectedPages.length > 0 ? selectedPages.map((page) => {
                    return (
                        <div className="d-flex flex-row ps-4 pt-2">
                            <Link to={ "/KnowledgeBase/page/" + (page.pageId ? page.pageId : page.contentId) + "/" + page.slug } > { page.name }</Link>
                        </div>
                    )
                }) : <div className="my-auto mx-auto"><FontAwesomeIcon icon="fas fa-spinner" pulse /></div> }
            </div>
        </div>
    )
}

export default KBCard;