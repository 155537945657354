import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import KnowledgeBaseExpandableChapter from '../knowledgebase/KnowledgeBaseExpandableChapter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function KnowledgeBaseExpandableBook({ book, type, handleSelectedContentClicked }) {

    const selectedContent = useSelector(state => state.page.kb.selectedContent);

    const [isExpanded, setIsExpanded] = useState(false);

    const handleRowExpand = () => {
        setIsExpanded(isExpanded => !isExpanded);
    }

    useEffect(() => {
        if (selectedContent) {
            setIsExpanded(selectedContent.bookId === book.bookId || (selectedContent.contentId === book.bookId && type === "book"));
        }        
    }, [selectedContent])

    return (
        <>
            { book.contents.length > 0 ?
                <div className="kb-book-selection ms-4 mt-4 me-4">
                    <div className="d-flex flex-row clickable" onClick={ () => handleRowExpand() }>
                        <div className="col-md-11">
                            <strong><Link onClick={ () => handleSelectedContentClicked() } className={ book.bookId === selectedContent.contentId && type === "book" ? "selected" : "" } to={ "/KnowledgeBase/book/" + book.bookId + "/" + encodeURIComponent(book.slug) }> { book.name }</Link></strong>
                        </div>
                        <div className="col-md-1 text-end" >
                            <FontAwesomeIcon className="my-auto" icon={ "fa-solid fa-angle-" + (isExpanded ? "down" : "right ") } />
                        </div>
                    </div>
                    { isExpanded &&
                        <>
                            { book.contents.map((content) => {
                                if (content.type == "chapter") {
                                    return (
                                        <KnowledgeBaseExpandableChapter chapter={ content } type={ type } handleSelectedContentClicked={ () => handleSelectedContentClicked() } />
                                    )
                                } else if (content.type == "page") {
                                    return (
                                        <div className='ms-3 me-2 mb-1' key={ content.contentId }>
                                            <Link onClick={() => handleSelectedContentClicked() } className={ content.contentId === selectedContent.contentId && type === "page" ? "selected" : "" } to={ "/KnowledgeBase/page/" + content.contentId + "/" + encodeURIComponent(content.slug ? content.slug : content.name) }> { content.name }</Link>
                                        </div>
                                    )
                                }
                            }) }
                        </>
                    }
                </div>
                : "" }
        </>
    )
};

export default KnowledgeBaseExpandableBook;