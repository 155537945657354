import React from 'react';
import * as pageActions from '../../../redux/actions/pageActions';
import { CTableRow, CTableDataCell } from '@coreui/bootstrap-react';
import { useDispatch, useSelector } from 'react-redux';
import * as bootstrap from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function RoleRow({ data, theme }) {

    const userPermissions = useSelector(state => state.auth.permissions);
    const dispatch = useDispatch();

    const handleEditRole = () => {
        dispatch(pageActions.updateSelectedRole(data));
        new bootstrap.Modal(document.getElementById('edit-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleDeleteRole = () => {
        dispatch(pageActions.updateSelectedRole(data));
        new bootstrap.Modal(document.getElementById('delete-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    return (
        <>
            <CTableRow className="clickable" key={ data.id } onDoubleClick={ !data.isSystemDefined && userPermissions.some(up => up.name == "EditRoles") ? handleEditRole : "" }>
                <CTableDataCell>{ data.name }</CTableDataCell>
                <CTableDataCell>{ data.partnerName }</CTableDataCell>
                <CTableDataCell>
                    { !data.isSystemDefined ?
                        <div className="d-flex">
                            { userPermissions.some(up => up.name == "EditRoles") ?
                                <a onClick={ handleEditRole } className="btn btn-new my-auto py-1 px-2 ms-auto me-2"><FontAwesomeIcon icon="fa-solid fa-sliders" /></a>
                                : ""
                            }
                            { userPermissions.some(up => up.name == "DeleteRoles") ?
                                <a onClick={ handleDeleteRole } className="btn btn-no my-auto py-1 px-2"><FontAwesomeIcon icon="fa-solid fa-trash-can" /></a>
                                : ""
                            }
                        </div>
                        : ""
                    }
                </CTableDataCell>
            </CTableRow>
        </>
    )
};

export default RoleRow;