import React from 'react';
import * as pageActions from '../../redux/actions/pageActions';
import { CTableRow, CTableDataCell } from '@coreui/bootstrap-react';
import { useDispatch, useSelector } from 'react-redux';
import * as bootstrap from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PartnerRow({ data, theme }) {

    const users = useSelector(state => state.users);
    const partner = useSelector(state => state.partners.find(partner => partner.partnerId == data.partnerId));
    const userPermissions = useSelector(state => state.auth.permissions);
    const dispatch = useDispatch();

    const handleEditPartner = () => {
        dispatch(pageActions.updateSelectedPartner(data));
        new bootstrap.Modal(document.getElementById('edit-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleDisplayPartnerDetails = () => {
        dispatch(pageActions.updateSelectedPartner(data));
        new bootstrap.Modal(document.getElementById('details-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleDisablePartner = () => {
        data.hasEnabledUsers = users.filter(user => !user.disabled && user.partnerId === data.partnerId).length > 0
        dispatch(pageActions.updateSelectedPartner(data));
        new bootstrap.Modal(document.getElementById('disable-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleEnablePartner = () => {
        dispatch(pageActions.updateSelectedPartner(data));
        new bootstrap.Modal(document.getElementById('enable-modal'), { backdrop: 'static', keyboard: false }).show();
    }
    return (
        <>
            <CTableRow className="clickable" key={ partner.partnerId } onDoubleClick={ userPermissions.some(up => up.name == "EditPartners" || up.name == "EditPartnerServices") && partner.name != "Oasis" ? handleEditPartner : "" }>
                <CTableDataCell>{ partner.name }</CTableDataCell>
                <CTableDataCell>{ partner.parentPartnerName }</CTableDataCell>
                <CTableDataCell>{ partner.partnerADIdentifier }</CTableDataCell>
                <CTableDataCell>{ partner.tams.map(tam => tam.firstName + " " + tam.lastName).join(';') }</CTableDataCell>
                <CTableDataCell>{ partner.sams.map(sam => sam.firstName + " " + sam.lastName).join(';') }</CTableDataCell>
                <CTableDataCell className="right-align">
                    <a className="dropdown" data-bs-toggle="dropdown"><FontAwesomeIcon icon="fas fa-ellipsis-h" /></a>
                    <div className={ "dropdown-menu dropdown-menu-left " + theme }>
                        { userPermissions.some(up => up.name == "EditPartners" || up.name == "EditPartnerServices") && partner.name != "Oasis" ?
                            <a onClick={ handleEditPartner } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="fas fa-pencil-alt" /> Edit</a>
                            : ""
                        }
                        <a onClick={ handleDisplayPartnerDetails } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="fas fa-info-circle" /> Details</a>
                        {
                            partner.disabled ?
                                userPermissions.some(up => up.name == "EnablePartners") ?
                                    <a onClick={ handleEnablePartner } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="fas fa-plus" /> Enable</a>
                                    : ""
                                : userPermissions.some(up => up.name == "DisablePartners") && partner.name != "Oasis" ?
                                    <a onClick={ handleDisablePartner } className={ "dropdown-item " + theme }><FontAwesomeIcon className="pe-1" icon="fas fa-minus" /> Disable</a>
                                    : ""
                        }
                    </div>
                </CTableDataCell>
            </CTableRow>
        </>
    )
};

export default PartnerRow;