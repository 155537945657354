import axios from 'axios'
import { config } from '../config';

export async function getAvailableLinks() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/links/available');
    return response.data;
}

export async function getGlobalLinks() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/links/global');
    return response.data;
}