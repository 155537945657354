import React from 'react';
import { Container } from '../../node_modules/reactstrap';
import NavMenu from './NavMenu';
import SideNavMenu from './SideNavMenu';
import { useSelector } from 'react-redux';

export default function Layout({ children, theme, sideNavState }) {

    const userPreferences = useSelector(state => state.userPreferences);
    const auth = useSelector(state => state.auth);

    return (
        <>
            { auth.user && userPreferences[0].userPreferenceId ?
                <div>
                    <NavMenu sideNavState={ sideNavState } theme={theme} />
                    <div className="d-flex m-0 row main-page">
                        <SideNavMenu sideNavState={ sideNavState } theme={theme}/>
                        <Container className={ "page-content col p-4 " + sideNavState + " " + theme}>
                            { children }
                        </Container>
                    </div>
                </div>
                :
                <div>
                    <div className="d-flex m-0 row main-page">
                        <Container className={ "page-content col p-4 " + sideNavState }>
                            { children }
                        </Container>
                    </div>
                </div>
            }
        </>
    );
}