import React, { useState } from 'react'
import Select from 'react-select';
import * as pageActions from '../../redux/actions/pageActions';
import * as usersService from '../../services/usersService';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as bootstrap from 'bootstrap';
import * as styles from '../common/DropdownStyles';
import { CFormCheck } from '@coreui/bootstrap-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function MassUserEdit({ theme }) {

    const [isSaving, setIsSaving] = useState(false);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
    const [massAction, setMassAction] = useState('add');

    const availableSubscriptions = useSelector(state => state.groups.availableSubscriptions);
    const selectedUsers = useSelector(state => state.page.users.selectedUsers);
    const dispatch = useDispatch();

    const handleMassSave = (event) => {
        setIsSaving(true);
        event.preventDefault();

        var shouldAdd = massAction === "add" ? true : false;
        var subs = selectedSubscriptions;
        var users = selectedUsers;
        var massEditSubscriptionInfo = { UserInfoMins: users, Subscriptions: subs, ShouldAdd: shouldAdd };

        usersService.massEditSubscriptions(massEditSubscriptionInfo)
            .then(empty => {
                bootstrap.Modal.getInstance(document.getElementById('mass-user-edit-modal')).hide();
                toast.success("Mass Operation Complete");
            })
            .catch(error => {
                bootstrap.Modal.getInstance(document.getElementById('mass-user-edit-modal')).hide();
                toast.error("Mass Update Users Failed: " + error.message ?? error, { autoClose: false });
            })
            .finally(() => {
                setIsSaving(false);
                dispatch(pageActions.clearSelectedUsers());
                setSelectedSubscriptions([]);
                setMassAction('add');
                document.getElementById("flexRadioDefault1").checked = true;
            });
    }

    const handleSubscriptionsChange = (event) => {
        let currentSubs = event.map(e => ({
            groupId: e.value,
            name: e.label
        }));
        setSelectedSubscriptions(currentSubs);
    }

    const onCloseClick = () => {
        setSelectedSubscriptions([]);
        setMassAction('add');
        document.getElementById("flexRadioDefault1").checked = true;
    }

    return (
        <>
            <div className="modal fade" role="dialog" id="mass-user-edit-modal">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className={ "modal-content " + theme }>
                        <form onSubmit={ handleMassSave }>
                            <div className="modal-header">
                                <h2 className="modal-title">Mass Edit Subscriptions</h2>
                                <a className="close" data-bs-dismiss="modal" aria-label="Close" onClick={ onCloseClick }>
                                    <FontAwesomeIcon icon="fas fa-times" />
                                </a>
                            </div>
                            <div className="modal-body">
                                { (isSaving) ?
                                    <div className="modal-overlay" />
                                    : ""
                                }
                                <div className="container-fluid h-100">
                                    <Select
                                        name="subscriptions"
                                        isMulti
                                        closeMenuOnSelect={ false }
                                        placeholder="No Subscriptions Selected"
                                        onChange={ handleSubscriptionsChange }
                                        value={ selectedSubscriptions.map(subscription => ({
                                            value: subscription.groupId,
                                            label: subscription.name
                                        })) }
                                        menuPosition="fixed"
                                        menuPlacement="bottom"
                                        options={ availableSubscriptions.map(subscription => ({
                                            value: subscription.groupId,
                                            label: subscription.name
                                        })) }
                                        styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div id="required-disclaimer" className="row ms-3">
                                    <div className="col" onClick={ () => setMassAction('add') }>
                                        <CFormCheck type="radio" name="flexRadioDefault" id="flexRadioDefault1" label="Add" defaultChecked />
                                    </div>
                                    <div className="col" onClick={ () => setMassAction('remove') }>
                                        <CFormCheck type="radio" name="flexRadioDefault" id="flexRadioDefault2" label="Remove" />
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col">
                                        { isSaving ?
                                            <div className="btn btn-new btn-block"><span className="saving-button fa-fade">Saving...</span></div>
                                            : <input type="submit" value="Save" className="btn btn-new btn-block" disabled={ selectedSubscriptions.length == 0 } />
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default MassUserEdit;