import React, { useEffect, useRef } from 'react';
import * as usersService from '../services/usersService';
import { storeUser } from '../redux/actions/authActions';
import { setAuthHeader } from './axiosHeaders';

export default function AuthProvider({ userManager: manager, store, children }) {

    let userManager = useRef();

    useEffect(() => {
        userManager.current = manager

        const handleStayLoggedIn = () => {
            userManager.current.signinSilent().then(user => {
                console.log(`User Refreshed: ${user.profile.name} expires in ${user.expires_in} second(s)`)
                store.dispatch(storeUser(user))
            }).catch(error => {
                console.log(error)
            });
        }

        const onUserLoaded = (user) => {
            console.log(`${Date.now()} - user loaded: ${user.profile.name} expires in ${user.expires_in} second(s)`)
            setAuthHeader(user.access_token);
            usersService.getUserPartnerName().then(partnerName => {
                user.profile.partnerName = partnerName;
            }).catch(error => console.log(error)).finally(() => store.dispatch(storeUser(user)));
        }

        const onUserUnloaded = () => {
            setAuthHeader(null)
            console.log(`user unloaded`)
        }

        const onAccessTokenExpiring = () => {
            handleStayLoggedIn();
            console.log(`user token expiring`)
        }

        const onAccessTokenExpired = () => {
            console.log(`${Date.now()} - user token expired`)
            userManager.current.signoutRedirect();
        }

        const onUserSignedOut = () => {
            console.log(`user signed out`)
        }

        // events for user
        userManager.current.events.addUserLoaded(onUserLoaded)
        userManager.current.events.addUserUnloaded(onUserUnloaded)
        userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring)
        userManager.current.events.addAccessTokenExpired(onAccessTokenExpired)
        userManager.current.events.addUserSignedOut(onUserSignedOut)

        // Specify how to clean up after this effect:
        return function cleanup() {
            userManager.current.events.removeUserLoaded(onUserLoaded);
            userManager.current.events.removeUserUnloaded(onUserUnloaded);
            userManager.current.events.removeAccessTokenExpiring(onAccessTokenExpiring)
            userManager.current.events.removeAccessTokenExpired(onAccessTokenExpired)
            userManager.current.events.removeUserSignedOut(onUserSignedOut)
        };
    }, [manager, store]);

    return (
        React.Children.only(children)
    )
}