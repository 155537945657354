import * as types from './types'
import * as userService from '../../services/userService'
import * as usersService from '../../services/usersService'
import { setAuthHeader } from '../../utils/axiosHeaders'

export function loadUserSuccess(user) {
    return { type: types.LOAD_USER_AUTH_SUCCESS, user }
}

export function updateUserActivitySuccess(userActivity) {
    return { type: types.UPDATE_USER_ACTIVITY, lastActive: userActivity.lastActive }
}

export function loadUser() {
    return function (dispatch) {
        return userService.loadUser()
            .then(user => {
                dispatch(loadUserSuccess(user));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function updateUserActivity() {
    return function (dispatch) {
        return usersService.updateUserActivity()
            .then(userActivity => {
                dispatch(updateUserActivitySuccess(userActivity));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function storeUser(user) {
    setAuthHeader(user.access_token);
    return {
        type: types.STORE_USER,
        payload: user
    }
}

export function loadingUser() {
    return {
        type: types.LOADING_USER
    }
}

export function storeUserError() {
    return {
        type: types.STORE_USER_ERROR
    }
}

export function userExpired() {
    return {
        type: types.USER_EXPIRED
    }
}

export function userSignedOut() {
    return {
        type: types.USER_SIGNED_OUT
    }
}