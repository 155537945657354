import axios from 'axios'
import { config } from '../config';

export async function getAvailableJiraOrganizations() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/jira/availableorganizations');
    return response.data;
}

export async function getAvailableJiraGroups() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/jira/availablegroups');
    return response.data;
}