import * as types from "./types";
import * as brainspaceStatsService from "../../services/brainspaceStatsService";

export function loadBrainspaceStatsSuccess(brainspaceStats) {
    return { type: types.LOAD_BRAINSPACE_STATS_SUCCESS, brainspaceStats }
}

export function clearBrainspaceStats() {
    return { type: types.CLEAR_BRAINSPACE_STATS }
}

export function reloadBrainspaceStats() {
    return function (dispatch) {
        dispatch(clearBrainspaceStats());
        dispatch(loadBrainspaceStats());
    }
}

export function loadBrainspaceStats() {
    return function (dispatch) {
        return brainspaceStatsService.getBrainspaceStats()
            .then(brainspaceStats => {
                dispatch(loadBrainspaceStatsSuccess(brainspaceStats));
            })
    };
}