import * as types from "./types";

export function updateDataLimitSetting(setting, recordType) {
    return function (dispatch) {
        return dispatch({ type: types.UPDATE_DATALIMIT_SETTING, setting, recordType });
    };
}

export function updateSortBySetting(setting, recordType) {
    return function (dispatch) {
        return dispatch({ type: types.UPDATE_SORTBY_SETTING, setting, recordType });
    };
}

export function updateFilterSetting(setting, recordType) {
    return function (dispatch) {
        return dispatch({ type: types.UPDATE_FILTER_SETTING, setting, recordType });
    };
}