import React from 'react';
import { CTableRow, CTableDataCell } from '@coreui/bootstrap-react';
import * as pageActions from '../../redux/actions/pageActions';
import { useDispatch, useSelector } from 'react-redux';
import * as bootstrap from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function VirtualMachineRow({ data, theme }) {

    const userPermissions = useSelector(state => state.auth.permissions);

    const dispatch = useDispatch();

    const handlePowerButtonClick = () => {
        if (data.statusString == "Stopped") {
            dispatch(pageActions.updateSelectedMachine(data));
            new bootstrap.Modal(document.getElementById('start-modal'), { backdrop: 'static', keyboard: false }).show();
        }

        if (data.statusString == "Running") {
            dispatch(pageActions.updateSelectedMachine(data));
            document.getElementById('stop-description').value = '';
            new bootstrap.Modal(document.getElementById('stop-modal'), { backdrop: 'static', keyboard: false }).show();
        }
    }

    const handleRestartButtonClick = () => {
        dispatch(pageActions.updateSelectedMachine(data));
        document.getElementById('restart-description').value = '';
        new bootstrap.Modal(document.getElementById('restart-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const powerButtonClass = data.statusString == "Running" ? "power-btn-on" : "power-btn-off";
    const powerTip = () => {
        if (data.statusString == "Running") {
            return "Power Off";
        }

        if (data.statusString == "Stopped") {
            return "Power On";
        }
    }

    return (
        <>
            <CTableRow className="clickable" key={ data.virtualMachineId }>
                <CTableDataCell>{ data.name }</CTableDataCell>
                <CTableDataCell>{ data.partnerName }</CTableDataCell>
                <CTableDataCell>{ data.maxMemory } MB</CTableDataCell>
                <CTableDataCell>{ data.memoryAvailablePercentage }%</CTableDataCell>
                <CTableDataCell>{ data.cpuCount }</CTableDataCell>
                <CTableDataCell>
                    { data.statusString == "Starting" || data.statusString == "Restarting" || data.statusString == "Stopping" ?
                        <span className="fa-fade">{ data.statusString }<FontAwesomeIcon className="loading-icon" icon="fas fa-ellipsis" /></span>
                        : data.statusString }
                </CTableDataCell>
                <CTableDataCell className="right-align">
                    { userPermissions.some(up => up.name == "ManageVirtualMachines") && data.virtualMachineRole.isManageable ?
                        <div>
                            { data.statusString == "Running" || data.statusString == "Stopped" ?
                                <FontAwesomeIcon data-tip={ powerTip() }
                                    data-delay-show="1000"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-border={ true }
                                    onClick={ handlePowerButtonClick }
                                    className={ powerButtonClass }
                                    icon="fas fa-power-off" />
                                : "" }
                            { data.statusString == "Running" ?
                                <FontAwesomeIcon onClick={ handleRestartButtonClick }
                                    data-tip="Restart"
                                    data-delay-show="1000"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-border={ true }
                                    className="ms-2 restart-btn"
                                    icon="fas fa-rotate" />
                                : "" }
                        </div>
                        : "" }

                </CTableDataCell>
            </CTableRow>
        </>
    )
};

export default VirtualMachineRow;