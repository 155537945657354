import * as types from '../actions/types';
import initialState from './InitialState';

export default function jiraReducer(state = initialState.jira, action) {
    switch (action.type) {
        case types.LOAD_AVAILABLE_JIRA_ORGANIZATIONS_SUCCESS:
            return { ...state, availableOrganizations: action.organizations.sort(sortOrganizations) };
        case types.LOAD_AVAILABLE_JIRA_GROUPS_SUCCESS:
            return { ...state, availableGroups: action.groups.sort(sortGroups) };
        default:
            return state;
    }
}

function sortOrganizations(organizationA, organizationB) {
    var x = organizationA.name.toLowerCase();
    var y = organizationB.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
}

function sortGroups(groupA, groupB) {
    var x = groupA.name.toLowerCase();
    var y = groupB.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
}