import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as userPreferencesActions from '../redux/actions/userPreferencesActions';
import * as permissionsActions from '../redux/actions/permissionsActions';
import * as kbActions from '../redux/actions/kbActions';
import { setAuthHeader } from '../utils/axiosHeaders';

export default function PreDataLoader({ children }) {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {       
        if (auth.user && auth.user.access_token && !auth.isLoadingUser) {
            setAuthHeader(auth.user.access_token);
            dispatch(permissionsActions.loadUserPermissions()).catch(error => {
                console.log(error + ' - Error loading pre data user permissions');
            });
            dispatch(userPreferencesActions.loadUserPreferences()).catch(error => {
                console.log(error + ' ' + auth.user.access_token);
            });
            dispatch(kbActions.loadKnowledgeBaseBooks()).catch(error => {
                console.log(error + ' - Error loading pre data kb books');
            });
        }
    }, [auth.user])

    return (
        React.Children.toArray(children)
    )
}