import * as types from '../actions/types';
import initialState from './InitialState';

export default function permissionsReducer(state = initialState.permissions, action) {
    switch (action.type) {
        case types.LOAD_PERMISSIONS_SUCCESS:
            return action.permissions;
        default:
            return state;
    }
}