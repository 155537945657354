import * as types from "./types";
import * as statCardActions from '../actions/statCardActions';
import * as fileTransferStatsService from "../../services/fileTransferStatsService";

export function loadFileTransferStatsSuccess(fileTransferStats) {
    return { type: types.LOAD_FILE_TRANSFER_STATS_SUCCESS, fileTransferStats }
}

export function clearFileTransferStats() {
    return { type: types.CLEAR_FILE_TRANSFER_STATS }
}

export function loadPartnerPortalsSuccess(portals) {
    return { type: types.LOAD_PARTNER_PORTALS_SUCCESS, portals }
}

export function reloadFileTransferStats(portalUrl) {
    return function (dispatch) {
        dispatch(statCardActions.updateCardNumber(1));
        dispatch(clearFileTransferStats());
        dispatch(loadFileTransferStats());
    }
}

export function loadFileTransferStats() {
    return function (dispatch) {
        return fileTransferStatsService.getFileTransferStats()
            .then(fileTransferStats => {
                dispatch(loadFileTransferStatsSuccess(fileTransferStats));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadPartnerPortals() {
    return function (dispatch) {
        return fileTransferStatsService.getPartnerPortals()
            .then(portals => {
                dispatch(loadPartnerPortalsSuccess(portals));
            })
            .catch(error => {
                throw error;
            });
    };
}