import React, { useEffect, useState, useMemo } from 'react';
import StatCard from './dashboard/widgets/StatCardWidget';
import PaginatedStatCard from './dashboard/widgets/PaginatedStatCardWidget';
import KBCard from './dashboard/widgets/KBCardWidget';
import PaginatedResourceCard from './dashboard/widgets/resourceCard/PaginatedResourceCardWidget';
import GraphWidget from './dashboard/widgets/GraphWidget';
import { toast } from 'react-toastify';
import * as relativityStatsActions from '../../redux/actions/relativityStatsActions';
import * as brainspaceStatsActions from '../../redux/actions/brainspaceStatsActions';
import * as storageStatsActions from '../../redux/actions/storageStatsActions';
import * as blackoutStatsActions from '../../redux/actions/blackoutStatsActions';
import * as readySuiteStatsActions from '../../redux/actions/readySuiteStatsActions';
import * as webProcessingStatsActions from '../../redux/actions/webProcessingStatsActions';
import * as lsiTranslationStatsActions from '../../redux/actions/lsiTranslationStatsActions';
import * as fileTransferStatsActions from '../../redux/actions/fileTransferStatsActions';
import * as supportStatsActions from '../../redux/actions/supportStatsActions';
import * as resourcesActions from '../../redux/actions/resourcesActions';
import * as appSettingsActions from '../../redux/actions/appSettingsActions';
import * as pageActions from '../../redux/actions/pageActions';
import { useDispatch, useSelector } from "react-redux";
import * as ModalBackgroundRemover from '../common/ModalBackgroundRemover';
import ResourceConfiguration from './dashboard/widgets/resourceCard/ResourceConfiguration';
import LoadingPage from '../LoadingPage';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ManageWidgetsModal from './ManageWidgetsModal';
import * as bootstrap from 'bootstrap';

function HomePage({ theme, sideNavState }) {

    const relativityStats = useSelector(state => state.relativityStats);
    const brainspaceStats = useSelector(state => state.brainspaceStats);
    const storageStats = useSelector(state => state.storageStats);
    const blackoutStats = useSelector(state => state.blackoutStats);
    const readySuiteStats = useSelector(state => state.readySuiteStats);
    const lsiTranslationStats = useSelector(state => state.lsiTranslationStats);
    const supportStats = useSelector(state => state.supportStats);
    const fileTransferStats = useSelector(state => state.fileTransferStats);
    const webProcessingStats = useSelector(state => state.webProcessingStats);
    const layout = useSelector(state => state.page.home.dashboardLayout);
    const isDashboardInEditMode = useSelector(state => state.page.home.isDashboardInEditMode);

    const HomePageGrid = useMemo(() => WidthProvider(Responsive), []);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(relativityStatsActions.loadUserRelativityInstances()).then(() => {
            dispatch(relativityStatsActions.loadRelativityStats()).catch(error => {
                toast.error("Loading Relativity Stats Failed:" + error, { autoClose: false })
            });
            dispatch(brainspaceStatsActions.loadBrainspaceStats()).catch(error => {
                toast.error("Loading Brainspace Stats Failed:" + error, { autoClose: false })
            });
            dispatch(storageStatsActions.loadStorageStats()).catch(error => {
                toast.error("Loading Storage Stats Failed:" + error, { autoClose: false })
            });
            dispatch(blackoutStatsActions.loadBlackoutStats()).catch(error => {
                toast.error("Loading Blackout Stats Failed:" + error, { autoClose: false })
            });
            dispatch(readySuiteStatsActions.loadReadySuiteStats()).catch(error => {
                toast.error("Loading ReadySuite Stats Failed:" + error, { autoClose: false })
            });
            dispatch(webProcessingStatsActions.loadWebProcessingStats()).catch(error => {
                toast.error("Loading WebProcessing Stats Failed:" + error, { autoClose: false })
            });
            dispatch(lsiTranslationStatsActions.loadLsiTranslationStats()).catch(error => {
                toast.error("Loading LSI Translation Stats Failed:" + error, { autoClose: false })
            });
        }).catch(error => {
            toast.error("Loading User Relativity Instances Failed:" + error, { autoClose: false });
        })

        dispatch(supportStatsActions.loadSupportStats()).catch(error => {
            toast.error("Loading Support Stats Failed:" + error, { autoClose: false })
        });
        dispatch(fileTransferStatsActions.loadFileTransferStats()).catch(error => {
            toast.error("Loading File Transfer Stats Failed:" + error, { autoClose: false })
        });
        dispatch(resourcesActions.loadResources()).catch(error => {
            toast.error("Loading Resources Failed:" + error, { autoClose: false })
        });
        dispatch(appSettingsActions.loadResourceCycleInterval()).catch(error => {
            toast.error("Loading App Setting Failed: " + error, { autoClose: false });
        });

        dispatch(pageActions.updateDashboardLayout({ lg: [] }));//load empty layout
        dispatch(pageActions.loadUserDashboardLayout()).catch(error => {
            toast.error("Loading User Dashboard Layout Failed: " + error, { autoClose: false });
        });
        dispatch(pageActions.loadAvailableWidgets()).catch(error => {
            toast.error("Loading Available Dashboard Widgets Failed: " + error, { autoClose: false });
        });

        ModalBackgroundRemover.removeModalBackground();

    }, []);

    useEffect(() => {
        fireResizeEvent();
    }, [sideNavState]);

    const fireResizeEvent = () => {
        window.dispatchEvent(new Event('resize'));
    }

    const reloadSupportStats = () => {
        dispatch(supportStatsActions.reloadSupportStats());
    }

    const reloadFileTransferStats = () => {
        dispatch(fileTransferStatsActions.reloadFileTransferStats());
    }

    const toggleEditMode = () => {
        dispatch(pageActions.updateDashboardEditMode(!isDashboardInEditMode));
    }

    const handleManageWidgetClick = () => {
        new bootstrap.Modal(document.getElementById('manage-widgets-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleSaveLayoutChangesClick = () => {
        dispatch(pageActions.saveUserDashboardLayout(layout));
        toggleEditMode();
    }

    const handleOnLayoutChange = (currentLayout, allLayouts) => {
        dispatch(pageActions.updateDashboardLayout(allLayouts));
    }

    const handleDeleteItem = (widgetId) => {
        const updatedLayoutLg = layout.lg.filter(x => x.i !== widgetId);
        const updatedLayout = { ...layout, lg: updatedLayoutLg };
        dispatch(pageActions.updateDashboardLayout(updatedLayout));
    }

    const cancelLayoutClick = () => {
        toggleEditMode();
        dispatch(pageActions.loadUserDashboardLayout()).catch(error => {
            toast.error("Loading User Dashboard Layout: " + error, { autoClose: false });
        });
    }

    return (
        <>
            { theme != undefined ?
                <>
                    <div className='dashboard-editor-container' >
                        { isDashboardInEditMode ?
                            <div className={ 'dashboard-editor-group p-1 ' + sideNavState + ' ' + theme }>
                                <button onClick={ handleManageWidgetClick } className={ "btn btn-short btn-edit-dashboard btn-edit-dashboard-settings " + theme } >
                                    <FontAwesomeIcon icon="fa-solid fa-gear" /> Dashboard Settings
                                </button>
                                <button
                                    onClick={ handleSaveLayoutChangesClick }
                                    className={ "btn btn-short btn-edit-dashboard btn-edit-dashboard-save " + theme }
                                    data-tip={ "Save Layout Changes" }
                                    data-delay-show="1000"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-border={ true }>
                                    <FontAwesomeIcon icon="fa-duotone fa-check" />
                                </button>
                                <button
                                    onClick={ cancelLayoutClick }
                                    className={ "btn btn-short btn-edit-dashboard btn-edit-dashboard-cancel " + theme }
                                    data-tip={ "Cancel" }
                                    data-delay-show="1000"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-border={ true }>
                                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                </button>
                            </div>
                            : ""
                        }
                    </div>

                    <HomePageGrid
                        className="home-page-grid"
                        layouts={ layout }
                        breakpoints={ { lg: 1200 } }
                        cols={ { lg: 12 } }
                        maxRows={ 18 }
                        rowHeight={ 30 }
                        margin={ [25, 25] }
                        containerPadding={ [0, 0] }
                        isDraggable={ isDashboardInEditMode }
                        isResizable={ isDashboardInEditMode }
                        onLayoutChange={ (currentLayout, allLayouts) => handleOnLayoutChange(currentLayout, allLayouts) }
                        isBounded={ true }
                        useCSSTransforms={ false }
                    >
                        { layout.lg.some(x => x.i == "Relativity Workspaces") ?
                            <div key="Relativity Workspaces">
                                <GraphWidget
                                    Stats={ relativityStats.stats?.length > 0 ? relativityStats.stats.find(x => x.label === "Workspaces") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme } sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Relativity Workspaces") }
                                    widgetTitle="Relativity Workspaces"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Relativity Users") ?
                            <div key="Relativity Users">
                                <GraphWidget
                                    Stats={ relativityStats.stats?.length > 0 ? relativityStats.stats.find(x => x.label === "Users") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Relativity Users") }
                                    widgetTitle="Relativity Users"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Relativity Hosted Size") ?
                            <div key="Relativity Hosted Size">
                                <GraphWidget
                                    Stats={ relativityStats.stats?.length > 0 ? relativityStats.stats.find(x => x.label === "Hosted Size") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Relativity Hosted Size") }
                                    widgetTitle="Relativity Hosted Size"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Relativity Documents") ?
                            <div key="Relativity Documents">
                                <GraphWidget
                                    Stats={ relativityStats.stats?.length > 0 ? relativityStats.stats.find(x => x.label === "Documents") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Relativity Documents") }
                                    widgetTitle="Relativity Documents"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Brainspace Documents") ?
                            <div key="Brainspace Documents">
                                <GraphWidget
                                    Stats={ brainspaceStats.stats?.length > 0 ? brainspaceStats.stats.find(x => x.label === "Documents") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Brainspace Documents") }
                                    widgetTitle="Brainspace Documents"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Blackout Native Redacted Documents") ?
                            <div key="Blackout Native Redacted Documents">
                                <GraphWidget
                                    Stats={ blackoutStats.stats?.length > 0 ? blackoutStats.stats.find(x => x.label === "Native Redacted Documents") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Blackout Native Redacted Documents") }
                                    widgetTitle="Blackout Native Redacted Documents"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Blackout Native Redactions Placed") ?
                            <div key="Blackout Native Redactions Placed">
                                <GraphWidget
                                    Stats={ blackoutStats.stats?.length > 0 ? blackoutStats.stats.find(x => x.label === "Native Redactions Placed") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Blackout Native Redactions Placed") }
                                    widgetTitle="Blackout Native Redactions Placed"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Blackout Image Redacted Documents") ?
                            <div key="Blackout Image Redacted Documents">
                                <GraphWidget
                                    Stats={ blackoutStats.stats?.length > 0 ? blackoutStats.stats.find(x => x.label === "Image Redacted Documents") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Blackout Image Redacted Documents") }
                                    widgetTitle="Blackout Image Redacted Documents"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Blackout Redacted Pages") ?
                            <div key="Blackout Redacted Pages">
                                <GraphWidget
                                    Stats={ blackoutStats.stats?.length > 0 ? blackoutStats.stats.find(x => x.label === "Redacted Pages") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Blackout Redacted Pages") }
                                    widgetTitle="Blackout Redacted Pages"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "ReadySuite Number of Users") ?
                            <div key="ReadySuite Number of Users">
                                <GraphWidget
                                    Stats={ readySuiteStats.stats?.length > 0 ? readySuiteStats.stats.find(x => x.label === "Number of Users") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("ReadySuite Number of Users") }
                                    widgetTitle="ReadySuite Number of Users"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Relativity Web Processing Discovered Documents") ?
                            <div key="Relativity Web Processing Discovered Documents">
                                <GraphWidget
                                    Stats={ webProcessingStats.stats?.length > 0 ? webProcessingStats.stats.find(x => x.label === "Discovered Documents") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Relativity Web Processing Discovered Documents") }
                                    widgetTitle="Relativity Web Processing Discovered Documents"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Relativity Web Processing Discovered Size") ?
                            <div key="Relativity Web Processing Discovered Size">
                                <GraphWidget
                                    Stats={ webProcessingStats.stats?.length > 0 ? webProcessingStats.stats.find(x => x.label === "Discovered Document Size") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Relativity Web Processing Discovered Size") }
                                    widgetTitle="Relativity Web Processing Discovered Size"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "LSI Translation Document Count") ?
                            <div key="LSI Translation Document Count">
                                <GraphWidget
                                    Stats={ lsiTranslationStats.stats?.length > 0 ? lsiTranslationStats.stats.find(x => x.label === "Number of Documents") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("LSI Translation Document Count") }
                                    widgetTitle="LSI Translation Document Count"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "LSI Translation Case Count") ?
                            <div key="LSI Translation Case Count">
                                <GraphWidget
                                    Stats={ lsiTranslationStats.stats?.length > 0 ? lsiTranslationStats.stats.find(x => x.label === "Number of Cases") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("LSI Translation Case Count") }
                                    widgetTitle="LSI Translation Case Count"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Support") ?
                            <div key="Support">
                                <StatCard Stats={ supportStats }
                                    ReloadStats={ reloadSupportStats }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Support") } />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "File Transfer") ?
                            <div key="File Transfer">
                                <PaginatedStatCard
                                    Stats={ fileTransferStats }
                                    ReloadStats={ reloadFileTransferStats }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("File Transfer") } />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Knowledge Base") ?
                            <div key="Knowledge Base">
                                <KBCard
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Knowledge Base") } />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Resources") ?
                            <div key="Resources">
                                <PaginatedResourceCard
                                    isPreview={ false }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Resources") } />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Storage Archive Size") ?
                            <div key="Storage Archive Size">
                                <GraphWidget
                                    Stats={ storageStats.stats?.length > 0 ? storageStats.stats.find(x => x.label === "Archive Server Size") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Storage Archive Size") }
                                    widgetTitle="Storage Archive Size"
                                />
                            </div> : <></>
                        }
                        { layout.lg.some(x => x.i == "Storage Staging Size") ?
                            <div key="Storage Staging Size">
                                <GraphWidget
                                    Stats={ storageStats.stats?.length > 0 ? storageStats.stats.find(x => x.label === "Staging Server Size") : { label: "Loading...", value: "0", statHistory: [] } }
                                    theme={ theme }
                                    sideNavState={ sideNavState }
                                    isInEditMode={ isDashboardInEditMode }
                                    onDeleteItem={ () => handleDeleteItem("Storage Staging Size") }
                                    widgetTitle="Storage Staging Size"
                                />
                            </div> : <></>
                        }
                    </HomePageGrid>
                    <ResourceConfiguration theme={ theme } sideNavState={ sideNavState } />
                    <ManageWidgetsModal theme={ theme } />
                </>
                :
                <LoadingPage />
            }
        </>
    )
}

export default HomePage;