import * as pageActions from "../actions/pageActions";
import * as types from "./types";
import * as usersService from "../../services/usersService";

export function loadUserStatusesSuccess(userStatuses) {
    return { type: types.LOAD_USER_STATUSES_SUCCESS, userStatuses }
}

export function loadUserStatuses() {
    return function (dispatch) {
        return usersService.getAllLoggedInUserStatuses()
            .then(userStatuses => {
                dispatch(loadUserStatusesSuccess(userStatuses));
                dispatch(pageActions.loadedPage("userStatuses"));
            })
            .catch(error => {
                throw error;
            });
    };
}