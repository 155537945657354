import * as types from '../actions/types';
import initialState from './InitialState';

export default function relativityStatsReducer(state = initialState.relativityStats, action) {
    switch (action.type) {
        case types.LOAD_RELATIVITY_STATS_SUCCESS:
            return { ...state, stats: action.relativityStats.stats, title: action.relativityStats.title, error: action.relativityStats.error };
        case types.CLEAR_RELATIVITY_STATS:
            return { ...state, stats: initialState.relativityStats.stats, title: initialState.relativityStats.title, error: initialState.relativityStats.error };
        case types.LOAD_RELATIVITY_INSTANCES_SUCCESS:
            return { ...state, instances: action.relativityInstances };
        case types.SAVE_USER_RELATIVITY_INSTANCES_SUCCESS:
            return { ...state, selectedRelativityInstances: action.relativityInstances };
        case types.LOAD_USER_RELATIVITY_INSTANCES_SUCCESS:
            return { ...state, selectedRelativityInstances: action.relativityInstances };
        default:
            return state;
    }
}