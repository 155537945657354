import * as types from "./types";
import * as communicationsService from "../../services/communicationsService";

export function loadAvailableTemplatesSuccess(templates) {
    return { type: types.LOAD_AVAILABLE_TEMPLATES_SUCCESS, templates }
}

export function loadAvailableTemplates() {
    return function (dispatch) {
        return communicationsService.getTemplates()
            .then(templates => {
                dispatch(loadAvailableTemplatesSuccess(templates));
            })
            .catch(error => {
                throw error;
            });
    };
}