import * as types from "./types";
import * as lsiTranslationStatsService from "../../services/lsiTranslationStatsService";

export function loadLsiTranslationStatsSuccess(lsiTranslationStats) {
    return { type: types.LOAD_LSI_TRANSLATION_STATS_SUCCESS, lsiTranslationStats }
}

export function clearLsiTranslationStats() {
    return { type: types.CLEAR_LSI_TRANSLATION_STATS }
}

export function reloadLsiTranslationStats() {
    return function (dispatch) {
        dispatch(clearLsiTranslationStats());
        dispatch(loadLsiTranslationStats());
    }
}

export function loadLsiTranslationStats() {
    return function (dispatch) {
        return lsiTranslationStatsService.getLsiTranslationStats()
            .then(lsiTranslationStats => {
                dispatch(loadLsiTranslationStatsSuccess(lsiTranslationStats));
            })
    };
}