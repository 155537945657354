import axios from 'axios'
import { config } from '../config';

export async function getRelativityStats() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/relativity/stats');
    return response.data;
}

export async function getRelativityInstances() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/relativity/instances');
    return response.data;
}

export async function saveUserRelativityInstances(relativityInstances) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/relativity/save/instances', relativityInstances);
    return response.data;
}

export async function getUserRelativityInstances() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/relativity/user/instances');
    return response.data;
}