export default {
    auth: {
        user: null,
        isLoadingUser: false,
        permissions: [],
        canAssignRoles: [],
        userRoles: [],
        lastActive: new Date(Date.now() + 1)
    },
    userPreferences: [
        {
            preferenceName: "Theme",
            preferenceValue: "Light"
        },
        {
            preferenceName: "SideNavState",
            preferenceValue: "Open"
        }
    ],
    notifications: [],
    kb: { books: [], searchResults: [], pdf: { fileName: "", content: "" } },
    resources: [],
    previewResources: [],
    selectedResource: {},
    relativityStats: {
        title: "Relativity",
        stats: [],
        instances: [],
        selectedRelativityInstances: []
    },
    brainspaceStats: {
        title: "Brainspace",
        stats: []
    },
    storageStats: {
        title: "Storage",
        stats: []
    },
    webProcessingStats: {
        title: "Relativity Web Processing",
        stats: []
    },
    blackoutStats: {
        title: "Blackout",
        stats: []
    },
    readySuiteStats: {
        title: "ReadySuite",
        stats: []
    },
    lsiTranslationStats: {
        title: "LSI Translation",
        stats: []
    },
    fileTransferStats: {
        title: "File Transfer",
        statCards: [{
            subTitle: "",
            stats: []
        }],
        portals: []
    },
    supportStats: {
        title: "Support",
        stats: [],
        url: ""
    },
    support: { groups: [] },
    statCard: { cardNumber: 1 },
    page: {
        home: {
            dashboardLayout: {//add new widgets here
                lg: [
                    {
                        "w": 2,
                        "h": 3,
                        "x": 0,
                        "y": 0,
                        "i": "Relativity Workspaces",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 2,
                        "y": 0,
                        "i": "Relativity Users",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 0,
                        "y": 3,
                        "i": "Relativity Hosted Size",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 2,
                        "y": 3,
                        "i": "Relativity Documents",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 4,
                        "h": 6,
                        "x": 4,
                        "y": 0,
                        "i": "Support",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 4,
                        "h": 6,
                        "x": 8,
                        "y": 0,
                        "i": "File Transfer",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 6,
                        "h": 7,
                        "x": 0,
                        "y": 6,
                        "i": "Knowledge Base",
                        "minW": 2,
                        "maxW": 6,
                        "minH": 4,
                        "maxH": 7
                    },
                    {
                        "w": 6,
                        "h": 7,
                        "x": 6,
                        "y": 6,
                        "i": "Resources",
                        "minW": 4,
                        "maxW": 6,
                        "minH": 5,
                        "maxH": 7
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 0,
                        "y": 9,
                        "i": "Brainspace Documents",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 0,
                        "y": 12,
                        "i": "Storage Staging Size",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6,
                        "moved": false,
                        "static": false
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 10,
                        "y": 9,
                        "i": "Storage Archive Size",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6,
                        "moved": false,
                        "static": false
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 2,
                        "y": 9,
                        "i": "Blackout Native Redacted Documents",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 4,
                        "y": 9,
                        "i": "Blackout Native Redactions Placed",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 6,
                        "y": 9,
                        "i": "Blackout Image Redacted Documents",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 8,
                        "y": 9,
                        "i": "Blackout Redacted Pages",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 2,
                        "y": 12,
                        "i": "ReadySuite Number of Users",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 4,
                        "y": 16,
                        "i": "LSI Translation Document Count",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 6,
                        "y": 16,
                        "i": "LSI Translation Case Count",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 8,
                        "y": 16,
                        "i": "Relativity Web Processing Discovered Size",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 0,
                        "y": 19,
                        "i": "Relativity Web Processing Discovered Documents",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    }
                ]
            },
            defaultDashboardLayout: {//this handles the default layout for new users
                lg: [
                    {
                        "w": 2,
                        "h": 3,
                        "x": 0,
                        "y": 0,
                        "i": "Relativity Workspaces",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 2,
                        "y": 0,
                        "i": "Relativity Users",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 0,
                        "y": 3,
                        "i": "Relativity Hosted Size",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 2,
                        "h": 3,
                        "x": 2,
                        "y": 3,
                        "i": "Relativity Documents",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 4,
                        "h": 6,
                        "x": 4,
                        "y": 0,
                        "i": "Support",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 4,
                        "h": 6,
                        "x": 8,
                        "y": 0,
                        "i": "File Transfer",
                        "minW": 2,
                        "maxW": 4,
                        "minH": 3,
                        "maxH": 6
                    },
                    {
                        "w": 6,
                        "h": 7,
                        "x": 0,
                        "y": 6,
                        "i": "Knowledge Base",
                        "minW": 2,
                        "maxW": 6,
                        "minH": 4,
                        "maxH": 7
                    },
                    {
                        "w": 6,
                        "h": 7,
                        "x": 6,
                        "y": 6,
                        "i": "Resources",
                        "minW": 4,
                        "maxW": 6,
                        "minH": 5,
                        "maxH": 7
                    }
                ]
            },
            availableWidgets: [],
            isDashboardInEditMode: false
        },
        kb: {
            selectedContent: {},
            searchTerm: ""
        },
        partners: {
            isLoading: true,
            searchTerm: "",
            filterByColumn: false,
            textFilters: {
                name: "",
                parentPartnerName: "",
                partnerADIdentifier: "",
                tams: "",
                sams: ""
            },
            selectedPartner: {
                availableServices: [], integrationServices: [], defaultSubscriptions: [], billingContacts: [], salesContacts: [],
                operationsContacts: [], authContacts: [], twoFactorMethods: [],
                availableGlobalLinks: [], externalLinks: [],
                defaultJiraOrganizations: [], mediaShuttlePortals: [], jiraGroups: [], tams: [], sams: [], availableJiraOrganizations: []
            },
            dataHeaders: [
                { text: "Name", dataField: "name", width: "25%", filterable: true },
                { text: "Parent Partner", dataField: "parentPartnerName", width: "25%", filterable: true },
                { text: "Identifier", dataField: "partnerADIdentifier", width: "8%", filterable: true },
                { text: "TAMs", dataField: "tams", width: "16%", filterable: true },
                { text: "SAMs", dataField: "sams", width: "16%", filterable: true },
                { text: "", dataField: "", width: "10%", filterable: false }
            ],
            pageNumber: 1,
            filters: [
                {
                    filterIdentifier: "enabled",
                    filterType: "triStateToggle",
                    group: "Miscellaneous",
                    leftLabel: "Enabled",
                    rightLabel: "Disabled",
                    leftTooltip: "", rightTooltip: ""
                },
                {
                    filterIdentifier: "recentlyUpdated",
                    filterType: "triStateToggle",
                    group: "Miscellaneous",
                    leftLabel: "Recent",
                    rightLabel: "Not Recent",
                    leftTooltip: "Partners that have been updated in the last month",
                    rightTooltip: "Partners that were last updated over a month ago"
                },
                {
                    filterIdentifier: "communications",
                    filterType: "triStateToggle",
                    group: "Service",
                    leftLabel: "Comms",
                    rightLabel: "No Comms",
                    leftTooltip: "Partners that have the communications service",
                    rightTooltip: "Partners that do not have the communications service"
                },
                {
                    filterIdentifier: "links",
                    filterType: "triStateToggle",
                    group: "Service",
                    leftLabel: "Links",
                    rightLabel: "No Links",
                    leftTooltip: "Partners that have the links service",
                    rightTooltip: "Partners that do not have the links service"
                },
                {
                    filterIdentifier: "support",
                    filterType: "triStateToggle",
                    group: "Service",
                    leftLabel: "Support",
                    rightLabel: "No Support",
                    leftTooltip: "Partners that have the support service",
                    rightTooltip: "Partners that do not have the support service"
                },
                {
                    filterIdentifier: "twoFA",
                    filterType: "triStateToggle",
                    group: "Service",
                    leftLabel: "Has 2FA",
                    rightLabel: "No 2FA",
                    leftTooltip: "Partners that have two factor authentication enabled",
                    rightTooltip: "Partners that have two factor authentication disabled"
                },
                { filterIdentifier: "tams", filterType: "multiSelect", label: "Tam(s)" },
                { filterIdentifier: "sams", filterType: "multiSelect", label: "Sam(s)" }
            ],
            dataLabel: "Partners"
        },
        users: {
            isLoading: true,
            searchTerm: "",
            filterByColumn: false,
            textFilters: {
                email: "",
                firstName: "",
                lastName: "",
                partnerName: "",
                lastLoginDate: ""
            },
            selectedUser: {
                integrationServices: [], availableServices: [], pocs: [], subscriptions: [], jiraGroups: [],
                roles: [], partnerId: 0, partnerName: "Select a Partner...",
                twoFactorMethodId: null, twoFactorMethodName: "", jiraOrganizations: [{ id: 0, name: "No Organization" }]
            },
            selectedUsers: [],
            allUsersSelected: false,
            dataHeaders: [
                { text: "", datafield: "all", width: "0%", filterable: false },
                { text: "Email", dataField: "email", width: "25%", filterable: true },
                { text: "First Name", dataField: "firstName", width: "18%", filterable: true },
                { text: "Last Name", dataField: "lastName", width: "18%", filterable: true },
                { text: "Partner", dataField: "partnerName", width: "18%", filterable: true },
                { text: "Last Login", dataField: "lastLoginDate", width: "11%", filterable: false },
                { text: "", dataField: "", width: "10%", filterable: false }
            ],
            pageNumber: 1,
            filters: [
                {
                    filterIdentifier: "enabled",
                    filterType: "triStateToggle",
                    group: "Miscellaneous",
                    leftLabel: "Enabled",
                    rightLabel: "Disabled",
                    leftTooltip: "",
                    rightTooltip: ""
                },
                {
                    filterIdentifier: "internal",
                    filterType: "triStateToggle",
                    group: "Miscellaneous",
                    leftLabel: "Internal",
                    rightLabel: "External",
                    leftTooltip: "Users that have the login service",
                    rightTooltip: "Users that do not have the login service"
                },
                {
                    filterIdentifier: "recentlyUpdated",
                    filterType: "triStateToggle",
                    group: "Miscellaneous",
                    leftLabel: "Recent",
                    rightLabel: "Not Recent",
                    leftTooltip: "Users that have been updated this month",
                    rightTooltip: "Users that were last updated over a month ago"
                },
                {
                    filterIdentifier: "communications",
                    filterType: "triStateToggle",
                    group: "Service",
                    leftLabel: "Comms",
                    rightLabel: "No Comms",
                    leftTooltip: "Users that have the communications service",
                    rightTooltip: "Users that do not have the communications service"
                },
                {
                    filterIdentifier: "twoFA",
                    filterType: "triStateToggle",
                    group: "Service",
                    leftLabel: "2FA Enabled",
                    rightLabel: "2FA Disabled",
                    leftTooltip: "Users that have two factor authentication enabled",
                    rightTooltip: "Users that have two factor authentication disabled"
                },
                {
                    filterIdentifier: "support",
                    filterType: "triStateToggle",
                    group: "Service",
                    leftLabel: "Support",
                    rightLabel: "No Support",
                    leftTooltip: "Users that have the support service",
                    rightTooltip: "Users that do not have the support service"
                },
                {
                    filterIdentifier: "auth",
                    filterType: "triStateToggle",
                    group: "Point Of Contact",
                    leftLabel: "Security",
                    rightLabel: "Not Security",
                    leftTooltip: "Users that are listed as a Security Point of Contact",
                    rightTooltip: "Users that are not listed as a Security Point of Contact"
                },
                {
                    filterIdentifier: "billing",
                    filterType: "triStateToggle",
                    group: "Point Of Contact",
                    leftLabel: "Accounting",
                    rightLabel: "Not Accounting",
                    leftTooltip: "Users that are listed as an Accounting Point of Contact",
                    rightTooltip: "Users that are not listed as an Accounting Point of Contact"
                },
                {
                    filterIdentifier: "operations",
                    filterType: "triStateToggle",
                    group: "Point Of Contact",
                    leftLabel: "Operations",
                    rightLabel: "Not Operations",
                    leftTooltip: "Users that are listed as an Operations Point of Contact",
                    rightTooltip: "Users that are not listed as an Operations Point of Contact"
                },
                {
                    filterIdentifier: "sales",
                    filterType: "triStateToggle",
                    group: "Point Of Contact",
                    leftLabel: "Sales",
                    rightLabel: "Not Sales",
                    leftTooltip: "Users that are listed as a Sales Point of Contact",
                    rightTooltip: "Users that are not listed as a Sales Point of Contact"
                },
                {
                    filterIdentifier: "emergencyNotifications",
                    filterType: "triStateToggle",
                    group: "Subscription",
                    leftLabel: "Emergency",
                    rightLabel: "No Emergency",
                    leftTooltip: "Users that have a subscription to Emergency Notifications",
                    rightTooltip: "Users that do not have a subscription to Emergency Notifications"
                },
                {
                    filterIdentifier: "insightsNewsletter",
                    filterType: "triStateToggle",
                    group: "Subscription",
                    leftLabel: "Insights",
                    rightLabel: "No Insights",
                    leftTooltip: "Users that have a subscription to the Insights Newsletter",
                    rightTooltip: "Users that do not have a subscription to the Insights Newsletter"
                },
                {
                    filterIdentifier: "oasisBlog",
                    filterType: "triStateToggle",
                    group: "Subscription",
                    leftLabel: "Oasis Blog",
                    rightLabel: "No Oasis Blog",
                    leftTooltip: "Users that have a subscription to the Oasis Blog",
                    rightTooltip: "Users that do not have a subscription to the Oasis Blog"
                },
                {
                    filterIdentifier: "maintenance",
                    filterType: "triStateToggle",
                    group: "Subscription",
                    leftLabel: "Maintenance",
                    rightLabel: "No Maintenance",
                    leftTooltip: "Users that have a subscription to the Maintenance/End of Month Notifications",
                    rightTooltip: "Users that do not have a subscription to the Maintenance/End of Month Notifications"
                },
                {
                    filterIdentifier: "ukMaintenance",
                    filterType: "triStateToggle",
                    group: "Subscription",
                    leftLabel: "UK Maintenance",
                    rightLabel: "No UK Maintenance",
                    leftTooltip: "Users that have a subscription to the UK Maintenance Communications",
                    rightTooltip: "Users that do not have a subscription to the UK Maintenance Communications"
                },
                {
                    filterIdentifier: "relativityOneEmergency",
                    filterType: "triStateToggle",
                    group: "Subscription",
                    leftLabel: "RelativityOne Emergency",
                    rightLabel: "No RelativityOne Emergency",
                    leftTooltip: "Users that have a subscription to the RelativityOne Emergency",
                    rightTooltip: "Users that do not have a subscription to the RelativityOne Emergency"
                },
                {
                    filterIdentifier: "revealEmergency",
                    filterType: "triStateToggle",
                    group: "Subscription",
                    leftLabel: "Reveal Emergency",
                    rightLabel: "No Reveal Emergency",
                    leftTooltip: "Users that have a subscription to the Reveal Emergency",
                    rightTooltip: "Users that do not have a subscription to the Reveal Emergency"
                }
            ],
            dataLabel: "Users"
        },
        virtualMachines: {
            isLoading: true,
            searchTerm: "",
            filterByColumn: false,
            textFilters: {
                name: "",
                partnerName: "",
                maxMemory: "",
                memoryAvailablePercentage: "",
                cpuCount: "",
                statusString: ""
            },
            selectedMachine: {},
            dataHeaders: [
                { text: "Name", dataField: "name", width: "23%", filterable: true },
                { text: "Partner", dataField: "partnerName", width: "22%", filterable: true },
                { text: "Max Memory", dataField: "maxMemory", width: "14%", filterable: false },
                { text: "Memory Available", dataField: "memoryAvailablePercentage", width: "15%", filterable: false },
                { text: "# of Cores", dataField: "cpuCount", width: "12%", filterable: false },
                { text: "Status", dataField: "statusString", width: "8%", filterable: true },
                { text: "", dataField: "", width: "6%", filterable: false }
            ],
            pageNumber: 1,
            filters: [],
            dataLabel: "Virtual Machines"
        },
        roles: {
            isLoading: true,
            searchTerm: "",
            filterByColumn: false,
            textFilters: {
                name: "",
                partnerName: ""
            },
            selectedRole: {
                partnerId: 0,
                permissions: [],
                canAssignRoles: []
            },
            dataHeaders: [
                { text: "Name", dataField: "name", width: "47%", filterable: true },
                { text: "Partner", dataField: "partnerName", width: "47%", filterable: true },
                { text: "", dataField: "", width: "6%", filterable: false }
            ],
            pageNumber: 1,
            filters: [
                {
                    filterIdentifier: "isSystemDefined",
                    filterType: "triStateToggle",
                    group: "Miscellaneous",
                    leftLabel: "System",
                    rightLabel: "User",
                    leftTooltip: "Roles that are defined by the app",
                    rightTooltip: "Roles created by users"
                }
            ],
            dataLabel: "Roles"
        },
        audits: {
            isLoading: true,
            searchTerm: "",
            filterByColumn: false,
            textFilters: {
                userEmail: "",
                partnerName: "",
                action: "",
                actedUpon: "",
                name: "",
                createdDate: ""
            },
            selectedAuditDetails: {
                userActionAuditLogEntry: {
                    actedUpon: "",
                    action: "",
                    createdDate: "",
                    partnerId: "",
                    partnerName: "",
                    name: "",
                    userActionAuditLogEntryId: "",
                    userEmail: "",
                    userId: ""
                }
            },
            dataHeaders: [
                { text: "User", dataField: "userEmail", width: "20%", filterable: true },
                { text: "Partner", dataField: "partnerName", width: "20%", filterable: true },
                { text: "Action", dataField: "action", width: "14%", filterable: true },
                { text: "Type", dataField: "actedUpon", width: "10%", filterable: true },
                { text: "Name", dataField: "name", width: "18%", filterable: true },
                { text: "Timestamp", dataField: "createdDate", width: "18%", filterable: false },
                { text: "", datafield: "", width: "4%", filterable: false }
            ],
            pageNumber: 1,
            filters: [
                { filterIdentifier: "date", filterType: "range", label: "Date Range" },
                { filterIdentifier: "users", filterType: "multiSelect", label: "User(s)" },
                { filterIdentifier: "partners", filterType: "multiSelect", label: "Partner(s)" },
                { filterIdentifier: "actions", filterType: "multiSelect", label: "Action(s)" },
                { filterIdentifier: "types", filterType: "multiSelect", label: "Type(s)" },
                { filterIdentifier: "names", filterType: "multiSelect", label: "Name(s)" }

            ],
            dataLabel: "Audits"
        },
        userStatuses: {
            isLoading: true,
            searchTerm: "",
            filterByColumn: false,
            textFilters: {
                emailAddress: "",
                firstName: "",
                lastName: "",
                partner: "",
                login: "",
                lastAction: ""
            },
            selectedUserStatus: {},
            dataHeaders: [
                { text: "Email Address", dataField: "emailAddress", width: "22%", filterable: true },
                { text: "First Name", dataField: "firstName", width: "14%", filterable: true },
                { text: "Last Name", dataField: "lastName", width: "14%", filterable: true },
                { text: "Partner", dataField: "partner", width: "20%", filterable: true },
                { text: "Login", dataField: "login", width: "15%", filterable: false },
                { text: "Last Action", dataField: "lastAction", width: "15%", filterable: false }
            ],
            pageNumber: 1,
            filters: [],
            dataLabel: "User Status"
        }
    },
    partners: [],
    users: [],
    virtualMachines: [],
    roles: [],
    permissions: [],
    userPermissions: [],
    audits: [],
    userStatuses: [],
    groups: { availableSubscriptions: [], availablePocs: [] },
    communications: { availableTemplates: [] },
    fileTransfer: { availablePortals: [] },
    services: { auth: [], partner: [] },
    jira: { availableOrganizations: [], availableGroups: [] },
    settings: {
        partners: {
            dataLimit: 25,
            sortBy: { value: "name", ascending: true },
            filters: {
                enabled: { isActive: true, value: true },
                recentlyUpdated: { isActive: false, value: true },
                communications: { isActive: false, value: true },
                links: { isActive: false, value: true },
                support: { isActive: false, value: true },
                infrastructure: { isActive: false, value: true },
                twoFA: { isActive: false, value: true },
                tams: { isActive: false, value: [] },
                sams: { isActive: false, value: [] }
            }
        },
        users: {
            dataLimit: 25,
            sortBy: { value: "email", ascending: true },
            filters: {
                enabled: { isActive: true, value: true },
                internal: { isActive: true, value: true },
                recentlyUpdated: { isActive: false, value: true },
                communications: { isActive: false, value: true },
                twoFA: { isActive: false, value: true },
                support: { isActive: false, value: true },
                auth: { isActive: false, value: true },
                billing: { isActive: false, value: true },
                operations: { isActive: false, value: true },
                sales: { isActive: false, value: true },
                emergencyNotifications: { isActive: false, value: true },
                insightsNewsletter: { isActive: false, value: true },
                oasisBlog: { isActive: false, value: true },
                maintenance: { isActive: false, value: true },
                ukMaintenance: { isActive: false, value: true },
                relativityOneEmergency: { isActive: false, value: true },
                revealEmergency: { isActive: false, value: true }
            }
        },
        virtualMachines: {
            dataLimit: 25,
            sortBy: { value: "name", ascending: true },
            filters: {}
        },
        roles: {
            dataLimit: 25,
            sortBy: { value: "name", ascending: true },
            filters: {
                isSystemDefined: { isActive: true, value: false }
            }
        },
        audits: {
            dataLimit: 25,
            sortBy: { value: "createdDate", ascending: false },
            filters: {
                date: { isActive: false, value: { from: "", to: "" } },
                users: { isActive: false, value: [] },
                actions: { isActive: false, value: [] },
                types: { isActive: false, value: [] },
                partners: { isActive: false, value: [] },
                names: { isActive: false, value: [] }
            }
        },
        userStatuses: {
            dataLimit: 25,
            sortBy: { value: "lastAction", ascending: false },
            filters: {}
        }
    },
    appSettings: { maintenanceMode: "disabled", resourceCycleIntervalInSeconds: 6 },
    links: { global: [], available: [] }
}