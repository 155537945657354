import * as types from "./types";
import * as jiraService from "../../services/jiraService";

export function loadAvailableJiraOrganizationsSuccess(organizations) {
    return { type: types.LOAD_AVAILABLE_JIRA_ORGANIZATIONS_SUCCESS, organizations }
}

export function loadAvailableJiraGroupsSuccess(groups) {
    return { type: types.LOAD_AVAILABLE_JIRA_GROUPS_SUCCESS, groups }
}

export function loadAvailableJiraOrganizations() {
    return function (dispatch) {
        return jiraService.getAvailableJiraOrganizations()
            .then(organizations => {
                dispatch(loadAvailableJiraOrganizationsSuccess(organizations));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadAvailableJiraGroups() {
    return function (dispatch) {
        return jiraService.getAvailableJiraGroups()
            .then(groups => {
                dispatch(loadAvailableJiraGroupsSuccess(groups));
            })
            .catch(error => {
                throw error;
            });
    };
}