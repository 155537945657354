import * as types from '../actions/types';
import initialState from './InitialState';

export default function readySuiteStatsReducer(state = initialState.readySuiteStats, action) {
    switch (action.type) {
        case types.LOAD_READYSUITE_STATS_SUCCESS:
            return { ...state, stats: action.readySuiteStats.stats, title: action.readySuiteStats.title, error: action.readySuiteStats.error };
        case types.CLEAR_READYSUITE_STATS:
            return { ...state, stats: initialState.readySuiteStats.stats, title: initialState.readySuiteStats.title, error: initialState.readySuiteStats.error };
        default:
            return state;
    }
}