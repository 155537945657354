import * as types from '../actions/types';
import initialState from './InitialState';

export default function (state = initialState.auth, action) {
    switch (action.type) {
        case types.STORE_USER:
            return {
                ...state,
                isLoadingUser: false,
                user: action.payload
            }
        case types.LOADING_USER:
            return {
                ...state,
                isLoadingUser: true
            }
        case types.USER_EXPIRED:
        case types.STORE_USER_ERROR:
        case types.USER_SIGNED_OUT:
            return {
                ...state,
                user: null,
                isLoadingUser: false
            }
        case types.LOAD_USER_AUTH_SUCCESS:
            return {
                ...state,
                user: action.user
            }
        case types.UPDATE_USER_ACTIVITY:
            return {
                ...state,
                lastActive: Date.parse(action.lastActive)
            }
        case types.LOAD_USER_PERMISSIONS_SUCCESS:
            return { ...state, permissions: action.userPermissions }
        case types.LOAD_CAN_ASSIGN_ROLES_SUCCESS:
            return { ...state, canAssignRoles: action.canAssignRoles }
        case types.LOAD_USER_ROLES_SUCCESS:
            return { ...state, userRoles: action.userRoles }
        default:
            return state
    }
}