export const PaginatedListMenuStyleLight = {
    container: () => ({
        color: "#131523",
        background: "#F1F7FD"
    }),
    control: (provided, state) => ({
        ...provided,
        color: "#131523",
        background: "#F1F7FD",
        opacity: state.isDisabled ? "0.5" : "1"
    }),
    valueContainer: (provided) => ({
        ...provided,
        color: '#131523'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#131523'
    }),
    menu: (provided) => ({
        ...provided,
        background: '#F1F7FD',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused || state.isSelected ? 'transparent' : 'transparent',
        color: state.isFocused || state.isSelected ? '#57A9DC' : '#131523',
        ':active': {
            backgroundColor: 'transparent'
        }
    }),
    input: (provided) => ({
        ...provided,
        color: '#131523'
    })
};

export const PaginatedListMenuStyleDark = {
    container: () => ({
        color: "#ffffff",
        background: "#161616"
    }),
    control: (provided, state) => ({
        ...provided,
        color: "#fff",
        background: "#161616",
        opacity: state.isDisabled ? "0.5" : "1"
    }),
    valueContainer: (provided) => ({
        ...provided,
        color: '#fff'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#fff'
    }),
    menu: (provided) => ({
        ...provided,
        background: '#161616',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused || state.isSelected ? 'transparent' : 'transparent',
        color: state.isFocused || state.isSelected ? '#57A9DC' : '#fff',
        ':active': {
            backgroundColor: 'transparent'
        }
    }),
    input: (provided) => ({
        ...provided,
        color: '#fff'
    })
};

export const DropdownSelectStyleLight = {
    container: () => ({
        color: "#131523",
        background: "#FFF"       
    }),
    control: (provided, state) => ({
        ...provided,
        color: "#131523",
        background: "#FFF",
        opacity: state.isDisabled ? "0.5" : "1",
        'border-color': "#92929D"
    }),
    valueContainer: (provided) => ({
        ...provided,
        color: '#131523'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#131523'
    }),
    menu: (provided) => ({
        ...provided,
        background: '#FFF',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused || state.isSelected ? 'transparent' : 'transparent',
        color: state.isFocused || state.isSelected ? '#57A9DC' : '#131523',
        ':active': {
            backgroundColor: 'transparent'
        }
    }),
    input: (provided) => ({
        ...provided,
        color: '#131523'
    })
};

export const DropdownSelectStyleDark = {
    container: () => ({
        color: "#ffffff",
        background: "#2c2c2c"
    }),
    control: (provided, state) => ({
        ...provided,
        color: "#fff",
        background: "#2c2c2c",
        opacity: state.isDisabled ? "0.5" : "1",
        'border-color': "#92929D"
    }),
    valueContainer: (provided) => ({
        ...provided,
        color: '#fff'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#fff'
    }),
    menu: (provided) => ({
        ...provided,
        background: '#2c2c2c',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused || state.isSelected ? 'transparent' : 'transparent',
        color: state.isFocused || state.isSelected ? '#57A9DC' : '#fff',
        ':active': {
            backgroundColor: 'transparent'
        }
    }),
    input: (provided) => ({
        ...provided,
        color: '#fff'
    })
};

export const MultiSelectStyleLight = {
    container: () => ({
        color: "#131523",
        background: "#FFF"
    }),
    control: (provided, state) => ({
        ...provided,
        color: "#131523",
        background: "#FFF",
        opacity: state.isDisabled ? "0.5" : "1",
        'border-color': "#92929D"
    }),
    valueContainer: (provided) => ({
        ...provided,
        color: '#131523'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#131523'
    }),
    menu: (provided) => ({
        ...provided,
        background: '#FFF',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused || state.isSelected ? 'transparent' : 'transparent',
        color: state.isFocused || state.isSelected ? '#57A9DC' : '#131523',
        ':active': {
            backgroundColor: 'transparent'
        }
    }),
    input: (provided) => ({
        ...provided,
        color: '#131523'
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'transparent'
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        display: state.isDisabled ? 'none' : 'inline-block', 
        ':hover': {
            backgroundColor: 'transparent',
            color: '#C44046'
        }
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#131523'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#131523'
    })
};

export const MultiSelectStyleDark = {
    container: () => ({
        color: "#ffffff",
        background: "#2c2c2c"
    }),
    control: (provided, state) => ({
        ...provided,
        color: "#fff",
        background: "#2c2c2c",
        opacity: state.isDisabled ? "0.5" : "1",
        'border-color': "#92929D"
    }),
    valueContainer: (provided) => ({
        ...provided,
        color: '#fff'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#fff'
    }),
    menu: (provided) => ({
        ...provided,
        background: '#2c2c2c',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused || state.isSelected ? 'transparent' : 'transparent',
        color: state.isFocused || state.isSelected ? '#57A9DC' : '#fff',
        ':active': {
            backgroundColor: 'transparent'
        }
    }),
    input: (provided) => ({
        ...provided,
        color: '#fff'
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: 'transparent'
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        display: state.isDisabled ? 'none' : 'inline-block', 
        ':hover': {
            backgroundColor: 'transparent',
            color: '#C44046'
        }
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#fff'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#fff'
    })
};