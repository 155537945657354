import * as types from '../actions/types';
import initialState from './InitialState';

export default function pageReducer(state = initialState.page, action) {
    switch (action.type) {
        case types.UPDATE_SELECTED_KB_CONTENT:
            return { ...state, kb: { ...state.kb, selectedContent: action.selectedContent } };
        case types.UPDATE_SELECTED_PARTNER:
            return { ...state, partners: { ...state.partners, selectedPartner: action.selectedPartner } };
        case types.UPDATE_SELECTED_USER:
            return { ...state, users: { ...state.users, selectedUser: action.selectedUser } };
        case types.UPDATE_SELECTED_USERS:
            return { ...state, users: { ...state.users, selectedUsers: action.selectedUsers } };
        case types.UPDATE_SELECTED_ROLE:
            return { ...state, roles: { ...state.roles, selectedRole: action.selectedRole } };
        case types.UPDATE_SELECTED_MACHINE:
            return { ...state, virtualMachines: { ...state.virtualMachines, selectedMachine: action.selectedMachine } };
        case types.UPDATE_SELECTED_AUDIT_DETAILS:
            return { ...state, audits: { ...state.audits, selectedAuditDetails: action.auditDetails } };
        case types.UPDATE_SEARCH_TERM:
            return { ...state, [action.recordType]: { ...state[action.recordType], searchTerm: action.searchTerm } };
        case types.UPDATE_TEXT_FILTER:
            return { ...state, [action.recordType]: { ...state[action.recordType], textFilters: { ...state[action.recordType].textFilters, [action.textFilter]: action.searchTerm } } };
        case types.CLEAR_TEXT_FILTERS:
            return { ...state, [action.recordType]: { ...state[action.recordType], textFilters: initialState.page[action.recordType].textFilters } };
        case types.TOGGLE_FILTER_BY_COLUMN:
            return { ...state, [action.recordType]: { ...state[action.recordType], filterByColumn: action.filterByColumn } };
        case types.UPDATE_PAGE_NUMBER:
            return { ...state, [action.recordType]: { ...state[action.recordType], pageNumber: action.pageNumber } };
        case types.CLEAR_SELECTED_KB_CONTENT:
            return { ...state, kb: { ...state.kb, selectedContent: initialState.page.kb.selectedContent } };
        case types.CLEAR_SELECTED_PARTNER:
            return { ...state, partners: { ...state.partners, selectedPartner: initialState.page.partners.selectedPartner } };
        case types.CLEAR_SELECTED_USER:
            return { ...state, users: { ...state.users, selectedUser: initialState.page.users.selectedUser } };
        case types.CLEAR_SELECTED_USERS:
            return { ...state, users: { ...state.users, selectedUsers: initialState.page.users.selectedUsers } };
        case types.CLEAR_SELECTED_ROLE:
            return { ...state, roles: { ...state.roles, selectedRole: initialState.page.roles.selectedRole } };
        case types.CLEAR_SELECTED_AUDIT_DETAILS:
            return { ...state, audits: { ...state.audits, selectedAuditDetails: initialState.page.audits.selectedAuditDetails } };
        case types.LOADED_PAGE:
            return { ...state, [action.recordType]: { ...state[action.recordType], isLoading: false } };
        case types.ALL_USERS_SELECTED:
            return { ...state, [action.recordType]: { ...state[action.recordType], allUsersSelected: action.allUsersSelected } };
        case types.UPDATE_DASHBOARD_LAYOUT:
            return { ...state, home: { ...state.home, dashboardLayout: action.dashboardLayout } };
        case types.LOAD_DASHBOARD_LAYOUT_SUCCESS:
            return { ...state, home: { ...state.home, dashboardLayout: action.dashboardLayout } }
        case types.LOAD_AVAILABLE_WIDGETS_SUCCESS:
            return { ...state, home: { ...state.home, availableWidgets: action.widgets } };
        case types.UPDATE_DASHBOARD_EDIT_MODE:
            return { ...state, home: { ...state.home, isDashboardInEditMode: action.isEnabled } }
        default:
            return state;
    }
}