import * as types from "./types";
import * as notificationService from "../../services/notificationsService";

export function loadNotificationsSuccess(notifications) {
    return { type: types.LOAD_NOTIFICATIONS_SUCCESS, notifications };
}

export function saveNotificationSuccess(notification) {
    return { type: types.SAVE_NOTIFICATION_SUCCESS, notification };
}

export function removeNotificationSuccess(notification) {
    return { type: types.REMOVE_NOTIFICATION_SUCCESS, notification };
}

export function updateNotificationSuccess(notification) {
    return { type: types.UPDATE_NOTIFICATION_SUCCESS, notification };
}

export function loadNotifications() {
    return function (dispatch) {
        return notificationService.loadNotifications()
            .then(notifications => {
                dispatch(loadNotificationsSuccess(notifications));
            })
            .catch(error => {
                throw error;
            });
    }
}

export function saveNotification(notification) {
    return function (dispatch) {
        return notificationService.saveNotification(notification)
            .then(notification => {
                dispatch(saveNotificationSuccess(notification));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function removeNotification(notification) {
    return function (dispatch) {
        return notificationService.removeNotification(notification)
            .then(notification => {
                dispatch(removeNotificationSuccess(notification));
            })
            .catch(error => {
                throw error;
            });
    }
}

export function updateNotification(notification) {
    return function (dispatch) {
        return notificationService.updateNotification(notification)
            .then(notification => {
                dispatch(updateNotificationSuccess(notification));
            })
            .catch(error => {
                throw error;
            });
    }
}