import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import * as bootstrap from 'bootstrap';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutoTruncateText from '../../../../common/AutoTruncateText';

function PaginatedResourceCard({ isPreview, theme, sideNavState, isInEditMode = false, onDeleteItem }) {

    const auth = useSelector(state => state.auth);
    const userPermissions = useSelector(state => state.auth.permissions);
    const resources = useSelector(state => isPreview ? state.previewResources : state.resources);
    const intervalDelayValue = useSelector(state => state.appSettings.resourceCycleIntervalInSeconds);

    const resizableDivRef = useRef(null);
    const titleRef = useRef(null);
    const contentRef = useRef(null);
    const imageRef = useRef(null);

    const [intervalId, setIntervalId] = useState();
    const [iterationCounter, setIterationCounter] = useState(0);

    const [activeCardNumber, setActiveCardNumber] = useState(1);
    const nextButtonRef = useRef(null);

    useEffect(() => {
        startAutoRotate();
        return () => {
            clearInterval(intervalId);
        }
    }, []);

    useEffect(() => {
        updateFontSize();
    }, [resizableDivRef?.current?.offsetWidth, resizableDivRef?.current?.offsetHeight]);

    useEffect(() => {
        goToNextCard();
    }, [iterationCounter]);

    useEffect(() => {
        setActiveCardNumber(1);
    }, [resources.length])

    const stopAutoRotate = () => {
        clearInterval(intervalId);
    }

    const startAutoRotate = () => {
        stopAutoRotate();
        var id = setInterval(() => {
            if (nextButtonRef.current != null) {
                setIterationCounter(current => current + 1);
            }
            else {
                clearInterval(id);
            }
        }, (intervalDelayValue * 1000));

        setIntervalId(id);
    }

    const goToNextCard = () => {
        setActiveCardNumber(nextCardNumber());
    }

    const nextCardNumber = () => {
        if (activeCardNumber > resources.length - 1) {
            return 1;
        } else {
            return activeCardNumber + 1;
        }
    }

    const goToPreviousCard = () => {
        setActiveCardNumber(previousCardNumber());
    }

    const previousCardNumber = () => {
        if (activeCardNumber < 2) {
            return resources.length;
        } else {
            return activeCardNumber - 1;
        }
    }

    const goToCard = (resource) => {
        var selectedCardNumber = resources.findIndex((r) => r.resourceId == resource.resourceId) + 1;

        setActiveCardNumber(selectedCardNumber);
    }

    const handleConfigureResources = () => {
        if (!isPreview) {
            new bootstrap.Modal(document.getElementById('resource-configuration-modal'), { backdrop: 'static', keyboard: false }).show();
            ReactTooltip.rebuild();
        }
    }

    const updateFontSize = () => {
        //lower size multiplier allows ref element to be bigger 
        updateElementSize(titleRef, 12, 35, 35, 'text');
        updateElementSize(contentRef, 10, 20, 70, 'text');
        updateElementSize(imageRef, 50, 150, 7, 'image');
    }

    const updateElementSize = (refItem, minSize, maxSize, sizeMultiplier, elementType) => {
        if (!refItem || !refItem.current) {
            return;
        }

        const element = refItem.current;
        const resizableDivElement = resizableDivRef.current;

        const newSize = (resizableDivElement.offsetHeight + resizableDivElement.offsetWidth) / sizeMultiplier;

        if (elementType === 'text') {
            element.style.fontSize = newSize + 'px';

            //handle min max size of header
            if (newSize < minSize) {
                element.style.fontSize = minSize + 'px';
            }

            if (newSize > maxSize) {
                element.style.fontSize = maxSize + 'px';
            }
        } else if (elementType === 'image') {
            element.style.width = newSize + 'px';
            element.style.height = newSize + 'px';

            //handle min max size of header
            if (newSize < minSize) {
                element.style.width = minSize + 'px';
                element.style.height = minSize + 'px';
            }

            if (newSize > maxSize) {
                element.style.width = maxSize + 'px';
                element.style.height = maxSize + 'px';
            }
        }

    }

    return (
        <>
            <div ref={ resizableDivRef } className={ theme + " d-flex flex-column py-3 px-4 oasis-rounded widget-container info-card " + sideNavState } id="home-resource-card" onMouseEnter={ () => stopAutoRotate() } onMouseLeave={ () => startAutoRotate() }>
                { isInEditMode == true ?
                    <div onClick={ () => onDeleteItem() }>
                        <a className='close btn-widget-delete'>
                            <FontAwesomeIcon icon="fas fa-times" beat={ true } />
                        </a>
                    </div> :
                    ""
                }
                { auth.user && userPermissions.length > 0 && userPermissions.some(p => p.name === "ConfigureResources") ?
                    <div className="btn-resource-setting">
                        <a data-tip="Configure Resources"
                            data-delay-show="500"
                            data-type={ theme }
                            data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                            data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                            data-border={ true }
                            onClick={ () => handleConfigureResources() }>
                            <FontAwesomeIcon icon="fa-solid fa-gear" />
                        </a>
                    </div>
                    : ""
                }
                <div className='resource-carousel-group'>
                    { resources.length > 1 && resources[activeCardNumber - 1] ?
                        resources.map((resource) => {
                            return (
                                <>
                                    <FontAwesomeIcon onClick={ () => goToCard(resource) } icon={ "fas fa-regular fa-circle-small " + (resource.resourceId == resources[activeCardNumber - 1].resourceId ? "" : "fa-duotone") } className="clickable pe-2" />
                                </>
                            )
                        }) : ""
                    }
                </div>
                <div className="row pe-3 pt-1">
                    <div ref={ titleRef }>
                        { resources.length > 0 && resources[activeCardNumber - 1] && resources[activeCardNumber - 1].siteLink ?
                            <a className="col" href={ resources[activeCardNumber - 1].siteLink } target="_blank">
                                <div className={ 'fw-bold resource-card-title ' + theme }><AutoTruncateText text={ resources[activeCardNumber - 1].contentTitle } theme={ theme } /></div>
                            </a>
                            : resources.length > 0 && resources[activeCardNumber - 1] ?
                                <div className="col">
                                    <div className='fw-bold'><AutoTruncateText text={ resources[activeCardNumber - 1].contentTitle } theme={ theme } /></div>
                                </div>
                                : ""
                        }
                    </div>
                </div>
                <div className="d-flex flex-row h-100 w-100">
                    { resources.length > 1 && resources[activeCardNumber - 1] ?
                        <div className="px-0 my-auto me-auto clickable">
                            <FontAwesomeIcon icon="fas fa-angle-left" className="card-nav-enabled" onClick={ () => goToPreviousCard() } />
                        </div>
                        : ""
                    }
                    { resources.length > 0 && resources[activeCardNumber - 1] ?
                        <>
                            <div className="d-flex flex-row ms-4 pt-2 w-100">
                                <div ref={ contentRef } className="d-flex flex-column">
                                    <div className="overflow-auto resource-description">
                                        { resources[activeCardNumber - 1].description }
                                    </div>
                                </div>
                                <div className="d-flex blog-thumb pt-2 ps-4 pe-2">
                                    <img ref={ imageRef } className={ "resource-image " + theme } src={ resources[activeCardNumber - 1].contentImg } />
                                </div>
                            </div>
                        </>
                        : <div className="my-auto mx-auto"><FontAwesomeIcon icon="fas fa-spinner" pulse /></div> }
                    { resources.length > 1 && resources[activeCardNumber - 1] ?
                        <div className="px-0 my-auto ms-auto ps-3 clickable">
                            <FontAwesomeIcon icon="fas fa-angle-right" className="card-nav-enabled" onClick={ () => goToNextCard() } ref={ nextButtonRef } />
                        </div>
                        : ""
                    }
                </div>
            </div>
        </>
    )
}

export default PaginatedResourceCard;