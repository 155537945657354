import React, { useEffect, useState } from 'react';
import * as usersActions from '../../redux/actions/usersActions';
import * as partnersActions from '../../redux/actions/partnersActions';
import * as groupsActions from '../../redux/actions/groupsActions';
import * as pageActions from '../../redux/actions/pageActions';
import * as idpUserService from '../../services/idpUserService';
import * as permissionsActions from '../../redux/actions/permissionsActions';
import * as servicesActions from '../../redux/actions/servicesActions';
import UserDetails from './UserDetails';
import UserEdit from './UserEdit';
import MassUserEdit from './MassUserEdit';
import { toast } from 'react-toastify';
import ConfirmationModal from '../common/ConfirmationModal';
import PaginatedList from '../common/PaginatedList';
import FilterSlideout from '../common/FilterSlideout';
import * as bootstrap from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as ModalBackgroundRemover from '../common/ModalBackgroundRemover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function UsersPage({ theme }) {

    const [columnFiltersPopulated, setColumnFiltersPopulated] = useState(false);

    const selectedUser = useSelector(state => state.page.users.selectedUser);
    const searchTerm = useSelector(state => state.page.users.searchTerm);
    const allPartners = useSelector(state => state.partners);
    const userPermissions = useSelector(state => state.auth.permissions);
    const filterByColumn = useSelector(state => state.page.users.filterByColumn);
    const textFilters = useSelector(state => state.page.users.textFilters);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userPermissions.some(p => p.name === "ViewPartners")) {
            dispatch(partnersActions.loadPartners()).catch(error => {
                toast.error("Loading Partners Failed: " + error, { autoClose: false })
            });
        }
        if (userPermissions.some(p => p.name === "ViewUsers")) {
            dispatch(usersActions.loadUsers()).catch(error => {
                toast.error("Loading Users Failed: " + error, { autoClose: false })
            });
        }

        dispatch(permissionsActions.loadCanAssignRoles()).catch(error => {
            toast.error("Loading User Assign Roles Failed: " + error, { autoClose: false })
        });
        dispatch(groupsActions.loadAvailablePocs()).catch(error => {
            toast.error("Loading Available Company Pocs Failed: " + error, { autoClose: false })
        });
        dispatch(groupsActions.loadAvailableSubscriptions()).catch(error => {
            toast.error("Loading Available Subscriptions Failed: " + error, { autoClose: false })
        });
        dispatch(servicesActions.loadAuthServices()).catch(error => {
            toast.error("Loading Auth Services Failed: " + error, { autoClose: false })
        });
        ModalBackgroundRemover.removeModalBackground();
    }, [])

    useEffect(() => {
        let noFilters = true;
        for (const text in textFilters) {
            if (textFilters[text] != "") {
                noFilters = false;
                setColumnFiltersPopulated(true);
            }
        }
        if (noFilters) {
            setColumnFiltersPopulated(false);
        }
    }, [textFilters])

    const handleNewUser = () => {
        let partner = { partnerId: 0, name: "Select a Partner...", availableUserServices: [] };
        let newUser = {
            integrationServices: [],
            availableServices: partner.availableUserServices,
            pocs: [],
            subscriptions: [],
            partnerId: partner.partnerId,
            partnerName: partner.name,
            twoFactorMethodId: null,
            twoFactorMethodName: "",
            jiraOrganizations: [],
            jiraGroups: [],
            roles: []
        };

        if (allPartners.length === 1) {
            partner = allPartners[0];

            //make sure you handle all additional properties that youd want new single partner users to be prepopulated below
            newUser = { ...newUser,
                availableServices: partner.availableUserServices,
                partnerId: partner.partnerId,
                partnerName: partner.name,
                jiraOrganizations: partner.defaultJiraOrganizations,
                jiraGroups: partner.jiraGroups
            };
        }
        
        if (newUser.availableServices.some(as => as.name === "Login")) {
            let loginService = newUser.availableServices.find(as => as.name === "Login");
            let shouldSendInviteEmail = newUser.shouldSendInviteEmail;
            let userIntegrationServices = [...newUser.integrationServices];
            let userAvailableServices = [...newUser.availableServices];
            let index = userAvailableServices.indexOf(loginService);
            userIntegrationServices.push(loginService);
            userAvailableServices.splice(index, 1);
            shouldSendInviteEmail = true;
            newUser = {
                ...newUser,
                integrationServices: userIntegrationServices,
                shouldSendInviteEmail,
                availableServices: userAvailableServices
            };
        }
        dispatch(pageActions.updateSelectedUser(newUser));
        new bootstrap.Modal(document.getElementById('edit-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleDisableUserConfirmed = (event) => {
        event.preventDefault();
        dispatch(usersActions.disableUser(selectedUser))
            .then(empty => {
                toast.success(selectedUser.email + " has been disabled");
                bootstrap.Modal.getInstance(document.getElementById('disable-modal')).hide();
            })
            .catch(error => {
                toast.error("Disable User Failed: " + error, { autoClose: false })
            });
    }

    const handleEnableUserConfirmed = (event) => {
        event.preventDefault();
        dispatch(usersActions.enableUser(selectedUser))
            .then(empty => {
                toast.success(selectedUser.email + " has been enabled");
                bootstrap.Modal.getInstance(document.getElementById('enable-modal')).hide();
            })
            .catch(error => {
                toast.error("Enable User Failed: " + error, { autoClose: false })
            });
    }

    const handleReset2faUserConfirmed = event => {
        event.preventDefault();
        dispatch(usersActions.resetUser2fa(selectedUser))
            .then(empty => {
                toast.success("Two factor authentication for " + selectedUser.email + " has been reset");
                bootstrap.Modal.getInstance(document.getElementById('reset-2fa-modal')).hide();
                idpUserService.sendEmail(selectedUser.userId, 'reset2fa').then(empty => {
                    toast.success(selectedUser.email + " has been sent the email to set up 2FA");
                }).catch(error => {
                    toast.error("User New Password Email Failed: " + error, { autoClose: false })
                });
            })
            .catch(error => {
                toast.error("Reset User 2FA Failed: " + error, { autoClose: false });
            });
    }

    const handleResetPasswordUserConfirmed = event => {
        event.preventDefault();
        dispatch(usersActions.resetUserPassword(selectedUser))
            .then(empty => {
                toast.success("Password for " + selectedUser.email + " has been reset");
                bootstrap.Modal.getInstance(document.getElementById('reset-password-modal')).hide();
                idpUserService.sendEmail(selectedUser.userId, 'UserNewPassword').then(empty => {
                    toast.success(selectedUser.email + " has been sent the email to set up a new password");
                }).catch(error => {
                    toast.error("User New Password Email Failed: " + error, { autoClose: false })
                });
            }).catch(error => {
                toast.error("Reset User Password Failed: " + error, { autoClose: false });
            });
    }

    const handleSearchTermChange = (event) => {
        dispatch(pageActions.updateSearchTerm("users", event.target.value));
    }

    const handleToggleFilterByColumn = () => {
        dispatch(pageActions.toggleFilterByColumn("users", !filterByColumn));
    }

    const handleClearFilterByColumn = () => {
        dispatch(pageActions.clearTextFilters("users"));
        setColumnFiltersPopulated(false);
    }

    return (
        <>
            <div className="h-100">
                <div id="view-all" className={ "py-3 px-4 oasis-rounded page-card media-controlled " + theme }>
                    <div className="d-flex flex-row py-3 ps-5 pe-4 page-card-header">
                        <div className="d-flex my-auto">
                            <h2 className="mb-0 list-header media-controlled">Users</h2>
                        </div>
                        <div className="d-flex ms-auto">
                            <div className="my-auto">
                                <a
                                    onClick={ handleToggleFilterByColumn }
                                    className={ theme + " column-filter-icon media-controlled" }
                                    data-tip="Show/Hide Filters"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-delay-show="500"
                                    data-border={ true }
                                >
                                    {
                                        filterByColumn ?
                                            <FontAwesomeIcon icon="fa-solid fa-filters" />
                                            : <FontAwesomeIcon icon="fa-regular fa-filters" />
                                    }
                                </a>
                                <a
                                    onClick={ handleClearFilterByColumn }
                                    className={ theme + " column-filter-icon media-controlled" }
                                    data-tip="Clear Filters"
                                    data-type={ theme }
                                    data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                    data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                    data-delay-show="500"
                                    data-border={ true }
                                    data-event-off="click"
                                >
                                    {
                                        columnFiltersPopulated ?
                                            <FontAwesomeIcon icon="fa-solid fa-filter-circle-xmark" />
                                            : <FontAwesomeIcon icon="fa-regular fa-filter-circle-xmark" />
                                    }
                                </a>
                            </div>
                            <div className="my-auto me-3">
                                <FontAwesomeIcon className="searchIcon media-controlled" icon="fas fa-search" />
                                <input
                                    onChange={ handleSearchTermChange }
                                    id="search_term"
                                    className={ "search-input media-controlled " + theme }
                                    autocomplete="off"
                                    name="SearchTerm"
                                    type="text"
                                    value={ searchTerm }
                                />
                            </div>
                        </div>
                        { userPermissions.some(up => up.name == "AddUsers") ?
                            <a onClick={ handleNewUser } className="media-controlled btn btn-new my-auto me-3 py-2 px-3"><FontAwesomeIcon icon="fas fa-plus" /> New User</a>
                            : ""
                        }
                    </div>
                    <div className="d-flex flex-row page-card-body">
                        <FilterSlideout RecordType={ "users" } theme={ theme } />
                        <div className="d-flex flex-column w-100 h-100">
                            <PaginatedList RecordType={ "users" } theme={ theme } />
                        </div>
                    </div>
                </div>
            </div>
            <UserEdit theme={ theme } />
            <UserDetails theme={ theme } />
            <MassUserEdit theme={ theme } />
            <ConfirmationModal
                title="Reset 2FA Confirmation"
                id="reset-2fa-modal"
                displayValue={ "Are you sure you want to reset two factor authentication for " + selectedUser.email + "?" }
                onSubmit={ handleReset2faUserConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Reset Password Confirmation"
                id="reset-password-modal"
                displayValue={ "Are you sure you want to reset the password for " + selectedUser.email + "?<br />This user will be sent an email to set up a new password." }
                onSubmit={ handleResetPasswordUserConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Disable Confirmation"
                id="disable-modal"
                displayValue={ "Are you sure you want to disable " + selectedUser.email + "?" }
                onSubmit={ handleDisableUserConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Enable Confirmation"
                id="enable-modal"
                displayValue={ "Are you sure you want to enable " + selectedUser.email + "?" }
                onSubmit={ handleEnableUserConfirmed }
                theme={ theme }
            />
        </>
    )
}

export default UsersPage;