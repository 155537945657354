import * as types from "./types";
import * as blackoutStatsService from "../../services/blackoutStatsService";

export function loadBlackoutStatsSuccess(blackoutStats) {
    return { type: types.LOAD_BLACKOUT_STATS_SUCCESS, blackoutStats }
}

export function clearBlackoutStats() {
    return { type: types.CLEAR_BLACKOUT_STATS }
}

export function reloadBlackoutStats() {
    return function (dispatch) {
        dispatch(clearBlackoutStats());
        dispatch(loadBlackoutStats());
    }
}

export function loadBlackoutStats() {
    return function (dispatch) {
        return blackoutStatsService.getBlackoutStats()
            .then(blackoutStats => {
                dispatch(loadBlackoutStatsSuccess(blackoutStats));
            })
    };
}