import * as types from '../actions/types';
import initialState from './InitialState';

export default function auditsReducer(state = initialState.audits, action) {
    switch (action.type) {
        case types.LOAD_AUDITS_SUCCESS:
            return action.audits;
        default:
            return state;
    }
}