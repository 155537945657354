import axios from 'axios'
import { config } from '../config';

export async function getTemplates() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/communication/templates').catch(error => {
        throw error
    });

    if (response.data.error) {
        throw response.data.error;
    }

    return response.data;
}

export async function sendCommunication(communicationInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/communication/send', communicationInfo).catch(error => {
        throw error
    });

    if (response.data.error) {
        throw response.data.error;
    }

    return response.data;
}