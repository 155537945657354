import React, { useEffect } from 'react';
import * as pageActions from '../../../redux/actions/pageActions';
import * as auditsActions from '../../../redux/actions/auditActions';
import * as dateUtils from '../../../utils/dateUtils';
import { CTableRow, CTableDataCell } from '@coreui/bootstrap-react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useState } from 'react';
import * as bootstrap from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function AuditRow({ data }) {
    const dispatch = useDispatch();

    const handleDisplayAuditDetails = () => {
        dispatch(auditsActions.loadAuditDetails(data)).then(() => {
            new bootstrap.Modal(document.getElementById('details-modal'), { backdrop: 'static', keyboard: false }).show();
        }).catch(error => {
            toast.error("Loading Audit Details Failed:" + error, { autoClose: false });
        });
    }

    return (
        <>
            <CTableRow className="clickable" key={ data.userActionAuditLogEntryId } onDoubleClick={ handleDisplayAuditDetails } >
                <CTableDataCell>{ data.userEmail }</CTableDataCell>
                <CTableDataCell>{ data.partnerName }</CTableDataCell>
                <CTableDataCell>{ data.action }</CTableDataCell>
                <CTableDataCell>{ data.actedUpon }</CTableDataCell>
                <CTableDataCell>{ data.name }</CTableDataCell>
                <CTableDataCell>{ dateUtils.getLocalDateTime(data.createdDate) }</CTableDataCell>
                <CTableDataCell className="right-align">
                    <div className="d-flex">
                        <a onClick={ handleDisplayAuditDetails }><FontAwesomeIcon icon="fa-solid fa-circle-info" /></a>
                    </div>
                </CTableDataCell>
            </CTableRow>
        </>
    )
};

export default AuditRow;