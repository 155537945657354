import * as pageActions from "../actions/pageActions";
import * as types from "./types";
import * as kbService from "../../services/kbService";

export function searchKBSuccess(searchResults) {
    return { type: types.SEARCH_KB_SUCCESS, searchResults }
}

export function loadKnowledgeBaseBooksSuccess(books) {
    return { type: types.LOAD_KNOWLEDGEBASE_BOOKS_SUCCESS, books }
}

export function clearKBSearchResults() {
    return { type: types.CLEAR_KB_SEARCH_RESULTS }
}

export function loadPdfSuccess(pdf) {
    return { type: types.LOAD_KB_PDF_SUCCESS, pdf }
}

export function loadKnowledgeBaseBooks() {
    return function (dispatch) {
        return kbService.getBooks()
            .then(books => {
                dispatch(loadKnowledgeBaseBooksSuccess(books));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadKnowledgeBaseContent(type, contentId) {
    return function (dispatch) {
        return kbService.getContent(type, contentId)
            .then(content => {
                dispatch(pageActions.updateSelectedKBContent(content));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function searchKnowledgeBase(query) {
    return function (dispatch) {
        return kbService.searchKnowledgeBase(query)
            .then(searchResults => {
                dispatch(searchKBSuccess(searchResults));
                dispatch(pageActions.clearSelectedKBContent());
            })
            .catch(error => {
                throw error;
            });
    }
}

export function getPdf(contentId) {
    return function (dispatch) {
        return kbService.getPdf(contentId)
            .then(pdf => {
                dispatch(loadPdfSuccess(pdf));
            })
            .catch(error => {
                throw error;
            });
    }
}

export function clearPdf() {
    return function (dispatch) {
        dispatch(loadPdfSuccess({ fileName: "", content: "" }));
    }
}