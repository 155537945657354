import * as pageActions from '../actions/pageActions';
import * as virtualMachinesService from '../../services/virtualMachinesService';
import * as types from "./types";

export function loadVirtualMachinesSuccess(virtualMachines) {
    return { type: types.LOAD_VIRTUAL_MACHINES_SUCCESS, virtualMachines };
}

export function updateVirtualMachineStatusSuccess(virtualMachineId, statusString, status) {
    return { type: types.UPDATE_VIRTUAL_MACHINE_STATUS_SUCCESS, virtualMachineId, statusString, status };
}

export function loadVirtualMachines() {
    return function (dispatch) {
        return virtualMachinesService.getVirtualMachines()
            .then(virtualMachines => {
                dispatch(loadVirtualMachinesSuccess(virtualMachines));
                dispatch(pageActions.loadedPage("virtualMachines"));
            })
            .catch(error => {
                throw error;
            });
    }
}

export function startVirtualMachine(virtualMachineId) {
    return function (dispatch) {
        dispatch(updateVirtualMachineStatusSuccess(virtualMachineId, "Starting", "Starting"));
        return virtualMachinesService.startVirtualMachine(virtualMachineId)
            .then(() => {
                dispatch(updateVirtualMachineStatusSuccess(virtualMachineId, "Running", "Running"));
            })
            .catch(error => {
                dispatch(updateVirtualMachineStatusSuccess(virtualMachineId, "Error", "Error"));
                throw error;
            });
    }
}

export function restartVirtualMachine(restartInfo) {
    return function (dispatch) {
        dispatch(updateVirtualMachineStatusSuccess(restartInfo.virtualMachineId, "Restarting", "Restarting"));
        return virtualMachinesService.restartVirtualMachine(restartInfo)
            .then(() => {
                dispatch(updateVirtualMachineStatusSuccess(restartInfo.virtualMachineId, "Running", "Running"));
            })
            .catch(error => {
                dispatch(updateVirtualMachineStatusSuccess(restartInfo.virtualMachineId, "Error", "Error"));
                throw error;
            });
    }
}

export function stopVirtualMachine(stopInfo) {
    return function (dispatch) {
        dispatch(updateVirtualMachineStatusSuccess(stopInfo.virtualMachineId, "Stopping", "Stopping"));
        return virtualMachinesService.stopVirtualMachine(stopInfo)
            .then(() => {
                dispatch(updateVirtualMachineStatusSuccess(stopInfo.virtualMachineId, "Stopped", "PowerOff"));
            })
            .catch(error => {
                dispatch(updateVirtualMachineStatusSuccess(stopInfo.virtualMachineId, "Error", "Error"));
                throw error;
            });
    }
}