import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AdministrationLayout } from './AdministrationLayout';
import UserStatusPage from './userStatus/UserStatusPage';
import AuditsPage from './audits/AuditsPage';
import RolesPage from './roles/RolesPage';
import SettingsPage from './settings/SettingsPage';
import PrivateRoute from '../../utils/protectedRoute'

function AdministrationPage({ theme }) {

    return (
        <>
            <div className="h-100">
                <div id="view-all" className={ "py-3 px-4 oasis-rounded page-card media-controlled " + theme }>
                    <div className="d-flex flex-row oasis-rounded page-card-header">
                        <AdministrationLayout>
                            <Routes>
                                <Route
                                    path="/UserStatus"
                                    element={
                                        <PrivateRoute redirectTo="/login" permission="ViewUserStatus">
                                            <UserStatusPage theme={ theme } />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/Audits"
                                    element={
                                        <PrivateRoute redirectTo="/login" permission="ViewAudits">
                                            <AuditsPage theme={ theme } />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/Roles"
                                    element={
                                        <PrivateRoute redirectTo="/login" permission="ViewRoles">
                                            <RolesPage theme={ theme } />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/Settings"
                                    element={
                                        <PrivateRoute redirectTo="/login" permission="ViewAdminSettings">
                                            <SettingsPage theme={ theme } />
                                        </PrivateRoute>
                                    }
                                />
                            </Routes>
                        </AdministrationLayout>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdministrationPage;