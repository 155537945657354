import React, { useEffect, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import { updateUserActivity, storeUser } from '../redux/actions/authActions';
import * as permissionActions from '../redux/actions/permissionsActions';
import * as appSettingsActions from '../redux/actions/appSettingsActions';
import * as permissionService from '../services/permissionsService';
import { useSelector } from 'react-redux';

export default function ({ userManager: manager, store, children }) {

    const userActivityUpdate = () => {
        let state = store.getState();
        if (state.auth.user) { 
            store.dispatch(updateUserActivity());
        }
    }

    const isMaintenanceModeEnabled = useSelector(state => state.appSettings.maintenanceMode === "enabled");
    const isSysAdmin = useSelector(state => state.auth.userRoles.some(userRole => userRole.role.name === "System Admin"));

    const ONE_HOUR = 1000 * 60 * 60;
    const ONE_MINUTE = 1000 * 60;
    let userManager = useRef();
    const stayLoggedInToastId = useRef(null);
    const signedOut = useRef(false);
    const intervalId = useRef(null);
    const stillActive = useRef(false);

    const logoutNonSysAdminUsersDuringMaintenance = useCallback(async () => {
        if (!signedOut.current && isMaintenanceModeEnabled && !isSysAdmin) {
            signedOut.current = true;
            userManager.current.signoutRedirect();
        }
    }, [isMaintenanceModeEnabled, isSysAdmin, userManager]);

    useEffect(() => {
        const reloadUserAndAppInfo = () => {
            permissionService.getUserRoles().then((userRoles) => {
                store.dispatch(permissionActions.loadUserRolesSuccess(userRoles));
                store.dispatch(appSettingsActions.loadMaintenanceMode());
            })
        };

        let loadingIntervalId = setInterval(() => reloadUserAndAppInfo(), 10000);

        let logoutCheckIntervalId = setInterval(() => logoutNonSysAdminUsersDuringMaintenance(), 10000);

        return function cleanup() {
            if (loadingIntervalId) {
                clearInterval(loadingIntervalId);
            }
            if (logoutCheckIntervalId) {
                clearInterval(logoutCheckIntervalId);
            }
        };
    }, [logoutNonSysAdminUsersDuringMaintenance])

    useEffect(() => {
        userManager.current = manager;

        userActivityUpdate();

        const expiringMessage = () => (
            <div className="container">
                <div className="row py-2">
                    You are about to be logged out due to inactivity:
                </div>
                <div className="row py-2">
                    To stay logged in, click this message or continue working in the Oasis Portal
                </div>
            </div>
        );

        const checkUserActivity = () => {
            let state = store.getState();
            let msFromLastActive = Date.now() - state.auth.lastActive;

            if (!signedOut.current && msFromLastActive >= (ONE_HOUR)) {
                signedOut.current = true;
                userManager.current.signoutRedirect();
            } else if (!stayLoggedInToastId.current && msFromLastActive > (ONE_HOUR - ONE_MINUTE) && msFromLastActive < (ONE_HOUR)) {//show toast a minute before expire
                stillActive.current = false;
                stayLoggedInToastId.current = toast.warning(expiringMessage, {
                    autoClose: ONE_MINUTE,
                    position: "top-center",
                    hideProgressBar: false,
                    closeOnClick: false,
                    closeButton: false,
                    draggable: false,
                    pauseOnFocusLoss: false,
                    pauseOnHover: false,
                    onClick: handleStayLoggedIn,
                    onClose: handleStayLoggedInClose
                })
            } else if (stayLoggedInToastId.current && (Date.now() - state.auth.lastActive) < (5000)) {
                stillActive.current = true;
                toast.dismiss(stayLoggedInToastId.current);
            }

        }

        intervalId.current = setInterval(() => checkUserActivity(), 100);

        const handleStayLoggedIn = () => {
            userManager.current.signinSilent().then(user => {
                console.log(`User Refreshed: ${user.profile.name} expires in ${user.expires_in} second(s)`)
                store.dispatch(storeUser(user))
            }).catch(error => {
                console.log(error)
            });
            if (stayLoggedInToastId.current) {
                stillActive.current = true;
                toast.dismiss(stayLoggedInToastId.current);
            }
        }

        const handleStayLoggedInClose = () => {
            if (!stillActive.current && !signedOut) {
                signedOut.current = true;
                userManager.current.signoutRedirect();
                if (intervalId.current) {
                    clearInterval(intervalId.current);
                    intervalId.current = null;
                }
            }
            stayLoggedInToastId.current = null;
        }

        return function cleanup() {
            if (intervalId.current) {
                clearInterval(intervalId.current);
            }
        };
    }, [manager, store])

    return (
        <div onClickCapture={ userActivityUpdate }>
            { React.Children.only(children) }
        </div>
    )
}