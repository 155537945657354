import axios from 'axios'
import { config } from '../config';

export async function enableMaintenanceMode() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/maintenance/mode/enabled');
    return response.data;
}

export async function disableMaintenanceMode() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/maintenance/mode/disabled');
    return response.data;
}

export async function getMaintenanceModeState() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/maintenance/mode').catch(error => {
        if (error.response.data.reason === "Maintenance Mode Active") {
            return { data: "enabled" };
        } else {
            throw error
        }
    });
    return response.data;
}

export async function sendMaintenanceCompleteEmail() {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/maintenance/sendemail/complete').catch(error => {
        throw error
    });

    if (response.data.error) {
        throw response.data.error;
    }

    return response.data;
}