import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import * as bootstrap from 'bootstrap';
import * as resourcesActions from '../../../../../redux/actions/resourcesActions';
import * as previewResourcesActions from '../../../../../redux/actions/previewResourcesActions';
import * as selectedResourceActions from '../../../../../redux/actions/selectedResourceActions';
import { useDispatch, useSelector } from 'react-redux';
import ResourceEdit from './ResourceEdit';
import ResourceCardPreview from './ResourceCardPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ResourceConfiguration({ theme }) {

    const allResources = useSelector(state => state.resources);

    const previewResources = useSelector(state => state.previewResources);

    const [isSaving, setIsSaving] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(previewResourcesActions.updatePreviewResources(allResources));
    }, [allResources])

    const handleResourcesSave = (event) => {
        setIsSaving(true);
        event.preventDefault();
        dispatch(resourcesActions.saveResources(previewResources)).then(empty => {
            bootstrap.Modal.getInstance(document.getElementById('resource-configuration-modal')).hide()
            setIsSaving(false);
            dispatch(resourcesActions.loadResources()).catch(error => {
                toast.error("Loading Resources Failed:" + error, { autoClose: false })
            });
        }).catch(error => {
            setIsSaving(false);
            toast.error("Saving Resources Failed: " + error, { autoClose: false })
        });
    }

    const handleAddResource = () => {
        let newResource = {
            resourceId: 0,
            siteLink: "",
            contentTitle: "",
            contentLink: "",
            contentThumbnailUrl: "",
            description: "",
            sortOrder: 1,
            allPartnersSelected: true,
            partners: []
        }
        let resourcesCopy = [...previewResources]
        for (let i = 0; i < (resourcesCopy.length); i++) {
            resourcesCopy[i].sortOrder = resourcesCopy[i].sortOrder + 1;
        }
        resourcesCopy.splice(0, 0, newResource);
        dispatch(previewResourcesActions.updatePreviewResources(resourcesCopy));
        dispatch(selectedResourceActions.updateSelectedResource(newResource));
        new bootstrap.Modal(document.getElementById('resource-edit-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleRemoveResource = (event) => {
        if (previewResources.length > 1) {
            let { name } = event.currentTarget;
            let resourcesCopy = [...previewResources];
            let index = parseInt(name.replace('remove-resource', ''));

            let resourceRemoved = previewResources[index];

            //prevent removal of last all partners resource
            if (resourceRemoved.allPartnersSelected === true && previewResources.filter(x => x.allPartnersSelected).length == 1) {
                toast.warning("You cannot remove the last resource associated with 'All Partners'.")
                return;
            }

            for (let i = index + 1; i < resourcesCopy.length; i++) {
                resourcesCopy[i].sortOrder = resourcesCopy[i].sortOrder - 1;
            }
            resourcesCopy.splice(index, 1);
            dispatch(previewResourcesActions.updatePreviewResources(resourcesCopy));
        } else {
            toast.warning("You cannot remove the last resource")
        }
    }

    const handleEditResource = (selectedResource) => {
        dispatch(selectedResourceActions.updateSelectedResource(selectedResource));
        new bootstrap.Modal(document.getElementById('resource-edit-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleShowResourceCardPreview = () => {
        new bootstrap.Modal(document.getElementById('resource-card-preview-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleMoveResourceUp = (event) => {
        let { name } = event.currentTarget;
        let resourcesCopy = [...previewResources];
        let index = parseInt(name.replace('move-up-resource', ''));
        if (index > 0) {
            let swapSortOrder = resourcesCopy[index].sortOrder;
            resourcesCopy[index].sortOrder = resourcesCopy[index - 1].sortOrder;
            resourcesCopy[index - 1].sortOrder = swapSortOrder;
            let swappedResource = resourcesCopy.splice(index, 1);
            resourcesCopy.splice(index - 1, 0, swappedResource[0]);
            dispatch(previewResourcesActions.updatePreviewResources(resourcesCopy));
        }
    }

    const handleMoveResourceDown = (event) => {
        let { name } = event.currentTarget;
        let resourcesCopy = [...previewResources];
        let index = parseInt(name.replace('move-down-resource', ''));
        if (index < (resourcesCopy.length - 1)) {
            let swapSortOrder = resourcesCopy[index].sortOrder;
            resourcesCopy[index].sortOrder = resourcesCopy[index + 1].sortOrder;
            resourcesCopy[(index + 1)].sortOrder = swapSortOrder;
            let swappedResource = resourcesCopy.splice(index, 1);
            resourcesCopy.splice(index + 1, 0, swappedResource[0]);
            dispatch(previewResourcesActions.updatePreviewResources(resourcesCopy));
        }
    }

    const handleResourceConfigureClose = () => {
        dispatch(resourcesActions.loadResources()).catch(error => {
            toast.error("Loading Resources Failed:" + error, { autoClose: false })
        });
    }

    return (
        <>
            <div className="modal fade" tabindex="-1" role="dialog" id='resource-configuration-modal' >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className={ "modal-content " + theme }>
                        <form onSubmit={ handleResourcesSave }>
                            <div className="modal-header">
                                <h2 className="modal-title">Configure Resources</h2>
                                <a onClick={ handleResourceConfigureClose } className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon="fas fa-times" />
                                </a>
                            </div>
                            <div className="modal-body">
                                { (isSaving) ?
                                    <div className="modal-overlay" />
                                    : ""
                                }
                                <div className="container-fluid h-100">
                                    <div className="row">
                                        <div className="col-1 ms-auto">
                                            <a
                                                data-tip="Preview Resource Card"
                                                data-delay-show="500"
                                                data-type={ theme }
                                                data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                                data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                                data-border={ true }
                                                data-class="modal-tip"
                                                onClick={ handleShowResourceCardPreview }
                                                className="btn btn-new"
                                            >
                                                <FontAwesomeIcon icon="fas fa-eye" />
                                            </a>
                                        </div>
                                        <div className="col-1 ps-0">
                                            <a
                                                data-tip="Add Resource"
                                                data-delay-show="500"
                                                data-type={ theme }
                                                data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                                data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                                data-border={ true }
                                                data-class="modal-tip"
                                                onClick={ handleAddResource }
                                                className="btn btn-new"
                                            >
                                                <FontAwesomeIcon icon="fas fa-plus" />
                                            </a>
                                        </div>
                                    </div>
                                    { previewResources.length > 0 ?
                                        <div className="row pt-2 link-headers mx-0">
                                            <div className="col-8 link-header">
                                                <label>Content Title</label>
                                            </div>
                                            <div className="col-2 ps-0 link-header">
                                                <label>Preview</label>
                                            </div>
                                            <div className="col-2 link-header">
                                                <label></label>
                                            </div>
                                        </div>
                                        : ""
                                    }
                                    <div className="link-tab-content">
                                        { previewResources.map(resource => {
                                            let index = previewResources.indexOf(resource);
                                            let removeResourceName = "remove-resource" + index;
                                            let moveResourceUpName = "move-up-resource" + index;
                                            let moveResourceDownName = "move-down-resource" + index;
                                            return (
                                                <div className="row m-0">
                                                    <div className="col-8 mt-3"
                                                        data-tip={ [...resource.contentTitle].length > 20 ? resource.contentTitle : "" }
                                                        data-delay-show="500"
                                                        data-type={ theme }
                                                        data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                                        data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                                        data-border={ true }
                                                        data-class="modal-tip"
                                                    >
                                                        { resource.contentTitle }
                                                    </div>
                                                    <div className="col-2 mt-3">
                                                        <img className="thumbnail-preview" src={ resource.contentImg } />
                                                    </div>
                                                    <div className="col-2 ps-0">
                                                        <div className="row m-0">
                                                            <div className="col p-0 mt-1">
                                                                <a name={ removeResourceName } onClick={ handleRemoveResource } className={ "btn btn-remove " + theme }>
                                                                    <FontAwesomeIcon icon="fa-solid fa-trash-can" />
                                                                </a>
                                                            </div>
                                                            <div className="col p-0 mt-1">
                                                                <a onClick={ () => handleEditResource(resource) } className="btn">
                                                                    <FontAwesomeIcon icon="fas fa-pen" />
                                                                </a>
                                                            </div>
                                                            <div className="col p-0 ps-2">
                                                                <a name={ moveResourceUpName } onClick={ handleMoveResourceUp }>
                                                                    <FontAwesomeIcon icon="fa-solid fa-angle-up" />
                                                                </a>
                                                                <a name={ moveResourceDownName } onClick={ handleMoveResourceDown }>
                                                                    <FontAwesomeIcon icon="fa-solid fa-angle-down" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }) }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="row">
                                    <div className="col">
                                        { isSaving ?
                                            <div className="btn btn-new btn-block"><span className="saving-button fa-fade">Saving...</span></div>
                                            : <input type="submit" value="Save" className="btn btn-new btn-block" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div >
                </div >
            </div >
            <ResourceEdit theme={ theme } />
            <ResourceCardPreview isPreview={ true } theme={ theme } />
        </>
    )
}

export default ResourceConfiguration;