import axios from 'axios'
import { config } from '../config';

export async function getFileTransferStats() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/filetransfer/stats');
    return response.data;
}

export async function getPartnerPortals() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/filetransfer/partner/portals');
    return response.data;
}