import * as pageActions from "../actions/pageActions";
import * as types from "./types";
import * as auditsService from "../../services/auditsService";

export function loadAuditsSuccess(audits) {
    return { type: types.LOAD_AUDITS_SUCCESS, audits }
}

export function loadAuditDetails(audit) {
    return function (dispatch) {
        return auditsService.getAuditDetails(audit.userActionAuditLogEntryId)
            .then(auditDetails => {
                dispatch(pageActions.updateSelectedAuditDetails(auditDetails));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadAudits() {
    return function (dispatch) {
        return auditsService.getAudits()
            .then(audits => {
                dispatch(loadAuditsSuccess(audits));
                dispatch(pageActions.loadedPage("audits"));
            })
            .catch(error => {
                throw error;
            });
    };
}