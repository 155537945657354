import * as types from '../actions/types';
import initialState from './InitialState';

export default function usersReducer(state = initialState.users, action) {
    switch (action.type) {
        case types.LOAD_USER_SUCCESS:
            return state.map(user => {
                if (user.userId === action.user.userId) {
                    return action.user
                } else {
                    return user
                }
            })
        case types.LOAD_USERS_SUCCESS:
            return action.users;
        case types.DISABLE_USER_SUCCESS:
            return state.map(user => {
                if (user.userId === action.user.userId) {
                    return action.user
                } else {
                    return user
                }
            })
        case types.ENABLE_USER_SUCCESS:
            return state.map(user => {
                if (user.userId === action.user.userId) {
                    return action.user
                } else {
                    return user
                }
            })
        case types.RESET_2FA_SUCCESS:
            return state.map(user => {
                if (user.userId === action.user.userId) {
                    return action.user;
                } else {
                    return user
                }
            })
        case types.RESET_PASSWORD_SUCCESS:
            return state.map(user => {
                if (user.userId === action.user.userId) {
                    return action.user;
                } else {
                    return user
                }
            })
        case types.SAVE_USER_SUCCESS:
            return state.map(user => {
                if (user.userId === action.user.userId) {
                    return action.user
                } else {
                    return user
                }
            }).sort(sortUsers)
        case types.CREATE_USER_SUCCESS:
            return [...state, { ...action.user }].sort(sortUsers) 
        default:
            return state;
    }
}

function sortUsers(userA, userB) {
    var x = userA.email.toLowerCase();
    var y = userB.email.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
}