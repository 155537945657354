import * as types from "./types";
import * as groupsService from "../../services/groupsService";

export function loadAvailableSubscriptionsSuccess(subscriptions) {
    return { type: types.LOAD_AVAILABLE_SUBSCRIPTIONS_SUCCESS, subscriptions }
}

export function loadAvailablePocsSuccess(pocs) {
    return { type: types.LOAD_AVAILABLE_POCS_SUCCESS, pocs }
}

export function loadAvailableSubscriptions() {
    return function (dispatch) {
        return groupsService.getAvailableSubscriptions()
            .then(subscriptions => {
                dispatch(loadAvailableSubscriptionsSuccess(subscriptions));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadAvailablePocs() {
    return function (dispatch) {
        return groupsService.getAvailablePocs()
            .then(pocs => {
                dispatch(loadAvailablePocsSuccess(pocs));
            })
            .catch(error => {
                throw error;
            });
    };
}