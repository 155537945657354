import axios from 'axios'
import { config } from '../config';

export async function getUserPreferences() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/userpreferences');
    return response.data;
}

export async function saveUserPreference(userPreference) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/userpreferences/save', userPreference).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data
}