import * as types from '../actions/types';
import initialState from './InitialState';

export default function virtualMachinesReducer(state = initialState.virtualMachines, action) {
    switch (action.type) {
        case types.LOAD_VIRTUAL_MACHINES_SUCCESS:
            return action.virtualMachines;
        case types.UPDATE_VIRTUAL_MACHINE_STATUS_SUCCESS:
            return state.map(virtualMachine => {
                if (virtualMachine.virtualMachineId === action.virtualMachineId) {
                    return { ...virtualMachine, statusString: action.statusString, status: action.status }
                } else {
                    return virtualMachine
                }
            });
        default:
            return state;
    }
}