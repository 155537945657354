import * as types from "./types";
import * as fileTransferService from "../../services/fileTransferService";

export function loadAvailablePortalsSuccess(portals) {
    return { type: types.LOAD_AVAILABLE_PORTALS_SUCCESS, portals }
}

export function loadAvailablePortals() {
    return function (dispatch) {
        return fileTransferService.getAvailablePortals()
            .then(portals => {
                dispatch(loadAvailablePortalsSuccess(portals));
            })
            .catch(error => {
                throw error;
            });
    };
}