import * as types from '../actions/types';
import initialState from './InitialState';

export default function partnersReducer(state = initialState.partners, action) {
    switch (action.type) {
        case types.LOAD_PARTNER_SUCCESS:
            return state.map(partner => {
                if (partner.partnerId === action.partner.partnerId) {
                    return action.partner
                } else {
                    return partner
                }
            })
        case types.LOAD_PARTNERS_SUCCESS:
            return action.partners;
        case types.DISABLE_PARTNER_SUCCESS:
            return state.map(partner => {
                if (partner.partnerId === action.partner.partnerId) {
                    return action.partner
                } else {
                    return partner
                }
            })
        case types.ENABLE_PARTNER_SUCCESS:
            return state.map(partner => {
                if (partner.partnerId === action.partner.partnerId) {
                    return action.partner
                } else {
                    return partner
                }
            })
        case types.SAVE_PARTNER_SUCCESS:
            return state.map(partner => {
                if (partner.partnerId === action.partner.partnerId) {
                    return action.partner
                } else {
                    return partner
                }
            }).sort(sortPartners)
        case types.CREATE_PARTNER_SUCCESS:
            return [...state, { ...action.partner }].sort(sortPartners)
        default:
            return state;
    }
}

function sortPartners(partnerA, partnerB) {
    var x = partnerA.name.toLowerCase();
    var y = partnerB.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
}