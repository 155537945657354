import { combineReducers } from 'redux';
import auth from './authReducer';
import kb from './kbReducer';
import resources from './resourcesReducer';
import previewResources from './previewResourcesReducer';
import selectedResource from './selectedResourceReducer';
import relativityStats from './relativityStatsReducer';
import brainspaceStats from './brainspaceStatsReducer';
import storageStats from './storageStatsReducer';
import blackoutStats from './blackoutStatsReducer';
import readySuiteStats from './readySuiteStatsReducer';
import lsiTranslationStats from './lsiTranslationStatsReducer';
import webProcessingStats from './webProcessingStatsReducer';
import fileTransferStats from './fileTransferStatsReducer';
import supportStats from './supportStatsReducer';
import partners from './partnersReducer';
import users from './usersReducer';
import services from './servicesReducer';
import settings from './settingsReducer';
import links from './linksReducer';
import jira from './jiraReducer';
import page from './pageReducer';
import virtualMachines from './virtualMachinesReducer';
import roles from './rolesReducer';
import permissions from './permissionsReducer';
import fileTransfer from './fileTransferReducer';
import statCard from './statCardReducer';
import audits from './auditsReducer';
import appSettings from './appSettingsReducer';
import userStatuses from '../reducers/userStatusesReducer';
import support from '../reducers/supportReducer';
import userPreferences from '../reducers/userPreferencesReducer';
import groups from '../reducers/groupsReducer';
import communications from '../reducers/communicationsReducer';
import notifications from './notificationReducer';

const rootReducer = combineReducers({
    auth,
    userPreferences,
    kb,
    resources,
    previewResources,
    selectedResource,
    relativityStats,
    brainspaceStats,
    storageStats,
    blackoutStats,
    readySuiteStats,
    webProcessingStats,
    lsiTranslationStats,
    fileTransferStats,
    supportStats,
    support,
    partners,
    users,
    services,
    jira,
    settings,
    links,
    page,
    virtualMachines,
    roles,
    permissions,
    fileTransfer,
    statCard,
    audits,
    appSettings,
    userStatuses,
    groups,
    communications,
    notifications
});

export default rootReducer;