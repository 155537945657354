import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ResourceCropper({ resource, onCropperSave, theme }) {
    const [crop, setCrop] = useState({
        aspect: 1 / 1
    });

    const [croppedImage, setCroppedImage] = useState();
    const [image, setImage] = useState();

    useEffect(() => {
        //reset image and crop
        setCroppedImage(null);
        setCrop({ aspect: 1 / 1 });
    }, [resource.contentImg])

    const onCropChange = (crop, percentCrop) => {
        setCrop(crop);
    };

    const onImageLoaded = (image) => {
        setImage(image);
    };

    const onCropComplete = (crop) => {
        makeClientCrop(crop);
    };

    const makeClientCrop = (crop) => {
        if (image && crop.width && crop.height) {
            const croppedImageDataUrl = getCroppedImg(
                image,
                crop
            );
            setCroppedImage(croppedImageDataUrl);
        }
    }

    const getCroppedImg = (image, crop) => {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        var dataUrl = canvas.toDataURL();
        return dataUrl;
    }

    return (
        <>
            <div className="modal fade" tabindex="-1" role="dialog" id="resource-cropper-modal" >
                <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div className={ "modal-content-dark-bg modal-content " + theme }>
                        <form>
                            <div className="modal-header pb-0">
                                <h2 className="modal-title">Resource Crop</h2>
                                <a className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon="fas fa-times" />
                                </a>
                            </div>
                            <div className="modal-body pt-0">
                                <div className="container">
                                    <div className="row mb-4 mt-4" id="resource-cropper">
                                        <div className='col d-flex justify-content-center'>
                                            <ReactCrop
                                                src={ resource.contentImg }
                                                crop={ crop }
                                                ruleOfThirds
                                                onImageLoaded={ onImageLoaded }
                                                onComplete={ onCropComplete }
                                                onChange={ onCropChange }
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="btn btn-new btn-block col" onClick={ () => onCropperSave(croppedImage) }><span className="saving-button fa-fade">Crop</span></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div >
                </div >
            </div >
        </>
    )
}

export default ResourceCropper;