import React, { useEffect, useState, useCallback } from 'react'
import TextInput from '../common/TextInput'
import * as usersActions from '../../redux/actions/usersActions';
import * as jiraActions from '../../redux/actions/jiraActions';
import * as permissionsActions from '../../redux/actions/permissionsActions';
import * as usersService from '../../services/usersService';
import * as pageActions from '../../redux/actions/pageActions';
import * as idpUserService from '../../services/idpUserService';
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/bootstrap-react'
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ConfirmationModal from '../common/ConfirmationModal';
import * as styles from '../common/DropdownStyles';
import * as bootstrap from 'bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function UserEdit({ theme }) {

    const [isLoading, setIsLoading] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [activeTab, setActiveTab] = useState("Info");
    const [refreshUserToastId, setRefreshUserToastId] = useState("");
    const [errors, setErrors] = useState({});
    const [editModal, setEditModal] = useState();
    const [unconfirmedUser, setUnconfirmedUser] = useState({});
    const [user, setUser] = useState(useSelector(state => state.page.users.selectedUser));

    const selectedUser = useSelector(state => state.page.users.selectedUser);
    const users = useSelector(state => state.users);
    const partners = useSelector(state => state.partners);
    const availablePocs = useSelector(state => state.groups.availablePocs);
    const availableSubscriptions = useSelector(state => state.groups.availableSubscriptions);
    const authServices = useSelector(state => state.services.auth);
    const userPermissions = useSelector(state => state.auth.permissions);
    const roles = useSelector(state => user.partnerName != "Oasis" ?
        state.auth.canAssignRoles.filter(p => { return p.name != "System Admin"; }) : state.auth.canAssignRoles);
    const jiraGroups = useSelector(state => state.jira.availableGroups);

    // userPartnerAuthServices only available if user has ViewPartners Permission to get partner info from state.
    const userPartnerAuthServices = useSelector(state => user.partnerId && state.partners.length > 0 ?
        state.partners.find(p => p.partnerId === user.partnerId).twoFactorMethods : []);

    const dispatch = useDispatch();

    useEffect(() => {
        if (userPermissions.some(p => p.name === "ViewJiraGroups")) {
            dispatch(jiraActions.loadAvailableJiraGroups()).catch(error => {
                toast.error("Loading Available Jira Groups Failed:" + error, { autoClose: false });
            });
        }

        return () => {
            dispatch(pageActions.clearSelectedUser());
        };
    }, [])

    const refreshMessage = (user) => (
        <div className="container">
            <div className="row py-2">
                { user.email }<br />has been modified:
            </div>
            <div className="row py-2">
                To refresh click this message.
            </div>
            <div className="row py-2 pt-1 tiny-msg">
                Please note that refreshing will overwrite any current changes and saving new changes will not be permitted without a refresh.
            </div>
        </div>
    );

    const refreshUser = (userId, dismissToast = false) => {
        if (editModal && editModal._isShown) {
            setIsLoading(true);
            usersService.getUser(userId)
                .then(returnedUser => {
                    dispatch(usersActions.loadUserSuccess(returnedUser));
                    if (dismissToast) {
                        toast.dismiss(refreshUserToastId);
                        setRefreshUserToastId("");
                    }
                    setUser(returnedUser);
                    dispatch(pageActions.updateSelectedUser(returnedUser));
                })
                .catch(error => {
                    if (error.message != "canceled") {
                        toast.error("Loading Selected User Failed:" + error, { autoClose: false });
                    }
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }

    const checkUpdateRequired = useCallback(async (user) => {
        if (editModal && editModal._isShown) {
            if (user.userId && !isSaving && !isLoading) {
                if (await usersService.updateRequired(user) && refreshUserToastId == '') {
                    let newToastId = toast.warning(refreshMessage(user), {
                        autoClose: false,
                        position: "top-center",
                        hideProgressBar: true,
                        closeOnClick: false,
                        closeButton: false,
                        draggable: false,
                        onClick: () => refreshUser(user.userId, true)
                    })
                    setRefreshUserToastId(newToastId);
                }
            }
        }
    }, [refreshUserToastId, isSaving, isLoading, editModal]);

    useEffect(() => {
        setUser(selectedUser);
        var editModal = document.getElementById('edit-modal');
        editModal.addEventListener('hidden.bs.modal', () => {
            if (usersService.getUserAbortController[selectedUser.userId]) {
                usersService.getUserAbortController[selectedUser.userId].abort();
            }
            dispatch(pageActions.clearSelectedUser());
            setErrors({});
            setActiveTab("Info");
        });
        setEditModal(bootstrap.Modal.getInstance(document.getElementById('edit-modal')));
    }, [selectedUser])

    useEffect(() => {
        if (selectedUser.userId) {
            refreshUser(selectedUser.userId);
        }
    }, [selectedUser.userId, editModal])

    useEffect(() => {
        const updateRequiredIntervalId = setInterval(() => checkUpdateRequired(user), 1000);
        return () => {
            clearInterval(updateRequiredIntervalId);
        };
    }, [checkUpdateRequired, user])

    useEffect(() => {
        if (!user.integrationServices.some(s => s.name === activeTab)) {
            setActiveTab("Info");
        }
    }, [user.integrationServices])

    const handleUserSave = (event) => {
        setIsSaving(true);
        event.preventDefault();
        let updateUser = { ...user };

        let isCreate = updateUser.userId === undefined;
        if (!userIsValid(updateUser)) {
            setIsSaving(false);
            return;
        }
        dispatch(usersActions.saveUser(updateUser))
            .then(empty => {
                if (isCreate) {
                    usersService.getUsers().then(users => {
                        updateUser.userId = users.find(user => user.email === updateUser.email).userId;
                        if (updateUser.shouldSendInviteEmail && userPermissions.some(p => p.name == "InviteUsers")) {
                            idpUserService.sendEmail(updateUser.userId, 'Invite').then(empty => {
                                toast.success(updateUser.email + " has been invited");
                            }).catch(error => {
                                toast.error("User Invite Failed: " + error, { autoClose: false });
                            })
                        }
                    }).catch(error => {
                        toast.error("User Invite Failed: " + error, { autoClose: false });
                    });
                } else {
                    if (updateUser.shouldSendInviteEmail && userPermissions.some(p => p.name == "InviteUsers")) {
                        if (!updateUser.isPasswordSet) {
                            idpUserService.sendEmail(updateUser.userId, 'Invite').then(empty => {
                                toast.success(updateUser.email + " has been invited");
                            }).catch(error => {
                                toast.error("User Invite Failed: " + error, { autoClose: false });
                            });
                        } else {
                            idpUserService.sendEmail(updateUser.userId, 'Reinvite').then(empty => {
                                toast.success(updateUser.email + " has been re-invited");
                            }).catch(error => {
                                toast.error("User Invite Failed: " + error, { autoClose: false });
                            });
                        }
                    }
                }
                //load userpermissions
                dispatch(permissionsActions.loadUserPermissions())              
                .catch(error => {
                    toast.error("Loading User Permissions Failed:" + error, { autoClose: false });
                });

                bootstrap.Modal.getInstance(document.getElementById('edit-modal')).hide();
                if (isCreate) {
                    toast.success(updateUser.email + " has been created");
                } else {
                    toast.success(updateUser.email + " has been saved");
                }
                dispatch(pageActions.clearSelectedUser());
                setIsSaving(false);
            }).catch(error => {
                setIsSaving(false);
                toast.error("Save User Failed: " + error, { autoClose: false })
            });
    }

    const userIsValid = (user) => {
        const { email, firstName, lastName, partnerId, integrationServices, userId, twoFactorMethodClaims: userTwoFactorMethodClaims, jiraGroups } = user;
        const errors = {};
        const mailformat = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,16})+$/;
        const partnerTwoFactorMethods = partnerId && partners.length > 0 ? partners.find(p => p.partnerId === partnerId).twoFactorMethods : [];

        if (!email) {
            errors.email = "(Required)";
        } else if ((!userId && users.find(u => u.email.toLowerCase() === email.toLowerCase())) ||
            (userId && (users.find(u => u.userId === userId).email.toLowerCase() != email.toLowerCase()) && users.find(u => u.email.toLowerCase() === email.toLowerCase()))) {
            errors.email = "(Duplicate Email)";
        } else if (!(email.match(mailformat))) {
            errors.email = "(Invalid Email)";
        }

        if (!firstName) {
            errors.firstName = "(Required)";
        }

        if (!lastName) {
            errors.lastName = "(Required)";
        }

        if (partnerId === 0) {
            errors.partnerId = "(Required)";
        }
        
        if (jiraGroups.length === 0) {
            errors.jiraGroups = "(Required)";
        }   

        let authClaimErrors = false;

        if (integrationServices.find(s => s.name === "Login")) {           
            if (user.twoFactorMethodId && hasViewPartnersPermission) {
                let partnerTwoFactorClaims = partnerTwoFactorMethods.find(s => s.twoFactorMethodId === user.twoFactorMethodId).claims;
                partnerTwoFactorClaims.forEach(ptfc => {
                    let claim = userTwoFactorMethodClaims.find(utfc => utfc.claimType === ptfc.claimType);
                    if (!claim || claim.claimLabel === "") {
                        errors[ptfc.claimType] = "(Required)";
                        authClaimErrors = true;
                    }
                })
            }          
        }

        if (errors.email || errors.firstName || errors.lastName || errors.partnerId) {
            setActiveTab("Info");
        } else if (errors.twoFactorMethodId || errors.roles || authClaimErrors) {
            setActiveTab("Login");
        } else if (errors.jiraGroups) {
            setActiveTab("Support");
        }

        setErrors(errors);

        return Object.keys(errors).length === 0
    }

    const handleUserChange = (event) => {
        const { name, value } = event.target;
        setUser({
            ...user,
            [name]: name === "partnerId" ? parseInt(value, 10) : value
        });
    }

    const handleServiceClaimChange = (event) => {
        const { name, value } = event.target;
        let updatedUser = { ...user }
        if (updatedUser.twoFactorMethodClaims.find(c => c.claimType === name)) {
            updatedUser.twoFactorMethodClaims.find(c => c.claimType === name).claimLabel = value;
        } else {
            updatedUser.twoFactorMethodClaims.push({ claimType: name, claimLabel: value });
        }
        setUser(updatedUser)
    }

    const handleUserPartnerChange = (event) => {
        let updatedUser = { ...user };
        let userPartner = partners.find(partner => partner.partnerId === event.value);
        let availableServices = [...userPartner.availableUserServices];
        let integrationServices = [...updatedUser.integrationServices];
        let partnerIntegrationServices = [...userPartner.integrationServices];
        let subscriptions = [...userPartner.defaultSubscriptions].map(ds => ({
            groupId: ds.subscriptionId,
            name: availableSubscriptions.find(as => as.groupId == ds.subscriptionId).name
        }));
        updatedUser.integrationServices.forEach(service => {
            if (availableServices.find(a => a.serviceId === service.serviceId) != undefined) {
                let availableService = availableServices.find(a => a.serviceId === service.serviceId)
                let index = availableServices.indexOf(availableService);
                availableServices.splice(index, 1);
            } else {
                let index = integrationServices.indexOf(service);
                integrationServices.splice(index, 1);
            }
        })
        if (availableServices.some(as => as.name === "Login") && !updatedUser.userId) {
            let loginService = availableServices.find(as => as.name === "Login");
            updatedUser.shouldSendInviteEmail = true;
            let index = availableServices.indexOf(loginService);
            integrationServices.push(loginService);
            availableServices.splice(index, 1);
        }
        if (partnerIntegrationServices.some(as => as.name === "Support") && !updatedUser.userId) {
            let supportService = partnerIntegrationServices.find(as => as.name === "Support");
            let index = availableServices.findIndex(as => as.serviceId == supportService.serviceId);

            //add support service if user doesnt have it
            if (index != -1) {
                integrationServices.push(supportService);
                availableServices.splice(index, 1);
            }
        }

        updatedUser.jiraGroups = userPartner.jiraGroups;//set default jiraGroups on partner edit
        updatedUser.jiraOrganizations = userPartner.defaultJiraOrganizations//set default jiraOrganization on partner edit
        updatedUser.availableJiraOrganizations = userPartner.availableJiraOrganizations;

        if (updatedUser.integrationServices.length > integrationServices.length) {
            setUnconfirmedUser({
                ...user,
                partnerId: event.value,
                partnerName: event.label,
                integrationServices,
                availableServices,
                roles: [],
                companyPocs: [],
                subscriptions,
                jiraGroups: updatedUser.jiraGroups,
                jiraOrganizations: updatedUser.jiraOrganizations,
                availableJiraOrganizations: updatedUser.availableJiraOrganizations,
                twoFactorMethodId: null,
                twoFactorMethodName: "",
                shouldSendInviteEmail: updatedUser.shouldSendInviteEmail
            });
            new bootstrap.Modal(document.getElementById('services-modal'), { backdrop: 'static', keyboard: false }).show();
        } else {
            setUser({
                ...user,
                partnerId: event.value,
                partnerName: event.label,
                integrationServices,
                availableServices,
                roles: [],
                companyPocs: [],
                subscriptions,
                jiraGroups: updatedUser.jiraGroups,
                jiraOrganizations: updatedUser.jiraOrganizations,
                availableJiraOrganizations: updatedUser.availableJiraOrganizations,
                twoFactorMethodId: null,
                twoFactorMethodName: "",
                shouldSendInviteEmail: updatedUser.shouldSendInviteEmail
            });
        }
    }

    const handleUserPartnerChangeConfirmed = (event) => {
        event.preventDefault();
        setUser(unconfirmedUser);
        bootstrap.Modal.getInstance(document.getElementById('services-modal')).hide();
    }

    const handleRolesChange = (event) => {
        let selectedRoles = event.map(e => ({ roleId: e.value, name: e.label }));
        setUser({
            ...user,
            roles: selectedRoles
        });
    }

    const handleUserAuthChange = (event) => {
        
        if(event){
            setUser({
                ...user,
                twoFactorMethodId: event.value,
                twoFactorMethodName: event.label,
                serviceClaims: []
            });
        }
        else{
            setUser({
                ...user,
                twoFactorMethodId: null,
                twoFactorMethodName: "",
                serviceClaims: []
            });
        }
    }

    const handlePocChange = (event) => {
        let selectedPocs = event.map(e => ({
            groupId: e.value,
            name: e.label,
            type: 'poc'
        }))
        setUser({
            ...user,
            pocs: selectedPocs
        });
    }

    const handleSubscriptionsChange = (event) => {
        let selectedSubscriptions = event.map(e => ({
            groupId: e.value,
            name: e.label,
            type: 'subscription'
        }));
        setUser({
            ...user,
            subscriptions: selectedSubscriptions
        });
    }

    const handleNewServiceAdd = (service) => {
        let shouldSendInviteEmail = user.shouldSendInviteEmail;
        let userPartner = partners.find(partner => partner.partnerId === user.partnerId);
        let integrationServices = [...user.integrationServices];
        integrationServices.push(service);
        let availableServices = [...user.availableServices];
        let index = availableServices.indexOf(service);
        availableServices.splice(index, 1);
        let newServiceName = service.name;

        if (service.name === 'Login') {
            shouldSendInviteEmail = true;
            setUnconfirmedUser({
                ...user,
                integrationServices,
                shouldSendInviteEmail,
                availableServices,
                newServiceName
            });
            new bootstrap.Modal(document.getElementById('login-service-modal'), { backdrop: 'static', keyboard: false }).show();
        }
        else if (service.name === 'Support') {
            user.jiraGroups = userPartner.jiraGroups;//set default jiraGroups on service add
            setUser({
                ...user,
                integrationServices,
                shouldSendInviteEmail,
                availableServices
            });
            setActiveTab(service.name);
        }
        else {
            setUser({
                ...user,
                integrationServices,
                shouldSendInviteEmail,
                availableServices
            });
            setActiveTab(service.name);
        }
    }

    const handleUserLoginServiceAddConfirmed = (event) => {
        event.preventDefault();
        setUser(unconfirmedUser);
        setActiveTab(unconfirmedUser.newServiceName);
        bootstrap.Modal.getInstance(document.getElementById('login-service-modal')).hide();
    }

    const handleRemoveService = (service) => {
        let shouldSendInviteEmail = user.shouldSendInviteEmail;
        let integrationServices = [...user.integrationServices];
        let index = integrationServices.indexOf(service);
        integrationServices.splice(index, 1);
        let availableServices = [...user.availableServices];
        availableServices.push(service);
        if (service.name === 'Login') {
            shouldSendInviteEmail = false;
        }
        setUser({
            ...user,
            integrationServices,
            shouldSendInviteEmail,
            availableServices
        });
    }

    const handleJiraGroupsChange = (event) => {
        if (!hasEditJiraUserGroupPermission) {
            return;
        }

        let selectedGroups = event.map(e => jiraGroups.find(g => g.groupId === e.value));
        setUser({
            ...user,
            jiraGroups: selectedGroups
        });
    }

    const handleJiraOrganizationChange = (event) => {   
        let selectedOrganizations = event.map(e => ({ id: e.value, name: e.label}));
        if (event.length > 0) {              
            if(selectedOrganizations[selectedOrganizations.length - 1].id == "0"){//check last selected element
                selectedOrganizations = [{ id: "0", name: "No Organization" }];
            }else if(selectedOrganizations[selectedOrganizations.length - 1].id == "-1"){
                selectedOrganizations = [{ id: "-1", name: "--Managed by Jira Admins--" }];
            }
            else {               
                selectedOrganizations = selectedOrganizations.filter(org => org.id != "0" && org.id != "-1");
            }
        } else {

            if(!selectedUser.availableJiraOrganizations.some(org => org.id === "0")){
                toast.error("Users parter doesn't have 'No Organization' option as an available organization", { autoClose: false });
                return;
            }
            selectedOrganizations = [{ id: "0", name: "No Organization" }];
        }

        setUser({
            ...user,
            jiraOrganizations: selectedOrganizations
        });   
    }

    const enabledPartners = partners.filter(p => p.disabled === false);

    const availableServices = user.availableServices ? user.availableServices.filter(service => !service.serviceCategories.some(cat => cat.name === 'Navigation')
        && !service.serviceCategories.some(cat => cat.name === 'PartnerOnly') && service.name != "Support") : [];

    const hasEditPointOfContactsPermission = userPermissions.some(up => up.name === "EditPointOfContacts");
    const hasViewPartnersPermission = userPermissions.some(up => up.name === "ViewPartners");
    const hasEditJiraUserGroupPermission = userPermissions.some(up => up.name === "EditJiraUserGroup");
    const hasEditJiraUserOrganizationPermission = userPermissions.some(up => up.name === "EditJiraUserOrganization");

    return (
        <>
            <div className="modal fade" tabindex="-1" role="dialog" id="edit-modal">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className={ "modal-content " + theme }>
                        <form onSubmit={ handleUserSave }>
                            <div className="modal-header">
                                <h2 className="modal-title">{ user.userId ? "Update User" : "New User" }</h2>
                                <a className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon="fas fa-times" />
                                </a>
                            </div>
                            <div className="modal-body">
                                { (isSaving || isLoading) ?
                                    <div className="modal-overlay" />
                                    : ""
                                }
                                <div className="row">
                                    <div className="col-md-12">
                                        <CNav className={ theme } variant="tabs" role="tablist">
                                            <CNavItem>
                                                <CNavLink
                                                    active={ activeTab === "Info" }
                                                    onClick={ () => setActiveTab("Info") }
                                                >
                                                    Info
                                                </CNavLink>                                                
                                            </CNavItem>
                                            <CNavItem>
                                                <CNavLink
                                                    active={ activeTab === "Support" }
                                                    onClick={ () => setActiveTab("Support") }
                                                >
                                                    Support
                                                </CNavLink>                                                
                                            </CNavItem>
                                            { user.integrationServices.filter(service => service.name != "Support" ).map(service => {
                                                return (
                                                    <CNavItem>
                                                        <CNavLink
                                                            active={ activeTab === service.name }
                                                            onClick={ () => setActiveTab(service.name) }
                                                        >
                                                            { service.name }<a className="remove" onClick={ () => handleRemoveService(service) }><FontAwesomeIcon icon="fas fa-times" /></a>
                                                        </CNavLink>
                                                    </CNavItem>
                                                )
                                            }) }
                                            { availableServices.length > 0 ?
                                                <li className="nav-item" role="presentation">
                                                    <div className="dropdown">
                                                        <a className="nav-link" id="newServiceDropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><FontAwesomeIcon icon="fa fa-plus" /></a>
                                                        <div className={ "dropdown-menu " + theme }>
                                                            { availableServices.map(service => {
                                                                return (
                                                                    <a className={ "dropdown-item " + theme }
                                                                        id={ service.name + "-menu-item" }
                                                                        onClick={ () => handleNewServiceAdd(service) }
                                                                    >
                                                                        { service.name }
                                                                    </a>
                                                                )
                                                            }) }
                                                        </div>
                                                    </div>
                                                </li>
                                                : ""
                                            }
                                        </CNav>
                                        <CTabContent className={ theme }>
                                            <CTabPane role="tabpanel" aria-labelledby="info-tab" visible={ activeTab === "Info" }>
                                                <div className="container-fluid h-100">
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                            <TextInput name="email" label="Email" value={ user.email || "" } onChange={ handleUserChange } error={ errors.email } wrapperClass="mb-3" isRequired={ true } />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <TextInput name="firstName" label="First Name" value={ user.firstName || "" } onChange={ handleUserChange } error={ errors.firstName } wrapperClass="mb-3" isRequired={ true } />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <TextInput name="lastName" label="Last Name" value={ user.lastName || "" } onChange={ handleUserChange } error={ errors.lastName } wrapperClass="mb-3" isRequired={ true } />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label className={ "form-label" + (!hasViewPartnersPermission ? " disabled" : "") }>Partner*{ errors.partnerId && <span className="text-danger field-validation-error ms-3">{ errors.partnerId }</span> }</label>
                                                                <Select
                                                                    type="text"
                                                                    isSearchable
                                                                    classNamePrefix="react-select"
                                                                    name="partnerId"
                                                                    value={ { value: user.partnerId, label: user.partnerName } }
                                                                    onChange={ handleUserPartnerChange }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ enabledPartners.map(partner => ({
                                                                        value: partner.partnerId,
                                                                        label: partner.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.DropdownSelectStyleLight : styles.DropdownSelectStyleDark }
                                                                    isDisabled={ !hasViewPartnersPermission }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CTabPane>
                                            <CTabPane role="tabpanel" aria-labelledby="communications-tab" visible={ activeTab === "Communications" }>
                                                <div className="container-fluid h-100">
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label className={ "form-label" + (!hasEditPointOfContactsPermission ? " disabled" : "") }>
                                                                    Point of Contact{ errors.poc && <span className="text-danger field-validation-error ms-3">{ errors.poc }</span> }
                                                                </label>
                                                                <Select
                                                                    name="companyPoc"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    placeholder="Not a Point of Contact"
                                                                    onChange={ handlePocChange }
                                                                    value={ user.pocs.map(poc => ({
                                                                        value: poc.groupId,
                                                                        label: poc.name
                                                                    })) }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ availablePocs.map(poc => ({
                                                                        value: poc.groupId,
                                                                        label: poc.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    isDisabled={ !hasEditPointOfContactsPermission }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label className="form-label">
                                                                    Subscriptions{ errors.subscriptions && <span className="text-danger field-validation-error ms-3">{ errors.subscriptions }</span> }
                                                                </label>
                                                                <Select
                                                                    name="subscriptions"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    placeholder="No Subscriptions Selected"
                                                                    onChange={ handleSubscriptionsChange }
                                                                    value={ user.subscriptions.map(subscription => ({
                                                                        value: subscription.groupId,
                                                                        label: subscription.name
                                                                    })) }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ availableSubscriptions.map(subscription => ({
                                                                        value: subscription.groupId,
                                                                        label: subscription.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CTabPane>
                                            <CTabPane role="tabpanel" aria-labelledby="support-tab" visible={ activeTab === "Support" }>
                                                <div className="container-fluid h-100">
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label className={ "form-label" + (!hasEditJiraUserOrganizationPermission ? " disabled" : "") }>Jira Organization{ errors.jiraOrganization && <span className="text-danger field-validation-error ms-3">{ errors.jiraOrganization }</span> }</label>
                                                                <Select
                                                                    type="text"
                                                                    isSearchable
                                                                    classNamePrefix="react-select"
                                                                    placeholder="No Jira Organization Selected"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    name="jiraOrganizationId"
                                                                    value={ user.jiraOrganizations.map(org => ({
                                                                        value: org.id,
                                                                        label: org.name
                                                                    })) }
                                                                    onChange={ handleJiraOrganizationChange }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ user.availableJiraOrganizations?.map(org => ({
                                                                        value: org.id,
                                                                        label: org.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    isDisabled={ !hasEditJiraUserOrganizationPermission }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label className={ "form-label" + (!hasEditJiraUserGroupPermission ? " disabled" : "") }>Jira Groups*{ errors.jiraGroups && <span className="text-danger field-validation-error ms-3">{ errors.jiraGroups }</span> }</label>
                                                                <Select
                                                                    type="text"
                                                                    isSearchable
                                                                    classNamePrefix="react-select"
                                                                    placeholder="No Jira Groups Associated to User"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    name="jiraGroupId"
                                                                    value={ user.jiraGroups?.map(group => ({
                                                                        value: group.groupId,
                                                                        label: group.name
                                                                    })) }
                                                                    onChange={ handleJiraGroupsChange }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ jiraGroups.map(group => ({
                                                                        value: group.groupId,
                                                                        label: group.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                    isDisabled={ !hasEditJiraUserGroupPermission }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CTabPane>
                                            <CTabPane role="tabpanel" aria-labelledby="login-tab" visible={ activeTab === "Login" }>
                                                <div className="container-fluid h-100 scrollable-tab-content">
                                                    <div className="row pt-4">
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label className="form-label">Roles{ errors.roles && <span className="text-danger field-validation-error ms-3">{ errors.roles }</span> }</label>
                                                                <Select
                                                                    name="roles"
                                                                    isMulti
                                                                    closeMenuOnSelect={ false }
                                                                    placeholder="Select User Roles..."
                                                                    classNamePrefix="react-select"
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    onChange={ handleRolesChange }
                                                                    value={ user.roles.map(role => ({
                                                                        value: role.roleId,
                                                                        label: role.name
                                                                    })) }
                                                                    options={ roles.map(role => ({
                                                                        value: role.roleId,
                                                                        label: role.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">
                                                            <div className="mb-3">
                                                                <label className={ "form-label" + (!hasViewPartnersPermission ? " disabled" : "") }>
                                                                    Two Factor Auth{ errors.twoFactorMethodId && <span className="text-danger field-validation-error ms-3">{ errors.twoFactorMethodId }</span> }
                                                                </label>
                                                                <Select
                                                                    type="text"
                                                                    placeholder="Select Two Factor Method..."    
                                                                    isClearable                                                        
                                                                    classNamePrefix="react-select"
                                                                    name="twoFactorMethodId"
                                                                    value={ user.twoFactorMethodId ? { value: user.twoFactorMethodId, label: user.twoFactorMethodName } : "" }
                                                                    onChange={ handleUserAuthChange }
                                                                    menuPosition="fixed"
                                                                    menuPlacement="bottom"
                                                                    options={ userPartnerAuthServices.map(service => ({
                                                                        value: service.twoFactorMethodId,
                                                                        label: service.name
                                                                    })) }
                                                                    styles={ theme == 'light' ? styles.DropdownSelectStyleLight : styles.DropdownSelectStyleDark }
                                                                    isDisabled={ !hasViewPartnersPermission }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="two-factor-claims" className="row">
                                                        <div className="col">
                                                            { user.twoFactorMethodId && authServices.length > 0 ? authServices.find(s => s.twoFactorMethodId === user.twoFactorMethodId).claims.map(claim => {
                                                                return (
                                                                    <TextInput
                                                                        name={ claim.claimType }
                                                                        label={ claim.claimLabel }
                                                                        value={ user.twoFactorMethodClaims.find(c => c.claimType === claim.claimType) ? user.twoFactorMethodClaims.find(c => c.claimType === claim.claimType).claimLabel : "" }
                                                                        onChange={ handleServiceClaimChange }
                                                                        error={ Object.keys(errors).find(key => key === claim.claimType) ? errors[claim.claimType] : "" }
                                                                        wrapperClass="mb-3"
                                                                        isRequired={ true }
                                                                    />
                                                                )
                                                            }) : "" }
                                                        </div>
                                                    </div>
                                                </div>
                                            </CTabPane>
                                        </CTabContent>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div id="required-disclaimer" className="col">
                                    <p className="ms-3">
                                        <span className="align-bottom">*</span><span className="small align-top"> Required</span>
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        { isSaving ?
                                            <div className="btn btn-new btn-block"><span className="saving-button fa-fade">Saving...</span></div>
                                            : isLoading ? <div className="btn btn-new btn-block"><span className="saving-button fa-fade">Loading...</span></div>
                                                : <input type="submit" value="Save" className="btn btn-new btn-block" />
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
            <ConfirmationModal
                title="Removal Confirmation"
                id="services-modal"
                displayValue={ "Are you sure you want to make this update to the user:<br /><br />Old Partner: <b>" + user.partnerName + "</b><br />New Partner: <b>" + unconfirmedUser.partnerName + "</b><br /><br />Making this change will result in one or more services being removed from the user?" }
                onSubmit={ handleUserPartnerChangeConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Login Confirmation"
                id="login-service-modal"
                displayValue={ "This action will create a user account with Oasis Portal access. Users with access will be able to create and submit tickets on behalf of your organization. Please confirm you wish to create this user account.<br><br><i>Note:</i> Saving this form with the Login tab will create the Oasis Portal user." }
                onSubmit={ handleUserLoginServiceAddConfirmed }
                theme={ theme }
            />
        </>
    )
}

export default UserEdit;