import * as types from '../actions/types';
import initialState from './InitialState';

export default function servicesReducer(state = initialState.services, action) {
    switch (action.type) {
        case types.LOAD_AUTH_SERVICES_SUCCESS:
            return { ...state, auth: action.services };
        case types.LOAD_PARTNER_SERVICES_SUCCESS:
            return { ...state, partner: action.services };
        default:
            return state;
    }
}