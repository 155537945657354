import * as types from '../actions/types';
import initialState from './InitialState';

export default function kbReducer(state = initialState.kb, action) {
    switch (action.type) {
        case types.LOAD_KNOWLEDGEBASE_BOOKS_SUCCESS:
            return { ...state, books: action.books };
        case types.LOAD_KNOWLEDGEBASE_PAGE_SUCCESS:
            return {
                ...state, books: state.books.map(book => {
                    book.contents.map(content => {
                        if (content.contentId === action.page.pageId) {
                            return action.page
                        } else {
                            return content
                        }
                    })
                })
            };
        case types.SEARCH_KB_SUCCESS:
            return { ...state, searchResults: action.searchResults };
        case types.CLEAR_KB_SEARCH_RESULTS:
            return { ...state, searchResults: initialState.kb.searchResults };
        case types.LOAD_KB_PDF_SUCCESS:
            return { ...state, pdf: action.pdf};
        default:
            return state;
    }
}