import * as types from '../actions/types';
import initialState from './InitialState';

export default function communicationsReducer(state = initialState.communications, action) {
    switch (action.type) {
        case types.LOAD_AVAILABLE_TEMPLATES_SUCCESS:
            return { ...state, availableTemplates: action.templates.sort(sortTemplates) };
        default:
            return state;
    }
}

function sortTemplates(templateA, templateB) {
    var x = templateA.name.toLowerCase();
    var y = templateB.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
}