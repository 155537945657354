import React, { useEffect, useState } from 'react';
import { config } from '../config';
import { NavbarBrand, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavLink as RRNavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import { signoutRedirect } from '../services/userService';
import * as linksActions from '../redux/actions/linksActions';
import * as permissionsActions from '../redux/actions/permissionsActions';
import * as pageActions from '../redux/actions/pageActions'
import { useDispatch, useSelector } from 'react-redux';
import { setAuthHeader } from '../utils/axiosHeaders';
import ReactTooltip from 'react-tooltip';
import * as auditsService from '../services/auditsService';
import * as notificationActions from '../redux/actions/notificationActions';
import { CContainer, CNavbar, CNavbarToggler, CCollapse, CNavbarNav, CNavItem } from '@coreui/bootstrap-react';
import * as appSettingsActions from '../redux/actions/appSettingsActions';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function NavMenu({sideNavState, theme}) {
    const [visible, setVisible] = useState(false);
    const [links, setLinks] = useState([]);

    const auth = useSelector(state => state.auth);
    const userPermissions = useSelector(state => state.auth.permissions);
    const partnerLinks = useSelector(state => state.links.available);
    const maintenanceMode = useSelector(state => state.appSettings.maintenanceMode);
    const notifications = useSelector(state => state.notifications);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        ReactTooltip.rebuild();
    })

    useEffect(() => {        
        dispatch(notificationActions.loadNotifications());

        const intervalMaintenanceCheck = setInterval(() => {
            dispatch(appSettingsActions.loadMaintenanceMode());
        }, 10000);

        const intervalNotificationCheck = setInterval(() => {
            dispatch(notificationActions.loadNotifications());
        }, 10000);

        return () => {
            clearInterval(intervalMaintenanceCheck);
            clearInterval(intervalNotificationCheck);
        }

    }, [])

    useEffect(() => {
        if (notifications.filter(x => x.type === "Alert").length > 0) {
            const ToastMessage = ({ closeToast, toastProps }) => (
                <>
                    <FontAwesomeIcon icon="fa-solid fa-bell" /> { notifications.filter(x => x.type === "Alert")[0].title
                    }: { notifications.filter(x => x.type === "Alert")[0].message }
                </>
            )
            toast(<ToastMessage />, { autoClose: false });
        }
    }, [notifications.length])

    useEffect(() => {
        if (auth.user && auth.user.access_token && !auth.isLoadingUser) {
            setAuthHeader(auth.user.access_token);
            dispatch(linksActions.loadAvailableLinks()).catch(error => {
                console.log(error + ' ' + auth.user.access_token);
            });
            dispatch(permissionsActions.loadUserRoles()).catch(error => {
                console.log(error + ' ' + auth.user.access_token);
            });
            dispatch(appSettingsActions.loadMaintenanceMode()).catch(error => {
                console.log(error + ' ' + auth.user.access_token);
            });
        }
    }, [auth.user])

    useEffect(() => {
        if (links !== partnerLinks) {
            setLinks(partnerLinks)
        }
    }, [partnerLinks])

    const handleNavClickLogging = (pageName) => {
        auditsService.logPageView(pageName);
    }

    const enableDashboardEditMode = () => {
        if(location.pathname !== "/"){
            navigate('/');
        }

        dispatch(pageActions.updateDashboardEditMode(true));
    }

    return (
        <header>
            <div className="top-nav-container-spacer col p-0" />
            <CNavbar expand={ "md top-nav-body static-font-size " + sideNavState + " " + theme } toggleable="md">
                <CContainer fluid>
                    <NavbarBrand tag={ Link } to="/" end>
                        <img
                            className="nav-logo"
                            src={ config.OASIS_PORTAL_URL + (theme == "light" ? "images/oasis-light-logo.svg" : "images/oasis-dark-logo.svg") }
                            alt="Oasis"
                        />
                    </NavbarBrand>
                    <CCollapse className="navbar-collapse" visible={ visible }>
                        <CNavbarNav className="navbar-nav ms-auto ">
                            <CNavItem>
                                <div className="me-3 pt-2" id="navPartner">
                                    { auth.user ? auth.user.profile.partnerName : "" }
                                </div>
                            </CNavItem>
                            { notifications.length > 0 ?
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav>
                                        <span className="fa-layers fa-fw">
                                            <FontAwesomeIcon icon="fa-solid fa-bell" />
                                            { notifications.length > 0 ?
                                                <span className="fa-layers-counter">{ notifications.length }</span>
                                                : ""
                                            }
                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu className={ "notification-dropdown-menu " + theme } end data-bs-popper="none">
                                        { notifications.filter(x => x.type === "Alert").length > 0 ? notifications.filter(x => x.type === "Alert").map((alert) => {
                                            return (
                                                <div className={ "alert " + theme }>
                                                    <strong>{ alert.title }<br /></strong> { alert.message }
                                                </div>
                                            )
                                        }) : "" }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                : <CNavItem>
                                    <div className="me-1 pt-2" id="navPartner">
                                        <FontAwesomeIcon icon="fa-solid fa-bell" />
                                    </div>
                                </CNavItem> }
                            <CNavItem>
                                { maintenanceMode === "enabled" && userPermissions.some(p => p.name === "ViewMaintenance") ?
                                    <CNavItem>
                                        <NavLink
                                            onClick={ () => handleNavClickLogging("Settings") }
                                            nav
                                            inNavbar
                                            data-tip="Maintenance Mode is Active"
                                            data-type={ theme }
                                            data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                            data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                            data-border={ true }
                                            tag={ RRNavLink }
                                            to="/Administration/Settings"
                                        >
                                            <FontAwesomeIcon id="navMaintenanceNotifier" icon="fa-solid fa-circle-exclamation" />
                                        </NavLink>
                                    </CNavItem>
                                    : ""
                                }
                            </CNavItem>
                            { links.length > 0 ?
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle
                                        nav
                                        data-tip="Links"
                                        data-delay-show="1000"
                                        data-type={ theme }
                                        data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                                        data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                                        data-border={ true }
                                    >
                                        <FontAwesomeIcon icon="fa-solid fa-globe" />
                                    </DropdownToggle>
                                    <DropdownMenu className={ theme } end data-bs-popper="none">
                                        { links.map(link => {
                                            return (
                                                <DropdownItem className={ theme } href={ link.url } target="_blank">
                                                    { link.label }<span className="text-nowrap"> { ">" }</span>
                                                </DropdownItem>
                                            )
                                        }) }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                : ""
                            }
                            <UncontrolledDropdown className="ms-4" nav inNavbar>
                                <DropdownToggle nav caret>{ auth.user ? <span>{ auth.user.profile.family_name }, { auth.user.profile.given_name }</span> : <FontAwesomeIcon icon="fas fa-spinner" pulse /> }</DropdownToggle>
                                <DropdownMenu className={ theme } end data-bs-popper="none">
                                    <DropdownItem className={ theme } onClick={ enableDashboardEditMode }><FontAwesomeIcon icon="fa-solid fa-square-dashed-circle-plus" /> Edit Dashboard</DropdownItem>
                                    <DropdownItem className={ theme } onClick={ signoutRedirect }>Logout</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </CNavbarNav>
                    </CCollapse>
                </CContainer>
            </CNavbar>
        </header>
    );

}

export default NavMenu;