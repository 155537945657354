import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { config } from '../config';
import { customLoginRedirect } from '../services/userService';
import * as permissionsActions from '../redux/actions/permissionsActions';

function ProtectedRoute({ children, redirectTo, permission }) {

    const user = useSelector(state => state.auth.user)
    const userPermissions = useSelector(state => state.auth.permissions);       
    const hasPermission = permission ? userPermissions.some(p => p.name === permission) : true;
    const dispatch = useDispatch();

    useEffect(() => {
        if(userPermissions == undefined || userPermissions.length == 0){
            dispatch(permissionsActions.loadUserPermissions()).catch(error => {
                console.log(error + ' - Error protected route user permissions');
            });
        }
    }, [])

    if ((window.location.origin + '/') === config.OASIS_PORTAL_URL) {
        return user && hasPermission ? children : <Navigate to={ redirectTo } />
    } else {
        return customLoginRedirect(window.location.origin);
    }
}

export default ProtectedRoute