import React, { useState } from 'react';
import { CCollapse, CCardBody, CTable, CTableBody, CTableRow, CTableDataCell } from '@coreui/bootstrap-react';
import TriToggleFilter from './FilterTypes/TriToggleFilter';
import RangeFilter from './FilterTypes/RangeFilter';
import MultiselectFilter from './FilterTypes/MultiselectFilter';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function FilterSlideout({ RecordType, theme }) {

    const [isPaneOpen, setIsPaneOpen] = useState(false);

    const filterSettings = useSelector(state => state.settings[RecordType].filters, (left, right) => {
        var hasChanged = false;
        Object.keys(left).forEach(key => hasChanged = (right[key].value != left[key].value || right[key].isActive != left[key].isActive));
        return hasChanged;
    });
    const filters = useSelector(state => state.page[RecordType].filters);

    const hasFilters = filters.some(filter => filterSettings[filter.filterIdentifier].isActive);

    const hasFiltersClass = hasFilters ? "slideoutIconContainerActive" : "slideoutIconContainer";

    const filterGroups = () => {
        const groups = filters.map(filter => filter.group);
        return [...new Set(groups)];
    }

    const handleTogglePane = () => {
        const newPaneStatus = !isPaneOpen;
        setIsPaneOpen(newPaneStatus);
    }

    const filterSwitch = (filter) => {
        switch (filter.filterType) {
            case 'triStateToggle':
                return <TriToggleFilter RecordType={ RecordType } Filter={ filter } theme={ theme } />;
            case 'range':
                return <RangeFilter RecordType={ RecordType } Filter={ filter } />;
            case 'multiSelect':
                return <MultiselectFilter RecordType={ RecordType } Filter={ filter } theme={ theme } />;
            default:
                return "";
        }
    }

    return (
        <div className="slideout d-flex">
            <CCollapse className={ "scroll flex-column h-100 " + theme } id="slideoutBlock" horizontal visible={ isPaneOpen }>
                <CCardBody className="pt-3 filter-block media-controlled" style={ { width: "340px" } }>
                    <CTable color={ theme } borderless>
                        <CTableBody>
                            { filters.length > 0 ?
                                filterGroups().map(group =>
                                    <>
                                        <CTableRow className="group-row m-0">
                                            <CTableDataCell id="toggle-group-header" className="media-controlled p-0 pt-3"><h5><strong>{ group }</strong></h5></CTableDataCell>
                                            <CTableDataCell className="p-0"> </CTableDataCell>
                                            <CTableDataCell id="toggle-group-spacer" className="p-0"> </CTableDataCell>
                                        </CTableRow>
                                        { filters.map(filter => filter.group === group ?
                                            <CTableRow className="toggle-row">
                                                { filterSwitch(filter) }
                                            </CTableRow>
                                            : ""
                                        ) }
                                    </>
                                )
                                : <div id="no-filter-dialog">
                                    No Filters for this Page
                                </div>
                            }
                        </CTableBody>
                    </CTable>
                </CCardBody>
            </CCollapse>
            <div className="d-flex flex-column slideoutBarContainer align-items-center">
                <div className="d-flex flex-row h-100">
                    <div className={ "d-flex flex-column media-controlled slideoutBar " + theme } onClick={ handleTogglePane }></div>
                    <div
                        className={ theme + " d-flex flex-column my-auto media-controlled " + hasFiltersClass }
                        data-tip="Click to open or close filtering options"
                        data-delay-show="1000"
                        data-type={ theme }
                        data-arrow-color={ theme == "light" ? "#92929D" : "#161616" }
                        data-background-color={ theme == "light" ? "#F4F7FC" : "#161616" }
                        data-border={ true }
                        onClick={ handleTogglePane }
                    >
                        <div className="my-auto">
                            <a className={ "slideoutLink " + theme }>{ isPaneOpen ? <FontAwesomeIcon icon="fas fa-angle-double-left" /> : <FontAwesomeIcon icon="fas fa-angle-double-right" /> }</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FilterSlideout;