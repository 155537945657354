import * as types from "./types";
import * as resourcesService from "../../services/resourcesService";

export function updateResources(resources) {
    return { type: types.UPDATE_RESOURCES, resources };
}

export function loadResources() {
    return function (dispatch) {
        return resourcesService.getResources()
            .then(resources => {
                dispatch(updateResources(resources));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function saveResources(resources) {
    return function (dispatch) {
        return resourcesService.saveResources(resources)
            .then(resources => {
                dispatch(updateResources(resources));
            })
            .catch(error => {
                throw error;
            });
    };
}