import React, { useEffect, useState } from 'react';
import { CFormSwitch } from '@coreui/bootstrap-react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DatePicker } from 'rsuite';
import isBefore from 'date-fns/isBefore';
import isThisHour from 'date-fns/isThisHour';
import isToday from 'date-fns/isToday';
import ConfirmationModal from '../../common/ConfirmationModal';
import TextInput from '../../common/TextInput';
import Select from 'react-select';
import * as styles from '../../common/DropdownStyles';
import * as bootstrap from 'bootstrap';
import * as supportActions from '../../../redux/actions/supportActions';
import * as maintenanceService from '../../../services/maintenanceService';
import * as appSettingsActions from '../../../redux/actions/appSettingsActions';
import * as communicationsActions from '../../../redux/actions/communicationsActions'
import * as groupsActions from '../../../redux/actions/groupsActions';
import * as communicationsService from '../../../services/communicationsService';
import * as notificationActions from '../../../redux/actions/notificationActions';
import * as dateUtils from '../../../utils/dateUtils';
import Collapsible from 'react-collapsible';

function SettingsPage({ theme }) {

    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState({ title: "", message: "", endTime: null, notificationId: 0, userId: null, type: "Alert" });
    const groups = useSelector(state => state.support.groups);
    const [selectedGroups, setSelectedGroups] = useState(groups);
    const [selectedSendComGroups, setSelectedSendComGroups] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({ emailTemplateId: 0, name: "Select Email Template to Send out..." });

    function sortGroups(groupA, groupB) {
        var x = groupA.name.toLowerCase();
        var y = groupB.name.toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    }

    const maintenanceMode = useSelector(state => state.appSettings.maintenanceMode);
    const userPermissions = useSelector(state => state.auth.permissions)
    const availableTemplates = useSelector(state => state.communications.availableTemplates);
    const availableSendComGroups = useSelector(state => state.groups.availableSubscriptions.concat(state.groups.availablePocs).sort(sortGroups));
    const notifications = useSelector(state => state.notifications);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(supportActions.loadGroups());
        dispatch(communicationsActions.loadAvailableTemplates());
        dispatch(groupsActions.loadAvailablePocs());
        dispatch(groupsActions.loadAvailableSubscriptions());
        dispatch(notificationActions.loadNotifications());

        const intervalNotificationCheck = setInterval(() => {
            dispatch(notificationActions.loadNotifications());
        }, 5000);

        return () => {
            clearInterval(intervalNotificationCheck);
        }
    }, []);

    useEffect(() => {
        if (notifications.length > 0) {
            setAlert({
                title: notifications[0].title,
                message: notifications[0].message,
                endTime: dateUtils.getLocalDateTime(notifications[0].endTime),
                notificationId: notifications[0].notificationId,
                type: "Alert"
            });
        }
        else {
            setAlert({
                title: alert.title,
                message: alert.message,
                endTime: alert.endTime,
                notificationId: 0,
                type: "Alert"
            });
        }
    }, [notifications.length]);

    useEffect(() => {
        setSelectedGroups(groups);
    }, [groups]);

    const maintenanceModeLabel = maintenanceMode === "enabled" ? "Maintenance Mode On" : "Maintenance Mode Off";

    const handleMaintenanceModeChange = (event) => {
        event.preventDefault();
        if (maintenanceMode === "disabled") {
            new bootstrap.Modal(document.getElementById('enable-maintenance-mode-modal'), { backdrop: 'static', keyboard: false }).show();
        } else {
            new bootstrap.Modal(document.getElementById('disable-maintenance-mode-modal'), { backdrop: 'static', keyboard: false }).show();
        }
    }

    const handleEnableMaintenanceModeConfirmed = (event) => {
        event.preventDefault();
        bootstrap.Modal.getInstance(document.getElementById('enable-maintenance-mode-modal')).hide();
        maintenanceService.enableMaintenanceMode().then(() => {
            dispatch(appSettingsActions.loadMaintenanceMode()).then(() => {
            });
        });
    }

    const handleDisableMaintenanceModeConfirmed = (event) => {
        event.preventDefault();
        bootstrap.Modal.getInstance(document.getElementById('disable-maintenance-mode-modal')).hide();
        maintenanceService.disableMaintenanceMode().then(() => {
            dispatch(appSettingsActions.loadMaintenanceMode()).then(() => {
            });;
        });
    }

    const handleCreateClick = (event) => {
        event.preventDefault();
        new bootstrap.Modal(document.getElementById('create-alert-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleRemoveAlertClick = (event) => {
        event.preventDefault();
        new bootstrap.Modal(document.getElementById('remove-alert-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleEditAlertClick = (event) => {
        event.preventDefault();
        new bootstrap.Modal(document.getElementById('edit-alert-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleCreateConfirmed = (event) => {
        event.preventDefault();
        bootstrap.Modal.getInstance(document.getElementById('create-alert-modal')).hide();
        if (alertIsValid(alert)) {

            dispatch(notificationActions.saveNotification(alert)).then(() => {
                dispatch(notificationActions.loadNotifications());
                toast.success("Alert Created Successfully")
            }).catch(error => {
                toast.error("Creation of the Alert Failed: " + error, { autoClose: false })
            })
        }
    }

    const handleRemoveConfirmed = (event) => {
        event.preventDefault();
        bootstrap.Modal.getInstance(document.getElementById('remove-alert-modal')).hide();

        dispatch(notificationActions.removeNotification(notifications[0])).then(() => {
            setAlert({ title: "", message: "", endTime: null, notificationId: 0, userId: null, type: "Alert" });
            dispatch(notificationActions.loadNotifications());
            toast.success("Alert Removed Successfully")
        }).catch(error => {
            toast.error("Removal of the Alert Failed: " + error, { autoClose: false })
        })
    }

    const handleEditConfirmed = (event) => {
        event.preventDefault();
        bootstrap.Modal.getInstance(document.getElementById('edit-alert-modal')).hide();

        var editedAlert = alert;
        editedAlert.endTime = new Date(editedAlert.endTime);

        if (alertIsValid(editedAlert)) {
            dispatch(notificationActions.updateNotification(editedAlert)).then(() => {
                toast.success("Alert Updated Successfully")
                dispatch(notificationActions.loadNotifications());
            }).catch(error => {
                toast.error("Update of the Alert Failed: " + error, { autoClose: false })
            })
        }
    }

    const handleAlertChange = (event) => {
        const { name, value } = event.target;
        setAlert({
            ...alert,
            [name]: value
        });
    }

    const handleEndTimeChange = (date) => {
        setAlert({
            ...alert,
            endTime: date
        });
    }

    const alertIsValid = (alert) => {
        const { title, message, endTime } = alert;
        const errors = {};

        if (!title) {
            errors.title = "(Required)";
        }

        if (!message) {
            errors.message = "(Required)";
        }

        if (message && message.length > 1000) {
            errors.message = "Message is limited to 1000 characters.";
        }

        if (endTime === null) {
            errors.endTime = "(Required)";
        }

        setErrors(errors);

        return Object.keys(errors).length === 0;
    }

    const handleSaveClick = (event) => {
        event.preventDefault();
        new bootstrap.Modal(document.getElementById('update-available-support-groups-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const handleUpdateConfirmed = (event) => {
        event.preventDefault();
        bootstrap.Modal.getInstance(document.getElementById('update-available-support-groups-modal')).hide();
        dispatch(supportActions.updateGroups(selectedGroups)).then(() => {
            toast.success("Available Support Groups Saved Successfully")
        }).catch(error => {
            toast.error("Save Available Support Groups Failed: " + error, { autoClose: false })
        })
    }

    const handleSupportGroupsChange = (event) => {
        let groups = event.map(e => ({ groupId: e.value, name: e.label }));
        let newSelectedGroups = [...selectedGroups];
        newSelectedGroups.forEach(group => group.isAvailableForSelection = groups.some(selectedGroup => selectedGroup.groupId === group.groupId));
        setSelectedGroups(newSelectedGroups);
    }

    const handleSendComGroupsChange = (event) => {
        let groups = event.map(e => ({ groupId: e.value, name: e.label }));
        setSelectedSendComGroups(groups);
    }

    const handleEmailTemplateChange = (event) => {
        let template = { emailTemplateId: event.value, name: event.label };
        setSelectedTemplate(template);
    }

    const handleSendCommunicationClick = (event) => {
        event.preventDefault();
        let communicationInfo = { template: selectedTemplate, groups: selectedSendComGroups };
        if (sendComIsValid(communicationInfo)) {
            new bootstrap.Modal(document.getElementById('send-communication-modal'), { backdrop: 'static', keyboard: false }).show();
        }
    }

    const sendComIsValid = (communicationInfo) => {
        const { template, groups } = communicationInfo;
        const errors = {};

        if (template.emailTemplateId === 0) {
            errors.template = "(Required)";
        }

        if (!groups.length > 0) {
            errors.sendComGroups = "(Required)";
        }

        setErrors(errors);

        return Object.keys(errors).length === 0;
    }

    const handleSendOutCommunicationConfirmed = (event) => {
        event.preventDefault();
        bootstrap.Modal.getInstance(document.getElementById('send-communication-modal')).hide();
        let communicationInfo = { template: selectedTemplate, groups: selectedSendComGroups };
        communicationsService.sendCommunication(communicationInfo).then(() => {
            toast.success("Communication Sent Successfully")
        }).catch(error => {
            toast.error("Sending the communication Failed: " + error, { autoClose: false })
        });
    }

    const alertInfoIsDirty = () => {
        if (alert.endTime != notifications[0].endTime ||
            alert.title != notifications[0].title ||
            alert.message != notifications[0].message) {
            return true;
        }
        return false;
    }

    const shouldHideHour = (hour, date) => {
        if (isToday(date)) {
            var currentHour = new Date().getHours();

            if (hour < currentHour) {
                return true;
            }
        }

        return false;
    }

    const shouldHideMinute = (minute, date) => {
        if (isToday(date) && isThisHour(date)) {
            var currentMinute = new Date().getMinutes();

            if (minute < currentMinute) {
                return true;
            }
        }

        return false;
    }

    return (
        <>
            <div id="view-all" className={ "d-flex flex-column admin-settings oasis-rounded h-100 " + theme }>
                <div className="maintenance-page-content">
                    { userPermissions.some(p => p.name === "ConfigureAlerts") ?
                        <Collapsible
                            trigger="Alert"
                            triggerTagName="h5"
                            triggerClassName={ "py-2 ps-4 mb-2 oasis-rounded collapsible-header " + theme }
                            triggerOpenedClassName={ "py-2 ps-4 mb-4 oasis-rounded collapsible-header " + theme }
                            className="mt-2"
                            openedClassName="mt-2">
                            <div className="row pb-5 px-5 border-bottom">
                                <div className="col-3 mb-3">
                                    <TextInput
                                        name="title"
                                        label="Title"
                                        value={ alert.title || "" }
                                        onChange={ handleAlertChange }
                                        error={ errors.title }
                                        wrapperClass="mb-3"
                                        isRequired={ true }
                                    />
                                </div>
                                <div className="col-3 mb-3">
                                    <TextInput
                                        name="message"
                                        label="Message"
                                        value={ alert.message || "" }
                                        onChange={ handleAlertChange }
                                        error={ errors.message }
                                        wrapperClass="mb-3"
                                        isRequired={ true }
                                    />
                                </div>
                                <div className="col-3 mb-3">
                                    <label className="form-label">
                                        End Time*{ errors.endTime && <span className="text-danger field-validation-error ms-3">{ errors.endTime }</span> }
                                    </label>
                                    <br />
                                    <DatePicker
                                        name="endTime"
                                        showOneCalendar
                                        placeholder="Select a Date and Time..."
                                        format="MM/dd/yyyy, hh:mm aa"
                                        onChange={ (date) => handleEndTimeChange(date) }
                                        value={ alert.endTime && Date.parse(alert.endTime) }
                                        disabledDate={ date => isBefore(date, new Date(new Date().setHours(-1))) }
                                        hideHours={ (hour, date) => shouldHideHour(hour, date) }
                                        hideMinutes={ (minute, date) => shouldHideMinute(minute, date) }
                                    />
                                </div>
                                <div className="col-3">
                                    { notifications.length < 1 ?

                                        <div className='row'>
                                            <div className="col-12">
                                                <div className="">
                                                    <a onClick={ handleCreateClick } className="btn btn-new py-3 px-3 mt-4">Create Alert</a>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className='row'>
                                            <div className="col-6">
                                                <div>
                                                    <a onClick={ handleRemoveAlertClick } className="btn btn-new py-3 mt-4">Remove Alert</a>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div>
                                                    <a onClick={ handleEditAlertClick } className={ "btn btn-new py-3 mt-4" + (!alertInfoIsDirty() ? " disabled" : "") }>Update Alert</a>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Collapsible>
                        : ""
                    }
                    { userPermissions.some(p => p.name === "SendCommunications") ?
                        <Collapsible
                            trigger="Communications"
                            triggerTagName="h5"
                            triggerClassName={ "py-2 ps-4 mb-2 oasis-rounded collapsible-header " + theme }
                            triggerOpenedClassName={ "py-2 ps-4 mb-4 oasis-rounded collapsible-header " + theme }
                            className="mt-2"
                            openedClassName="mt-2">
                            <div className="row pb-5 px-5 border-bottom">
                                <div className="col-4 mb-3">
                                    <label className="form-label">
                                        Template*{ errors.template && <span className="text-danger field-validation-error ms-3">{ errors.template }</span> }
                                    </label>
                                    <Select
                                        name="template"
                                        placeholder="Select Email Template to Send out..."
                                        classNamePrefix="react-select"
                                        menuPosition="fixed"
                                        menuPlacement="bottom"
                                        onChange={ handleEmailTemplateChange }
                                        value={ {
                                            value: selectedTemplate.emailTemplateId,
                                            label: selectedTemplate.name
                                        } }
                                        options={ availableTemplates.map(template => ({
                                            value: template.emailTemplateId,
                                            label: template.name
                                        })) }
                                        styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                    />
                                </div>
                                <div className="col-5 mb-3">
                                    <label className="form-label">
                                        Groups*{ errors.sendComGroups && <span className="text-danger field-validation-error ms-3">{ errors.sendComGroups }</span> }
                                    </label>
                                    <Select
                                        name="sendComGroups"
                                        isMulti
                                        closeMenuOnSelect={ false }
                                        placeholder="Select Group(s) to Send Communication to..."
                                        classNamePrefix="react-select"
                                        menuPosition="fixed"
                                        menuPlacement="bottom"
                                        onChange={ handleSendComGroupsChange }
                                        value={ selectedSendComGroups.map(group => ({
                                            value: group.groupId,
                                            label: group.name
                                        })) }
                                        options={ availableSendComGroups.map(group => ({
                                            value: group.groupId,
                                            label: group.name
                                        })) }
                                        styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                    />
                                </div>
                                <div className="col-3">
                                    <div className="mb-3">
                                        <a onClick={ handleSendCommunicationClick } className="btn btn-new py-2 px-3 mt-4">Send Communication</a>
                                    </div>
                                </div>
                            </div>
                        </Collapsible>
                        : ""
                    }
                    { userPermissions.some(p => p.name === "ViewMaintenance") ?
                        <Collapsible
                            trigger="Maintenance"
                            triggerTagName="h5"
                            triggerClassName={ "py-2 ps-4 mb-2 oasis-rounded collapsible-header " + theme }
                            triggerOpenedClassName={ "py-2 ps-4 mb-4 oasis-rounded collapsible-header " + theme }
                            className="mt-2"
                            openedClassName="mt-2">
                            <div className="row pb-5 px-5 border-bottom">
                                <div className="col-3 my-auto">
                                    <CFormSwitch
                                        className={ theme }
                                        id="maintenanceModeToggle"
                                        size="xl"
                                        label={ maintenanceModeLabel }
                                        checked={ maintenanceMode === "enabled" }
                                        onChange={ handleMaintenanceModeChange }
                                    />
                                </div>
                            </div>
                        </Collapsible>
                        : ""
                    }
                    { userPermissions.some(p => p.name === "ConfigureSupportGroups") ?
                        <Collapsible
                            trigger="Support"
                            triggerTagName="h5"
                            triggerClassName={ "py-2 ps-4 mb-2 oasis-rounded collapsible-header " + theme }
                            triggerOpenedClassName={ "py-2 ps-4 mb-4 oasis-rounded collapsible-header " + theme }
                            className="mt-2"
                            openedClassName="mt-2">
                            <div className="row px-5">
                                <div className="col-8">
                                    <label className="form-label">
                                        Available Support Groups{ errors.supportGroups && <span className="text-danger field-validation-error ms-3">{ errors.supportGroups }</span> }
                                    </label>
                                    <Select
                                        name="supportGroups"
                                        isMulti
                                        closeMenuOnSelect={ false }
                                        placeholder="Select Available Support Groups..."
                                        classNamePrefix="react-select"
                                        menuPosition="fixed"
                                        menuPlacement="bottom"
                                        onChange={ handleSupportGroupsChange }
                                        value={ selectedGroups.filter(supportGroup => supportGroup.isAvailableForSelection).map(group => ({
                                            value: group.groupId,
                                            label: group.name
                                        })) }
                                        options={ groups.map(group => ({
                                            value: group.groupId,
                                            label: group.name
                                        })) }
                                        styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                    />
                                </div>
                                <div className="col-4 mt-auto">
                                    <div onClick={ handleSaveClick } className="btn btn-new py-2 px-3">Save</div>
                                </div>
                            </div>
                        </Collapsible>
                        : ""
                    }
                </div>
            </div>
            <ConfirmationModal
                title="Enable Maintenance Mode Confirmation"
                id="enable-maintenance-mode-modal"
                displayValue={ "Enabling Maintenance Mode will log off any current users, as well as prevent any new login attempts while Maintenance Mode is active. Do you wish to proceed?" }
                onSubmit={ handleEnableMaintenanceModeConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Disable Maintenance Mode Confirmation"
                id="disable-maintenance-mode-modal"
                displayValue={ "Disabling Maintenance Mode will allow all users to log in. Do you wish to proceed?" }
                onSubmit={ handleDisableMaintenanceModeConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Send Communication Confirmation"
                id="send-communication-modal"
                displayValue={ "This will send the <b>" + selectedTemplate.name + "</b> email to all users in these groups:<br />" + selectedSendComGroups.map(group => "<b>" + group.name + "</b><br />").toString().replace(',', '') + "<br />Do you wish to proceed?" }
                onSubmit={ handleSendOutCommunicationConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Create System Wide Alert"
                id="create-alert-modal"
                displayValue={ "This will create a system wide alert that all users will see. Do you wish to proceed?" }
                onSubmit={ handleCreateConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Remove System Wide Alert"
                id="remove-alert-modal"
                displayValue={ "This will remove the system wide alert that all users are currently seeing. Do you wish to proceed?" }
                onSubmit={ handleRemoveConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Edit System Wide Alert"
                id="edit-alert-modal"
                displayValue={ "This will edit the system wide alert that all users are currently seeing. Do you wish to proceed?" }
                onSubmit={ handleEditConfirmed }
                theme={ theme }
            />
            <ConfirmationModal
                title="Update Available Support Groups Confirmation"
                id="update-available-support-groups-modal"
                displayValue={ "This will update the available support groups for selection during a partner edit. Do you wish to proceed?" }
                onSubmit={ handleUpdateConfirmed }
                theme={ theme }
            />
        </>
    )
}

export default SettingsPage;