import * as types from "./types";
import * as userPreferencesService from "../../services/userPreferencesService";

export function loadUserPreferencesSuccess(userPreferences) {
    return { type: types.LOAD_USER_PREFERENCES_SUCCESS, userPreferences }
}

export function saveUserPreferenceSuccess(userPreference) {
    return { type: types.SAVE_USER_PREFERENCE_SUCCESS, userPreference }
}

export function loadUserPreferences() {
    return function (dispatch) {
        return userPreferencesService.getUserPreferences()
            .then(userPreferences => {
                dispatch(loadUserPreferencesSuccess(userPreferences));
            })
            .catch(error => {
                throw error;
            });
    }
}

export function saveUserPreference(userPreference) {
    return function (dispatch) {
        return userPreferencesService.saveUserPreference(userPreference)
            .then(savedUserPreference => {
                dispatch(saveUserPreferenceSuccess(savedUserPreference));
            }).catch(error => {
                throw error;
            })
    }
}