import * as types from '../actions/types';
import initialState from './InitialState';

export default function fileTransferReducer(state = initialState.fileTransfer, action) {
    switch (action.type) {
        case types.LOAD_AVAILABLE_PORTALS_SUCCESS:
            return { ...state, availablePortals: action.portals.sort(sortPortals) };
        default:
            return state;
    }
}

function sortPortals(portalA, portalB) {
    var x = portalA.name.toLowerCase();
    var y = portalB.name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
}