import React, { useState } from 'react';
import { toast } from 'react-toastify';
import TextInput from '../../../../common/TextInput';
import { useEffect } from 'react';
import Select from 'react-select';
import * as bootstrap from 'bootstrap';
import * as previewResourcesActions from '../../../../../redux/actions/previewResourcesActions';
import * as partnersActions from '../../../../../redux/actions/partnersActions';
import * as styles from '../../../../common/DropdownStyles';
import { useDispatch, useSelector } from 'react-redux';
import ResourceCropper from './ResourceCropper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function ResourceEdit({ theme }) {

    const [resource, setResource] = useState({});
    const [resourceIndex, setResourceIndex] = useState(0);
    const [errors, setErrors] = useState({});
    const [isAdded, setIsAdded] = useState(false);
    const [isSelectedFileValid, setIsSelectedFileValid] = useState();
    const [inputImageValue, setInputImageValue] = useState();

    const selectedResource = useSelector(state => state.selectedResource);
    const previewResources = useSelector(state => state.previewResources);
    const userPermissions = useSelector(state => state.auth.permissions);
    const partners = useSelector(state => state.partners);
    const enabledPartners = partners.filter(p => p.disabled === false);
    const selectAllOption = {
        value: "All",
        label: "All"
    };

    useEffect(() => {
        if (userPermissions.some(p => p.name === "ViewPartners")) {
            dispatch(partnersActions.loadPartners()).catch(error => {
                toast.error("Loading Partners Failed" + error, { autoClose: false });
            });
        }

    }, [])

    const dispatch = useDispatch();

    useEffect(() => {
        setIsAdded(false);
        setResource(selectedResource);
        setResourceIndex(previewResources.indexOf(selectedResource));
        if (selectedResource.contentTitle === "") {
            setIsAdded(true);
        }

        //if contentThumbnail is empty clear value of input 
        if (selectedResource.contentThumbnailUrl === '') {
            setInputImageValue('');
        } else {
            setIsSelectedFileValid(true);
        }
    }, [selectedResource])

    const handleResourceCheck = (event) => {
        event.preventDefault();
        let updatedResources = [...previewResources];
        if (!resourceIsValid()) {
            return;
        }
        updatedResources.splice(resourceIndex, 1, resource);
        dispatch(previewResourcesActions.updatePreviewResources(updatedResources));
        bootstrap.Modal.getInstance(document.getElementById('resource-edit-modal')).hide();
    }

    const resourceIsValid = () => {

        const errors = {};

        if (resource.contentTitle === "") {
            errors.contentTitle = "(Required)";
        }

        if (resource.contentThumbnailUrl === "" || !isSelectedFileValid) {
            errors.contentThumbnailUrl = "(Required)";
        }

        if (resource.description === "") {
            errors.description = "(Required)";
        }

        if (resource.partners.length === 0 && resource.allPartnersSelected === false) {
            errors.partners = "(Required)";
        }

        setErrors(errors);

        return Object.keys(errors).length === 0;
    }

    const handleResourceChange = (event) => {
        let { name, value } = event.target;
        let editedResource = { ...resource };
        if (name == 'contentTitle') {
            editedResource.contentTitle = value;
            setResource(editedResource);
        } else if (name == 'siteLink') {
            editedResource.siteLink = value;
            setResource(editedResource);
        } else if (name == 'description') {
            editedResource.description = value;
            setResource(editedResource);
        }
    }

    const handleResourceImageChange = (event) => {

        var file = event.target.files[0];
        let { value } = event.target;
        let editedResource = { ...resource };

        //file type not an image 
        if (file && !file.type.includes("image")) {
            toast.error("The file type is not supported. Please choose an image.", { autoClose: false });
            setIsSelectedFileValid(false);
            return event.preventDefault();
        }

        setIsSelectedFileValid(true);

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            var imageStr = reader.result;
            editedResource.contentThumbnailUrl = value;
            editedResource.contentImg = imageStr;
            setResource(editedResource);
            setInputImageValue('');
            showCropModal();
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    const showCropModal = () => {
        new bootstrap.Modal(document.getElementById('resource-cropper-modal'), { backdrop: 'static', keyboard: false }).show();
    }

    const hideCropModal = () => {
        bootstrap.Modal.getInstance(document.getElementById('resource-cropper-modal')).hide();
    }

    const handlePartnerResourceChange = (values) => {
        var selectedPartners = partners.filter(x => values.some(y => x.partnerId === y.value));
        var everyPartnerSelected = enabledPartners.every(x => values.some(y => y.value === x.partnerId));
        var allOptionSelected = values.some(x => x.value == "All");

        //prevent update of last All partners resource
        if (previewResources.filter(x => x.allPartnersSelected).length == 1 && resource.allPartnersSelected == true) {
            toast.warning("Atleast 1 resource must have 'All Partners' selected.")
            return;
        }

        //if every partner selected, override to the all partner option
        if (everyPartnerSelected) {
            let editedResource = { ...resource, partners: enabledPartners };
            editedResource.allPartnersSelected = true;
            setResource(editedResource);
            return;
        }

        if (allOptionSelected) {
            if (values.length > 1) {
                //if first selection wasnt all but was later selected  
                if (values[0].value != "All") {
                    let editedResource = { ...resource, partners: enabledPartners };
                    editedResource.allPartnersSelected = true;
                    setResource(editedResource);
                    return;
                }

                //if first option is the all option then replace with selected partner
                let editedResource = { ...resource, partners: selectedPartners };
                editedResource.allPartnersSelected = false;
                setResource(editedResource);
                return;
            }

            //if all option is the only option selected
            let editedResource = { ...resource, partners: enabledPartners };
            editedResource.allPartnersSelected = true;
            setResource(editedResource);
            return;
        }

        //set selectedPartners
        let editedResource = { ...resource, partners: selectedPartners };
        editedResource.allPartnersSelected = false;
        setResource(editedResource);
    }

    const handleResourceEditClose = () => {
        if (isAdded === true) {
            let updatedResources = [...previewResources];
            for (let i = 0; i < (updatedResources.length); i++) {
                updatedResources[i].sortOrder = updatedResources[i].sortOrder - 1;
            }
            updatedResources.shift();
            dispatch(previewResourcesActions.updatePreviewResources(updatedResources));
        }
        setErrors({});
    }

    const getPartnerOptions = () => [selectAllOption, ...enabledPartners.map(partner => ({
        value: partner.partnerId,
        label: partner.name
    }))]

    const onCropperSave = (croppedImg) => {
        if (croppedImg) {
            let editedResource = { ...resource };
            editedResource.contentImg = croppedImg;
            setResource(editedResource);
        }
        hideCropModal();
    }

    return (
        <>
            <div className="modal fade" tabindex="-1" role="dialog" id='resource-edit-modal' >
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className={ "modal-content " + theme }>
                        <form onSubmit={ handleResourceCheck }>
                            <div className="modal-header">
                                <h2 className="modal-title">{ isAdded === false ? "Edit Resource" : "New Resource" }</h2>
                                <a onClick={ handleResourceEditClose } className="close" data-bs-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon="fas fa-times" />
                                </a>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid h-100">
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput
                                                name="contentTitle"
                                                label="Card Title*"
                                                value={ resource.contentTitle }
                                                onChange={ handleResourceChange }
                                                error={ errors.contentTitle }
                                                maxlength="35"
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label className="form-label">Partner(s)*{ errors.partners && <span className="text-danger field-validation-error ms-3">{ errors.partners }</span> }</label>
                                            <Select
                                                type="text"
                                                isSearchable
                                                isMulti
                                                closeMenuOnSelect={ false }
                                                classNamePrefix="react-select"
                                                name="partners"
                                                value={ resource.allPartnersSelected ? [selectAllOption]
                                                    : resource.partners?.map(partner => ({
                                                        value: partner.partnerId,
                                                        label: partner.name
                                                    })) }
                                                onChange={ handlePartnerResourceChange }
                                                menuPosition="fixed"
                                                menuPlacement="bottom"
                                                options={ getPartnerOptions() }
                                                styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            <TextInput
                                                name="siteLink"
                                                label="Site Link"
                                                value={ resource.siteLink }
                                                onChange={ handleResourceChange }
                                                error={ errors.siteLink }
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-9 mt-4">
                                            <label className="form-label" htmlFor="contentThumbnailUrl">
                                                { "Content Image*" }{ errors.contentThumbnailUrl && <span className="text-danger field-validation-error ms-3">{ errors.contentThumbnailUrl }</span> }
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="file"
                                                accept="image/*"
                                                name="contentThumbnailUrl"
                                                className="form-control"
                                                onChange={ handleResourceImageChange }
                                                id="contentThumbnailUrl"
                                                value={ inputImageValue }
                                            />
                                        </div>
                                        <div className="col m-auto">
                                            { resource.contentImg ? <img className={ "resource-image " + theme } src={ resource.contentImg } /> : <div className={ "resource-image " + theme }/> }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <TextInput
                                                textArea
                                                name="description"
                                                label="Description*"
                                                value={ resource.description }
                                                onChange={ handleResourceChange }
                                                error={ errors.description }
                                                maxlength="400"
                                                rows="3"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div id="required-disclaimer" className="col">
                                    <p className="ms-3">
                                        <span className="align-bottom">*</span><span className="small align-top"> Required</span>
                                    </p>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <input type="submit" value="Done" className="btn btn-new btn-block" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div >
                </div >
            </div >
            <ResourceCropper resource={ resource } onCropperSave={ onCropperSave } theme={ theme } />
        </>
    )
}

export default ResourceEdit;