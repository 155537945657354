import axios from 'axios'
import { config } from '../config';

export async function updateRequired(roleInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/role/update-required', roleInfo);
    return response.data;
}

export async function getRole(roleId) {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/role/' + roleId).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function getRoles() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/role');
    return response.data;
}

export async function deleteRole(roleId) {
    const response = await axios.delete(config.OASIS_PORTAL_URL + 'api/role/' + roleId).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function saveRole(roleInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/role', roleInfo).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data
}