import * as types from '../actions/types';
import initialState from './InitialState';

export default function settingsReducer(state = initialState.settings, action) {
    switch (action.type) {
        case types.UPDATE_DATALIMIT_SETTING:
            return { ...state, [action.recordType]: { ...state[action.recordType], dataLimit: action.setting } };
        case types.UPDATE_SORTBY_SETTING:
            return { ...state, [action.recordType]: { ...state[action.recordType], sortBy: action.setting } };
        case types.UPDATE_FILTER_SETTING:
            return { ...state, [action.recordType]: { ...state[action.recordType], filters: action.setting } };
        default:
            return state;
    }
}