import React from 'react'
import * as pageActions from '../../redux/actions/pageActions';
import * as relativityStatsActions from '../../redux/actions/relativityStatsActions'
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import initialState from '../../redux/reducers/InitialState.js';
import Select from 'react-select';
import * as styles from '../common/DropdownStyles';
import { toast } from 'react-toastify';

function ManageWidgetsModal({ theme }) {  
    const layout = useSelector(state => state.page.home.dashboardLayout);
    const availableWidgets = useSelector(state => state.page.home.availableWidgets);
    const relativityStats = useSelector(state => state.relativityStats);
    const availableRelativityInstances = useSelector(state => state.relativityStats.instances);
    const selectedRelativityInstances = useSelector(state => state.relativityStats.selectedRelativityInstances);
    const defaultWidgets = initialState.page.home.dashboardLayout;//initial widget layout
    
    const dispatch = useDispatch();

    const onWidgetChange = (event) => {
        var editedWidgets = [];
        event.forEach(( widget ) => {
            if(layout.lg.some( x => x.i == widget.value)){
                //keep formatting for existing widgets
                var currentLayoutWidget = layout.lg.find(x => x.i == widget.value);
                editedWidgets.push(currentLayoutWidget);
            }
            else{
                //use widgets from default widgets when adding new
                var defaultWidget = defaultWidgets.lg.find(x => x.i == widget.value);
                editedWidgets.push(defaultWidget);
            }
        })

        var editedLayout = {...layout, lg: editedWidgets};
        dispatch(pageActions.updateDashboardLayout(editedLayout));
    }

    const handleRelativityInstanceChange = (event) => {
        var instances = event.map(instance => ({
            relativityInstanceId: instance.value,
            friendlyName: instance.label
        }));

        dispatch(relativityStatsActions.saveUserRelativityInstances(instances)).catch(error => {
            toast.error("Saving User Relativity Instance Failed:" + error, { autoClose: false })
        });
    }

    return (
        <div className="modal fade" tabindex="-1" role="dialog" id='manage-widgets-modal' >
            <div className="modal-dialog modal-lg" role="document">
                <div className={ "modal-content " + theme }>
                    <div className="modal-header bottom-border">
                        <h2 className="modal-title">Dashboard Settings</h2>
                        <a className="close" data-bs-dismiss="modal" aria-label="Close">
                            <FontAwesomeIcon icon="fas fa-times" />
                        </a>
                    </div>
                    <div className="modal-body p-0">
                        <div className="container-fluid">
                            <div className='row my-3'>
                                <label className="form-label">
                                    Relativity Instances
                                </label>
                                <Select
                                    type="text"
                                    isSearchable
                                    classNamePrefix="relativity-select"
                                    placeholder="Please Select a Relativity Instance(s)"
                                    isMulti
                                    closeMenuOnSelect={ false }
                                    name="relativityInstancesSelect"
                                    value={ selectedRelativityInstances?.map(relativityInstance => ({
                                        value: relativityInstance.relativityInstanceId,
                                        label: relativityInstance.friendlyName
                                    })) }
                                    onChange={ handleRelativityInstanceChange }
                                    menuPosition="fixed"
                                    menuPlacement="bottom"
                                    options={ availableRelativityInstances.map(relativityInstance => ({
                                        value: relativityInstance.relativityInstanceId,
                                        label: relativityInstance.friendlyName
                                    })) }
                                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                />           
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <label className="form-label">
                                        Available Widgets
                                    </label>
                                    <Select
                                        type="text"
                                        isSearchable
                                        classNamePrefix="manage-widgets-select"
                                        placeholder="Please Select Widget(s)"
                                        isMulti
                                        closeMenuOnSelect={ false }
                                        name="manageWidgetsSelect"
                                        value={ layout.lg?.map(widget => ({
                                            value: widget.i,
                                            label: widget.i
                                        })) }
                                        onChange={ onWidgetChange }
                                        menuPosition="fixed"
                                        menuPlacement="bottom"
                                        options={ availableWidgets?.map(widget => ({
                                            value: widget.widgetName,
                                            label: widget.widgetName
                                        })) }
                                        styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                                    />           
                                </div>                               
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManageWidgetsModal;