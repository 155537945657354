import React from 'react'
import { signinRedirect } from '../../services/userService'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Login() {
  const user = useSelector(state => state.auth.user)

  return (
    (user) ?
      (<Navigate to={'/'} />)
      :
      (
        signinRedirect()
      )
  )
}

export default Login
