import * as types from "./types";
import * as servicesService from "../../services/servicesService";

export function loadAuthServicesSuccess(services) {
    return { type: types.LOAD_AUTH_SERVICES_SUCCESS, services }
}

export function loadPartnerServicesSuccess(services) {
    return { type: types.LOAD_PARTNER_SERVICES_SUCCESS, services }
}

export function loadAuthServices() {
    return function (dispatch) {
        return servicesService.getAuthServices()
            .then(services => {
                dispatch(loadAuthServicesSuccess(services));
            })
            .catch(error => {
                throw error;
            });
    };
}

export function loadPartnerServices() {
    return function (dispatch) {
        return servicesService.getPartnerServices()
            .then(services => {
                dispatch(loadPartnerServicesSuccess(services));
            })
            .catch(error => {
                throw error;
            });
    };
}