import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function KnowledgeBaseExpandableChapter({ chapter, type, handleSelectedContentClicked }) {

    const selectedContent = useSelector(state => state.page.kb.selectedContent);

    const [isExpanded, setIsExpanded] = useState(false);

    const handleRowExpand = () => {
        setIsExpanded(isExpanded => !isExpanded);
    }

    useEffect(() => {
        if (selectedContent) {
            setIsExpanded(selectedContent.chapterId === chapter.contentId || (selectedContent.contentId === chapter.contentId && type === "chapter"));
        }
    }, [selectedContent])

    return (
        <>
            { chapter.pages.length > 0 ?
                <div className="kb-chapter-selection ms-3 me-2 mb-1">
                    <div className="d-flex flex-row clickable" onClick={ () => handleRowExpand() }>
                        <div className="col-md-11">
                            <strong><Link onClick={ () => handleSelectedContentClicked() } className={ chapter.contentId === selectedContent.contentId && type === "chapter" ? "selected" : "" } to={ "/KnowledgeBase/chapter/" + chapter.contentId + "/" + encodeURIComponent(chapter.slug) }> { chapter.name }</Link></strong>
                        </div>
                        <div className="col-md-1 text-end" >
                            <FontAwesomeIcon className="my-auto" icon={ "fa-solid fa-angle-" + (isExpanded ? "down" : "right ") } />
                        </div>
                    </div>
                    { isExpanded &&
                        <>
                            { chapter.pages.map((page) => (
                                <div className='ms-3 me-2 mb-1' key={ page.pageId }>
                                    <Link onClick={ () => handleSelectedContentClicked() } className={ page.pageId === selectedContent.contentId && type === "page" ? "selected" : "" } to={ "/KnowledgeBase/page/" + page.pageId + "/" + encodeURIComponent(page.slug ? page.slug : page.name) }> { page.name }</Link>
                                </div>
                            )
                            ) }
                        </>
                    }
                </div>
                : "" }
        </>
    )
};

export default KnowledgeBaseExpandableChapter;