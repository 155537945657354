import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as settingsActions from '../../../redux/actions/settingsActions';
import * as styles from '../DropdownStyles';
import Select from 'react-select';
import { CTableDataCell } from '@coreui/bootstrap-react';

function MultiselectFilter({ RecordType, Filter, theme }) {

    const filters = useSelector(state => state.settings[RecordType].filters);
    const filterSettings = useSelector(state => state.settings[RecordType].filters[Filter.filterIdentifier]);
    const users = useSelector(state => state.audits).map(audit => (audit.userEmail));
    const actions = useSelector(state => state.audits).map(audit => (audit.action));
    const types = useSelector(state => state.audits).map(audit => (audit.actedUpon));
    const names = useSelector(state => state.audits).map(audit => (audit.name));
    const partners = useSelector(state => state.audits).map(audit => (audit.partnerName));
    const tams = useSelector(state => state.partners).map(partner => (partner.tams.map(tam => tam.firstName + " " + tam.lastName)));
    const sams = useSelector(state => state.partners).map(partner => (partner.sams.map(sam => sam.firstName + " " + sam.lastName)));
    const availableActions = [...new Set(actions)];
    const availableTypes = [...new Set(types)];
    const availableNames = [...new Set(names)];
    const availableUsers = [...new Set(users)];
    const availablePartners = [...new Set(partners)];
    const availableTams = [...new Set(tams.flat())];
    const availableSams = [...new Set(sams.flat())];
    const dispatch = useDispatch();

    const available = () => {
        switch (Filter.filterIdentifier) {
            case "actions":
                return availableActions;
            case "types":
                return availableTypes;
            case "partners":
                return availablePartners;
            case "names":
                return availableNames;
            case "users":
                return availableUsers;
            case "tams":
                return availableTams;
            case "sams":
                return availableSams;
        }
    }

    const handleMultiSelectChange = (event) => {
        filterSettings.value = event.map(e => ({
            value: e.value,
            label: e.label
        }))
        if (filterSettings.value.length > 0) {
            filterSettings.isActive = true;
        } else {
            filterSettings.isActive = false;
        }
        dispatch(settingsActions.updateFilterSetting(RecordType, filters));
    }

    const handleValue = () => {
        filterSettings.value.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase()) ? 1 : -1);
        return filterSettings.value.map(x => ({
            value: x.value,
            label: x.label
        }));
    }

    const handleOptions = () => {
        let sortedOptions = available().sort((a, b) => (a.toLowerCase() > b.toLowerCase()) ? 1: -1);
        return sortedOptions.map(x => ({
            value: x,
            label: x
        }));
    }

    return (
        <>
            <CTableDataCell colSpan="3">
                <Select
                    name="multiSelectFilter"
                    classNamePrefix="react-select"
                    isMulti
                    closeMenuOnSelect={ false }
                    placeholder={ `Select ${Filter.label}...` }
                    menuPosition="fixed"
                    menuPlacement="bottom"
                    onChange={ handleMultiSelectChange }
                    value={ handleValue() }
                    options={ handleOptions() }
                    styles={ theme == 'light' ? styles.MultiSelectStyleLight : styles.MultiSelectStyleDark }
                />
            </CTableDataCell>
        </>
    )
}

export default MultiselectFilter;