import axios from 'axios'
import { config } from '../config';

export let getPartnerAbortController = [];

export async function updateRequired(partnerInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/partner/update-required', partnerInfo);
    return response.data;
}

export async function getPartner(partnerId) {
    getPartnerAbortController[partnerId] = new AbortController();
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/partner?partnerId=' + partnerId, { signal: getPartnerAbortController[partnerId].signal }).catch(error => {
        if (error.message != "canceled" && error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function getPartners() {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/partner/list');
    return response.data;
}

export async function disablePartner(partnerId) {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/partner/disable?partnerId=' + partnerId).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function enablePartner(partnerId) {
    const response = await axios.get(config.OASIS_PORTAL_URL + 'api/partner/enable?partnerId=' + partnerId).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data;
}

export async function savePartner(partnerInfo) {
    const response = await axios.post(config.OASIS_PORTAL_URL + 'api/partner/save', partnerInfo).catch(error => {
        if (error.response.data.error) {
            throw error.response.data.error.message;
        } else {
            throw error
        }
    });
    return response.data
}