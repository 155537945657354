import React from 'react';
import { CTableRow, CTableDataCell } from '@coreui/bootstrap-react';
import * as dateUtils from '../../../utils/dateUtils';

function UserStatusRow({ data }) {
    return (
        <>
            <CTableRow key={ data.userId } >
                <CTableDataCell>{ data.emailAddress }</CTableDataCell>
                <CTableDataCell>{ data.firstName }</CTableDataCell>
                <CTableDataCell>{ data.lastName }</CTableDataCell>
                <CTableDataCell>{ data.partner }</CTableDataCell>
                <CTableDataCell>{ dateUtils.getLocalDateTime(data.login) }</CTableDataCell>
                <CTableDataCell>{ dateUtils.getLocalDateTime(data.lastAction) }</CTableDataCell>
            </CTableRow>
        </>
    )
};

export default UserStatusRow;